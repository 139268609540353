import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import { Stack } from "@mantine/core";
import styled from "@emotion/styled";
import { fontBlack } from "constants/colors";
import TextArea from "ui/inputs/Textarea";
import Button from "ui/buttons/Button";
import { PrivateContext, UserContext } from "context/state";
import { useForm } from "@mantine/form";
import Checkbox from "ui/inputs/Checkbox";

interface AboutProps {
  show?: boolean;
  onHide?: any;
  getData?: any;
  passData?: any;
  title?: string;
  setAddClickedFor?: any;
}
const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-top: 26px;
  color: ${fontBlack};
  max-width: 298px;
`;

const StyledCheckBoxWrapper = styled.div`
  display: flex;
  margin: 27px 0 267px 0;
  flex-direction: column;
  //gap: 39px;
  padding-bottom: 18px;
`;

const AddButtonWrapper = styled.div`
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  left:0;
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  z-index: 2000;
  text-align: center;
`;
const EUCitizenDrawer: React.FC<AboutProps> = ({
  show,
  onHide,
  title,
  setAddClickedFor,
}) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const { setEUCitizen } = useContext(PrivateContext);
  let initialValue = userData?.citizen!="" ? userData?.citizen :"107";
  const form = useForm({
    initialValues: {
      citizen: initialValue,
    },
  });

  const handleAboutSubmit = (values: any) => {
    setEUCitizen(values?.citizen);
    setAddClickedFor();
  };
  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        //title={title ? title : 'Title'}
        setShow={onHide}
        showSearch={false}
        //clearAll={() => true}
        showClear={false}
        //showFilter_Clear={2}
        buttonText={t(`Add`)}
        //resetFilter={setRestData} // Reseting the Data
        //setAddElements={setAddElements}
      >
        <Title>{t("EU_citizen")}</Title>
        <form onSubmit={form.onSubmit((values) => handleAboutSubmit(values))}>
          <StyledCheckBoxWrapper>
            <div style={{ paddingRight: "39px" }}>
              <Checkbox
                checked={form?.values?.citizen == "106" ? true : false}
                label={t("Yes")}
                onChange={(event) => {
                  form.setFieldValue(
                    "citizen",
                    event.currentTarget.checked ? "106" : "107"
                  );
                }}
              />
            </div>
            <Checkbox
              checked={form?.values?.citizen == "107" ? true : false}
              label={t("No")}
              onChange={(event) => {
                form.setFieldValue(
                  "citizen",
                  event.currentTarget.checked ? "107" : "106"
                );
              }}
            />
          </StyledCheckBoxWrapper>
          <AddButtonWrapper>
            <Button
              type="submit"
              variant="filled"
              children={t("Save")}
              width="335px"
              height="54px"
            />
          </AddButtonWrapper>
        </form>
      </SearchAndSelectAbstraction>
    </Stack>
  );
};

export default EUCitizenDrawer;
