export const TRANSLATIONS_NO = {
  Find_the_Perfect_Job_Match: "Finn den perfekte jobbmatchen",
  Providing_the_best_solutions_to_find_the_people_you_need_to_take_your_business_to_the_next_level:
    "Tilbyr de beste løsningene for å få menneskene du trenger til å drive virksomheten til neste nivå.",
  I_am: "Jeg",
  Yes: "Ja",
  No: "Nei",
  Search: "Søk",
  Type_of_work: "Velg type jobb du søker",
  Driving_License: "Førerkort",
  Select_Sec_lang: "Velg andre språk du snakker",
  Select_Pri_lang: "Morsmål",
  select_the_below_option: "(Velg et alternativ nedenfor)",
  Looking_to_Hire: "Arbeidsgiver",
  Looking_for_Work: "Arbeidssøker",
  Country_you_like: "Hvilke land ønsker du å jobbe i",
  Login: "Innlogging",
  Back: "Tilbake",
  Age: "Alder",
  Email_Address: "E-postadresse",
  Password: "Passord",
  Already_Have_Account_Log_In: "Har du allerede en konto? ",
  Forgot_Password: "Glemt passordet?",
  Log_in: "Logg inn",
  Continue: "Fortsett",
  Or_Continue_with: "Eller fortsett med",
  New_User_Create_Account: "Ny bruker? Opprett konto",
  Create_Account: "",
  We_provide_the_best_solution_to_fill_the_workplace_with_the_right_people:
    "Kobler jobbsøkere og arbeidsgivere på minutter!",
  Send_Email: "Send e-post",
  Check_your_email: "Sjekk e-posten din",
  We_have_sent_password_recovery_instructions_to_your_email:
    "Vi har sendt instruksjoner for gjenopprettelse av passord til e-postadressen din",
  Okay: "Ok",
  Create_New_Password: "Opprett nytt passord",
  Your_new_password_must_be_different_from_your_previous_passwords: "Nytt passord må være forskjellig fra det gamle passordet.",
  Reset_Login: "Tilbakestill og logg inn",
  Confirm_Password: "Bekreft passord",
  Create_an_Account: "Opprett en konto",
  Phone: "Telefonnummer",
  Verify_Phone: "Bekreft telefonnummer",
  Please_enter_the_verification_code_sent_to: "Skriv inn engangskoden du har mottatt på SMS",
  Didnt_receive_the_code: "Har du ikke mottatt koden? ",
  Resend: "Send på nytt",
  Verify: "Bekreft",
  Please_Wait_Verifying_OTP: "Vent på bekreftelse av OTP",
  Your_mobile_number_is_verified: "Mobilnummeret er bekreftet!",
  Verify_Email: "Bekreft e-postadresse",
  We_have_sent_an_email: "Vi har sendt en e-post til ",
  We_have_sent_an_email1: "",
  Please_click_the_link_to_verify:
    ". Vennligst klikk på linken for å verifisere at dette er din e-postadresse. Husk å sjekk spam.",
  Proceed: "Fortsett",
  Didnt_receive_the_link: "Har du ikke mottatt lenken? ",
  Congratulations: "Gratulerer!",
  You_are_now_ready_to_find_you_first_candidates:
    "Nå er du klar til å finne de første kandidatene!",
  Filter: "Filter",
  Refine_your_search_with_extra_filters_to_pinpoint_the_people_you_need:
    "Juster søket ditt med ekstra filtre for å finne de riktige menneskene du trenger.",
  Favourites: "Favoritter",
  Find_the_talent_you_have_saved_organize_them_and_hire:
    "Finn lagrede kandidater, organiser og ansett!",
  Chat: "Meldinger",
  Find_all_your_messages_in_one_place:
    "Finn alle meldingene dine på ett sted. Svar og planlegg et møte!",
  Start_Filtering: "Start filtrering",
  Reset_Filter: "Nullstill filter",
  Start_by_filtering_your_requirements: "Begynn med å filtrere for å finne de riktige kandidatene",
  Where_is_the_job_located: "Hvor er jobben lokalisert?",
  Employees_needed_from_which_industry: "Hvilken bransje trengs det ansatte fra?",
  What_position_do_you_need_to_fill: "Hvilken stilling trenger du noen til?",
  Required_language: "Påkrevd språk",
  When_should_the_position_be_filled: "Når må stillingen tiltres?",
  Drivers_license_required: "Er det påkrevd med førerkort?",
  Apply_filter: "Bruk filter",
  Clear_All: "Tøm alle",
  What_industry_do_you_seek_employees: "Til hvilken bransje ser du etter ansatte?",
  Add: "Legg til",
  What_position_need_to_beemployed: "Hvilken stilling må fylles?",
  Required_drivers_license: "Nødvendige førerkort",
  What_position_need_to_be_employed: "Hvilken stilling må fylles",
  Home: "Hjem",
  Message: "Melding",
  Settings: "Innstillinger",
  Welcome: "Velkommen",
  Filter_By: "Filtrer etter",
  years: "år",
  Primary_language: "Primærspråk",
  Experience: "Arbeidserfaring",
  Country: "Land",
  Verified: "Bekreftet",
  Info_Experience: "Om",
  Secondary_languages: "Sekundærspråk",
  Secondary_languages_no_data: "Sekundærspråk er ikke tilgjengelig",
  License_no_data: "Førerkort ikke tilgjengelig",
  Drivers_license: "Førerkort",
  Notice_Period: "",
  Availability: "Tilgjengelighet",
  CV: "CV",
  Send_CV_on_Email: "Send CV på e-post",
  Download_CV: "Last ned CV",
  References_available: "Tilgjengelige referanser",
  Maria_can_provide_references_upon_request: " kan gi referanser ved forespørsel.",
  Chat_Now: "Chat nå",
  Start_talking: "Begynn å snakke!",
  To_chat_with_name: "Velg et abonnement for å chatte med",
  please_select_subscription: "!",
  Select_subscription: "Velg abonnement",
  Add_to_favourites: "Legg til favoritter!",
  to_add: "Du må velge et abonnement for å kunne legge",
  to_fav: "i favoritter!",
  Edit_Profile: "Rediger profil",
  Password_and_securtity: "Passord og sikkerhet",
  Billing_and_payments: "Fakturering og betalinger",
  FAQ: "Spørsmål og svar",
  FAQs: "Spørsmål og svar",
  Email_Notifications: "E-postvarsler",
  Logout: "Logg ut",
  Support: "Kundeservice",
  Get_in_touch_contact: "Ta kontakt med",
  Privacy_Policy: "Retningslinjer for personvern",
  Terms_of_use: "Bruksvilkår",
  Current_Password: "Gjeldende passord",
  Update_Password: "Oppdater passord",
  Billing_Details: "Faktureringsopplysninger",
  Invoice: "Faktura",
  Subscription_Details: "Abonnementsopplysninger",
  Continue_to_Checkout: "Fortsett til betalingssiden",
  Disable_subscription: "Deaktiver abonnement",
  month: "måned",
  Expiration_date: "Utløpsdato",
  Transactions: "Transaksjoner",
  Lite_Subscription_paid: "Lite-abonnement betalt",
  Transaction_ID: "Transaksjons-ID:",
  Invoice_Details: "Fakturaopplysninger",
  Invoice_no: "Fakturanummer:",
  Paid: "Betalt",
  Billing_Address: "Fakturaadresse",
  Amount_Paid: "Betalt beløp",
  Notifications: "Varsler",
  send_you_message: "Richard Maxwell har sendt deg en melding",
  _Days_Ago: "To dager siden",
  Subscription: "Abonnement",
  Choose_a_plan: "Velg en plan",
  Currency: "Valuta",
  Contact_Us: "Kontakt oss",
  Or: "Eller",
  Name: "Navn",
  Email: "E-postadresse",
  Company_name: "Firmanavn",
  Submit: "Send inn",
  Address: "Adresse",
  Billing_email: "E-postadresse for fakturering",
  City: "By/sted",
  Zip: "Postnummer",
  Different_billing_information: "Andre fakturaopplysninger",
  I_accept_the_Terms_and_Conditions_for_using_Jobpark: "Jeg godtar vilkårene for å bruke Jobpark",
  Place_order: "Legg inn bestilling",
  Payment: "Betaling",
  Are_you_Sure: "Er du sikker?",
  Are_you_sure_you_want_to_disable_the_subscriptions:
    "Er du sikker på at du vil deaktivere ditt abonnement",
  Confirm: "Bekreft",
  Cancel: "Avbryt",
  Complete_company_profile: "Fullfør bedriftsprofil",
  Company_logo: "Bedriftslogo (valgfritt)",
  _300_x_300_pixels_for_best_quality:
    "300 x 300 piksler for beste kvalitet. JPG, JPEG eller PNG er støttet.",
  Social_Media_Links: "Lenker til sosiale medier",
  Facebook_Link: "Facebook-lenke",
  Twitter_Link: "Twitter-lenke",
  Linkedin_Link: "LinkedIn-lenke",
  Instagram_Link: "Instagram-lenke",
  Select_your_industry: "Velg bransje",
  Link_to_website: "Lenke til nettsted",
  Description: "Beskrivelse",
  Save: "Lagre",
  Skip: "Hopp over",
  location: "Velg land",
  Invite_2_New_Users_: "Inviter to nye brukere - GRATIS",
  Add_New_User: "Legg til ny bruker",
  A_notification_will_be_send_to_the_users_email_address:
    "Det sendes et varsel til brukerens e-postadresse",
  Type_a_message: "Skriv en melding",
  No_Conversation_yet: "Ingen samtale ennå!",
  We_will_let_you_know_when_someone_needs_your_help:
    "Vi gir deg beskjed når noen trenger hjelp fra deg",
  Available: "Tilgjengelig",
  Type_of_position: "Ønsket stilling",
  Name_SM: " på sosiale medier",
  Check: "Se ",
  CV_is_Forwarded_to_Email: "CV er videresendt til e-post",
  CV_is_Downloaded: "CV er lastet ned",
  Link: "Lenke",
  Social_media: "Sosiale medier",
  An_email_has_been_sent:
    "Det er sendt en e-post til adresse xyz@gmail.com. Klikk på lenken i e-posten for å bekrefte e-postadressen din.",
  Tell_us_about_yourself: "Gratulerer, fortell oss litt om deg selv",
  Congrats_Tell_us_about_you: "",
  Your_name: "Navn",
  Birthday: "Fødselsdag",
  Positions_held: "Stillinger du har hatt",
  Select_Position: "Velg stilling",
  Select_Industry: "Velg bransje",
  Where_are_you_from: "Hvor kommer du fra?",
  Select_Country: "Velg land",
  Next: "Neste",
  Will_expect_new_document: "Ok, skjønner!",
  EU_citizen: "EU-/EØS-borger?",
  EU_citizen1: "EU-/EØS-borger",
  Search__Select: "Søk og velg",
  Select: "Velg",
  Profile_picture_: "Profilbilde (valgfritt)",
  Video_: "Video (valgfritt)",
  Aspect_ratio_: "Størrelsesforhold 4:3 eller 16:9. MOV og MP4 støttes.",
  Upload_CV_: "Last opp CV (valgfritt)",
  Supported_file_pdf: "Støttet filtype: PDF",
  Can_you_provide_refrences_upon_request: "Kan du oppgi referanser ved forespørsel?",
  Write_about_yourself: "Skriv litt om deg selv",
  Write_about_your_experience: "Skriv om praksisen din",
  Tell_us_about_your_education: "Fortell om utdanningen din",
  Last_Step: "Siste steg",
  Ok_so_tell_us_what_you_are_searching:
    "Fortell hva du søker etter og hvor du har lyst til å jobbe – så tar vi oss av resten!",
  What_work_do_you_seek: "Hvilket arbeid ser du etter?",
  What_country_do_you_seek_to_work_in: "Hvilket land ønsker du å arbeide i?",
  When_are_you_available_to_start: "Når kan du starte?",
  Now: "Nå",
  Other: "Annet",
  Full_time_or_part_time: "Fulltid eller deltid?",
  Welcome_to_JobPark: "Velkommen til Jobpark! Slik fungerer det",
  How_it_works: "",
  You_are_now_registered_and_your_profile_will_be_displayed_for_employers:
    "Nå er du registrert, og profilen din vises for arbeidsgivere.",
  Find_all_your_messages_in_oneplace:
    "Finn alle meldingene dine på ett sted. Svar og snakk med arbeidsgivere!",
  Use_settings_to_change_your_profile_and_what_employers_you_are_exposed_to:
    "Bruk innstillingene til å endre profilen din og velg hvilke arbeidsgivere du vises til.",
  Lets_Go: "Sett i gang",
  Welcome_Back: "Velkommen tilbake!",
  Industry_Experience: "Bransjeerfaring",
  Businesss_Type: "Type bedrift",
  Profile_Activation: "Profilaktivering",
  Set_your_profile_inactive: "Sett profilen din som inaktiv",
  Why_are_you_deactivating: "Hvorfor deaktiverer du profilen?",
  Please_tell_us_more_so_we_can_make_Jobpark_better:
    "Gi oss tilbakemeldinger, slik at vi kan gjøre Jobpark bedre",
  I_was_hired_through_Jobpark: "Jeg fikk jobb gjennom Jobpark",
  I_was_hired_through_another_platform: "Jeg fikk jobb gjennom en annen plattform",
  Jobpark_didnt_work_for_me: "Jobpark fungerte ikke helt for meg",
  I_dont_want_to_use_Jobpark: "Jeg ønsker ikke å bruke Jobpark",
  Use_settings_to_change_your_profile:
    "Bruk instillinger for å endre profilen og hvilke arbeidsgivere som kan se den.",
  Youll_find_all_your_messages:
    "Du kan finne alle meldingene på ett sted, der du kan svare og snakke med arbeidsgivere!",
  to_remove_fav: "",
  to_remove: "For å flytte ",
  from_fav: "til favoritter, vennligst velg et abonnement!",
  new_password: "Nytt passord",
  Select_country: "Velg land",
  Cancel_subscription: "Kanseller/Pause abonnement",
  Expires_end: "Utløper",
  No_active_subscription: "Ingen aktive abonnement",
  No_Favourites_found: "Ingen favoritter funnet",
  add_as_fav: "Vennligst legg til kandidater som favoritter fra Hjem",
  No_Results: "Ingen søkeresultater funnet!",
  No_item_match: "Vi kan ikke finne resultater som matcher ditt søk",
  First_language: "Primærspråk",
  Second_language: "Sekundærspråk",
  Where_do_you_work: "Hvor ønsker du å jobbe?",
  About_me: "Om meg",
  Education: "Utdannelse",
  References_not_available: "Referanser ikke tilgjengelig",
  My_social_media: "Mine sosiale medier",
  BusinessType: "Bedriftstype",
  Connect_media: "Sosiale medier",
  lite_plan_description: "For små bedrifter som trenger å finne den rette kandidaten",
  premium_plan_description: "For bedrifter som ansetter et par ganger i året",
  enterprise_plan_description: "For rekrutteringsbyråer og bedrifter",
  OTP_verified_successfully: "Mobilnummer er verifisert!",
  This_section_is:
    "Denne delen er kun tilgjengelig for betalende brukere med et aktivt abonnement. ",
  Please_select_Subscription_Plan: "Vennligst velg et abonnement.",
  Name_required_field: "Vennligst skriv inn ditt navn.",
  Please_enter_phone_number: "Vennligst skriv inn mobilnummer",
  Phone_number_is_not_valid: "Mobilnummer er ikke gyldig. Legg til landskode.",
  Company_Nameis_a_required_field: "Vennligst skriv inn firmanavn.",
  Address_is_a_required_field: "Vennligst skriv inn adresse.",
  Country_cannot_be_empty: "Land kan ikke være tomt.",
  City_is_a_required_field: "Vennligst skriv inn by.",
  Only_alphabets_are_allowed_for_this_field: "Kun bokstaver er tillatt i dette feltet. ",
  Zip_is_a_required_field: "Vennligst skriv inn postnummer.",
  Enter_valid_zip_code_with_no_spaces: "Skriv inn gyldig postnummer, uten mellomrom.",
  Please_Select_Country: "Vennligst velg land.",
  Please_Select_Location: "Vennligst velg et alternativ.",
  Enter_Password: "Skriv inn passord.",
  Password_must_contain_8_characters:
    "Passord må inneholde minst 8 tegn. 1 stor bokstav, 1 tall og 1 spesialtegn. ",
  Passwords_must_match: "Passord må samsvare.",
  Please_enter_valid_password: "Vennligst skriv inn riktig passord. ",
  Please_enter_name: "Vennligst skriv inn navn.",
  Please_complete_your_profile: "Vennligst fullfør profilen din for å fortsette.",
  Name_should_have_at_least_2_letters: "Navn må ha minst 2 bokstaver.",
  Please_Select_First_Language: "Vennligst velg ditt første språk.",
  Please_Select_Secondary_Language: "Vennligst velg andrespråk.",
  Please_Select_Driving_License: "Vennligst velg førerkortklasse.",
  Enter_valid_Link: "Skriv inn gyldig lenke.",
  Please_select_a_reason: "Vennligst velg et alternativ.",
  Invalid_file_format_jpg: "Ugyldig filformat. Bruk .jpg, .jpeg eller .png.",
  Please_enter_password: "Vennligst skriv inn passord.",
  Enter_valid_link_URl: "Vennligst skriv inn en gyldig URL",
  Enter_valid_facebook_URl: "Vennligst skriv inn en gyldig Facebook-URL",
  Enter_valid_instagram_URl: "Vennligst skriv inn en gyldig Instagram-URL",
  Enter_valid_linkedin_URl: "Vennligst skriv inn en gyldig LinkedIn-URL",
  Enter_valid_twitter_URl: "Vennligst skriv inn en gyldig Twitter-URL",
  Must_be_a_valid_email_address: "Må være en gyldig e-post.",
  Email_must_be_less_than_100_characters: "E-post må være under 100 tegn.",
  Please_enter_valid_email: "Må være en gyldig e-post.",
  Date_of_Birth_cannot_be_empty: "Fødselsdato kan ikke være tom.",
  Please_select_time_frame: "Vennligst velg et alternativ.",
  Please_verify_email_to_proceed: "Vennligst verifiser e-post for å fortsette.",
  Please_enter_email_address: "Vennligst skriv inn en gyldig e-post",
  Invalid_email_address: "Ugyldig e-postadresse. ",
  Form_Submitted: "Skjema sendt inn.",
  Invalid_file_format_pdf: "Ugyldig filformat. Last kun opp .pdf.",
  Invalid_image_format: "Ugyldig bildeformat",
  Please_select_Industry_exp: "Vennligst velg et alternativ.", //pending
  Please_select_work_schedule: "Vennligst velg et alternativ.", //pending
  Experience_from_industry_alert:
    "Velg hvilken industri du har erfaring fra. Du kan velge flere industrier om du trenger det.",
  What_positions_have_you_worked_in_alert:
    "Fortell hvilke stillinger du har jobbet i tidligere. Dette hjelper arbeidsgivere og rekrutterere å finne deg. ",
  Where_are_you_from_alert: "Fortell hvor du er fra.",
  EU_EEA_citizen_alert: "Det er viktig for arbeidsgivere å vite om du er EU/EØS-borger.",
  Primary_language_alert:
    "Vennligst kryss av for ditt morsmål, slik at arbeidsgivere og rekrutterere enkelt kan matche kriterier.",
  Secondary_language_alert:
    "Vennligst kryss av andrespråk du snakker. Du kan legge til flere. Dette er språk du kan gjøre deg selv forstått på. ",
  Drives_license_alert:
    "Vennligst kryss av alle dine førerkortklasser, så arbeidsgivere og rekrutterere enkelt kan matche kriterier.",
  Profile_picture_alert: "Last opp og tilpass ditt profilbilde. Dette er valgfritt.",
  Upload_Video_alert: "Makslengde 60 sekunder og 50MB. Dette er valgfritt.",
  Upload_CV_alert:
    "Last opp CV, så arbeidsgivere og rekrutterere kan laste ned din CV direkte fra profilen din. Dette er valgfritt. ",
  Write_about_yourself_alert:
    "Vi anbefaler å skrive på engelsk. Skriv en kort beskrivelse av hvem du er og hva du kan bidra med på arbeidsplassen. Dersom du ønsker å jobbe i en spesifikk by i et land, kan du legge dette til her.",
  Write_about_your_experience_alert: "Vi anbefaler å skrive på engelsk. Utdyp om din jobberfaring.",
  Tell_us_about_your_education_alert: "Vi anbefaler å skrive på engelsk. ",
  What_work_do_you_seek_alert:
    "Velg hvilken industri du vil jobbe innen og hvilken stilling du søker. Vi matcher deg med de riktige arbeidsgiverne!",
  What_country_do_you_seek_to_work_in_alert:
    "Fortell hvor i Europa du ønsker å jobbe. Er du åpen for alt? Kryss av alle!",
  When_are_you_available_to_start_alert:
    "Fortell når du er klar for å starte i en ny jobb. Dette vil vises i din profil også.",
  Full_time_or_part_time_alert: "Fortell om du ønsker fulltids- eller deltidsstillinger?",
  Invalid_phone_number: "Ugyldig mobilnummer.",
  You_are_now_registered:
    "Du er nå registrert som arbeidssøker og er synlig for arbeidsgivere som søker etter arbeidskraft i de landene du ønsker å jobbe i.",
  When_an_employer_contact: "Når en arbeidsgiver kontakter deg vil du se dem her.",
  Keep_profile_updated: "Hold profilen din oppdatert for å bli kontaktet av arbeidsgivere.",
  Find_all_your_messages: "Finn alle meldingene dine på ett sted. Svar og snakk med arbeidsgivere!",
  Use_settings_to_change:
    "Bruk innstillingene til å endre profilen din og velg hvilke arbeidsgivere du vises til.",
  Please_select_country_code: "Velg landskode",
  Austria: "Østerrike",
  Belgium: "Belgia",
  Bulgaria: "Bulgaria",
  Croatia: "Kroatia",
  Cyprus: "Kypros",
  Czech_Republic: "Tjekkia",
  Denmark: "Danmark",
  Netherlands: "Nedeland",
  United_Kingdom: "Storbritannia",
  Estonia: "Estonia",
  Finland: "Finland",
  France: "Frankrike",
  Germany: "Tyskland",
  Greece: "Hellas",
  Hungary: "Ungarn",
  Iceland: "Island",
  Ireland: "Irland",
  Italy: "Italia",
  Latvia: "Latvia",
  Lithuania: "Litauen",
  Luxembourg: "Luxemburg",
  Malta: "Malta",
  Norway: "Norge",
  Poland: "Polen",
  Portugal: "Portugal",
  Romania: "Romania",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  Spain: "Spania",
  Sweden: "Sverige",
  Ukraine: "Ukraina",
  Please_select_Industry: "Vennligst velg et alternativ.",
  Please_select_Positions: "Vennligst velg tidligere stillinger.",
  Set_your_profile_active: "Sett profilen din som aktiv",
  First_Name: "Fornavn",
  Last_Name: "Etternavn",
  Your_account_is_not_verified:
    "Din konto er ikke verifisert. Vennligst fortsett for å verifisere.",
  Your_email_was_successfully_verified: "Din e-post er nå verifisert.",
  All: "Alle",
  First_Name_should_have_at_least_2_letters: "Fornavn må ha minst to bokstaver.",
  Company_Name_should_have_at_least_2_letters: "Firmanavn må ha minst to bokstaver.",
  Last_Name_should_have_at_least_2_letters: "Etternavn må ha minst to bokstaver.",
  By_registering: "Ved å registrere meg aksepterer jeg Jobparks",
  Terms_of_Use: "Brukervilkår",
  information_in_accordance: "og at Jobpark bruker min informasjon i henhold til",
  privacy_policy: "Personvernpolicyen.",
  platformy_Jobpark: "",
  Invalid_Day: "Ugyldig dag.",
  Day_cannot_be_empty: "Velg dag.",
  Month_cannot_be_empty: "Velg måned.",
  Year_cannot_be_empty: "Velg år.",
  Remember_me: "Husk meg",
  default_error_message: "Noe gikk galt. Vennligst prøv igjen.",
  when_can_you_start:
    "Eksempel: Når kan du starte i ny jobb, hvilken by ønsker du jobb, hvorfor søker du nye erfaringer osv. Skriv på engelsk om du søker i flere land enn Norge.",
    Get_OTP:"Motta SMS-kode",
  Not_Verified:"Ikke verifisert",
  Click_here_to_verify:"Klikk her for å bekrefte.",
  "Your mobile has not yet been verified. Verify Now!":"Ditt mobilnummer er ikke verifisert. Verifiser nå!",
  "Get notified when employers contact you. Verify phone!":"Få notifikasjoner når arbeidsgivere kontakter deg. Verifiser telefonnummer!",
  "Never miss a notification. Verify mobile now!":"Ikke gå glipp av meldinger fra arbeidsgivere. Verifiser telefonnummer.",
  "Stay updated on all messages. Verify phone number!":"Hold deg oppdatert på alle meldinger. Verifiser telefon.",
  "Missing out on notifications from potential employers? Verify phone number!":"Går du glipp av meldinger fra potensielle arbeidsgivere? Verifiser telefon nå.",
  "Get notifications on your phone when a candidate answers. Verify phone now!":"Få SMS når kandidater svarer. Verifiser telefonnummer!",
  "Missing out on your chat notifications? Verify phone now!":"Går du glipp av svarene på chat? Verifiser telefon nå.",
  "Phone number is in use. Add a different number.":"Telefonnummer er allerede i bruk. Legg inn et annet nummer.",
  "Yay! You have seen it all":"Jippi! Du har sett alt",
  Delete_account:"Avslutt konto",
  Are_you_sure:"Er du sikker?",
  are_you_sure_you_want_to_delete_the_account_all_the_account_data_will_be_deleted:"Er du sikker på at du vil avslutte kontoen? All data vil bli slettet.",
  please_wait_till_we_delete_your_account_permanently:"Vennligst vent mens din konto avsluttes.",
  "Create Profile":"Lag profil",
  basic_information:"Om deg",
  "Select Experience":"Velg erfaring",
  "Select your experience":"Velg din erfaring",
  "What industry you're looking at?":"Hvilke jobber søker du etter?",
  "Position held":"Stilling inneholdt",
  your_search:"Dine søk",
  your_search_name:"Stillinger {{name}} søker etter",
  about:"Om meg",
  about_name:"Om {{name}}",
  which_country_you_will_like_to_work:"Hvilke land ønsker du å jobbe i",
  which_country_you_will_like_to_work_name:"Område {{name}} ønsker å jobbe i",
  country_of_origin:"Opprinnelse",
  Enter_Bio:"Eksempel: Når kan du starte i ny jobb, hvilken by ønsker du jobb, hvorfor søker du nye erfaringer osv. Skriv på engelsk om du søker i flere land enn Norge.",
  upload:"Last opp",
  profile_image:'Profilbilde',
  Edit:'Rediger',
  profile_completion_text:' av profilen din er ferdig. Øk sannsynligheten for å bli kontaktet ved å legge til relevant informasjon om deg i din profil.',
  preview:'Forhåndsvis',
  contact_person:"Kontaktperson",
  what_industries_you_re_looking_for_people_in:	"Hvilken bransje ser du etter kandidater i?",
  country_you_re_looking_for_people_in:	"Hvilket land ser du etter kandidater i?",
  create_company_profile:"Lag profil",
  Industry:"Bransje",
  Contact_Person_should_have_at_least_2_letters:"Kontaktperson må ha minst 2 bokstaver.",
  phone:"Telefon",
  profile_completed:"Ferdig profil",
  Website:"Hjemmeside",
  where_is_your_business_located:"Hvor er bedriften lokalisert?",
  what_are_you_looking_to_do:"Jeg vil",
  find_a_job:"Finne en jobb",
  find_candidates_and_hire:"Lete og ansette kandidater",

  save_filter_as:"Lagre filter som",
  filter_saved_successfully:"Filteret er lagret.",
  save_filter_failed:"Filteret ble ikke lagret, vennligst prøv på nytt.",
  saved_filters_plus_additional_filters_will_be_added_to_the_existing_filter:"Endringene vil bli lagret.",
  update_the_filter:"Ønsker du å oppdatere det lagrede filteret?",
  saved_filter:"Lagre filter",
  save_deleted_successfully:"Filteret er nå slettet.",
  save_filter_get_notified_of_new_matching_results:"Lagre filter og få e-post med matchende kandidater.",
  do_you_want_to_receive_email_for_new_candidate_for_this_filter:"Ønsker du å motta e-post med nye kandidater for dette filteret?",
  daily:"Daglig",
  weekly:"Ukentlig",
  delete:"Avslutt",
  save_search:"Lagre filter å motta e-post med matchende kandidater daglig eller ukentlig.",
  select_only_3_industries :"Velg opptil 3 bransjer",
  please_select_only_3_industries:"Du har valgt for mange bransjer, vennligst velg stillinger i maksimalt 3 bransjer.",
  view_contact_details:"Kontaktinformasjon",
  company_information:"Bedriftsinformasjon",
  where_is_the_business_located:"Hvor er virksomheten lokalisert?",
  i_want_to:"Jeg ønsker å:",
  get_hired:"Bli ansatt",
  want_to_find_candidates_instead: "Vil du finne kandidater istedenfor?",
  find_candidates : "Finne kandidater",
  want_to_get_hired_instead: "Vil du bli ansatt istedenfor?",
  click_here:	"Klikk her.",
  saved_filters:"Lagre filter"
};
