import * as React from "react";

function DeleteIcon2(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 11 12" fill="none" {...props}>
      <path
        d="M.786 10.667C.786 11.4 1.493 12 2.357 12h6.286c.864 0 1.571-.6 1.571-1.333v-8H.786v8zM2.357 4h6.286v6.667H2.357V4zM8.25.667L7.464 0H3.536L2.75.667H0V2h11V.667H8.25z"
        fill="red"
      />
    </svg>
  );
}

const MemoDeleteIcon2 = React.memo(DeleteIcon2);
export default MemoDeleteIcon2;
