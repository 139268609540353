import React, { ReactNode, useContext } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import ThumbsUp from "ui/icons/ThumbsUp.png";
import OrangeSetting from "assets/images/OrangeSetting.png";
import MemoChat from "ui/icons/ChatIcon";
// import Home from "ui/icons/Home";
import Home from "ui/icons/home.svg";

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 20px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 334px;
  /* padding: 10px 0 10px 0; */
  padding: 20px 0 20px 0;
  @media only screen and (max-height: 800px) {
    height: 120%;
  }
  @supports (-webkit-touch-callout: none) {
    // CSS specific to iOS devices
    margin-bottom: 80px;
    padding: 20px 0 20px 0;
  }
  @media only screen and (min-width: 1000px) {
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
`;
const StyleThembsUpPNG = styled.img`
  width: 28px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 0 auto;
`;

const StyleMessage = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #123b3d;
  margin-top: 15px;
  margin-bottom: 10px;
  @media only screen and (min-width: 1000px) {
    margin-top: 10px;
  }
`;
const StyleMainDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #123b3d;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    margin-top: 10px;
    max-width: 567px;
    font-size: 18px;
  }
`;

const StyleDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6e6e6e;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    max-width: 358px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
`;

const FeaturesDesktop = styled.div`
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 60px;
    height: 210px;
  }
`;

const FeaturesMobile = styled.div`
  margin-top: 37px;
  @media only screen and (min-width: 1000px) {
    margin-top: 50px;
  }
`;

const MobileContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 1000px) {
  }
`;
const MobileTextWrapper = styled.div`
  text-align: left;
  /* display: flex; */
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1000px) {
  }
`;

const ContentCenter = styled.div`
  @media only screen and (min-width: 1000px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
`;
const ContentHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
    text-align: center;
    margin: 10px 0 15px 0;
  }
`;

const Contenttext = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #6e6e6e;
  max-width: 217px;
  @media only screen and (min-width: 1000px) {
    text-align: center;
  }
`;

const ShowDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;

const ShowMobile = styled.div`
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

interface NoResultsFoundLayoutProps {
  viewType?: string;
}
const NoResultsFoundLayout: React.FC<NoResultsFoundLayoutProps> = ({ viewType = "login" }) => {
  const { t } = useTranslation();

  const DesktopView = () => {
    return (
      <FeaturesDesktop>
        <ContentCenter>
          <img src={Home} alt='home' width='50px' height='50px'  />
          <ContentHead>{t("Home")}</ContentHead>
          <Contenttext>{t("Keep_profile_updated")}</Contenttext>
        </ContentCenter>
        <ContentCenter>
          <img src={OrangeSetting} alt='setting' width='50px' height='50px' />
          <ContentHead>{t("Settings")}</ContentHead>
          <Contenttext>{t("Use_settings_to_change")}</Contenttext>
        </ContentCenter>
      </FeaturesDesktop>
    );
  };

  const MobileView = () => {
    return (
      <FeaturesMobile>
        <MobileContentWrapper>
          <img src={Home} alt='Home' width='50px' height='50px' />
          <MobileTextWrapper>
            <ContentHead>{t("Home")}</ContentHead>
            <Contenttext>{t("Keep_profile_updated")}</Contenttext>
          </MobileTextWrapper>
        </MobileContentWrapper>
        <MobileContentWrapper>
          <MemoChat size='50px' />
          <MobileTextWrapper>
            <ContentHead>{t("Chat")}</ContentHead>
            <Contenttext>{t("Find_all_your_messages")}</Contenttext>
          </MobileTextWrapper>
        </MobileContentWrapper>
        <MobileContentWrapper>
          <img src={OrangeSetting} alt='setting' width='50px' height='50px' />
          <MobileTextWrapper>
            <ContentHead>{t("Settings")}</ContentHead>
            <Contenttext>{t("Use_settings_to_change")}</Contenttext>
          </MobileTextWrapper>
        </MobileContentWrapper>
      </FeaturesMobile>
    );
  };
  return (
    <Wrapper>
      <div>
        <StyleThembsUpPNG src={ThumbsUp} />
        <StyleMessage>{t("Congratulations")}</StyleMessage>
        <StyleMainDescription> {t("You_are_now_registered")}</StyleMainDescription>
        {/* <StyleDescription>{t("You_are_now_registered")}</StyleDescription> */}
        <ShowDesktop>{DesktopView()}</ShowDesktop>
        <ShowMobile>{MobileView()}</ShowMobile>
      </div>
    </Wrapper>
  );
};

export default NoResultsFoundLayout;
