import * as React from "react";

function GreyPhone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 15" fill="none" {...props}>
      <path
        d="M7.071 0H1.93C.86 0 0 .914 0 2.045v10.91C0 14.085.861 15 1.929 15H7.07C8.14 15 9 14.086 9 12.954V2.045C9 .914 8.139 0 7.071 0zM5.786 13.636H3.214v-.681h2.572v.681zm2.089-2.045h-6.75V2.045h6.75v9.546z"
        fill="#838383"
      />
    </svg>
  );
}

const MemoGreyPhone = React.memo(GreyPhone);
export default MemoGreyPhone;
