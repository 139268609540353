import { useContext, useEffect, useState } from "react";
import { SelectedSearchContext, UserContext } from "context/state";
import styled from "styled-components";
import { Box } from "@mantine/core";
import { useTranslation } from "react-i18next";
import MemoDeleteIcon2 from "ui/icons/DeleteIcon2";
import MemoOrangeEdit from "ui/icons/OrangeEdit";
import Filter from "screens/home-screen/filter";
import { getFilter } from "services/saveFilter";
import Loader from "ui/feedback/Loader";
import ToggleSwitch from "ui/inputs/Switch";
import Radio from "ui/inputs/Radio";

interface CardsProps {
  name: string;
  id:number;
  handleDeleteFilter:(id: number) => void;
  handleUpdateSavedFilter:(id: number,data:{}) => void;

}

const StyleCard = styled(Box)`
  background: #ffffff;
  width: 335px;
  height: 126px;
  border-radius: 12px;
  position: relative;
  word-wrap: break-word;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1000px) {
    margin-top: 0;
    margin-right: 21px;
    margin-bottom: 16px;
  }
`;
const Text = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 20.4px;
  color: #123b3d;
  align-items: center;
  height: 60%;
  font-weight: 600;
  margin-top: 21px;
  margin-bottom: 25px;
  padding-left: 18px;
  padding-right: 18px;
`;

const CardButton = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #123b3d;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  width: 50%;
  border: 1px solid #c3c3c3;
  border-bottom: none;
  cursor:pointer;
`;

const SaveSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 0px;
`;
const ModalText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e6e6e;
  margin: 16px 0 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;
const ModalItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 10px 0px;
  margin: 22px 0 0 0;
  justify-content: space-between;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
    margin: 0 0 10px 0;
  }
`;
const StyleToggle = styled.div`
  position: relative;
  align-items: right;
  @media only screen and (min-width: 1000px) {
    align-items: right;
  }
`;
const StyledCheckBoxWrapper = styled.div`
  display: flex;
  margin: 27px 0 0 0;
  flex-direction: row;
  gap: 28px;
`;
interface Filters {
  age: number[];
  positions: string[];
  industry: string[];
  country: string[];
  language: string[];
  availability: string[];
  workType: string[];
  drivingLicense: string[];
  preferredIndustryPosition: string[];
}
const SavedSearchCard: React.FC<CardsProps> = ({ name,id,handleDeleteFilter,handleUpdateSavedFilter }) => {
  const { t } = useTranslation();
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const {setSelectedCard,selectedCard} = useContext(SelectedSearchContext);
  const [emailNotifications, setEmailNotifications] = useState<boolean>();
  const {
    // filterData,
    // setFilterData,
    savedAge,
    setSavedAge,
  } = useContext(UserContext);
  const [filterData, setFilterData] = useState({
    age: [18, 67],
    positions: [],
    industry: [],
    country: [],
    language: [],
    availability: [],
    workType: [],
    drivingLicense: [],
    preferredIndustryPosition: [],
  });
  const getSavedFilter = (id:number) => {
    getFilter(id)
    .then((res:any)=>{
      setLoader(true)
      if(res){
        setSavedFilter(res.data); 
      }
      else{
       console.log("error",res)
      }
  })
  }
  const setSavedFilter = (apiData:any) => {
    let filters = {
      age: [apiData.birthdate_max, apiData.birthdate_min],
      positions: apiData.position_held ,
      industry: apiData.Industry,
      country: apiData.location ,
      language: apiData.language ,
      availability: [],
      workType: apiData.part_full_time,
      drivingLicense: apiData.license_type ,
      preferredIndustryPosition: apiData.preferred_industry_position ?? [],
      //email_type:apiData.email_type,
    };
    setSelectedCard({
      searchTitle:apiData.search_title,
      searchId:apiData.search_id,
      emailType:apiData.email_type
    })
      setFilterData(filters);
      setSavedAge(filters?.age);
      setLoader(false)
      setShowUpdate(true);
  }

  // update filter
  const updateSavedFilter = (values:Filters) => {
    let currentFilters = values;
    let age = savedAge;
    let minAge = 67;
    let maxAge = 18;
    if (age?.length != 0 && age != undefined) {
      minAge = age[1];
      maxAge = age[0];
    }
    let birthdayMin = maxAge;
    let birthdayMax = minAge;

    let concatenatedPositions =
      currentFilters?.industry !== undefined
        ? currentFilters?.positions.concat(currentFilters?.industry)
        : []; //concatinating arrays
    let set = new Set(concatenatedPositions); //using Set to make sure no duplication
    concatenatedPositions = Array.from(set);

    let data = {
      // ...(concatenatedPositions !== undefined &&
      //   concatenatedPositions.length > 0 && {
      //     position_held: concatenatedPositions,
      //   }),
      drivers_license: currentFilters?.drivingLicense, //done
      ///email_type: currentFilters.email_type ?? "", //done
      birthdate_max: birthdayMax, //done
      birthdate_min: birthdayMin, //done
      position_type: currentFilters?.workType, //done
      position_held: concatenatedPositions,
      industry: currentFilters?.industry, //done
      search_title: selectedCard.searchTitle.trim(), //done
      language: currentFilters?.language, //done
      location: currentFilters?.country, //done
      email_type:selectedCard.emailType
    };
  handleUpdateSavedFilter(selectedCard.searchId, data)
  };
  useEffect(() => {
    if (emailNotifications === false) {
      setSelectedCard(() => ({ ...selectedCard, emailType: "" }));
    }
  }, [emailNotifications]);
  useEffect(()=>{
    setEmailNotifications(selectedCard.emailType == "" ? false : true);
  },[selectedCard.emailType])

  const FilterFooter =()=>{
    const handleSwitchToggle=(event:React.ChangeEvent<HTMLInputElement>)=>{
      setEmailNotifications(!emailNotifications)
      if(event.target.checked && selectedCard.emailType==""){
        setSelectedCard(() => ({...selectedCard, emailType:"daily"}));
      }
    }
    return (
      <>
            <ModalItemWrapper>
              <ModalText
                style={{ color: "rgba(18, 59, 61, 1)", width: "219px" }}
              >
                {t(
                  "do_you_want_to_receive_email_for_new_candidate_for_this_filter"
                )}
              </ModalText>
              <StyleToggle>
                <ToggleSwitch
                  checked={emailNotifications}
                  onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleSwitchToggle(event)}
                />
              </StyleToggle>
            </ModalItemWrapper>
            {emailNotifications && (
              <StyledCheckBoxWrapper>
                <Radio
                  name="emailNotification"
                  options={[
                    {
                      key: t("daily"),
                      value: "daily",
                      id: "1",
                    },
                    {
                      key: t("weekly"),
                      value: "weekly",
                      id: "2",
                    },
                  ]}
                  value={selectedCard.emailType}
                  onChange={(e:any) =>{
                    if(emailNotifications){
                      setSelectedCard(() => ({...selectedCard,emailType:e}));
                        }
                        else{
                          setSelectedCard(() => ({...selectedCard, emailType:""}));
                        }
                  }
                    // setRadioValue(e)
                  }
                />
              </StyledCheckBoxWrapper>
            )}
          </>
    )
  }

  return (
    <>
      <StyleCard >
        <Text>{name}</Text>
        <SaveSearchWrapper>
          <CardButton style={{ borderLeft: "none" }} onClick={() =>{getSavedFilter(id)}}>
            <MemoOrangeEdit
              style={{
                paddingRight: "8px",
              }}
              width="14px"
              height="18px"
            />{" "}
            {t("Edit")}
          </CardButton>
          <CardButton style={{ borderRight: "none" }} onClick={() =>{handleDeleteFilter(id)}}>
            <MemoDeleteIcon2
              style={{ paddingRight: "8px" }}
              width="14px"
              height="18px"
            />{" "}
            {t("delete")}
          </CardButton>
        </SaveSearchWrapper>
      </StyleCard>
      {loader ? (
        <Loader />
      ) : (
       <Filter
            showFilter={showUpdate}
            setShowFilter={setShowUpdate}
            showSaveSearchFeatures ={true}
            currentSelectedFilters={filterData} 
            filterUpdate={updateSavedFilter}
            filterFooter={<FilterFooter/>}
            filterSubmitButtonText={t("Save")}
          />
      )}
      
    </>
  );
};

export default SavedSearchCard;
