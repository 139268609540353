import { useContext, useState } from "react";
import { Stepper, Group } from "@mantine/core";
import MemoStepperIcon from "ui/icons/StepperIcon";
import MemoStepperIconActive from "ui/icons/StepperIconActive";
import Button from "ui/buttons/Button";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { getUserDetails, putUserDetails } from "services/user/User";
import { UserContext } from "context/state";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface props {
  dataSteps: any[];
  data?: any;
  setData?: any;
  step1Data?: any;
  step2Data?: any;
  step3Data?: any;
  step4Data?: any;
  step5Data?: any;
  isValid?: boolean;
  setShowValidation0?: any;
  setShowValidation1?: any;
}

const StyleButton = styled.div`
  position: fixed;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px -16px 44px rgba(0, 0, 0, 0.1);
  margin: 0 0 0 -14px;
  padding: 2px;
  min-height: 72px;
  bottom: 0;
  width: 100%;
  @media only screen and (min-width: 1000px) {
    background: transparent;
    position: relative;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    bottom: 0;
    padding: 0;
    margin: 0;
    min-height: 0;
    max-width: 557px;
  }
`;

const WrapperStyle = styled.div`
  margin-bottom: 120px;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 0px;
    width: 100%;
    max-width: 550px;
  }
`;

const StyleButtonWrapper = styled.div`
  margin: 0 20px 0 10px;
  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

const Container = styled.div`
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  .mantine-Stepper-steps {
    justify-content: center;
  }
  @media only screen and (min-width: 1000px) {
  }
`;

const StepIndicator: React.FC<props> = ({
  dataSteps,
  step1Data,
  step2Data,
  step3Data,
  step4Data,
  step5Data,
  isValid,
  ...props
}) => {
  const [active, setActive] = useState(0);
  const { push, goBack } = useHistory();
  const { t } = useTranslation();
  const { userData, role, userId, firstSession,setUserData } = useContext(UserContext);
  const getCheckedIds = (
    data: [],
    preferredJob?: boolean | undefined,
    industryOnly?: boolean | undefined
  ) => {
    let checked: any = [];
    if (preferredJob) {
      data?.forEach((item: any) =>
        item?.checked ? checked.push(item?.id, item?.parentId) : ""
      );
    } else if (industryOnly) {
      data?.forEach((item: any) =>
        item?.checked ? checked.push(item?.parentId) : ""
      );
    } else {
      data?.forEach((item: any) =>
        item?.checked ? checked.push(item?.id) : ""
      );
    }
    return checked;
  };
  const nextStep = () => {
    if (active === 0) {
      let primaryLanguage = getCheckedIds(step1Data?.primaryLanguage[0]?.data);
      let formatedDOB = moment(step1Data?.DOB).format("YYYY-MM-DD");
      let originCountry = getCheckedIds(step1Data?.country[0]?.data);
      let putData = {
        step: 1,
        first_name: step1Data?.firstName.trim(),
        last_name: step1Data?.lastName.trim(),
        birthday: formatedDOB,
        primary_language: primaryLanguage,
        country_origin: originCountry[0]
      };
      putUserDetails(userId, putData)
        .then((response) => {
          if (response?.success)
          {
            getUserDetails(userData?.user_id).then((res) => {
              if (res?.success) {
                setUserData(res?.data);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(res?.data ?? "")
                );
              }
            });
            setActive((current) =>
              current < dataSteps.length - 1 ? current + 1 : current
            );
          }
          // push({
          //   pathname: "/home",
          //   state: { showModal: true },
          // });
        })
        .catch((error) => console.log(error));
    } else if (active === 1) {
      let preferredCountry = getCheckedIds(step2Data?.country[0]?.data);
      let preferredJob: any = [];
      let industryType: any = [];
      let positionType: any = [];
      step2Data?.pastIndustryExperience.forEach((type: any) => {
        industryType.push(...getCheckedIds(type?.data, false, true));
      });
      let set1 = new Set(industryType);
      industryType = Array.from(set1);
      step2Data?.pastIndustryExperience.forEach((type: any) => {
        positionType.push(...getCheckedIds(type?.data, false, false));
      });
      let set2 = new Set(positionType);
      positionType = Array.from(set2);
      step2Data?.industry.forEach((type: any) => {
        preferredJob.push(...getCheckedIds(type?.data, true, false));
      });
      let set = new Set(preferredJob); //getting unique Ids only
      preferredJob = Array.from(set);

      let putData = {
        step: 2,
        industry_type: industryType,
        position_type: positionType,
        preferred_job: preferredJob,
        preferred_country: preferredCountry,
        part_full_time: step2Data?.timeofwork,
      };
      putUserDetails(userId, putData)
        .then((response) => {
          if (response?.success) {
            push({
              pathname: "/home",
              state: { showModal: true },
            });
          }
        })
        .catch((error) => console.log(error));
    }
    // if (active == dataSteps.length - 1) {
    //   //push('/profile')
    //   let industryExperience = getCheckedIds(step1Data?.industryExperience[0]?.data);
    //   let positionheld: any = [];
    //   step1Data?.positionHeld.forEach((position: any) => {
    //     positionheld.push(...getCheckedIds(position?.data));
    //   });
    //   let originCountry = getCheckedIds(step1Data?.country[0]?.data);
    //   let drivingLicense: any = [];
    //   step2Data?.drivingLicense.forEach((type: any) => {
    //     drivingLicense.push(...getCheckedIds(type?.data));
    //   });
    //   let primaryLanguage = getCheckedIds(step2Data?.primaryLanguage[0]?.data);
    //   let secondaryLanguage = getCheckedIds(step2Data?.secondaryLanguage[0]?.data);
    //   let preferredCountry = getCheckedIds(step5Data?.country[0]?.data);
    //   let preferredJob: any = [];
    //   step5Data?.industry.forEach((type: any) => {
    //     preferredJob.push(...getCheckedIds(type?.data, true));
    //   });
    //   let set = new Set(preferredJob); //getting unique Ids only
    //   preferredJob = Array.from(set);
    //   let formatedDOB = moment(step1Data?.DOB).format("YYYY-MM-DD");
    //   let putData = {
    //     first_name: step1Data?.firstName.trim(),
    //     last_name: step1Data?.lastName.trim(),
    //     birthday: formatedDOB,
    //     industry_type: industryExperience,
    //     position_type: positionheld,
    //     country_origin: originCountry[0],
    //     citizen: step2Data?.euCitizen,
    //     primary_language: primaryLanguage,
    //     secondary_language: secondaryLanguage,
    //     license_type: drivingLicense,
    //     about_yourself: step4Data?.personalDescription,
    //     your_experience: step4Data?.experience,
    //     your_education: step4Data?.education,
    //     social_facebook_link: step4Data?.facebook,
    //     social_twitter_link: step4Data?.twitter,
    //     social_linkedin_link: step4Data?.linkedin,
    //     social_instagram_link: step4Data?.instagram,
    //     preferred_job: preferredJob,
    //     preferred_country: preferredCountry,
    //     availability: step5Data?.joining,
    //     part_full_time: step5Data?.timeofwork,
    //     provide_references: step3Data?.refrences ? "1" : "0",
    //     description: "",
    //     location: "",
    //     link_to_website: "",
    //   };
    //   putUserDetails(userId, putData)
    //     .then((response) => {
    //       // console.log(response);
    //       push({
    //         pathname: "/home",
    //         state: { showModal: true },
    //       });
    //     })
    //     .catch((error) => console.log(error));
    // } else {
    //   setActive((current) => (current < dataSteps.length - 1 ? current + 1 : current));
    // }
  };
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  let stepperStyles = {
    stepBody: {
      display: "none",
      height: "100%",
      width: "100%",
      transition: "none !important",
      transform: "none !important",
    },

    step: {
      display: "none",
      padding: 0,
      backgroundColor: "none",
      transition: "none !important",
      transform: "none !important",
    },

    stepIcon: {
      borderWidth: 0,
      border: 0,
      backgroundColor: "transparent !important",
      "&:completed": {
        backgroundColor: "none !important",
        transition: "none !important",
        transform: "none !important",
      },
      "&:active": {
        backgroundColor: "none !important",
        transition: "none !important",
        transform: "none !important",
      },
    },
    stepCompletedIcon: {
      border: 0,
      backgroundColor: "none !important",
      transition: "none !important",
      transform: "none !important",
    },
    stepCompleted: {
      backgroundColor: "none",
      transition: "none !important",
      transform: "none !important",
    },
    separatorActive: {
      backgroundColor: "#FF3000",
      transition: "none !important",
      transform: "none !important",
    },
    separator: {
      display: "none",
      marginLeft: -2,
      marginRight: -2,
      height: 3,
      maxWidth: "224px !important",
      transition: "none !important",
      transform: "none !important",
    },
    content: {
      paddingTop: "13px",
    },
  };
  return (
    <Wrapper>
      <Stepper
        styles={stepperStyles}
        iconSize={24}
        active={active}
        // onStepClick={setActive}
        {...props}
      >
        {dataSteps.map((step, index) => {
          return (
            <Stepper.Step
              icon={<MemoStepperIcon width="100%" height="auto" />}
              key={"step" + index}
              progressIcon={
                <MemoStepperIconActive width="100%" height="100%" />
              }
              completedIcon={
                <MemoStepperIconActive width="100%" height="100%" />
              }
            >
              <Container>
                <WrapperStyle>{step}</WrapperStyle>
              </Container>
            </Stepper.Step>
          );
        })}
      </Stepper>
      <Container>
        <StyleButton>
          <Group
            position="center"
            mt="xs"
            sx={{
              display: "flex",
              bottom: "0",
              justifyContent: "space-between",
            }}
          >
            <StyleButtonWrapper>
              {active === 0 ? (
                <>
                  {userData?.profile_status && (
                    <Button
                      style={{
                        marginLeft: "0px",
                        backgroundColor: "transparent",
                      }}
                      //disabled={firstSession}
                      variant="default"
                      width="125px"
                      onClick={goBack}
                    >
                      {t("Back")}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  style={{
                    marginLeft: "0px",
                    backgroundColor: "transparent",
                  }}
                  variant="default"
                  width="125px"
                  onClick={() => {
                    if (active % 2 == 0) props?.setShowValidation0(false);
                    else props?.setShowValidation1(false);
                    prevStep();
                  }}
                >
                  {t("Back")}
                </Button>
              )}
            </StyleButtonWrapper>
            <StyleButtonWrapper>
              <button
                type="submit"
                form={`F${active}`}
                style={{
                  marginRight: "0px",
                  width: "125px",
                  height: "54px",
                  fontSize: "16px",
                  borderRadius: "52px",
                  border: "none",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
                }}
                onClick={() => {
                  // console.log(`is active`, active);
                  switch (active) {
                    case 0:
                      props?.setShowValidation0(true);
                      break;
                    case 1:
                      props?.setShowValidation1(true);
                      break;
                    case 3:
                      props?.setShowValidation0(true);
                      break;
                    case 4:
                      props?.setShowValidation1(true);
                      break;
                    case 5:
                      props?.setShowValidation0(true);
                      break;
                    default:
                    // code block
                  }
                  if (isValid) {
                    if (active % 2 == 0) props?.setShowValidation0(false);
                    else props?.setShowValidation1(false);
                    nextStep();
                  }
                }}
              >
                {t("Next")}
              </button>
            </StyleButtonWrapper>
          </Group>
        </StyleButton>
      </Container>
    </Wrapper>
  );
};

export default StepIndicator;
