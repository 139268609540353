import * as React from "react";

function Download(props) {
    return (
        <svg width="32" height="21" viewBox="0 0 32 21" fill="none" {...props}>
            <path
                d="M25.8 7.9275C24.8933 3.39937 20.8533 0 16 0C12.1467 0 8.8 2.1525 7.13333 5.3025C3.12 5.7225 0 9.06938 0 13.125C0 17.4694 3.58667 21 8 21H25.3333C29.0133 21 32 18.06 32 14.4375C32 10.9725 29.2667 8.16375 25.8 7.9275ZM22.6667 11.8125L16 18.375L9.33333 11.8125H13.3333V6.5625H18.6667V11.8125H22.6667Z"
                fill="#FF5500"
            />
        </svg>

    );
}

const MemoDownload = React.memo(Download);
export default MemoDownload;
