import styled from "styled-components";
import React, { ReactNode } from "react";
import { Textarea, TextareaProps } from "@mantine/core";

interface CustomTextAreaProps extends TextareaProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  placeholder?: string;
  error?: string | ReactNode;
  description?: string;
  label?: string;
  className?: string;
  icon?: ReactNode;
  sx?: any;
  minRows?: number;
}

const StyleTextArea = styled.div`
  font-family: Poppins !important;
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  @media only screen and (min-width: 1000px) {
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
`;

const TextArea: React.FC<CustomTextAreaProps> = ({
  width,
  height,
  color,
  backgroundColor,
  fontSize,
  placeholder,
  error,
  label,
  className,
  ...props
}) => {
  const styles = {
    width: width,
    height: height,
    color: color ?? "#123B3D !important",
    fontFamily: "Poppins !important",
    fontSize: fontSize ?? "16px",
    "::placeholder": {
      color: "#6E6E6E !important",
      fontFamily: "Poppins !important",
      opacity: "1",
      fontWeight: 400,
      fontSize: fontSize ?? "16px",
    },
  };
  return (
    <StyleTextArea>
      <Textarea
        label={label}
        placeholder={placeholder}
        error={error}
        style={styles}
        radius={12}
        maxLength={1000}
        {...props}
      />
    </StyleTextArea>
  );
};
export default TextArea;
