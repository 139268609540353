import * as React from "react";

function ChevronDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M5.398 7.8l3.6 3.6 3.6-3.6"
        stroke="#6A6A6A"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
    </svg>
  );
}

const MemoChevronDown = React.memo(ChevronDown);
export default MemoChevronDown;
