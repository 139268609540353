import React, { ReactNode, useState } from 'react';
import { Modal, ModalProps } from '@mantine/core';
import styled from 'styled-components';
import MemoI_Big from '../icons/I_Big';
import Button from '../../ui/buttons/Button';
import { useTranslation } from 'react-i18next';

interface ProfilCreationAlertModelProps extends ModalProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: () => void;
  infoText?:string
}
const StyleBodyText = styled.p`
  text-align: center;
  padding: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6e6e6e;
`;

export const ProfilCreationAlertModel: React.FC<
  ProfilCreationAlertModelProps
> = ({ children, show, onHide, ...props }) => {
  const [opened, setOpened] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <Modal
        centered
        {...props}
        overlayOpacity={0.4}
        overlayBlur={6}
        // size={400}
        styles={{
          root: {
            width: '100%',
            '@media only screen and (min-width: 1000px)': {
              width: '44%',
            },
          },
          overlay: {
            width: '100%',
            '@media only screen and (min-width: 1000px)': {
              width: '44%',
            },
          },
          modal: {
            borderRadius: '20px',
            height: 'auto',
            width: 'auto',
            maxWidth: '360px',
            margin: '10px',
          },
          close: { display: 'none' },
          title: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
        title={<MemoI_Big width='50px' height='50px' />}
      >
        <StyleBodyText>
          {props?.infoText ?? ""}
        </StyleBodyText>
        <Button
          style={{
            wordWrap: 'break-word',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={() => props.onClose()}
          variant='filled'
          height='50px'
          fontSize='16px'
          // width='fit-content'
        >
          {t(`Will_expect_new_document`)}
        </Button>
      </Modal>
    </>
  );
};
