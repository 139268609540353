import React, { lazy, useEffect, useState } from 'react'
import './App.css'
import { Helmet } from 'react-helmet'
import Router from './AppRouter'
import { UserContext } from './context/state'

interface Filters {
  age: number[];
  positions: string[];
  industry: string[];
  country: string[];
  language: string[];
  availability: string[];
  workType: string[];
  drivingLicense: [];
  preferredIndustryPosition: string[];
}

declare global {
  interface Window {
    eirikVerifiesThingsAreWorking: any;
  }
}

function App() {
  window.eirikVerifiesThingsAreWorking = true;
  const userJson = localStorage.getItem('User')
  let userobj = userJson ? JSON.parse(userJson) : {}
  let userDataCached = localStorage.getItem('userData') ?? null
  let extractedData = //move this to useEffect and set initial value to null todo
    userDataCached !== null && userDataCached != undefined
      ? JSON.parse(userDataCached != undefined ? userDataCached : '')
      : null
  const initialRole = userobj?.role ==="administrator" ?"employer":userobj.role
  const [role, setRole] = useState(initialRole)
  const [userData, setUserData] = useState(extractedData ? extractedData : {})
  const [userId, setUserId] = useState(userobj?.userId ?? '')
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0)
  let filtersStringified = localStorage.getItem('filters')
  const [showDropdown, setShowDropdown] = useState(false)
  const [showSelectDay, setShowSelectDay] = useState(false)
  const [showSelectMonth, setShowSelectMonth] = useState(false)
  const [showSelectYear, setShowSelectYear] = useState(false)
  //let filters = filtersStringified !==undefined && filtersStringified !==null? JSON.parse(filtersStringified):undefined
  //console.log(`user data app`, userData);
  const [filterData, setFilterData] = useState<Filters>({
    age: [18, 67],
    positions: [],
    industry: [],
    country: [],
    language: [],
    availability: [],
    workType: [],
    drivingLicense: [],
    preferredIndustryPosition: [],
  })
  const [savedAge, setSavedAge] = useState<any>([18, 67])
  const [showMobileVerify,setShowMobileVerify] = useState(false)
  const [activeTap, setActiveTap] = useState(1)
  const [redirectToSubscription, setRedirectToSubscription] = useState(false)
  const [firstSession, setFirstSession] = useState(false) /// todo false
  const [count, setCount] = useState<number>(0)
  const [loginType, setLoginType] = useState<{
    type: string
    access_toke: string
    email?: string
  }>()
  const [addElements, setAddElements] = useState<boolean>(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [taxonomyLoaded, setTaxonomyLoaded] = useState<boolean>(false)
  const [isAdmin,setIsAdmin] = useState<boolean>(false)
  const contextValues = {
    role,
    setRole,
    userData,
    setUserData,
    userId,
    setUserId,
    filterData,
    setFilterData,
    savedAge,
    setSavedAge,
    activeTap,
    setActiveTap,
    redirectToSubscription,
    setRedirectToSubscription,
    firstSession,
    setFirstSession,
    numberOfUnreadMessages,
    setNumberOfUnreadMessages,
    showDropdown,
    setShowDropdown,
    showSelectDay,
    setShowSelectDay,
    showSelectMonth,
    setShowSelectMonth,
    showSelectYear,
    setShowSelectYear,
    loginType,
    setLoginType,
    addElements, 
    setAddElements,
    showMobileVerify,
    setShowMobileVerify,
    count, setCount,
    showUserProfile, setShowUserProfile,
    taxonomyLoaded, setTaxonomyLoaded,
    isAdmin, setIsAdmin
  }
  //remember to test the state being persisted without this useEffect
  useEffect(() => {
    if (userJson) {
      let userobj = JSON.parse(userJson)
      if(userobj?.role ==="administrator"){
        setRole("employer")
      }
      else{
        setRole(userobj?.role)
      }
      
      setUserId(userobj?.userId)
      setUserData(userDataCached ? JSON.parse(userDataCached) : {}) //todo
    }
  }, [])
  //console.log("facebbok id", process.env.REACT_APP_GOOGLE_APP_ID as string)
  // To set default language in Filter i.e. ID = 104 = English
  // useEffect(() => {
  //   setFilterData({
  //     ...filterData,
  //     language: userData?.apply_filters == false ? ["104"] : filterData?.language,
  //   });
  // }, [userData]);

  return (
    <>
      <Helmet>
        <title>Jobpark</title>
        <meta
          name='description'
          content='A world of job opportunities at your fingertips. Find jobs and get hired in minutes. No hidden costs or fees. Sign up today!'
        />
        <link
          rel='canonical'
          href='https://app.jobpark.com/'
        />
      </Helmet>
      <UserContext.Provider value={contextValues}>
        <Router />
      </UserContext.Provider>
    </>
  )
}

export default App
