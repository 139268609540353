import { useForm, yupResolver } from "@mantine/form";
import MemoInfo from "ui/icons/Info";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "context/state";
import InputField from "ui/inputs/Inputfield";
import * as Yup from "yup";
import Drawer from "ui/data-display/Drawer";
import { MultiSelectButton } from "ui/inputs/MultiSelectButton";
import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import moment from "moment";
import CustomSelect from "./custom-select";
import { DropZone } from "ui/inputs/Dropzone";
import LanguageSearch from "helpers/search-select/LanguageSearch";
import { LanguageStore } from "stores/taxnomies/language.store";
import { CommonPageStepLabel } from "./CommonPageStepLabel";
import { fontBlack } from "constants/colors";
import { useViewportSize } from "@mantine/hooks";
import CountrySearch from "helpers/search-select/CountrySearch";
import { CountryStore } from "stores/taxnomies/country.store";
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const years = [
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
];

const StyledText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${fontBlack};
  margin: 34px 0 34px 0;

  @media only screen and (min-width: 1000px) {
    text-align: center;
  }
`;

const StyleSpacing = styled.div`
  margin-top: 20px;
  @media only screen and (min-width: 1000px) {
    margin-top: 20px;
  }
`;

const CountryGrid = styled(Grid)`
  /* margin-top: 25px;
  @media only screen and (min-width: 1000px) {
    margin-top: 15px;
  } */
`;

const StyleError = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  /* margin-top: 5px; */
  word-break: break-word;
  color: #f03e3e;
  display: flex;
  font-family: Poppins;
`;

const BirthdayTitle = styled.div`
  font-size: 14px;
  color: #123b3d;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
  line-height: 20px;
  @media only screen and (min-width: 1000px) and (max-width: 1280px) {
    //margin-top: -10px;
    //height: 40px;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 10px;
  }
`;
const BirthdayWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    justify-content: space-between;
  }
`;
const StyleContainer = styled.div`
  margin: 10px 0 0 0;
  @media only screen and (min-width: 1000px) {
    margin: 40px 0px 20px 0px;
  }
`;
const PrimaryLanguageWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    //margin-top: -10px;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 10px;
  }
`;

function UserProfileStep1(props: any) {
  const {
    userData,
    showSelectDay,
    setShowSelectDay,
    showSelectMonth,
    setShowSelectMonth,
    showSelectYear,
    setShowSelectYear,
  } = useContext(UserContext);

  const { t } = useTranslation();
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [countryData, setCountryData] = useState(
    props?.data?.country ? props?.data?.country : []
  );
  let cachedUserData = localStorage.getItem("userData");
  let parsedUserData = cachedUserData ? JSON.parse(cachedUserData) : {};

  //new changes sprint 3
  const [showLanguage, setShowLanguage] = useState(false);
  const [primaryLanguage, setPrimaryLanguage] = useState(
    props?.data?.primaryLanguage ? props?.data?.primaryLanguage : []
  );
  const [secondaryLanguage, setSecondaryLanguage] = useState(
    props?.data?.secondaryLanguage ? props?.data?.secondaryLanguage : []
  );

  const [languageBoxHeight, setLanguageBoxHeight] = useState("54px");
  //end
  const schema = Yup.object().shape({
    //name: Yup.string().min(2, t("Name_should_have_at_least_2_letters")),
    firstName: Yup.string()
      .matches(/^(?!\s+$).*/, t("First_Name_should_have_at_least_2_letters"))
      .min(2, t("First_Name_should_have_at_least_2_letters")),
    lastName: Yup.string()
      .matches(/^(?!\s+$).*/, t("Last_Name_should_have_at_least_2_letters"))
      .min(2, t("Last_Name_should_have_at_least_2_letters")),
    DOB: Yup.date().required(t("Date_of_Birth_cannot_be_empty")).nullable(),
    // birthday:Yup.string().required().max(10,"Invalid Date"),
    day: Yup.string().required(t("Day_cannot_be_empty")).nullable(),
    month: Yup.string().required(t("Month_cannot_be_empty")).nullable(),
    year: Yup.string().required(t("Year_cannot_be_empty")).nullable(),
    primaryLanguage: Yup.string().required(),
    secondaryLanguage: Yup.string().required(),
    country: Yup.string().required(t("Country_cannot_be_empty")),
  });

  // Function to display saved data
  const savedDataHandler = (data: any, savedData: any) => {
    let flag = false;
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true;
    });
    return flag;
  };

  const label = (props: string) => {
    return (
      <>
        {props}
        <MemoInfo style={{ alignSelf: "center" }} />
      </>
    );
  };
  // Function to Get all the checked IDs
  const getCheckedIds = (data: any) => {
    let checkedIds: any = [];
    data?.forEach((objectData: any) => {
      objectData?.data?.forEach((checkboxData: any) => {
        if (checkboxData.checked) {
          checkedIds.push(checkboxData.id);
        }
      });
    });
    return checkedIds;
  };

  let DOBvariable = props?.data?.DOB
    ? props.data.DOB
    : userData?.birthday
    ? new Date(userData?.birthday)
    : null;
  let DOBstring = [""];
  if (DOBvariable) {
    DOBvariable = moment(DOBvariable).format("DD/MM/YYYY");
    DOBstring = DOBvariable.toString().split("/");
  }

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      //name: props?.data?.name ? props.data.name : userData?.name ? userData?.name : "",
      firstName: props?.data?.firstName
        ? props.data.firstName
        : userData?.first_name
        ? userData?.first_name
        : "",
      lastName: props?.data?.lastName
        ? props.data.lastName
        : userData?.last_name
        ? userData?.last_name
        : "",
      DOB: props?.data?.DOB
        ? props.data.DOB
        : userData?.birthday
        ? new Date(userData?.birthday)
        : null,
      //birthday:formatDate(DOBvariable?.toString()),
      day: DOBstring[0] ?? "",
      month: DOBstring[1] ?? "",
      year: DOBstring[2] ?? "",
      profilePicture: props?.data?.profilePicture
        ? props?.data?.profilePicture
        : userData?.profile_picture
        ? userData?.profile_picture
        : "",
      primaryLanguage: props?.data?.primaryLanguage
        ? props?.data?.primaryLanguage
        : [],
      secondaryLanguage: props?.data?.secondaryLanguage
        ? props?.data?.secondaryLanguage
        : [],
      country: props?.data?.country ? props.data.country : countryData,
    },
  });
  //Validation State
  useEffect(() => {
    props.setIsValid(false);
    if (
      Array.from(LanguageStore.data, ([id, name]) => ({ id, name }))?.length !=
        0 &&
      secondaryLanguage?.length == 0 &&
      primaryLanguage?.length == 0 &&
      countryData?.length == 0
    ) {
      setPrimaryLanguage([
        {
          headerName: "",
          data: Array.from(LanguageStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              userData?.primary_language?.length != 0
                ? savedDataHandler(obj, userData?.primary_language)
                : false,
            show: true,
          })),
        },
      ]);

      setSecondaryLanguage([
        {
          headerName: "",
          data: Array.from(LanguageStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              userData?.secondary_language?.length != 0
                ? savedDataHandler(obj, userData?.secondary_language)
                : false,
            show: true,
          })),
        },
      ]);
    }
    setCountryData([
      {
        headerName: "",
        data: Array.from(CountryStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            [userData?.country_origin]?.length != 0
              ? savedDataHandler(obj, [userData?.country_origin])
              : false,
          show: true,
        })),
      },
    ]);
  }, []);

  const { width } = useViewportSize();
  useEffect(() => {
    if (width < 1000) {
      setLanguageBoxHeight("122px");
    } else {
      setLanguageBoxHeight("54px");
    }
  }, [width]);

  useEffect(() => {
    form.setFieldValue("primaryLanguage", primaryLanguage);
  }, [primaryLanguage]);
  useEffect(() => {
    form.setFieldValue("country", countryData);
  }, [countryData]);

  useEffect(() => {
    if (form.values?.day && form.values?.month && form.values?.year) {
      let dob = `${form.values?.day}/${form.values?.month}/${form.values?.year}`;
      const dateVariable = moment(dob, "DD/MM/YYYY");
      if (dateVariable.isValid()) {
        let validDate = moment(dateVariable).toDate();
        form.setFieldValue("DOB", validDate);
        form.clearFieldError("day");
      } else {
        form.setFieldError("day", t("Invalid_Day"));
      }
    }
  }, [form.values.day, form.values.month, form.values.year]);

  useEffect(() => {
    props.setData(form.values);
    let dob = `${form.values?.day}/${form.values?.month}/${form.values?.year}`;
    const dateVariable = moment(dob, "DD/MM/YYYY");
    if (
      //form.values.name &&
      form.values.firstName.trim().length >= 2 &&
      form.values.lastName.trim().length >= 2 &&
      getCheckedIds(form.values.primaryLanguage).length != 0 &&
      getCheckedIds(form.values.country).length != 0 &&
      dateVariable.isValid() &&
      form.values.day &&
      form.values.month &&
      form.values.year
    ) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, [form.values]);

  return (
    <form
      id="F0"
      onSubmit={form.onSubmit((values: any) =>
        {}
      )}
    >
      <CommonPageStepLabel step="1" />
      <StyledText>{t("basic_information")}</StyledText>
      <StyleContainer>
        <DropZone
          label={t("Profile_picture_")}
          description={t("_300_x_300_pixels_for_best_quality")}
          acceptType={"image"}
          savedData={
            form?.values?.profilePicture ? [form?.values?.profilePicture] : []
          }
          infoText={t("Profile_picture_alert")}
          form={form}
        />
      </StyleContainer>
      <Grid gutter={width > 1000 ? 20 : 10.5}>
        <Grid.Col lg={6}>
          <InputField
            width="100%"
            height="auto"
            id="yourName"
            label={t("First_Name")}
            {...form.getInputProps("firstName")}
            style={{ marginTop: "10px" }}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <InputField
            width="100%"
            height="auto"
            id="yourName"
            label={t("Last_Name")}
            {...form.getInputProps("lastName")}
            style={{ marginTop: "10px" }}
          />
        </Grid.Col>
        <Grid.Col lg={6} md={12}>
          <BirthdayTitle>{t("Birthday")}</BirthdayTitle>
          <BirthdayWrapper>
            <CustomSelect
              data={days}
              dataType="day"
              form={form}
              selectedValue={form.values.day}
              showSelectedDropdown={showSelectDay}
              setShowSelectedDropdown={setShowSelectDay}
            />
            <CustomSelect
              data={months}
              dataType="month"
              form={form}
              selectedValue={form.values.month}
              showSelectedDropdown={showSelectMonth}
              setShowSelectedDropdown={setShowSelectMonth}
            />
            <CustomSelect
              id="year"
              data={years}
              dataType="year"
              form={form}
              selectedValue={form.values.year}
              showSelectedDropdown={showSelectYear}
              setShowSelectedDropdown={setShowSelectYear}
            />
          </BirthdayWrapper>
          {form?.values?.day ? (
            !moment(
              `${form.values?.day}/${form.values?.month}/${form.values?.year}`,
              "DD/MM/YYYY"
            ).isValid() && (
              <StyleError>
                {form?.errors?.day ? form?.errors?.day : t("Invalid_Day")}
              </StyleError>
            )
          ) : (
            <StyleError>{form?.errors?.day}</StyleError>
          )}
          <StyleError>{form?.errors?.month}</StyleError>
          <StyleError>{form?.errors?.year}</StyleError>
        </Grid.Col>
        <Grid.Col lg={6} md={12}>
          <PrimaryLanguageWrapper>
            <MultiSelectButton
              type="in"
              showAll={true}
              setData={setPrimaryLanguage}
              data={primaryLanguage}
              languageIds={getCheckedIds(secondaryLanguage)}
              header={t("Select_Pri_lang")}
              onClick={() => setShowLanguage(true)}
              infoText={t("Primary_language_alert")}
              errorMessage={
                props?.showValidation &&
                form?.errors?.primaryLanguage &&
                getCheckedIds(primaryLanguage).length == 0 &&
                t("Please_Select_First_Language")
              }
            />
          </PrimaryLanguageWrapper>
          <Drawer
            padding={"0px"}
            position="right"
            opened={showLanguage}
            onClose={() => setShowLanguage(false)}
          >
            <LanguageSearch
              title={t("First_language")}
              passData={primaryLanguage}
              getData={setPrimaryLanguage}
              onHide={() => setShowLanguage(false)}
              savedData={
                userData?.primary_language?.length != 0
                  ? userData?.primary_language
                  : []
              }
              selectedPrimarySecondaryLanguageIds={getCheckedIds(
                secondaryLanguage
              )}
              primaryLanguage={true}
            />
          </Drawer>
        </Grid.Col>
        <Grid.Col lg={6}>
        <MultiSelectButton
              data={countryData}
              header={t("Where_are_you_from")}
              type="in"
              setData={setCountryData}
              buttonLabel={t("Search__Select")}
              onClick={() => setShowCountrySearch(true)}
              infoText={t("Where_are_you_from_alert")}
              errorMessage={
                props?.showValidation &&
                form?.errors?.country &&
                getCheckedIds(countryData)?.length == 0 &&
                t("Please_Select_Country")
              }
            />
             <Drawer
            position="right"
            padding={"0px"}
            onClose={() => setShowCountrySearch(false)}
            opened={showCountrySearch}
          >
            <CountrySearch
              title={t("Where_are_you_from")}
              singleSelect={true}
              getData={setCountryData}
              passData={countryData}
              onHide={() => setShowCountrySearch(false)}
              savedData={
                userData?.origin_country?.length != 0
                  ? userData?.origin_country
                  : []
              }
            />
          </Drawer>
        </Grid.Col>
      </Grid>
    </form>
  );
}
export default UserProfileStep1;
