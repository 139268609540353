import * as React from "react";

function EyeSlash(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 13" fill="none" {...props}>
      <path
        d="M1.731.163a.558.558 0 000 .79l11.884 11.884a.558.558 0 00.79-.79L2.52.163a.558.558 0 00-.789 0zM0 6.431c0-.473.948-1.86 2.473-3.109l.774.81-.136.111a10.123 10.123 0 00-1.577 1.625 4.255 4.255 0 00-.406.637l.002.006c.013.054.035.126.074.217.076.183.202.411.384.672.364.522.91 1.115 1.603 1.674 1.396 1.126 3.249 2.008 5.18 2.008.468 0 .926-.055 1.371-.155l.88.92c-.711.221-1.465.35-2.251.35C3.85 12.198 0 8.106 0 6.432z"
        fill="#6A6A6A"
      />
      <path
        d="M5.208 6.803c0-.193.018-.382.051-.566l3.537 3.7a3.162 3.162 0 01-3.587-3.134zM7.609 3.733l3.794 3.97a3.164 3.164 0 00-3.794-3.97z"
        fill="#6A6A6A"
      />
      <path
        d="M13.33 9.033c-.118.103-.24.205-.365.304l.774.81c1.628-1.314 2.63-2.917 2.63-3.716 0-1.302-3.664-5.394-8.184-5.394-.936 0-1.835.191-2.673.503l.851.89a6.34 6.34 0 011.822-.277c1.928 0 3.735.88 5.09 1.964.671.538 1.2 1.101 1.55 1.58.176.24.294.443.365.596.037.082.054.133.06.156a1.25 1.25 0 01-.075.242 3.948 3.948 0 01-.356.66 8.387 8.387 0 01-1.489 1.682z"
        fill="#6A6A6A"
      />
    </svg>
  );
}

const MemoEyeSlash = React.memo(EyeSlash);
export default MemoEyeSlash;
