import React, { ReactNode, useEffect, useState, useRef, useContext } from "react";
import styled from "@emotion/styled";
import {  Grid } from "@mantine/core";
import OTPInputField from "ui/inputs/OTPInputfield";
import Button from "ui/buttons/Button";
import { useTranslation } from "react-i18next";
import { createOTP, getUserDetails, resendEmail, verifyOTP } from "services/user/User";
import { useHistory } from "react-router-dom";
import Loader from "ui/feedback/Loader";
import VerifyPhoneSuccess from "./VerifyPhone";
import { ErrorModal } from "ui/modals/ErrorModal";
import { UserContext } from "context/state";
interface VerifyPhoneProps {
  children?: ReactNode;
  label?: string | any;
  description?: string | any;
  show?: boolean;
  onHide?: () => void;
  contactDetails?: {
    phone?: string;
    email?: string;
    password?: string;
  };
  handleVerify?:any
  loadermode?:any
  verifyPhone?:any
}

const StyleHeader = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #123b3d;
`;

const StyleText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6E6E6E;
`;

const StyleBoldText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #123b3d;
  display: inline;
`;

const StyleWrapper = styled.div`
  margin: 5px;
  overflow-y: hidden;
  overflow-x: hidden;
  @media only screen and (min-width: 1000px) {
    margin: 0px auto 0px auto;
    max-width: 335px;
  }
`;
const FormContainer = styled.div`
  @media only screen and(min-width: 1000px) {
    /* margin: 0px 180px 0px 140px; */
  }
`;
const DisplayForMobile = styled.div`
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;
const DisplayForDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;

const StyleTimer = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #6a6a6a;
  text-align: center;
  margin: 10px 0 10px 0;
`;
const StyleTimeElement = styled.p`
  display: inline;
  padding: 2px;
  font-size: 16px;
  line-height: 16px;
`;
const StyleLoaderText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #123B3D;
    z-index: 20;
    margin: 20px;
    align-self: center;
    width: 246px;
    margin-top: 193px;
    position: relative;
    @media only screen and (min-width: 1000px) {
      margin-top: 130px;
  }
`;

export const VerifyPhoneOTP: React.FC<VerifyPhoneProps> = ({
  label,
  description,
  contactDetails,
  loadermode,
  verifyPhone,
  ...props
}) => {
  const [contactNumber, setContactNumber] = useState(contactDetails?.phone);
  const [resetTimer, setResetTimer] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otp, setOTP] = useState<string>("");
  const [verifyClicked, setVerifyClicked] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const {userId , setUserData, setIsSubscribed} = useContext(UserContext)

  const Min = useRef<HTMLInputElement>(null);
  const Sec = useRef<HTMLInputElement>(null);

  const TimerHandler = () => {
    let time = 120;
    let countdown: any = setInterval(update, 1000);
    function update() {
      let min = Math.floor(time / 60);
      let sec: any = time % 60;
      sec = sec < 10 ? "0" + sec : sec;
      if (Min.current) {
        Min.current.innerText = `${min}`;
      }
      if (Sec.current) {
        Sec.current.innerText = `${sec}`;
      }
      time--;
      if (min == 0 && sec == 0) {
        clearInterval(countdown);
        setDisabled(false);
      }
    }
  };

  const autoSubmitOTP=()=>{
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      // setTimeout(() => {
      //   ac.abort();
      // }, 1 * 60 * 1000);
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          setOTP(otp?.code)
          if (otp?.code && otp?.code.length === 4) {
            handleVerify(otp?.code)
          }
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          console.log(err);
        });
    }
  }

  useEffect(() => {
    autoSubmitOTP()
    TimerHandler();
    //setDisableInput(false);
    //setResetTimer(false)
  }, []);

  useEffect(() => {
    //TimerHandler();
    //setDisableInput(false);
    if (resetTimer) {
      TimerHandler();
      setResetTimer(false);
    } else {
      setResetTimer(false);
    }
  }, [resetTimer]);

  useEffect(() => {
    if (showVerified) {
      getUserDetails(userId)
      .then((response:any)=>{
        setUserData(response?.data);
        localStorage.setItem("userData", JSON.stringify(response?.data ?? ""));
        setIsSubscribed(response?.data?.is_subscribed);
      })
      setTimeout(() => {
        props?.handleVerify()
      }, 5000);
    }
  }, [showVerified]);

  const handleResend = () => {
    if (disabled === false) {
      setResetTimer(true);
      let data = {
        phone: contactNumber,
      };
      createOTP(data)
        .then((response) => { autoSubmitOTP() })
        .catch((error) => console.log(error));
      setDisabled(true);
    } else {
      //console.log('wait for timer to finish');
    }
  };

  const handleVerify = (code?:any) => {
    setVerifyClicked(true);
    let otpToSubmit = code ?? otp
    setShowLoader(true);
    let data = {
      phone: contactNumber,
      otp: otpToSubmit,
    };
    verifyOTP(data)
      .then((response) => {
        setShowLoader(false);
        if (response.success === true) {
          let data = {
            email: contactDetails?.email,
          };
          resendEmail(data)
            .then((response) => {})
            .catch((error) => console.log(error));
          setShowVerified(true);
          verifyPhone(true)
        } else {
          setShowError(true);
          setErrorText(response?.response?.data?.message);
        }
      })
      .catch((error) => console.log(error));
  };

  if (showLoader){
    loadermode(true);
    return (<div style={{marginTop:"42%", display:"flex", flexDirection:"column"}}>
    <Loader margin="40%" text={t("Please_Wait_Verifying_OTP")}/>
    </div>);
  }else{
    loadermode(false);
  }

    
  if (showVerified) return <VerifyPhoneSuccess />;
  return (
    <>
      <FormContainer>
        <StyleWrapper>
          <ErrorModal opened={showError} message={errortext} onClose={() => setShowError(false)} />
          {/* <BackButton>{t("Back")}</BackButton>
          <Grid columns={10} style={{ marginTop: "25px" }}>
            <Grid.Col span={10}>
              <StyleHeader>{t("Verify_Phone")}</StyleHeader>
            </Grid.Col>
          </Grid> */}
          <Grid columns={10}>
            <Grid.Col span={10}>
              <StyleText>
                {t("Please_enter_the_verification_code_sent_to") + " "}
                <StyleBoldText>{contactNumber}</StyleBoldText>
              </StyleText>
            </Grid.Col>
          </Grid>
          <Grid columns={10}>
            <Grid.Col span={10}>
              <OTPInputField
                setOTP={setOTP}
                otp={otp}
              />
            </Grid.Col>
          </Grid>
          <Grid columns={10} style={{ marginLeft: "40px", marginRight: "40px" }}>
            <Grid.Col span={10}>
              <StyleTimer>
                <StyleTimeElement ref={Min}>{"0"}</StyleTimeElement>
                <StyleTimeElement>:</StyleTimeElement>
                <StyleTimeElement ref={Sec}>{"00"}</StyleTimeElement>
              </StyleTimer>
            </Grid.Col>
          </Grid>
          <Grid columns={10}>
            <Grid.Col span={10}>
              <div style={{ textAlign: "center" }}>
                <StyleText style={{ fontSize: "16px", color:"#6A6A6A" }}>
                  {t("Didnt_receive_the_code") + " "}
                  <StyleBoldText
                    style={{
                      color: "#FF3000",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    onClick={() => handleResend()}>
                    {t("Resend")}
                  </StyleBoldText>
                </StyleText>
              </div>
            </Grid.Col>
          </Grid>
          <Grid columns={10}>
            <Grid.Col span={10}>
              <DisplayForMobile>
                <Button
                  variant='filled'
                  style={{
                    marginTop: "20px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "130%",
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                  onClick={() => handleVerify()}>
                  {t("Verify")}
                </Button>
              </DisplayForMobile>
              <DisplayForDesktop>
                <Button
                  variant='filled'
                  style={{
                    marginTop: "20px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "110%",
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                  onClick={() => handleVerify()}>
                  {t("Verify")}
                </Button>
              </DisplayForDesktop>
            </Grid.Col>
          </Grid>
        </StyleWrapper>
      </FormContainer>
    </>
  );
};
