import React, { useContext, useEffect, useState } from "react";
import Password from "ui/inputs/Password";
import { useForm, yupResolver } from "@mantine/form";
import Button from "ui/buttons/Button";
import { Box, Title } from "@mantine/core";
import MemoBlackChevronLeft from "ui/icons/BlackChevronLeft";
import PublicLayout from "layouts/PublicLayout";
import PrivateLayout from "layouts/PrivateLayout";
import BackButton from "ui/buttons/BackButton";
import { updateUserPassword } from "services/user/User";
import { UserContext } from "context/state";
import { useHistory } from "react-router-dom";
import { ErrorModal } from "ui/modals/ErrorModal";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const StyleWrapperBox = styled.div`
  margin: 0 20px 0 20px;
  width: auto;
  @media only screen and (min-width: 1000px) {
    margin: 26px 0 0 20px;
    max-width: 340px;
  }
`;

const StyleHeadWrapper = styled.div`
  position: relative;
  margin: 20px;
  @media only screen and (min-width: 1000px) {
    margin: 0 0 0 20px;
  }
`;

const StyleBackButton = styled.div`
  margin: -30px 0 40px 0;
  @media only screen and (min-width: 1000px) {
    margin: 0px;
  }
`;
const StyleSubmitButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  @media only screen and (min-width: 1000px) {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
`;

const StyleHeadTextWrapper = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
    position: absolute;
    top: 2px;
    left: 25px;
    max-width: 340px;
  }
`;

function ResetPassword() {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    newpassword: Yup.string()
      .required(t("Enter_Password"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        t("Password_must_contain_8_characters")
      ),
    verifypassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], t("Passwords_must_match"))
      .required(t("Please_enter_valid_password")),
  });

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      oldpassword: "",
      newpassword: "",
      verifypassword: "",
    },

    // validate: (values) => ({
    //     oldpassword: (values.oldpassword.length > 3 ? null : 'Enter Correct password'),
    //     newpassword: (values.newpassword.length > 3 ? null : 'Password length must be greater than 3'),
    //     verifypassword: (values.verifypassword !== values.newpassword ? 'Passwords did not match' : null)
    // })
  });

  const { userId } = useContext(UserContext);
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");

  // API call to update Password
  const handleSubmit = () => {
    let data = {};
    if (
      form.errors.newpassword == undefined &&
      form.errors.oldpassword == undefined &&
      form.errors.verifypassword == undefined &&
      form.values.newpassword == form.values.verifypassword &&
      form.values.newpassword &&
      form.values.oldpassword &&
      form.values.verifypassword
    ) {
      data = {
        current_password: form.values.oldpassword,
        new_password: form.values.newpassword,
        confirm_password: form.values.verifypassword,
      };
      updateUserPassword(data)
        .then((response) => {
          // console.log(`res`, response);
          if (response.success) {
            setErrorText(response.message);
            setShowError(true);
            form.reset();
          } else if (response?.response?.data?.success == false) {
            setErrorText(response?.response?.data?.message[0]);
            setShowError(true);
          }
        })
        .catch((error) => {
          console.log(`error`, error);
        });
    }
  };
  const { setActiveTap } = useContext(UserContext);
  useEffect(() => {
    setActiveTap(4);
  }, []);
  return (
    <PrivateLayout showMobileViewLogo={false} showBottomMenu={false}>
      <StyleHeadWrapper>
        <StyleBackButton>
          <BackButton />
        </StyleBackButton>
        <StyleHeadTextWrapper>{t("Password_and_securtity")}</StyleHeadTextWrapper>
      </StyleHeadWrapper>
      <form onSubmit={form.onSubmit((values) => handleSubmit())}>
        <StyleWrapperBox>
          <Password
            id='oldpassword'
            placeholder={t("Current_Password")}
            width='100%'
            {...form.getInputProps("oldpassword")}
          />
          <Password
            id='newpassword'
            placeholder={t("new_password")}
            width='100%'
            {...form.getInputProps("newpassword")}
          />
          <Password
            id='verifypassword'
            placeholder={t("Confirm_Password")}
            width='100%'
            {...form.getInputProps("verifypassword")}
          />
          <StyleSubmitButton>
            <Button variant='filled' type='submit' width='100%'>
              {t("Update_Password")}
            </Button>
          </StyleSubmitButton>
        </StyleWrapperBox>
      </form>
      <ErrorModal opened={showError} message={errortext} onClose={() => setShowError(false)} />
    </PrivateLayout>
  );
}

export default ResetPassword;
