import * as React from "react";

function EditIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
            <path d="M0 14.2505V18H3.74948L14.8079 6.94154L11.0585 3.19206L0 14.2505ZM17.7075 4.04194C18.0975 3.65199 18.0975 3.02208 17.7075 2.63213L15.3679 0.292459C14.9779 -0.0974865 14.348 -0.0974865 13.9581 0.292459L12.1283 2.12221L15.8778 5.87168L17.7075 4.04194Z" fill="white" />
        </svg>
    );
}

const MemoEditIcon = React.memo(EditIcon);
export default MemoEditIcon;
