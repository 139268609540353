import { Input, InputWrapper, TextInput, TextInputProps } from '@mantine/core'
import MemoSearch from 'ui/icons/Search'
import React from 'react'
import styled from '@emotion/styled'

interface InputFieldProps extends TextInputProps {
  width?: string,
  height?: string,
  color?: string,
  backgroundColor?: string,
  fontSize?: string,
  placeholder?: string,
  id?: string,
  error?: string,
  description?: string,
  label?: string,
  value?: string | number | undefined,
  sx?: any,
  mx?: any,
  placeholderColor?: string
};

const StyleSearchField = styled.div`
  width: 100%;
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  @media only screen and (min-width: 1000px) {
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px;
    }
}
`
const Search: React.FC<InputFieldProps> = ({ width, height, color, backgroundColor, fontSize, placeholderColor, ...props }) => {
  const styles = {
    width: width ?? "100%",
    height: height,
    fontSize: fontSize ?? "16px",
    fontFamily: "Poppins",
    lineHeight:'16px'
  }
  return (
    <StyleSearchField>
      <TextInput
        styles={(theme) => ({
          root: styles,
          input: {
            height: '54px',
            fontFamily: 'Poppins !important',
            lineHeight: '16px !important',
            '::placeholder': {
              color: placeholderColor ? placeholderColor : '#6E6E6E',
              fontFamily: 'Poppins !important',
              opacity: '1',
              lineHeight: '16px !important'
            }
          }
        })}
        icon={<MemoSearch fontSize={"19px"} />}
        radius={15}
        {...props}
      />
    </StyleSearchField>
  )
}

export default Search