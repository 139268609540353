import React from 'react'
import jwt_decode from "jwt-decode";
//rename to authenticate
export default function authenticate() {
    const token= localStorage.getItem("token") 
    //console.log("check token",token)
    let authenticated = token ? true:false
    //console.log("check flag",authenticated)
    if(!authenticated){
        return authenticated //return if no token present
    }
    let jwtDecoded ={
        exp:1,   //todo define jwt type
        iat:1,
        drupal:{}
    }
    if(token) jwtDecoded=jwt_decode(token)
    if(new Date() > new Date(jwtDecoded?.exp * 1000)){
        authenticated= false //if token has expired
        //console.log("check",new Date() > new Date(jwtDecoded?.exp * 1000))
    }
    return authenticated
}
