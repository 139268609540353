
import axiosInstance from "services/AxiosInstance"


export const login = (data: any) => {
    return axiosInstance.post('login?_format=json', data)
        .then((response) => response.data)
        //.then((response)=>DataValidator.parse(response))
        .catch((error) => error)
}

export const createAccount = (data: any) => {
    return axiosInstance.post('register', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const createOTP = (data: any) => {
    return axiosInstance.post('send_otp', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const verifyOTP = (data: any) => {
    return axiosInstance.post('verify_otp', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const forgotPassword = (data: any) => {
    return axiosInstance.post('forgot-password', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const createNewPassword = (data: any) => {
    return axiosInstance.post('create-new-password', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const resendEmail = (data: any) => {
    return axiosInstance.post('send-verification-email', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const getUserDetails = (data: any) => {
    return axiosInstance.get(`profile/${data}`)
        .then((response) => response.data)
        .catch((error) => error)
}
export const putUserDetails = (userId: any, data: any) => {
    return axiosInstance.put(`profile/${userId}`, data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const deleteUserCompany = (userId: any) => {
    return axiosInstance.delete(`/user-delete/${userId}`)
        .then((response) => response.data)
        .catch((error) => error)
}

export const updateUserPassword = (data: any) => {
    return axiosInstance.put('user/password/update', data)
        .then((response) => response.data)
        .catch((error) => error)
}


export const uploadData = (data: any) => {
    return axiosInstance.post('upload_user_file', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then((response) => response.data)
        .catch((error) => error)
}

export const deleteFiles = (data: any) => {
    return axiosInstance.delete(`delete-user-file/${data?.uid}/${data?.type}`)
        .then((response) => response.data)
        .catch((error) => error)
}

export const logOut = (data: any) => {
    return axiosInstance.post('logout', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const emailNotificationSetting = (data: {}) => {
    return axiosInstance.put(`settings`, data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const getCV = (data?:number)=>{
    return axiosInstance.get(`downloadcv${data ? '/'+data :''}`)
        .then((response) => response.data)
        .catch((error) => error.response.data)
}

export const addMobile =(data?:{})=>{
    return axiosInstance.post("add-mobile",data)
    .then((response) => response.data)
    .catch((error) => error.response.data)
}

export const deleteUser = (id:any)=>{
    return axiosInstance.delete(`user-delete/${id}`)
    .then((response)=> response.data)
    .catch((error)=>error)
}