import * as React from "react";

function arrowAccordion(props) {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" {...props}>
            <path d="M1.40039 1.79999L5.00039 5.39999L8.60039 1.79999" stroke="#FF5500" stroke-width="1.5" stroke-linecap="square" />
        </svg>

    );
}

const MemoArrowAccordion = React.memo(arrowAccordion);
export default MemoArrowAccordion;
