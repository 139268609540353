import axios from "axios";
import getBrowserLanguage from "helpers/get-browser-language";
//import { Header } from "./auth-header";
import i18n from "i18next";
import Swal from "sweetalert2";
const getToken = () => {
  let token = localStorage.getItem("token") ?? "";
  return token;
};
// console.log("Env link", process.env.REACT_APP_API_URL);
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://staging-api.jobpark.com/",
  headers: {
    Organisation: "kilowott",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers["Authorization"] = `${"Bearer" + " " + getToken()}`;
    config.headers["Accept-Language"] = `${
      getBrowserLanguage()
      // navigator?.language == "no"
      //   ? "nb"
      //   : navigator?.language == "en"
      //   ? "en"
      //   : navigator?.language == "pl" || navigator?.language == "pl-PL"
      //   ? "pl"
      //   : navigator?.language == "nb" || navigator?.language == "nb-NO"
      //   ? "nb"
      //   : "en" //set fall back language to English
    }`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (res) => {
//     console.log("response ---> check ", res);

//     if (res.status === 400) {
//       alert("something went wrong");
//     }
//     return Promise.resolve(res);
//   },
//   (e) => {
//     console.log("error", e);
//     // alert("something went wrong");

//     return Promise.reject(e);
//   }
// );
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status == 401 || status === 403) {
      Swal.fire({
        icon: "info",
        text: `${
          navigator?.language == "no"
            ? "Noe gikk galt."
            : navigator?.language == "en"
            ? "Something went wrong."
            : navigator?.language == "pl" || navigator?.language == "pl-PL"
            ? "Coś poszło nie tak."
            : navigator?.language == "nb" || navigator?.language == "nb-NO"
            ? "Noe gikk galt."
            : "Something went wrong." //set fall back language to English
        }`,
        color: "#123BD",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok",
        confirmButtonColor: "#ff3000",
        width: "300px",
        focusConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed && (status === 401 || status === 403)) {
          localStorage.removeItem("token");
        }
      });
    } else if (status >= 500) {
      Swal.fire({
        icon: "info",
        text: `${
          navigator?.language == "no"
            ? "Noe gikk galt."
            : navigator?.language == "en"
            ? "Something went wrong."
            : navigator?.language == "pl" || navigator?.language == "pl-PL"
            ? "Coś poszło nie tak."
            : navigator?.language == "nb" || navigator?.language == "nb-NO"
            ? "Noe gikk galt."
            : "Something went wrong." //set fall back language to English
        }`,
        color: "#123BD",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok",
        confirmButtonColor: "#ff3000",
        width: "300px",
        focusConfirm: true,
      });
    }
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.request.use(
//   (config) => {
//     if (!config) {
//       config = {};
//      }
//      if (!config.headers) {
//        config.headers = {};
//      }
//      config.headers["Content-Type"] = "application/json"
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// axios.interceptors.response.use((res) => {
//   console.log(res.data.json);
//   // you must return the response object after you are done
//   return res;
// });
// console.log(`LanguageDetector`, i18n, navigator);
export default axiosInstance;
