import styled from "@emotion/styled";
import { Box, Grid, Stack } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import MemoOrangeFilter from "ui/icons/OrangeFilter";
import Search from "ui/inputs/Search";
import Hand from "ui/icons/Hand.png";
import { useViewportSize } from "@mantine/hooks";
import MemoFilter from "ui/icons/Filter";
import MemoFilterArrow from "ui/icons/FilterArrow";
import Star from "assets/images/Star.png";
import Laptop from "assets/images/laptop.png";
import { UserContext } from "context/state";
import MemoFilterDash from "ui/icons/FilterDash";
import { useTranslation } from "react-i18next";
import BackButton from "ui/buttons/BackButton";
import { primaryColor } from "constants/colors";
import Button from "ui/buttons/Button";

interface props {
  width?: string;
}
const TitleWrapper = styled.div<props>`
  text-align: left;
  font-size: 24px;
  /* min-width: ${(p) => p?.width}; */
  width: 334px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #1e1e1e;
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
  margin-left: 0px;
  @media only screen and (min-width: 1000px) {
    padding-top: 0px;
  }
`;
const TitleWrapperMessage = styled.div<props>`
  text-align: left;
  font-size: 24px;
  /* min-width: ${(p) => p?.width}; */
  width: 344px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #1e1e1e;
  padding-top: 0px;
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  @media only screen and (min-width: 1000px) {
    padding-top: 0px;
    margin-left: 0px;
    width: 100%;
    margin-top: 0;
  }
`;

const StyledBackButton = styled(BackButton)`
  display: flex;
  align-items: flex-end;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const FilterWrapper = styled.div`
  position: absolute;
  bottom: 8%;
  left: 80%;
  z-index: 11;
  width: fit-content;
  height: fit-content;
`;
const FilterBackground = styled.div`
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, #ff3000 0%, #ff3000 100%);
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.45) !important;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
const TextAndSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
  /* width: 100%; */
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    margin-left: 0;
    align-items: center;
  }
`;
const FilterBy = styled.div`
  min-width: 129px;
  height: 43px;
  background: #ffffff;
  box-shadow: 0px 10px 35px rgba(64, 59, 75, 0.1);
  border-radius: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MobileManageButtonWrapper = styled.div`
  display: flex;
  width: 334px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
`

interface componentProps {
  setShowFilter?: any;
  isFavouriteScreen?: boolean;
  isMessageScreen: boolean;
  searchString?: string;
  setSearchString?: any;
  firstTime?: boolean;
  isFilterAtSide?: any; //todo make boolean
  setIsFilterAtSide?: any;
  showHeader?: boolean;
  selectMode?:boolean;
  setSelectMode?:()=>(void);
  shouldShowManageButton ?:boolean;
}
const HomeFavouriteComponent: React.FC<componentProps> = ({
  setShowFilter,
  isFavouriteScreen,
  isMessageScreen,
  searchString,
  setSearchString,
  firstTime,
  showHeader,
  selectMode,
  setSelectMode,
  shouldShowManageButton=false,
  ...props
}) => {
  const { height, width } = useViewportSize();
  const { role, userData ,isAdmin} = useContext(UserContext);
  const { t } = useTranslation();
  // const [isFilterAtSide, setIsFilterAtSide] = useState(false)
  useEffect(() => {
    if (!isFavouriteScreen && !isMessageScreen)
      if (width > 1000) {
        //todo move this 1000 to constants make this for all constants
        props?.setIsFilterAtSide(true);
      } else {
        props?.setIsFilterAtSide(false);
      }
  }, [width]);
  return (
    <>
      {/* {role === "employer" && !isFavouriteScreen && !props?.isFilterAtSide && <FilterWrapper ><FilterBackground><MemoFilterDash onClick={() => setShowFilter(true)} width="23px" height='16px' /></FilterBackground></FilterWrapper>} */}
      <Stack mx="auto" sx={{ padding: "0" }}>
        <InputContainer>
          {isMessageScreen
            ? showHeader && (
                <TitleWrapperMessage width="400px">
                  {/* <StyledBackButton goOnHome={true} /> */}
                  {t("Message")}{" "}
                  <img
                    src={Laptop}
                    height="22px"
                    style={{ marginBottom: "8px", marginLeft: "5px" }}
                    alt="Message"
                  />
                </TitleWrapperMessage>
              )
            : ""}
          <TextAndSearchWrapper>
            {isFavouriteScreen ? (
              <TitleWrapper width="155px">
                {t("Favourites")}{" "}
                <img
                  src={Star}
                  style={{ marginBottom: "8px", marginLeft: "5px" }}
                  alt="favourites"
                />
              </TitleWrapper>
            ) : (
              !isMessageScreen && (
                <TitleWrapper width="155px">
                  {t("Welcome")}{" "}
                  <img
                    src={Hand}
                    style={{ marginBottom: "8px", marginLeft: "5px" }}
                    alt="Welcome"
                  />
                </TitleWrapper>
              )
            )}
            {role === "employer" && !isMessageScreen && (
              <Search
                value={searchString}
                placeholder={t("Search")}
                placeholderColor={userData?.is_subscribed ? "#282B2C" : ""}
                onChange={(event) => setSearchString(event.currentTarget.value)}
                width="334px"
                height="54px"
              />
            )}
          </TextAndSearchWrapper>
          {isAdmin &&
            shouldShowManageButton &&
            props?.isFilterAtSide &&
            !isFavouriteScreen &&
            !isMessageScreen && (
              <div style={{ marginRight: "11px" }}>
                <Button
                  variant={selectMode ? "filled" : "default"}
                  width="fit-content"
                  height="43px"
                  radius={10}
                  color={selectMode ? "#fff" : primaryColor}
                  backgroundColor={selectMode ? primaryColor : "transparent"}
                  border={selectMode ? "none" : `1px solid ${primaryColor}`}
                  onClick={setSelectMode}
                >
                  {t("manage")}
                </Button>
              </div>
            )}
          {role === "employer" && !isFavouriteScreen && props?.isFilterAtSide && (
            <FilterBy onClick={() => setShowFilter()}>
              <MemoFilter
                style={{ paddingLeft: "8px", paddingRight: "6px" }}
                width="19px"
                height="13px"
              />{" "}
              {t("Filter_By")}{" "}
              <MemoFilterArrow
                width="11px"
                height="8px"
                style={{ paddingLeft: "19px" }}
              />
            </FilterBy>
          )}
        </InputContainer>
        {isAdmin &&
          shouldShowManageButton &&
          !props?.isFilterAtSide &&
          !isFavouriteScreen &&
          !isMessageScreen && (
            <MobileManageButtonWrapper>
              <Button
                variant={selectMode ? "filled" : "default"}
                width="fit-content"
                height="43px"
                radius={10}
                color={selectMode ? "#fff" : primaryColor}
                backgroundColor={selectMode ? primaryColor : "transparent"}
                border={selectMode ? "none" : `1px solid ${primaryColor}`}
                onClick={setSelectMode}
              >
                {t("manage")}
              </Button>
            </MobileManageButtonWrapper>
          )}
      </Stack>
    </>
  );
};

export default HomeFavouriteComponent;
