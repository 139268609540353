import * as React from "react";

function FAQ(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M26.15 13.4h-1.7v7.65H13.4v1.7c0 .468.383.85.85.85h9.35L27 27V14.25a.852.852 0 00-.85-.85zm-3.4 5.1v-7.65a.852.852 0 00-.85-.85H10.85a.852.852 0 00-.85.85v11.9l3.4-3.4h8.5c.468 0 .85-.383.85-.85z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoFAQ = React.memo(FAQ);
export default MemoFAQ;
