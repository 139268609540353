import * as React from "react";

function arrow(props) {
    return (
        <svg width="11" height="19" viewBox="0 0 11 19" fill="none" {...props}>
            <path d="M2 2L9.5 9.5L2 17" stroke="#FF3000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

const MemoArrow = React.memo(arrow);
export default MemoArrow;
