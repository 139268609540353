import { Box } from "@mantine/core";
import React from "react";
import Button from "ui/buttons/Button";
import Password from "ui/inputs/Password";
import { useForm } from "@mantine/form";
import InputField from "ui/inputs/Inputfield";
import { Card, Image, Text, Badge, Group } from '@mantine/core';
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';

function UnsubscribeMail() {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      companyname: "",
    },

    validate: (values) => ({
      name: values.name.length > 2 ? null : "Name should be greater than 2 characters",
      email: /^\S+@\S+$/.test(values.email) ? null : t("Invalid_email_address"),
      phone: values.name.length > 10 ? null : "Invalid number",
      companyname: values.name.length > 2 ? null : "Name should be greater than 2 characters",
    }),
  });
  const Heading = styled.h2`
  color: rgb(255, 48, 0);
  text-align:center;
  border-bottom: solid 1px #e1e1e1;
  font-size: 36px;
`;
const TextContent = styled.p`
display: flex;
justify-content: center;
    color: black;
    font-size: 20px;
`;
  return (
    <>
       <div style={{ width: 940, margin: 'auto' }}>
      <Card shadow="sm" p="md" radius="sm"  withBorder style={{marginTop:"83px"}}>
      <Card.Section>
        <Heading>Done!</Heading>
      </Card.Section>

        <TextContent> Your email ID has been succesfully unsubscribed to our mailing list(s).</TextContent>
       

    </Card>
    </div>
    </>
  );
}

export default UnsubscribeMail;
