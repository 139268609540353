import axiosInstance from "services/AxiosInstance";

export const getFAQData = (role: string) => {
  return axiosInstance
    .get(
      `api/faq?Accept-Language=${
        navigator?.language == "no"
          ? "nb"
          : navigator?.language == "en"
          ? "en"
          : navigator?.language == "pl" || navigator?.language == "pl-PL"
          ? "pl"
          : navigator?.language == "nb" || navigator?.language == "nb-NO"
          ? "nb"
          : "en" //set fall back language to English
      }&role=${role}&_format=json`
    )
    .then((response) => response.data)
    .catch((error) => error);
};
