import React, { ReactNode, useEffect, useState } from 'react'
import { Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import CheckboxGroup from 'ui/inputs/CheckboxGroup'
import { useTranslation } from 'react-i18next'
import { getAllEmployers } from 'services/admin/admin'
import Loader from 'ui/feedback/Loader'
interface EmployerListingProps {
  children?: ReactNode
  show?: boolean
  onHide: ()=>void
  title?: string,
  dontHideDrawer?:boolean,
  buttonText?:string
  updateEmployersAndSubmit:(employerIds:string[])=>void
}

interface Employer{
    id:string,
    name:string,
    checked?:boolean,
    show?:boolean
}
interface Employers{
    headerName:'',
    data:Employer[]
}
const EmployerSelection: React.FC<EmployerListingProps> = ({
  children,
  title,
  dontHideDrawer,
  buttonText,
  updateEmployersAndSubmit,
  onHide,
  ...props
}) => {
  const [resetData, setRestData] = useState(false)

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)
  const [employers, setEmployers] = useState<Employers[]>([]) //stores all employers
  const [searchString, setSearchString] = useState('') //stores search string
  useEffect(() => {
    getAllEmployers()
    .then((res)=>{
        setEmployers(formatEmployersForCheckbox(res))
        setLoader(false)
    })
    .catch((error)=>console.log(error))
  }, [])

  //function transforms api data to the format suited for checkbox component
  const formatEmployersForCheckbox=(list:Employer[])=>{
    let transformer:Employers[] = [{
        headerName:'',
        data: list?.map((employer:Employer)=>{
            return{
                ...employer,
                name:employer?.name!=''? employer.name : "NAME NOT AVAILABLE",
                checked:false,
                show:true
            }
        })
    }]
    return transformer
  }
  useEffect(()=>{
    if(addElements && employers.length >0){
        let checkedItems = getCheckedEmployerIds(employers) ?? [""]
        updateEmployersAndSubmit(checkedItems)
    }
  },[addElements,employers])
  const getCheckedEmployerIds = (data: Employers[]) => {
    let checkedIds: string[] = [];
    data?.forEach((objectData: Employers) => {
      objectData?.data?.forEach((checkboxData: Employer) => {
        if (checkboxData.checked) {
          checkedIds.push(checkboxData.id);
        }
      });
    });
    return checkedIds;
  };
  const { t } = useTranslation()
  if(loader) return <Loader margin="40%"/>

  return (
    <Stack align={'center'}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={onHide}
        showSearch={true}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        setAddElements={setAddElements}
        searchString={searchString}
        setSearchString={setSearchString}
        children={
          <>
            {employers?.map((dataGroup: any, index: any) => {
              return (
                <CheckboxGroup
                  checkAll={true}
                  data={employers[index]?.data?.length ? employers : []}
                  header={dataGroup.headerName}
                  getData={setEmployers}
                  index={index}
                  resetData={resetData}
                  setRestData={setRestData}
                  addElements={addElements}
                  setAddElements={setAddElements}
                  showAddButton={employers?.length == index + 1 ? true : false}
                  setShow={onHide}
                  dontHideDrawer={dontHideDrawer}
                  submitButtonText={t("send")}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
              )
            })}
          </>
        }
      />
    </Stack>
  )
}
export default EmployerSelection