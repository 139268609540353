import * as React from "react";

function Like(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 21" fill="none" {...props}>
      <path
        d="M6.36 1.958C6.443.709 7.58-.257 8.808.061l.335.088c.594.155 1.121.61 1.298 1.29.283 1.09.684 3.354.08 6.024.189-.027.378-.05.568-.068.915-.087 2.142-.096 3.229.28.665.231 1.276.91 1.54 1.7.236.711.205 1.553-.3 2.316.074.159.132.323.177.485.099.36.145.757.145 1.143s-.046.783-.145 1.143c-.05.18-.116.365-.206.54.217.517.138 1.094-.004 1.534-.144.434-.356.84-.626 1.203.07.203.098.417.098.621 0 .408-.114.835-.325 1.219-.428.782-1.28 1.421-2.482 1.421H7.7c-.777 0-1.374-.108-1.882-.291a6.094 6.094 0 01-1.245-.647l-.061-.04c-.647-.41-1.282-.813-2.654-.964C.875 18.948 0 18.123 0 16.993v-5.344c0-1.135.879-1.913 1.741-2.157 1.09-.31 2.02-1.051 2.736-1.884.719-.837 1.173-1.71 1.333-2.189.256-.768.457-2.056.55-3.46v-.001z"
        fill={props?.color ? props.color : "#6E6E6E"}
      />
    </svg>
  );
}

const MemoLike = React.memo(Like);
export default MemoLike;
