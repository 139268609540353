import React, { ReactNode, useState, useEffect } from 'react'
import { Box, Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import Checkbox from 'ui/inputs/CheckboxGroup'
import styled from '@emotion/styled'
import { LanguageStore } from 'stores/taxnomies/language.store'
import { useTranslation } from 'react-i18next'
import isNone from 'helpers/isNone'

interface LanguageSearchProps {
  children?: ReactNode
  show?: boolean
  onHide?: any
  getData: any
  passData: any
  title?: string
  savedData?: any // Pass the Saved Data
  selectedPrimarySecondaryLanguageIds?: any
  setAddClickedFor?:any
  dontHideDrawer?:boolean,
  primaryLanguage?:boolean,
  showNone?:boolean
}

const CheckBoxWrapper = styled(Stack)`
  width: auto;
  height: auto;
  /* overflow-y: auto; */
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
`
const LanguageSearch: React.FC<LanguageSearchProps> = ({
  children,
  passData,
  getData,
  title,
  savedData = [],
  selectedPrimarySecondaryLanguageIds = [],
  setAddClickedFor,
  dontHideDrawer,
  showNone=false,
  ...props
}) => {
  const [data, setData] = useState<any>([])

  //State to reset selected checkbox
  const [resetData, setRestData] = useState(false)

  const [searchString, setSearchString] = useState('')

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)

  const [noneIndex,setNoneIndex]= useState<number|null>(null)

  // Function to display saved data
  const savedDataHandler = (data: any) => {
    let flag = false
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true
    })
    return flag
  }

  let LanguageStoreData = Array.from(LanguageStore.data, ([id, name]) => ({
    id,
    name,
  }))
  //console.log(`savedData`, savedData);
 
  let LanguageStoreDataUpdated:{ headerName: string, data: any }[] = [
    {
      headerName: '',
      data: LanguageStoreData.map((obj) => ({
        ...obj,
        checked: savedData?.length != 0 ? savedDataHandler(obj) : false,
        show: true,
      })),
    },
  ]
  //console.log(LanguageStoreDataUpdated[0].data,"LanguageStoreDataUpdated");
  
  //LanguageStoreDataUpdated[0].data.push({ id: "250", name: "None",checked: true, show: true})

  useEffect(() => {
    let sortedData = [];
    if (!showNone) {
      LanguageStoreDataUpdated = [
        {
          headerName: "",
          data: LanguageStoreDataUpdated[0].data.filter(
            (obj: any) => !isNone(obj.name)
          ),
        },
      ];
    } else {
      sortedData = LanguageStoreDataUpdated[0].data;
      let none: any = {};
      const indexOfNone = sortedData.findIndex(
        (item: any) => isNone(item.name)
      );
      if (indexOfNone != null && showNone && indexOfNone > -1) {
        none = sortedData.splice(indexOfNone, 1);
      }
      if (none.length > 0) sortedData.unshift(none[0]);
      LanguageStoreDataUpdated = [
        {
          headerName: "",
          data: sortedData,
        },
      ];
      setNoneIndex(0)
    }
    setData(passData);
    getData(LanguageStoreDataUpdated)
  }, []);

  useEffect(()=>{
    setData(LanguageStoreDataUpdated)
  },[savedData])

  const { t } = useTranslation();

  // console.log(`lang search`, data, savedData, passData);
  return (
    <Stack
      align={'center'}
      sx={{ minHeight: '100%' }}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={props.onHide}
        showSearch={false}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        searchString={searchString}
        setSearchString={setSearchString}
        setAddElements={setAddElements}
        children={
          <CheckBoxWrapper>
            {data?.map((dataGroup: any, index: any) => {
              return (
                <Checkbox
                  checkAll={true}
                  data={passData?.length != 0 ? passData : data[index]?.data.length ? data : []}
                  header={dataGroup.headerName}
                  getData={getData}
                  index={index}
                  resetData={resetData}
                  setRestData={setRestData}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  addElements={addElements}
                  setAddElements={setAddElements}
                  languageCheck={true}
                  selectedIds={selectedPrimarySecondaryLanguageIds}
                  showAddButton={data?.length == index + 1 ? true : false}
                  setShow={props.onHide}
                  setAddClickedFor={setAddClickedFor}
                  dontHideDrawer={dontHideDrawer}
                  primaryLanguage={props.primaryLanguage}
                  noneIndex={noneIndex}
                  secondaryLanguage={showNone}
                />
              )
            })}
          </CheckBoxWrapper>
        }
      />
    </Stack>
  )
}

export default LanguageSearch
