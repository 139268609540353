import React, { useContext } from "react";
import styled from "styled-components";
import { Box } from "@mantine/core";
import MemoFavouriteOrange from "ui/icons/FavouriteOrange";
import MemoFavouriteGrey from "ui/icons/FavouriteGrey";
import MemoStartChat from "ui/icons/StartChat";
import CvIcon from "ui/icons/CvIcon";
import moment from "moment";
import Profile from "assets/images/Profile.png";
import CompanyLogo from "assets/images/CompanyLogo.png";
import { UserContext } from "context/state";
import NameSplitter from "helpers/name-splitter/NameSplitter";
import { useTranslation } from "react-i18next";

interface CardsProps {
  type?: number;
  profileImageURL?: string;
  profileName?: string;
  time?: string;
  location?: string;
  description?: string;
  activeStatus?: boolean;
  onChange?: () => void;
  favourite?: boolean;
  cvstatus?:boolean;
  data?: {};
  onClick: () => void;
  chatInitiated: () => void;
  setFavourite: () => void;
  borderColor?:string,
}

const StyleCard = styled.div`
  background: #ffffff;
  width: 335px;
  min-height: 136px;
  height: auto;
  box-shadow: 0px 10px 35px -10px #f7f7f7;
  border-radius: 20px;
  position: relative;
  word-wrap: break-word;
  margin-top: 18px;
  cursor: pointer;
  @media only screen and (min-width: 1000px) {
    margin-top: 0;
    /* margin-right:21px; */
    margin-bottom: 15px;
  }
`;

const StyleCardCorner = styled(Box)`
  position: absolute;
  background: #e7e7e7;
  width: 67px;
  height: 136px;
  right: 0;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const StyleContainer = styled.div`
  position: absolute;
`;

const StyleProfileContainerMain = styled(Box)`
  position: absolute;
`;

const StyleContainerProfile = styled(Box)`
  position: relative;
`;

const StyleContainerButton = styled(Box)`
  position: relative;
  height: 80%;
  margin-top: 20%;
`;

const StyleButton = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 56px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

const StyleProfileContainer = styled(Box)`
  width: 250px;
  position: relative;
  margin-left: 15px;
  margin-top: 20px;
`;

const StyleDescriptionContainer = styled.div`
  position: absolute;
  width: 220px;
  margin-top: 85px;
  margin-left: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6e6e6e;
  height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const StyleCVContainer = styled.div`
  position: relative;
  font-size: 30px;
  left: 230px;
  top: 90px;
`;

const StyleProfile = styled(Box)`
  position: relative;
  width: 58px;
  height: 58px;
`;

const StyleIndicator = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border: 2px solid #ffffff;
  border-radius: 50%;
  bottom: 0px;
  right: -4px;
`;

const StyleAvatar = styled.img`
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 15px;
  object-fit: contain;
`;

const StyleProfileName = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #123b3d;
  max-width: 180px;
  max-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyleProfileDetails = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6e6e6e;
  margin-top: 3px;
  margin-bottom: 4px;
  margin-top: 4px;
`;

const IconWrapper = styled(Box)`
  width: 28px;
  height: 28px;
  border-radius: 56px;
  background-color: #ffffff;
`;
const Cards: React.FC<CardsProps> = ({
  type, // 1 - emplayee & 2 - candidate
  profileImageURL,
  profileName,
  location,
  time,
  description,
  onChange,
  activeStatus = false,
  favourite,
  cvstatus=false,
  borderColor="",
  onClick,
  chatInitiated,
  setFavourite,
  ...props
}) => {
  const { role,isAdmin } = useContext(UserContext);
  const { t } = useTranslation();

  //function callbacks parent setfavorite and chatInitiated functions
  function handleClickForChatAndFavoriteButtons(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    calledFor: "favourite" | "chat"
  ) {
    event.stopPropagation();
    if (!isAdmin) {
      if (calledFor === "favourite") setFavourite();
    }
    if (calledFor === "chat") chatInitiated();
  }

  return (
    <>
      <StyleCard
        style={{
          border:
            borderColor != ""
              ? `2px solid ${borderColor}`
              : "2px solid #f7f7f7",
        }}
        onClick={onClick}
      >
        <StyleContainer>
          <StyleContainerProfile>
            <StyleProfileContainerMain>
              <StyleProfileContainer>
                <div style={{ position: "absolute" }}>
                  <StyleProfile>
                    <StyleAvatar
                      alt="profile picture"
                      src={
                        profileImageURL
                          ? profileImageURL
                          : role === "employer"
                          ? Profile
                          : CompanyLogo
                      }
                    />
                    {/* <StyleIndicator style={{ backgroundColor: activeStatus ? "#4CAF50" : "" }} /> */}
                  </StyleProfile>
                </div>
                <div style={{ position: "absolute", left: "70px" }}>
                  <StyleProfileName>{profileName}</StyleProfileName>
                  <StyleProfileDetails>{location}</StyleProfileDetails>
                  {role === "employer" && (
                    <StyleProfileDetails>
                      {moment().diff(time, "years", false)} {t("years")}
                    </StyleProfileDetails>
                  )}
                </div>
              </StyleProfileContainer>
            </StyleProfileContainerMain>
            <StyleDescriptionContainer>{description}</StyleDescriptionContainer>
            {cvstatus == true ? (
              <StyleCVContainer>
                <CvIcon />
              </StyleCVContainer>
            ) : (
              ""
            )}
          </StyleContainerProfile>
        </StyleContainer>
        <StyleCardCorner>
          {type == 1 && (
            <StyleButton
              style={{ cursor: isAdmin ?"not-allowed" : "pointer" }}
              onClick={(event) => handleClickForChatAndFavoriteButtons(event,'favourite')}
            >
              {favourite ? (
                <MemoFavouriteOrange width="13px" height="17px" />
              ) : (
                <MemoFavouriteGrey width="13px" height="17px" />
              )}
            </StyleButton>
          )}

        </StyleCardCorner>
      </StyleCard>
    </>
  );
};

export default Cards;
