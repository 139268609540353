import React, { ReactNode, useState } from "react";
import { Modal, ModalProps } from "@mantine/core";
import styled from "styled-components";
import MemoI_Big from "../icons/I_Big";
import Button from "../../ui/buttons/Button";
import { useTranslation } from "react-i18next";

interface ProfilCreationAlertModelProps extends ModalProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: () => void;
  message?: string;
  callFunction?: () => void;
}
const StyleBodyText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #6e6e6e;
  padding: 10px;
`;

export const ErrorModal: React.FC<ProfilCreationAlertModelProps> = ({
  children,
  show,
  onHide,
  message,
  callFunction,
  ...props
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);
  return (
    <>
      <Modal
        centered
        {...props}
        overlayOpacity={0.55}
        overlayBlur={5}
        // size={400}
        styles={{
          modal: {
            borderRadius: "20px",
            height: "auto",
            width: "auto",
            maxWidth: "360px",
            minWidth: "300px",
            margin: "10px",
            zIndex: 500,
          },
          close: { display: "none" },
          title: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
        title={<MemoI_Big width='50px' height='50px' />}>
        <StyleBodyText>{message ? message : t("default_error_message")}</StyleBodyText>
        <Button
          style={{
            wordWrap: "break-word",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onClick={() => {
            callFunction !== undefined ? callFunction() : props.onClose();
          }}
          variant='filled'
          height='50px'
          fontSize='18px'>
          {t(`Okay`)}
        </Button>
      </Modal>
    </>
  );
};
