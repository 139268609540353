import { Grid, Stack } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { UserContext } from 'context/state'
import getBrowserLanguage from 'helpers/get-browser-language'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VerifyPhoneOTP } from 'screens/verify-phone/VerifyPhoneOTP'
import { addMobile, createOTP } from 'services/user/User'
import styled from '@emotion/styled'
import BackButton from 'ui/buttons/BackButton'
import Button from 'ui/buttons/Button'
import MemoChevronDown from 'ui/icons/ChevronDown'
import MemoPhoneIcon from 'ui/icons/PhoneIcon'
import InputField from 'ui/inputs/Inputfield'
import { StyleSelectField } from 'ui/inputs/Select'
import { ErrorModal } from 'ui/modals/ErrorModal'
import * as Yup from 'yup'
import SeperatePhoneAndCode from 'screens/profile-creation/SeperatePhoneAndCode'
import { getCountryCodes } from 'constants/countrycodes'

const StyleSpacing = styled.div`
  margin: 0 0 24px 0;
  @media only screen and (min-width: 1000px) {
  }
`
interface propsheaded{
  addmargin?:string
}
const StyleHeader = styled.p<propsheaded>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #123b3d;
  margin: 0 0 19px 5px;
  @media only screen and (min-width: 1000px) {
    margin-left: ${props => props?.addmargin ? props?.addmargin :""};
  }
 
`;






const StyleError = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  display: flex;
  font-family: Poppins;
`

interface AddPhoneProps{
    setShowMobileVerify?:any
}

const AddPhone:React.FC<AddPhoneProps> =(props)=> {
    const { t } = useTranslation()
    const{showDropdown, setShowDropdown,loading,userData,role } = useContext(UserContext)
   
    const [numberadded,setNumberAdded] = useState(false)
    const [loader,setLoader] = useState(false)
    const [verified,setVerified] = useState(false)
    const [showError, setShowError] = useState(false);
    const countryCodes = getCountryCodes()
  const [errortext, setErrorText] = useState("");
    const schema = Yup.object().shape({
        phone: Yup.string()
          .matches(/^[0-9]*$/, t('Invalid_phone_number'))
          .required(t('Please_enter_phone_number')),
        code: Yup.string()
          .matches(/^(--.+|(?!--).*)$/, t('Please_select_country_code'))
          .required(t('Please_enter_phone_number')),
    
      })
    const form = useForm({
        schema: yupResolver(schema),
        initialValues: {
          code: '--',
          phone: '',
        },
      })

    const handleBackButton = ()=>{
        if(numberadded){
            setNumberAdded(false)
        }
        else{
            handleVerified()
        }
    }
    const handleNumberAdd=()=>{
        let data ={
            phone:form.values.code + form.values.phone
        }
        addMobile(data)
        .then((res:any)=>{
            if(res?.success){
                setNumberAdded(true)
                createOTP(data)
            }
            else{
              setErrorText(res?.message[0])
              setShowError(true)
            }
        })
    }
    const handleVerified=() =>props?.setShowMobileVerify()
  useEffect(()=>{
    //extracting phone and code from phone number string
    let phonenumberToPass = userData.phone ? userData.phone :""
    if(role==="employer" && phonenumberToPass!="" && phonenumberToPass!="+0000000000"){
      let seperated:{
        code?:string,
        phoneNumber?:string
      }= SeperatePhoneAndCode(phonenumberToPass)
      if(seperated?.code && seperated?.phoneNumber){
        form.setFieldValue("code",`+${seperated?.code}`)
        form.setFieldValue("phone",seperated?.phoneNumber)
      }
    }
  },[userData?.phone])
  return (
    <Stack sx={{padding:"0 20px 0 20px", gap:"0"}}>
        
        {loader==false&&verified==false?
        <>
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => setShowError(false)}
      />
        <BackButton
        onButtonClick ={()=> handleBackButton()}
        addPhone={true}
        >{t("Back")}</BackButton>
          <Grid columns={10} style={{ marginTop: "27px" }}>
            <Grid.Col span={10}>
              <StyleHeader addmargin={numberadded? "30px":""} >{t("Verify_Phone")}</StyleHeader>
            </Grid.Col>
          </Grid>
        </>:""
        
          }

    {numberadded === false && <form onSubmit={form.onSubmit(() => handleNumberAdd())}>
    <StyleSpacing>
                <InputField
                  id='phone'
                  phoneType={true}
                  placeholder={t('Phone')}
                  icon={
                    <div style={{ position: 'relative' }}>
                      <StyleSelectField>
                        <label className='dropdown'>
                          <div
                            className='dd-button'
                            onClick={(e) => {
                              e.preventDefault()
                              setShowDropdown(!showDropdown)
                            }}
                            style={{
                              width: '65px',
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              marginRight: '10px',
                              borderRadius: '0',
                            }}>
                            {form?.values?.code}
                            <MemoChevronDown
                              className='dd-button-logo'
                              style={{ marginRight: '10px' }}
                            />
                          </div>

                          <input
                            type='checkbox'
                            className='dd-input'
                            checked={showDropdown}
                            id={form?.values?.code}
                          />

                          <ul
                            id='dd-menuID'
                            className='dd-menu'
                            style={{
                              zIndex: '200',
                              width: 'auto',
                              maxHeight: '300px',
                              overflow: 'auto',
                              borderRadius: '10px',
                            }}>
                            {countryCodes?.length != 0 &&
                              countryCodes
                                ?.sort((a: any, b: any) =>
                                  t(a?.label)?.localeCompare(
                                    t(b?.label),
                                    `${getBrowserLanguage()}`,
                                    {
                                      ignorePunctuation: true,
                                    }
                                  )
                                )
                                ?.map((item: any) => {
                                  return (
                                    <li
                                      style={{
                                        zIndex: '200',
                                        width: 'auto',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '10px',
                                      }}
                                      onClick={() => {
                                        form.setFieldValue('code', item?.value)
                                        setShowDropdown(false)
                                      }}>
                                      {/* {`${item?.label} ${item?.value}`} */}
                                      <div> {t(`${item?.label}`)}</div>
                                      <div> {`${item?.value}`}</div>
                                    </li>
                                  )
                                })}
                          </ul>
                        </label>
                      </StyleSelectField>
                      <MemoPhoneIcon
                        width='16.03px'
                        height='24.26px'
                        style={{ position: 'absolute', top: '5px', left: '90px' }}
                      />
                    </div>
                  }
                  {...form.getInputProps('phone')}
                />
                <StyleError>{form?.errors?.code}</StyleError>
                <StyleError>{form?.errors?.phone}</StyleError>
              </StyleSpacing>
              <Button
                type='submit'
                fontWeight={600}
                fontSize={"16px"}
                lineHeight={"16px"}
                variant='filled'>
                {t('Get_OTP')}{' '}
              </Button>
              </form>}
              {numberadded && 
                <VerifyPhoneOTP
                 contactDetails={{phone:form.values.code + form.values.phone}}
                 handleVerify={()=>handleVerified()}
                 loadermode={setLoader}
                 verifyPhone={setVerified}
                 />
              }
        </Stack>
  )
}

export default AddPhone