import styled from "@emotion/styled";
import { fontBlack, primaryColor } from "constants/colors";
import { useTranslation } from "react-i18next";
import TickGrey from "../../assets/images/Tick_Grey.png";
import TickOrange from "../../assets/images/Tick_Orange.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 400;
  color: ${fontBlack};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
`;
interface StepProps {
  step?: string;
}
const Experience = styled.div<StepProps>`
  min-width: 115px;
  max-width: 115px;
  margin-left: 28%;
  text-align: center;
  font-weight: ${(props) => (props?.step == "2" ? 600 : 400)};
  @media only screen and (min-width: 1000px) {
    margin-left: 30%;
  }
`;
const BasicInformation = styled.div`
  min-width: 115px;
  max-width: 115px;
  margin-right: 28%;
  text-align: center;
  @media only screen and (min-width: 1000px) {
    margin-right: 30%;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 48px;
`;

const Seperator = styled.div<StepProps>`
  width: 224px;
  background: ${(props) => (props?.step == "2" ? primaryColor : "#C4C4C4")};
  height: 3px;
`;
const SeperatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CommonProps {
  step?: string;
}
export const CommonPageStepLabel: React.FC<CommonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconWrapper>
            <img width="24px" height="24px" src={TickOrange} alt="tick" />
          </IconWrapper>
          <SeperatorWrapper>
            <Seperator step={props?.step} />
          </SeperatorWrapper>
          <IconWrapper>
            {props?.step == "2" ? (
              <img width="24px" height="24px" src={TickOrange} alt="tick" />
            ) : (
              <img width="24px" height="24px" src={TickGrey} alt="tick" />
            )}
          </IconWrapper>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13px",
          }}
        >
          <BasicInformation>{t("basic_information")}</BasicInformation>
          <Experience step={props?.step}>{t("Last_Step")}</Experience>
        </div>
      </Wrapper>
    </>
  );
};
