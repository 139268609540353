import React, { useContext } from "react";
import styled from "@emotion/styled";
import Home from "ui/icons/Home";
import MemoMessage from "ui/icons/Message";
import MemoLike from "ui/icons/Like";
import MemoSetting from "ui/icons/Setting";
import { useHistory } from "react-router-dom";
import { UserContext } from "context/state";
import MemoFilterDash from "ui/icons/FilterDash";
import { useTranslation } from "react-i18next";
import getPathForHome from "helpers/GetPathForHome";

const MobileWrapper = styled.div`
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  float: left;
  width: 25%;
`;

const Element = styled.div`
  text-align: center;
`;

const MenuBarMobileWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0px;
  background-color: #fff;
  border-radius: 30px 30px 0px 0px;
  z-index: 10;
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const MenuText = styled.div`
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
    font-size: 14px;
    color: #6e6e6e;
  }
`;

const FilterWrapper = styled.div`
  position: fixed;
  bottom: 112px;
  right: 26px;
  z-index: 11;
  width: fit-content;
  height: fit-content;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const FilterBackground = styled.div`
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, #ff3000 0%, #ff3000 100%);
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.45) !important;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

interface MobileLayoutProps {
  backgroundImg?: any;
  position?: "top" | "center";
  setActiveTap?: any;
  activeTap?: number;
  setShowFilter?: any;
  isFavouriteScreen?: any;
  isFilterAtSide?: any;
}

const MobileLayout: React.FC<MobileLayoutProps> = (props: any) => {
  const history = useHistory();
  const { role, activeTap, setActiveTap, numberOfUnreadMessages,isAdmin } = useContext(UserContext);
  const contaxtData = useContext(UserContext);
  const { t } = useTranslation();
  //console.log(`state`, contaxtData)
  return (
    <>
      {role === "employer" && !props?.isFavouriteScreen && !props?.isFilterAtSide && (
        <FilterWrapper>
          <FilterBackground onClick={() => props?.setShowFilter()}>
            <MemoFilterDash width='23px' height='16px' />
          </FilterBackground>
        </FilterWrapper>
      )}
      <MenuBarMobileWrapper>
        <MobileWrapper
          onClick={() => {
            setActiveTap(1);
            history.push(getPathForHome(role,isAdmin))
          }}>
          <Element>
            <Home width='20px' height='20px' color={activeTap == 1 ? "#FF3000" : ""} />
            <MenuText style={{ color: activeTap == 1 ? "#FF3000" : "" }}>{t("Home")}</MenuText>
          </Element>
        </MobileWrapper>
        <MobileWrapper
          onClick={() => {
            setActiveTap(2);
            history.push("/message");
          }}>
          <Element>
            <MemoMessage width='20px' height='20px' color={activeTap == 2 ? "#FF3000" : ""} />
            <MenuText style={{ color: activeTap == 2 ? "#FF3000" : "" }}>
              {" "}
              {t("Message")}
              {numberOfUnreadMessages != 0 && (
                <span style={{ fontSize: "11px" }}>{` (${numberOfUnreadMessages})`}</span>
              )}
            </MenuText>
          </Element>
        </MobileWrapper>
        {role === "employer" && !isAdmin && (
          <MobileWrapper
            onClick={() => {
              setActiveTap(3);
              history.push("/favourites");
            }}>
            <Element>
              <MemoLike width='20px' height='20px' color={activeTap == 3 ? "#FF3000" : ""} />
              <MenuText style={{ color: activeTap == 3 ? "#FF3000" : "" }}>
                {" "}
                {t("Favourites")}
              </MenuText>
            </Element>
          </MobileWrapper>
        )}
        <MobileWrapper
          onClick={() => {
            setActiveTap(4);
            history.push("/setting");
          }}>
          <Element>
            <MemoSetting width='20px' height='20px' color={activeTap == 4 ? "#FF3000" : ""} />
            <MenuText style={{ color: activeTap == 4 ? "#FF3000" : "" }}>{t("Settings")}</MenuText>
          </Element>
        </MobileWrapper>
      </MenuBarMobileWrapper>
    </>
  );
};
export default MobileLayout;
