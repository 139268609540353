import * as React from "react";

function Logout(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M16.611 14.727V11L10 17.523l6.611 6.523v-3.82c4.722 0 8.028 1.49 10.389 4.752-.944-4.66-3.778-9.319-10.389-10.25z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoLogout = React.memo(Logout);
export default MemoLogout;
