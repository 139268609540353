import PublicLayout from "layouts/PublicLayout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CreateAccountForm from "screens/account-creation/CreateAccountForm";
import Loader from "ui/feedback/Loader";
import authorized from "helpers/authentication/authenticate";
import { Helmet } from "react-helmet";

export default function CreateAccountScreen() {
  const history = useHistory();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // console.log("forgot", authorized());
    if (authorized()) {
      //const token = localStorage.getItem("token")
      const userJson = localStorage.getItem("User");
      let userobj = userJson ? JSON.parse(userJson) : "";
      let pathName = "";
      switch (userobj?.role) {
        case "employer":
          pathName = "/employer-home";
          break;
        case "candidate":
          pathName = "/home";
          break;
        case "administrator":
          pathName = "/admin-home";
          break;
        default:
          pathName = "/";
          break;
      }
      setLoader(false);
      history.push(pathName);
    } else {
      localStorage.removeItem("token");
      setLoader(false);
    }
  }, []);
  if (loader)
    return (
      <>
      <Helmet>
        <title>Jobpark - Get Hired in Minutes</title>
      </Helmet>
      <PublicLayout dontShowRightPane={true}>
        <Loader />
      </PublicLayout>
      </>
    );

  return (
    <>
    <Helmet>
        <title>Jobpark - Get Hired in Minutes</title>
        <meta name="description" content="A world of job opportunities at your fingertips. Find jobs and get hired in minutes. No hidden costs or fees. Sign up today!" />
        <link rel='canonical' href="https://app.jobpark.com/" />
      </Helmet>
    <PublicLayout position='top'>
      <CreateAccountForm />
    </PublicLayout>
    </>
  );
}
