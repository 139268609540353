import * as React from "react";

function EmailNotifications(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 15.5c.334.802.515.863 1.125 1 .698.156.875.649.875 1v1c0 .351-.177.844-.875 1-.61.137-.79.198-1.125 1-.313.75-.236.949.099 1.477.382.605.16 1.077-.089 1.326l-.707.707c-.249.25-.722.471-1.326.089-.528-.335-.703-.412-1.477-.099-.75.303-.863.515-1 1.125-.157.698-.649.875-1 .875h-1c-.351 0-.844-.177-1-.875-.137-.61-.25-.822-1-1.125-.774-.313-.949-.236-1.477.1-.605.382-1.077.16-1.326-.09l-.707-.706c-.249-.249-.472-.723-.089-1.326.335-.53.412-.728.099-1.478-.334-.802-.515-.863-1.125-1-.698-.156-.875-.649-.875-1v-1c0-.351.177-.844.875-1 .61-.137.79-.198 1.125-1 .313-.75.236-.949-.099-1.477-.382-.604-.16-1.077.088-1.326l.708-.708c.249-.248.722-.47 1.326-.088.528.335.703.412 1.477.099.75-.303.863-.515 1-1.125.156-.698.649-.875 1-.875h1c.351 0 .843.177 1 .875.137.61.25.822 1 1.125.774.313.949.236 1.477-.1.605-.382 1.077-.16 1.326.09l.707.706c.249.249.472.723.089 1.327-.335.528-.412.727-.099 1.477zM16 18a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoEmailNotifications = React.memo(EmailNotifications);
export default MemoEmailNotifications;
