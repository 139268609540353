import React, { ReactNode, useEffect, useState } from "react";
import { Modal as MTModal, ModalProps } from "@mantine/core";
import styled from "styled-components";
import Checkbox from "ui/inputs/Checkbox";
import Button from "ui/buttons/Button";
import TextArea from "ui/inputs/Textarea";
import { Box } from "@mantine/core";
import { setProfileStatus } from "services/candidate/candidate";
import { getUserDetails } from "services/user/User";
import { useTranslation } from "react-i18next";

interface DeactivatingModalProps extends ModalProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: () => void;
  handleDeactivate?: any;
}

const StyleElement = styled(Box)`
  padding: 10px;
`;

const StyleError = styled.span`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  font-family: Poppins;
`;

export const DeactivatingModal: React.FC<DeactivatingModalProps> = ({
  children,
  show,
  onHide,
  ...props
}) => {
  const [opened, setOpened] = useState(true);
  const [validationError, setValidationError] = useState(false);
  const [reasons, setReasons] = useState([
    {
      label: "I_was_hired_through_Jobpark",
      checked: false,
    },
    {
      label: "I_was_hired_through_another_platform",
      checked: false,
    },
    {
      label: "Jobpark_didnt_work_for_me",
      checked: false,
    },
    {
      label: "I_dont_want_to_use_Jobpark",
      checked: false,
    },
  ]);
  useEffect(() => {
    if (props?.opened) {
      setReasons([
        {
          label: "I_was_hired_through_Jobpark",
          checked: false,
        },
        {
          label: "I_was_hired_through_another_platform",
          checked: false,
        },
        {
          label: "Jobpark_didnt_work_for_me",
          checked: false,
        },
        {
          label: "I_dont_want_to_use_Jobpark",
          checked: false,
        },
      ]);
      setDescription("");
    }
  }, [props?.opened]);
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const onChangeReasons = (currentIndex: any, value: boolean) => {
    let temporary = reasons.map((item, index) => {
      if (currentIndex === index) {
        item = { ...item, checked: value };
        return item;
      }
      return item;
    });
    setReasons(temporary);
    setValidationError(false);
  };

  const checkBoxSelectionCheck = () => {
    let flag = false;
    if (reasons?.length) {
      reasons?.map((reason: any) => {
        if (reason?.checked) {
          flag = true;
        }
      });
    }
    return flag;
  };

  // const handleDeactivate=()=>{

  //             let status = userData?.user_account_status === true? 0:1
  //         let data={
  //             why_deactivate:"I was hired through JobPark",
  //             description:"Will be back "
  //         }
  //             console.log("user",userData?.user_account_status)
  //             console.log("profile status active",status)
  //             setProfileStatus(status,data)
  //         .then(response=> console.log(response))
  //         getUserDetails(userId)
  //         .then((response) => {  setUserData(response) })
  //         .catch(err => console.log(err))

  // }

  return (
    <>
      <MTModal
        {...props}
        padding={20}
        radius={30}
        overlayBlur={5}
        title={t("Why_are_you_deactivating")}
        styles={{
          close: {
            display: "none",
          },
          title: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            textAlign: "right",
            letterSpacing: "0.3px",
            color: "#123B3D",
            padding: "10px 0 10px 0",
          },
          modal: {
            background: "#F7F7F7",
          },
        }}>
        {reasons.map((item, index) => {
          return (
            <Checkbox
              label={t(item?.label)}
              checked={item?.checked}
              onChange={(event) => onChangeReasons(index, event.target.checked)}
            />
          );
        })}
        {/* <Checkbox label="I was hired through Jobpark" />
                <Checkbox label="I was hired through another platform" />
                <Checkbox label="Jobpark didn´t work for me" />
                <Checkbox label="I dont want to use Jobpark" /> */}
        <StyleElement>
          <TextArea
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
            placeholder={t(`Please_tell_us_more_so_we_can_make_Jobpark_better`)}
          />
        </StyleElement>
        {validationError && <StyleError>{t("Please_select_a_reason")}</StyleError>}
        <StyleElement>
          <Button
            variant='filled'
            children={t("Save")}
            style={{
              height: "54px",
            }}
            onClick={() => {
              if (!checkBoxSelectionCheck()) {
                setValidationError(true);
              }
              if (checkBoxSelectionCheck()) {
                props.handleDeactivate(reasons, description);
              }
            }}
          />
          <Button
            variant='subtle'
            children={t("Cancel")}
            style={{
              height: "54px",
            }}
            onClick={props.onClose}
          />
        </StyleElement>
      </MTModal>
    </>
  );
};
