import React, { ReactNode, useState, useEffect, useContext } from 'react'
import { Accordion, Box, createStyles, Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import CheckboxGroup from 'ui/inputs/CheckboxGroup'
import { PositionStore } from 'stores/taxnomies/position.store'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'context/state'
import styled from 'styled-components'
import MemoArrowAccordion from 'ui/icons/arrowAccordion'
import MemoChevronDown from 'ui/icons/ChevronDown'
import getBrowserLanguage from 'helpers/get-browser-language'
import isNone from 'helpers/isNone'
import { Industry, Position, Positions } from 'modules/imports/interfaces/interfaces'
import filterNewIndustries from 'helpers/filter-new-industry'

interface PositionSearchProps {
  children?: ReactNode
  show?: boolean
  onHide?: any
  getData?: any
  passData?: any
  filterDataRef?: any
  resetData?: any
  dependent?: any
  title?: string
  setAddClickedFor?:any,
  dontHideDrawer?:boolean,
  showNone?:boolean
}
const CheckBoxWrapper = styled(Stack)`
  width: auto;
  height: auto;
  /* overflow-y: auto; */
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
`

const useStyles = createStyles((theme) => ({
  item: {
    background: "#transparent !important",
    borderRadius: "12px",
    margin: "7px 20px 7px 20px",
    position: "relative",
    minHeight: "70px",
    borderBottom:"1px solid #D5D5D5 !important",
    "& :hover": {
      borderRadius: "12px !important",
      backgroundColor: "transparent !important",
    },
    paddingLeft:"0px !important"
  },
  icon: {
    display: "none  !important",
    transform: "none !important",
  },
  control:{
    paddingLeft:"0px !important"
  },
  content:{
    paddingLeft:"0px !important"
  },
  contentInner:{
    paddingLeft:"0px !important"
  }
}));

const StyleIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  @media only screen and (min-width: 1000px) {
  }
`;


const StyleHeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #123b3d;
  margin: 0 30px 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;

export const PositionSearch: React.FC<PositionSearchProps> = ({
  children,
  passData,
  filterDataRef,
  dependent = false,
  title,
  setAddClickedFor,
  dontHideDrawer,
  showNone=false,
  ...props
}) => {
  const [resetData, setRestData] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [noneClicked , setNoneClicked] = useState(false)
  const [noneIndex,setNoneIndex]= useState<number|null>(null)
  const [unCheckNone,setUncheckNone] = useState(false)
  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)


  //function to sort Data
  const sortData = (dataToSort: any) => {
    let sortedData = dataToSort
    let none:any = []
    const indexOfNone=dataToSort.findIndex((item:any)=> isNone(item.headerName))
    if(indexOfNone!=null && showNone && indexOfNone>-1){
      none = sortedData.splice(indexOfNone,1)
    }
    sortedData = sortedData?.sort((a: any, b: any) =>
      a?.headerName?.localeCompare(b?.headerName, `${getBrowserLanguage()}`, {
        ignorePunctuation: true,
      })
    );
    if(none.length>0)sortedData.unshift(none[0])
    return sortedData;
  };

  // Update Checkbox on Add Button
  
  const [updateCheckboxData, setUpdateCheckboxData] = useState<Positions[]>([])
  useEffect(() => {
    let sorted = sortData(passData)
    if(!showNone){
    sorted = sorted.filter((item:Positions)=> !isNone(item.headerName))
    }
    setUpdateCheckboxData(sorted)
    const indexOfNone=updateCheckboxData.findIndex((item:Positions)=> isNone(item.headerName))
    setNoneIndex(indexOfNone)
  }, [passData])
  useEffect(() => {
    const indexOfNone=updateCheckboxData.findIndex((item:Positions)=> isNone(item.headerName))
    setNoneIndex(indexOfNone)
  }, [updateCheckboxData])

  useEffect(() => {
    if(unCheckNone &&showNone){
      let dataTransform = updateCheckboxData
      if (noneIndex !=null) {
        let noneData= dataTransform[noneIndex].data
        dataTransform[noneIndex] = {
          ...dataTransform[noneIndex],
          data: noneData.map((item:Position)=>{
            return{
              ...item,
              checked:isNone(item.name)?false:item.checked
            }
          }),
        };
      }
      setUpdateCheckboxData(dataTransform);
    }
  }, [unCheckNone])
  useEffect(() => {
    if (resetData && dependent) resetHandlerDependent()
    if (resetData == true && dependent == false) resetHandlerNotDependent()
  }, [resetData])

  useEffect(()=>{
    if(noneClicked && showNone){
      let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({ id, name }))
    let PositionStoreDataUpdated = PositionStoreData?.map((positionType: any) => {
      let positionData = {}
      positionData = {
        headerName: positionType?.name?.name,
        parentId: positionType?.id,
        data: positionType?.name?.positions.map((item:any)=>{
          return{
            ...item,
            checked:isNone(item.name)? true: false,
            show:true,
            parentId: positionType?.id
          }
        }),
      }
      return positionData
    })

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated?.filter(
      (value) => Object.keys(value).length !== 0
    )
    PositionStoreDataUpdatedFilter = PositionStoreDataUpdatedFilter.filter(filterNewIndustries)
    let sortedPosition = sortData(PositionStoreDataUpdatedFilter)
    setUpdateCheckboxData(sortedPosition)
    }
  },[noneClicked])


  // useEffect(() => {
  //   if (addElements) {
  //     props?.getData(updateCheckboxData)
  //     setAddElements(false)
  //   }
  // }, [addElements])

  const addElementHandler = () => {
    props?.getData(updateCheckboxData)
    //  setAddElements(false) 
  }

  const resetHandlerNotDependent = () => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({ id, name }))
    let PositionStoreDataUpdated = PositionStoreData?.map((positionType: any) => {
      let positionData = {}
      positionData = {
        headerName: positionType?.name?.name,
        parentId: positionType?.id,
        data: positionType?.name?.positions.map((eachPosition:Position)=>{
          return {
            ...eachPosition,
            checked:false,
            show:true,
            parentId: positionType?.id
          }
        }),
      }
      return positionData
    })

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated?.filter(
      (value) => Object.keys(value).length !== 0
    )
    let sortedPosition = sortData(PositionStoreDataUpdatedFilter)
    if(!showNone){
      sortedPosition = sortedPosition.filter((item:Positions)=> !isNone(item.headerName))
    }
    setUpdateCheckboxData(sortedPosition)
  }

  const resetHandlerDependent = () => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }))
    let PositionStoreDataUpdated = PositionStoreData.map((positionType: any) => {
      let Data = {}
      filterDataRef[0]?.data.map((filterData: Industry) => {
        if (filterData.id == positionType.id && filterData.checked == true) {
          Data = {
            headerName: positionType.name.name,
            parentId: positionType?.id,
            data: positionType?.name?.positions.map((eachPosition:Position)=>{
              return {
                ...eachPosition,
                checked:false,
                show:true,
                parentId: positionType?.id
              }
            }),
          }
        }
      })
      return Data
    })

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    )
    let sortedPosition = sortData( PositionStoreDataUpdatedFilter)
    if(!showNone){
      sortedPosition = sortedPosition.filter((item:Positions)=> !isNone(item.headerName))
    }
    setUpdateCheckboxData(sortedPosition)
  }
  const { t } = useTranslation()
  passData = passData.filter(filterNewIndustries)
  return (
    <Stack align={'center'}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={props.onHide}
        showSearch={false}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        searchString={searchString}
        setSearchString={setSearchString}
        bottomMargin="27px"
        subtext={t("select_only_3_industries")}
      >
        <CheckBoxWrapper>
        {updateCheckboxData?.map((dataGroup: any, index: any) => {
          return (
            
            <CheckboxGroup
              checkAll={true}
              data={updateCheckboxData}
              header={dataGroup?.headerName}
              getData={setUpdateCheckboxData}
              index={index}
              resetData={resetData}
              setRestData={setRestData}
              searchString={searchString}
              setSearchString={setSearchString}
              addElements={addElements}
              setAddElements={setAddElements}
              multiObject={true}
              showAddButton={updateCheckboxData?.length == index + 1 ? true : false}
              setShow={props.onHide}
              addElementHandler={addElementHandler}
              showAccordion={true}
              setAddClickedFor={setAddClickedFor}
              dontHideDrawer={dontHideDrawer}
              noneClicked={noneClicked}
              setNoneClicked={setNoneClicked}
              noneIndex={noneIndex}
              setUncheckNone={setUncheckNone}
              unCheckNone={unCheckNone}
            />
            // </Accordion.Item>
            // </Accordion>
          )
        })}
        </CheckBoxWrapper>
      </SearchAndSelectAbstraction>
    </Stack>
  )
}
