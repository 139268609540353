import * as React from "react";

function BlackChevronDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 12" fill="none" {...props}>
      <path
        d="M19.098 2.878l-8.4 8.425c-.1.1-.208.17-.325.212a1.098 1.098 0 01-.375.063c-.133 0-.258-.02-.375-.063a.871.871 0 01-.325-.212L.873 2.878a1.187 1.187 0 01-.35-.875c0-.35.125-.65.375-.9a1.2 1.2 0 01.875-.375 1.2 1.2 0 01.875.375l7.35 7.35 7.35-7.35c.234-.233.521-.35.862-.35.342 0 .638.125.888.375s.375.542.375.875a1.2 1.2 0 01-.375.875z"
        fill="#000"
      />
    </svg>
  );
}

const MemoBlackChevronDown = React.memo(BlackChevronDown);
export default MemoBlackChevronDown;
