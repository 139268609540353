import * as React from "react";

function Linkedin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx={20} cy={20} r={20} fill="#fff" />
      <path
        d="M15.595 26h-3.313V15.986h3.313V26zm-1.658-11.381a2.02 2.02 0 01-1.073-.3 1.841 1.841 0 01-.714-.81 1.707 1.707 0 01-.115-1.044c.073-.352.255-.675.523-.93.269-.253.611-.427.985-.499a2.046 2.046 0 011.114.099c.353.135.655.366.869.662.213.297.328.646.33 1.004 0 .48-.201.94-.56 1.28a2 2 0 01-1.36.538zM27.995 26h-3.306v-4.875c0-1.162-.025-2.652-1.725-2.652-1.725 0-1.987 1.263-1.987 2.568V26h-3.306V15.986h3.177v1.366h.048a3.39 3.39 0 011.329-1.217 3.668 3.668 0 011.806-.402c3.353 0 3.969 2.072 3.969 4.764V26h-.005z"
        fill="#F50"
      />
    </svg>
  );
}

const MemoLinkedin = React.memo(Linkedin);
export default MemoLinkedin;
