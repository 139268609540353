import React, { ReactNode, useState, useEffect } from "react";
import { Accordion, createStyles } from "@mantine/core";
import styled from "@emotion/styled";
import MemoCheckLogo from "ui/icons/checkLogo";
import { useTranslation } from "react-i18next";

const StyleHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #123b3d;
`;

const StyleCost = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2d8e4c;
`;

const StyleExtraCost = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #535353;
`;

const StyleUnitPeriod = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6a6a6a;
`;
const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 50px;
  column-gap: 10px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PriceWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ExtraPriceWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 80px;
  top: 35px;
`;

const StyleDetails = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #123b3d;
  margin: 3px;
`;

const StyleContactUs = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #ff5500;
  margin: 36px 0 0 0;
  cursor: pointer;
`;

// const StyleOr = styled.div`
//   font-family: "Poppins";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 11px;
//   line-height: 16px;
//   color: #123b3d;
//   margin: 14px 0 0 0;
// `;

const StylePlanText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #123b3d;
`;
const StyleHR = styled.div`
  margin: 25px 0 30px 0;
  border-bottom: 1px solid #6e6e6e;
`;
const useStyles = createStyles((theme) => ({
  item: {
    background: "#FFFFFF !important",
    boxShadow: "0px 10px 35px rgba(64, 59, 75, 0.1)",
    borderRadius: "20px",
    margin: "7px 20px 7px 20px",
    minHeight: "70px",
    "& :hover": {
      borderRadius: "20px !important",
      backgroundColor: "#FFFFFF !important",
    },
  },
  icon: {
    display: "none  !important",
    transform: "none !important",
  },
  // content: {
  //     pointerEvents: 'none',
  // }
}));

const CheckLogoWrapper = styled.div`
  margin: auto auto auto 5px;
  @media only screen and (min-width: 1000px) {
    margin: 0px 14px auto 20px;
  }
`;

const AccordionListing = (props: any) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<any>([]);
  const { t } = useTranslation();

  // To set State for a Selected Plan
  useEffect(() => {
    let selectedPlanIndex: number | null = null;

    value?.map((selection: any, selectionIndex: any) => {
      if (selection) selectedPlanIndex = selectionIndex;
    });

    if (selectedPlanIndex != null) props?.setSelectedPlanData(props?.data[selectedPlanIndex]);
    else props?.setSelectedPlanData();
  }, [value]);

  return (
    <>
      <Accordion
        transitionDuration={500}
        iconPosition='right'
        onChange={(value: any) => {
          let selection: any = [];
          for (const [id, status] of Object.entries(value)) {
            selection.push(status);
          }
          setValue(selection);
        }}
        classNames={{
          item: classes.item,
          icon: classes.icon,
          // content: classes.content,
        }}>
        {props?.data?.map((planDetails: any, planDetailsIndex: any) => {
          var pricing: any = "";
          var extraPricing: any = "";
          var selectedPlanCheck: any = "";
          for (const [currenct, price] of Object.entries(planDetails.pricing)) {
            if (currenct == props?.currencySelected) {
              pricing = price;
            }
          }

          for (const [currenct, price] of Object.entries(planDetails.extra_pricing)) {
            if (currenct == props?.currencySelected) {
              extraPricing = price;
            }
          }
          value?.map((selected: any, selectedIndex: any) => {
            if (selectedIndex == planDetailsIndex && selected == true) {
              selectedPlanCheck = planDetails;
            }
          });
          return (
            <Accordion.Item
              key={`${planDetails?.id}`}
              label={
                <>
                  <HeadWrapper>
                    <StyleHead>{planDetails?.name}</StyleHead>
                    <PriceWrapper>
                      <StyleCost>
                        {planDetails?.id != "207" &&
                          `${
                            props?.currencySelected == "EUR"
                              ? "€"
                              : props?.currencySelected == "NOK"
                              ? "kr"
                              : "$"
                          }${pricing}`}
                      </StyleCost>
                      {planDetails?.id != "207" && (
                        <StyleUnitPeriod>{`/${t("month")}`}</StyleUnitPeriod>
                      )}
                      {planDetails?.id == "207" && (
                        <StyleContactUs
                          style={{ marginTop: "0px" }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            // console.log(`clicked`);
                            props.setShowContactUs(true);
                          }}>
                          {t("Contact_Us")}
                        </StyleContactUs>
                      )}
                      <CheckLogoWrapper>
                        {value
                          ?.map((selected: any, index: any) => {
                            if (index == planDetailsIndex && selected == true) {
                              return "#FF3000";
                            }
                          })
                          .filter(function (element: any) {
                            return element !== undefined;
                          }) == "#FF3000" ? (
                          <MemoCheckLogo
                            width='21px'
                            height='21px'
                            fill={"#FF3000"}
                            style={{
                              margin: "auto auto auto 5px",
                            }}
                          />
                        ) : (
                          <MemoCheckLogo
                            width='21px'
                            height='21px'
                            fill={"#E7E7E7"}
                            style={{
                              margin: "auto auto auto 5px",
                            }}
                          />
                        )}
                      </CheckLogoWrapper>
                    </PriceWrapper>
                    {planDetails?.id != "207" && extraPricing && (
                      <ExtraPriceWrapper>
                        <StyleExtraCost>{`+ ${
                          props?.currencySelected == "EUR"
                            ? "€"
                            : props?.currencySelected == "NOK"
                            ? "kr"
                            : "$"
                        }${extraPricing} / ${t("month")}`}</StyleExtraCost>
                      </ExtraPriceWrapper>
                    )}
                  </HeadWrapper>
                </>
              }>
              <>
                {planDetails?.id == "205" && (
                  <StylePlanText>{t("lite_plan_description")}</StylePlanText>
                )}
                {planDetails?.id == "206" && (
                  <StylePlanText>{t("premium_plan_description")}</StylePlanText>
                )}
                {planDetails?.id == "207" && (
                  <StylePlanText>{t("enterprise_plan_description")}</StylePlanText>
                )}
                <StyleHR />
                {planDetails?.description?.map((details: any) => {
                  return (
                    <>
                      <DetailsWrapper>
                        <MemoCheckLogo width='13px' height='13px' />
                        <StyleDetails>{details}</StyleDetails>
                      </DetailsWrapper>
                    </>
                  );
                })}
                {planDetailsIndex == props?.data?.length - 1 && (
                  <>
                    {/* <StyleOr>Or</StyleOr> */}
                    <StyleContactUs
                      onClick={(e: any) => {
                        e.preventDefault();
                        // console.log(`clicked`);
                        props.setShowContactUs(true);
                      }}>
                      {t("Contact_Us")}
                    </StyleContactUs>
                  </>
                )}
              </>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};
export default AccordionListing;
