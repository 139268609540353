import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import { Stack } from "@mantine/core";
import styled from "@emotion/styled";
import { fontBlack, fontGrey } from "constants/colors";
import Button from "ui/buttons/Button";
import { PrivateContext, UserContext } from "context/state";
import { useForm } from "@mantine/form";
import Checkbox from "ui/inputs/Checkbox";
import { DropZone } from "ui/inputs/Dropzone";

interface AboutProps {
  show?: boolean;
  onHide?: any;
  getData?: any;
  passData?: any;
  title?: string;
  setAddClickedFor?: any;
}
const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-top: 26px;
  margin-bottom: 7px;
  color: ${fontBlack};
  max-width: 298px;
`;

const StyleDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6e6e6e;
  margin-bottom: 20px;
`;
const AddButtonWrapper = styled.div`
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  left:0;
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  z-index: 2000;
  text-align: center;
`;

const CVDrawer: React.FC<AboutProps> = ({
  show,
  onHide,
  title,
  setAddClickedFor,
}) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const { cv, setCV, references, setReferences } = useContext(PrivateContext);
  const form = useForm({
    initialValues: {
      uploadCV: userData?.upload_cv ?? "",
      refrences: userData?.provide_references ?? "0",
    },
  });

  const handleCVSubmit = (values: any) => {
    setReferences(values?.refrences);
    setAddClickedFor();
  };
  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        //title={title ? title : 'Title'}
        setShow={onHide}
        showSearch={false}
        //clearAll={() => true}
        showClear={false}
        //showFilter_Clear={2}
        buttonText={t(`Add`)}
        //resetFilter={setRestData} // Reseting the Data
        //setAddElements={setAddElements}
      >
        <Title>{t("CV")}</Title>
        <form onSubmit={form.onSubmit((values) => handleCVSubmit(values))}>
          <DropZone
            //label={t("Upload_CV_")}
            description={t("Supported_file_pdf")}
            acceptType={"file"}
            savedData={form?.values?.uploadCV ? [form?.values?.uploadCV] : []}
            form={form}
            hideInfo={true}
            customCV={true}
          />
          <StyleDescription>{t("Supported_file_pdf")}</StyleDescription>
          <div style={{ marginLeft: "0px", marginBottom: "0px" }}>
            {/* <Checkbox label={t("Can_you_provide_refrences_upon_request")} /> */}
            <Checkbox
              customLabelColor={fontGrey}
              checked={form?.values?.refrences == 1}
              label={t("Can_you_provide_refrences_upon_request")}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  form.setFieldValue("refrences", "1");
                } else {
                  form.setFieldValue("refrences", "0");
                }
              }}
            />
          </div>
          <AddButtonWrapper>
            <Button
              type="submit"
              variant="filled"
              children={t("Save")}
              width="335px"
              height="54px"
            />
          </AddButtonWrapper>
        </form>
      </SearchAndSelectAbstraction>
    </Stack>
  );
};

export default CVDrawer;
