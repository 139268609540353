import * as React from "react";

function OrangeEdit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        d="M0 7.626v1.875h1.875l5.53-5.53L5.53 2.096 0 7.626zm8.855-5.105a.498.498 0 000-.705L7.685.646a.498.498 0 00-.705 0l-.915.915L7.94 3.436l.915-.915z"
        fill={ props?.fill ? props?.fill :"#F50"}
      />
    </svg>
  );
}

const MemoOrangeEdit = React.memo(OrangeEdit);
export default MemoOrangeEdit;
