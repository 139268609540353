export const primaryColor = "#FF3000"

export const secondaryColor="#FF5500"

export const fontWhite = "#FFF"

export const fontGrey ="#6E6E6E"

export const fontBlack ="#123B3D"

export const greyHeader ="#A1A1A1"