import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mantine/core";
import Button from "ui/buttons/Button";
import { useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import BackButton from "ui/buttons/BackButton";
import PublicLayout from "layouts/PublicLayout";
import { useTranslation } from "react-i18next";
import { UserContext } from "context/state";
import { login, resendEmail } from "services/user/User";
import { ErrorModal } from "ui/modals/ErrorModal";
import authenticate from "helpers/authentication/authenticate";

const FormContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Heading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #123b3d;

  @media only screen and (min-width: 1000px) {
  }
`;
const StyleWrapper = styled.div`
  margin: 0px;
  @media only screen and (min-width: 1000px) {
    max-width: 335px;
  }
`;

const GuideText = styled.text`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;
const EmailText = styled.text`
  font-weight: 600;
  color: #123b3d;
`;

const NotReceivedText = styled.span`
  padding-top: 110px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #123b3d;
  margin-bottom: 0px;
  @media only screen and (min-width: 1000px) {
    padding-top: 51px;
  }
`;
const EmailVerificationForm: React.FC<{ email?: string }> = (props) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const location = useLocation<{ email: string; password: string }>();
  const { role, setUserId, setFirstSession,loginType,setRole } = useContext(UserContext);
  let email = location?.state?.email;
  let password = location?.state?.password;
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");

  useEffect(()=>{
    handleResend()
  },[])

  const handleResend = () => {
    let data = {
      email: email,
    };
    resendEmail(data)
      .then((response) => {
        if (response?.response?.data) {
          setShowError(true);
          setErrorText(response?.response?.data);
        }
      })
      .catch((error) => console.log(error));
  };
  const handleProceed = () => {
    let data={}
    if(loginType?.type!='' && password===""){
        data={
          ...loginType
        }
    }
    else{
      data = {
        email,
        password,
        type: "basic",
        role: role,
      };
    }
    if (authenticate()) {
      const userJson = localStorage.getItem("User");
      let userobj = userJson ? JSON.parse(userJson) : "";
      let pathName = "";
      switch (userobj?.role) {
        case "employer":
          pathName = "/employer-home";
          break;
        case "candidate":
          pathName = "/home";
          break;
        case "administrator":
          pathName = "/admin-home";
          break;
        default:
          pathName = "/";
          break;
      }
      //setLoader(false)
      push(pathName);
    } else {
      login(data)
        .then((response) => {
          //console.log(response.data)
          if (response.success) {
            let uid = response?.data?.current_user?.uid;
            setUserId(uid);
            let responseRole= response?.data?.current_user?.role
            if (response?.data?.current_user?.verification_status) {
              let user = JSON.stringify({
                userId: uid,
                role: responseRole,
                token: response?.data?.jwt_token,
              });
              localStorage.setItem("User", user);
              localStorage.setItem("token", response?.data?.jwt_token);
              setFirstSession(response?.data?.current_user?.first_time_login);
              if(role ==="administrator"){
                setRole("employer")
              }
              else{
                setRole(responseRole)
              }
              responseRole === "candidate"
                ? push("/profile-creation")
                : push({
                    pathname: "/profile-creation",
                    state: {
                      showModal: true,
                    },
                  });
            }
          } else {
            setShowError(true);
            setErrorText(t("Please_verify_email_to_proceed"));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <PublicLayout>
      <FormContainer>
        <StyleWrapper>
          <ErrorModal opened={showError} message={errortext} onClose={() => setShowError(false)} />
          <BackButton> {t("Back")}</BackButton>
          <Stack spacing={10} style={{ marginTop: "25px" }}>
            <Heading>{t("Verify_Email")}</Heading>
            <GuideText>
              {t("We_have_sent_an_email")}
              <EmailText>{email ? email : "xyz@gmail.com"}</EmailText>
              {t("We_have_sent_an_email1")}
              {t("Please_click_the_link_to_verify")}
            </GuideText>
            <NotReceivedText>
              {t("Didnt_receive_the_link") + " "}
              <Button
                variant='subtle'
                onClick={() => handleResend()}
                width='fit-content'
                sx={{ color: "#FF3000", padding: 0 }}>
                {t("Resend")}
              </Button>
            </NotReceivedText>
            <Button
              onClick={() => handleProceed()}
              fontWeight={700}
              fontSize='18px'
              lineHeight='110%'
              variant='filled'>
              {t("Proceed")}{" "}
            </Button>
          </Stack>
        </StyleWrapper>
      </FormContainer>
    </PublicLayout>
  );
};

export default EmailVerificationForm;
