import { PasswordInput, PasswordInputProps } from "@mantine/core";
import MemoEye from "ui/icons/Eye";
import MemoEyeSlash from "ui/icons/EyeSlash";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";

interface Props extends PasswordInputProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  placeholder?: string;
  description?: string;
  label?: string;
  icon?: ReactNode;
}

const StyleInputField = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  @media only screen and (min-width: 1000px) {
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
  @media screen and (-moz-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
    /* .password-mask { */
    /* -webkit-text-security: disc;
        -moz-webkit-text-security: disc;
        -moz-text-security: disc; */
    /* } */
  }
`;

const Password: React.FC<Props> = ({
  width,
  height,
  color,
  backgroundColor,
  fontSize,
  ...props
}) => {
  const styles = {
    width: width ?? "100%",
    height: height,
    fontSize: fontSize ?? "16px",
    fontFamily: "Poppins",
    paddingBottom: "15px",
    color: " #6A6A6A",
  };
  return (
    <StyleInputField>
      <PasswordInput
        styles={(theme) => ({
          root: styles,
          input: {
            height: "54px !important",
            fontFamily: "Poppins !important",
            background: "#FFF",
            fontSize: fontSize ?? "16px",
            fontWeight: 400,
            border: "0px",
            padding: "0",
            color: "#123B3D !important",
            "::placeholder": {
              color: "#6E6E6E !important",
              fontFamily: "Poppins !important",
              opacity: "1",
            },
          },
          innerInput: {
            height: "54px !important",
            fontFamily: "Poppins !important",
            color: "#123B3D !important",
            "::placeholder": {
              color: "#6E6E6E !important",
              fontFamily: "Poppins !important",
              opacity: "1",
            },
          },
          error: {
            fontFamily: "Poppins !important",
          },
        })}
        radius={12}
        defaultValue='secret'
        visibilityToggleIcon={({ reveal, size }) =>
          reveal ? (
            <MemoEye width='20.37px' height='17px' onClick={(e: any) => e.preventDefault()} />
          ) : (
            <MemoEyeSlash width='16.37px' height='13px' onClick={(e: any) => e.preventDefault()} />
          )
        }
        required
        {...props}
      />
    </StyleInputField>
  );
};

export default Password;
