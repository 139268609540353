import * as React from "react";

function GrayAlert(props) {
    return (
        <svg width={props?.width ? props?.width : "80"} height={props?.height ? props?.height : "80"} viewBox="0 0 80 80" fill="none" {...props}>
            <path d="M40 0C17.92 0 0 17.92 0 40C0 62.08 17.92 80 40 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 40 0ZM44 60H36V36H44V60ZM44 28H36V20H44V28Z" fill="#BCBCBC" />
        </svg>
    );
}

const MemoGrayAlert = React.memo(GrayAlert);
export default MemoGrayAlert;
