import styled from "@emotion/styled";
import React, { useEffect, useState, useContext } from "react";
import PrivateLayout from "layouts/PrivateLayout";
import { Accordion, createStyles } from "@mantine/core";
import MemoArrowAccordion from "ui/icons/arrowAccordion";
import BackButton from "ui/buttons/BackButton";
import { getFAQData } from "services/faq/faq";
import { useTranslation } from "react-i18next";
import { UserContext } from "context/state";
import Loader from "ui/feedback/Loader";

export const ContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 1000px) {
    overflow-y: hidden;
    height: 100%;
    margin-bottom: 0;
  }
`;

const StyleElement = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    width: 50%;
    float: left;
    overflow: auto;
  }
`;

export const StyleHeadWrapper = styled.div`
  position: relative;
  margin: 20px;
  @media only screen and (min-width: 1000px) {
    margin: 0 0 0 20px;
  }
`;

export const StyleHeadTextWrapper = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
    position: absolute;
    top: 2px;
    left: 25px;
  }
`;

const StyleIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  @media only screen and (min-width: 1000px) {
  }
`;

export const StyleBackButton = styled.div`
  margin: -30px 0 40px 0;
  @media only screen and (min-width: 1000px) {
    margin: 0px;
  }
`;

const StyleHeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #123b3d;
  margin: 0 30px 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;

const StyleDescriptionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #123b3d;
  text-align: justify;
  margin-right: 7px;
  @media only screen and (min-width: 1000px) {
  }
`;

const useStyles = createStyles((theme) => ({
  item: {
    background: "#FFFFFF !important",
    borderRadius: "12px",
    margin: "7px 20px 7px 20px",
    position: "relative",
    minHeight: "70px",
    "& :hover": {
      borderRadius: "12px !important",
      backgroundColor: "#FFFFFF !important",
    },
  },
  icon: {
    display: "none  !important",
    transform: "none !important",
  },
}));

const FAQscreenComponent = (props: any) => {
  const { classes } = useStyles();

  const [loader, setLoader] = useState(true);

  // State for FAQ DATA
  const [dataFAQ, setDataFAQ] = useState([]);

  const { role } = useContext(UserContext);

  // GET API call for FAQ Data
  useEffect(() => {
    getFAQData(role)
      .then((response) => {
        // console.log(`res Data`, response);
        setDataFAQ(response);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { t } = useTranslation();
  if (loader) return <Loader />;
  return (
    <PrivateLayout showBottomMenu={false} showMobileViewLogo={false}>
      <ContainerStyle>
        <StyleHeadWrapper>
          <StyleBackButton>
            <BackButton />
          </StyleBackButton>
          <StyleHeadTextWrapper>{t("FAQ")}</StyleHeadTextWrapper>
        </StyleHeadWrapper>
        <StyleElement>
          <Accordion
            transitionDuration={500}
            iconPosition='right'
            multiple={true}
            classNames={{
              item: classes.item,
              icon: classes.icon,
            }}>
            {dataFAQ?.length != 0 && // remove todo
              dataFAQ?.map((element: any, index: any) => {
                if (index % 2 == 0)
                  return (
                    <Accordion.Item
                      label={
                        <>
                          <StyleHeadText>{element.question}</StyleHeadText>
                          <StyleIcon>
                            <MemoArrowAccordion />
                          </StyleIcon>
                        </>
                      }>
                      <StyleDescriptionText>{element.answer}</StyleDescriptionText>
                    </Accordion.Item>
                  );
              })}
          </Accordion>
        </StyleElement>
        <StyleElement>
          <Accordion
            transitionDuration={500}
            iconPosition='right'
            multiple={true}
            classNames={{
              item: classes.item,
              icon: classes.icon,
            }}>
            {dataFAQ?.length != 0 && // remove todo
              dataFAQ?.map((element: any, index: any) => {
                if (index % 2 != 0)
                  return (
                    <Accordion.Item
                      label={
                        <>
                          <StyleHeadText>{element.question}</StyleHeadText>
                          <StyleIcon>
                            <MemoArrowAccordion />
                          </StyleIcon>
                        </>
                      }>
                      <StyleDescriptionText>{element.answer}</StyleDescriptionText>
                    </Accordion.Item>
                  );
              })}
          </Accordion>
        </StyleElement>
      </ContainerStyle>
    </PrivateLayout>
  );
};
export default FAQscreenComponent;
