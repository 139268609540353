import React, { ReactNode, useContext, useEffect, useState } from "react";
import { RangeSlider as MTSlider, Box } from "@mantine/core";
import styled from "@emotion/styled";
import { UserContext } from "context/state";

interface SliderProps {
  children?: ReactNode;
  labelText?: string;
}

const StyledLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #123b3d;
  position: relative;
`;
const Slider: React.FC<SliderProps> = ({ children, labelText, ...props }) => {
  const { savedAge, setSavedAge } = useContext(UserContext);
  //const [age, setAge] = useState<[number, number]>(savedAge ?? [18, 24])
  const labelStyles = {
    marginTop: "8px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px !important",
    lineHeight: "15px !important",
    color: "#000000 !important",
    background: "#F7F7F7 !important",
  };

  useEffect(() => {
    if (savedAge.length === 0) {
      setSavedAge([18, 67]);
    }
  }, [savedAge]);
  return (
    <Box style={{ position: "relative", height: "auto" }}>
      <StyledLabel>{labelText}</StyledLabel>
      <Box
        style={{
          position: "relative",
          marginTop: "50px",
          marginBottom: "20px",
        }}
      >
        <MTSlider
          styles={{
            markLabel: { ...labelStyles, display: "none" },
            label: { ...labelStyles, marginTop: 0 },
            bar: {
              backgroundColor: "#FF3000",
              height: "2px",
            },
            thumb: {
              backgroundColor: "#FF3000 !important",
              border: "3px solid #fff !important",
              width: "23px",
              height: "23px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            },
            mark: {
              display: "none",
            },
            track: {
              height: "2px",
              "&::before": {
                backgroundColor: "#000 !important",
              },
            },
          }}
          size={"sm"}
          min={18}
          max={67}
          value={savedAge ?? [18, 67]}
          onChange={setSavedAge}
          minRange={1}
          labelAlwaysOn
        />
      </Box>
    </Box>
  );
};

export default Slider;
