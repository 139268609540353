import * as React from "react";

function FavouriteOrange(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 17" fill="none" {...props}>
      <path
        d="M5.167 1.585C5.235.574 6.16-.208 7.157.049l.272.072c.482.125.91.493 1.055 1.043.229.883.555 2.716.064 4.877.154-.022.308-.04.462-.055.744-.07 1.74-.078 2.623.227.54.187 1.037.737 1.252 1.377a2.118 2.118 0 01-.245 1.874c.061.129.108.262.144.392.08.292.118.614.118.926 0 .313-.037.634-.118.926-.04.146-.093.295-.166.437.176.418.111.885-.004 1.241-.117.351-.289.68-.508.974.056.165.079.338.079.503 0 .33-.093.676-.264.986-.348.634-1.04 1.151-2.016 1.151h-3.65c-.63 0-1.115-.088-1.528-.236a4.96 4.96 0 01-1.011-.523l-.05-.033c-.526-.331-1.042-.658-2.156-.78C.71 15.339 0 14.67 0 13.756V9.43c0-.919.714-1.548 1.415-1.746.885-.25 1.64-.851 2.223-1.525.583-.678.953-1.384 1.083-1.772.207-.622.371-1.664.446-2.8v-.002z"
        fill="red"
      />
    </svg>
  );
}

const MemoFavouriteOrange = React.memo(FavouriteOrange);
export default MemoFavouriteOrange;
