import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/state";
import HomeFavouriteComponent from "helpers/HomeFavouriteComponent";
import Filter from "screens/home-screen/filter";
import styled from "@emotion/styled";
import Cards from "ui/data-display/Cards";
import { useHistory, useLocation } from "react-router-dom";
import { getUserDetails } from "services/user/User";
import Loader from "ui/feedback/Loader";
import PrivateLayout from "layouts/PrivateLayout";
import { SearchModal } from "ui/modals/SearchModal";
import Drawer from "ui/data-display/Drawer";
import UserProfileDisplay from "screens/user-company-profile/UserProfileDisplay";
import CompanyProfileDisplay from "screens/user-company-profile/CompanyProfileDisplay";
import { getEmployers } from "services/candidate/candidate";
import {
  addFavourite,
  chatStarted,
  getFilteredCandidates,
} from "services/employer/employer";
import NoResultFound from "helpers/NoResultFound";
import { ChatFavModal } from "helpers/chat-fav-modal/modal";
import SubscriptionListing from "screens/subscription-payment/SubscriptionListing";
import InfiniteScroll from "react-infinite-scroll-component";
import { ErrorModal } from "ui/modals/ErrorModal";
import { stringify, parse } from "qs";
import { useTranslation } from "react-i18next";
import NoResultsFoundLayout from "layouts/NoResultFoundLayout";
import {
  CandidateStrings,
  CommonStrings,
  EmployerStrings,
  timer,
} from "constants/MobileNotVerifiedConstants";
import { clearInterval, setInterval, setTimeout } from "worker-timers";
import { useViewportSize } from "@mantine/hooks";
import MemoBellicon from "ui/icons/Bellicon";
import { primaryColor, secondaryColor } from "constants/colors";
import getPageLimit from "helpers/GetPageLimit";
import { FormList, formList,useForm } from "@mantine/form";
import { each } from "lodash";
import Button from "ui/buttons/Button";
import EmployerSelection from "screens/admin-panel/EmployerSelection";
import { addFavouriteList } from "services/admin/admin";
interface ProfileWrapperProps {
  customHeight?: string;
}

const ProfileWrapper = styled.div<ProfileWrapperProps>`
  overflow-y: auto;
  height: ${(props) => (props?.customHeight ? "70vh" : "75vh")};
  @media only screen and (min-width: 1000px) {
    margin-top: 18px;
    height: ${(props) => (props?.customHeight ? props?.customHeight : "71vh")};
    padding-left: 5px;
    padding-right: 5px;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 150px;
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    gap: 15px;
    padding-bottom: 0px;
  }
`;

interface NoResultFoundWrapperProp {
  margin?: string;
}

const NoResultFoundWrapper = styled.div<NoResultFoundWrapperProp>`
  width: 334px;
  height: 85%;
  padding: 5% 5% 5% 5%;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: 100%;
  }
  @supports (-webkit-touch-callout: none) {
    // CSS specific to iOS devices
    margin: ${(prop) => prop?.margin};
    height: 100%;
  }
`;

const MobileNotVerifiedWrapper = styled.div`
  width: 334px;
  height: 43px;
  height: auto;
  background: #4caf50;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  margin: 24px auto 0 auto;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    margin: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;

const NotVerifiedText = styled.div`
  width: auto;
  margin: 14px 12px 10px 12px;
  @media only screen and (min-width: 1000px) {
    width: auto;
  }
`;
const ClickHere = styled.text`
  text-decoration: underline;
  cursor: pointer;
`;

const ProfileCompletionWrapper = styled.div`
  width: 334px;
  height: 43px;
  height: auto;
  background: #fff;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #123b3d;
  display: flex;
  justify-content: flex-start;
  margin: 24px auto 0 auto;
  padding: 8px 0 9px 0px;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    margin: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
    align-items: center;
    padding: 19px 0 12px 0px;
  }
`;
const ProfileCompletionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #123b3d;
  margin-left: 15px;
  @media only screen and (min-width: 1000px) {
    width: auto;
    font-size: 14px;
  }
`;
const BellIconWrapper = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 66px;
  background-color: #fff9e7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  @media only screen and (min-width: 1000px) {
    width: 38px;
    height: 38px;
    border-radius: 76px;
  }
`;

const EditWrapper = styled.div`
  margin-right: 11px;
  @media only screen and (min-width: 1000px) {
    margin-left: auto;
    margin-right: 26px;
  }
`;
const EditText = styled.text`
  cursor: pointer;
  color: ${primaryColor};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
`;
const PercentageWrapper = styled.span`
  margin: 0;
  font-weight: 600;
  @media only screen and (min-width: 1000px) {
    font-size: 16px;
  }
`;
const SubmitProfilesButton = styled.button`
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: flex-end;
  @media only screen and (min-width: 1000px) {
    //
  }
`;
const SelectionModeButtonsWrapper = styled.div`
  position: fixed;
  bottom: 112px;
  left: 26px;
  z-index: 11;
  width: fit-content;
  height: fit-content;
  @media only screen and (min-width: 1000px) {
    position: inherit;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
  }
`;
interface CandidateType {
  about: string;
  date_of_birth: string;
  favourite: boolean;
  first_name: string;
  last_name: string;
  location: string;
  name: string;
  profile_picture: string;
  uid: string;
  username: string;
}
interface Filters {
  age: number[];
  positions: string[];
  industry: string[];
  country: string[];
  language: string[];
  availability: string[];
  workType: string[];
  drivingLicense: string[];
  preferredIndustryPosition: string[];
}
const AdminPanel = (props: any) => {
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation<any>();
  const parsedFilters = parse(location?.search.slice(1));
  const {
    userId,
    setUserData,
    role,
    setActiveTap,
    filterData,
    redirectToSubscription,
    setRedirectToSubscription,
    setFilterData,
    savedAge,
    setSavedAge,
    userData,
    setUserId,
    showMobileVerify,
    setShowMobileVerify,
    count,
    setCount,
    setShowUserProfile,
    setRole,
    isAdmin,
    setIsAdmin
  } = useContext(UserContext);
  const { height, width } = useViewportSize();
  let from = false;
  const { t } = useTranslation();
  const contextData = useContext(UserContext);
  const [loader, setLoader] = useState(true);
  const [candidateList, setCandidateList] = useState<CandidateType[]>([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSubscriptionListing, setShowSubscriptionListing] = useState(
    redirectToSubscription ? redirectToSubscription : false
  );
  const [showProfile, setShowProfile] = useState(false);
  const [showModaBox, setShowModalBox] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(undefined);
  const [selectedProfile, setSelectedProfile] = useState<{
    [key: string]: any;
  }>({});
  const [modalType, setModalType] = useState<1 | 2>(1);
  const [searchString, setSearchString] = useState(undefined);
  const [selectedName, setSelectedName] = useState("");
  const [activePage, setPage] = useState(1);
  const [moreProfiles, setMoreProfiles] = useState(true);
  const [isFavourite, setIsFavourite] = useState(undefined);

  const [isFilterAtSide, setIsFilterAtSide] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const history = useHistory();

  const [strings, setStrings] = useState(CommonStrings);
  const [currentString, setCurrentString] = useState("");
  //new states for the feature
  const [selectMode, setSelectMode] = useState(false) //toggles between normal mode and select mode
  const [showEmployerList, setShowEmployerList] = useState(false) //toggles the drawer to show list of employers
  const form = useForm({
    initialValues: {
      selectedProfiles: formList<{}>([]), //holds selected profiles in manage mode
    },
  });
  useEffect(() => {
    setActiveTap(1);
    let temporaryStrings = [];
    if (role === "candidate") {
      temporaryStrings = strings.concat(CandidateStrings);
    } else {
      temporaryStrings = strings.concat(EmployerStrings);
    }
    setStrings(temporaryStrings);
    let id = userId;
    if (id === undefined || id === "" || id === null) {
      const userJson = localStorage.getItem("User");
      let userobj = userJson ? JSON.parse(userJson) : {};
      id = userobj?.userId;
      setUserId(id);
    }
    getUserDetails(id)
      .then((response) => {
        setUserData(response?.data);
        localStorage.setItem("userData", JSON.stringify(response?.data ?? ""));
        setIsSubscribed(response?.data?.is_subscribed);
        const currentUser = response?.data;
        const loggedInUserRole = response?.data?.role;
        const uid = currentUser?.user_id;
        switch (true) {
          case currentUser?.profile_status &&
            loggedInUserRole === "candidate": {
            history.push({ pathname: "/home", state: { uid } });
            break;
          }
          case currentUser?.emp_profile_completed &&
            loggedInUserRole === "employer": {
            history.push({ pathname: "/employer-home", state: { uid } });
            break;
          }
          case !currentUser?.profile_status && loggedInUserRole === "candidate":
          case !currentUser?.emp_profile_completed &&
            loggedInUserRole === "employer":
            history.push({ pathname: "/profile-creation", state: { uid } });
            break;
          case !currentUser?.verification_status &&
            loggedInUserRole === "employer": {
            localStorage.removeItem("token");
            history.push("/login");
            break;
          }
          case loggedInUserRole === "administrator": {
            setIsAdmin(true)
            history.push({ pathname: "/admin-home", state: { uid } });
            break;
          }
          default:
            break;
        }
        if (role === "employer") {
          let filters = {
            industry: response?.data?.industry_type_filter,
            positions: response?.data?.preferred_job.length
              ? response?.data?.preferred_job
              : [],
            country: response?.data?.location_filter,
            language:
              response?.data?.primary_language?.length != 0
                ? response?.data?.primary_language
                : // : userData?.apply_filters == false
                  // ? ["104"]
                  [],
            availability: response?.data?.availability,
            workType: response?.data?.part_full_time,
            drivingLicense: response?.data?.license_type,
            age: [response?.data?.birthday_max, response?.data?.birthday_min],
            preferredIndustryPosition:
              response?.data?.preferred_industry_position ?? [],
          };
          //setLoader(false);
          if (response?.data?.apply_filters) {
            //setAllChecked();
            setFilterData((prevState: any) => ({ ...prevState, ...filters }));
            setSavedAge(filters?.age);
            localStorage.setItem("filters", JSON.stringify(filters));
            getList(false, filters);
          } else {
            setLoader(false);
          }
        }
      })
      .catch((err) => console.log(err));

    if (role === "candidate") {
      getEmployers().then((response) => {
        setCandidateList(response?.data);
        setLoader(false);
      });
    }
    if (!userData?.mobile_verification_status) {
      const interval = setInterval(() => {
        if (count <= strings.length - 1) {
          setCount((count: any) => count + 1);
        }
      }, timer);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  //setting the index of constant strings
  useEffect(() => {
    if (count === strings.length) {
      setCount(0);
    } else {
      let stringToSet = "";
      if (strings[count]?.trim() != "") {
        stringToSet = strings[count];
      }
      setCurrentString(stringToSet);
    }
  }, [count]);

  useEffect(() => {
    if (contextData?.firstSession && contextData?.role === "employer" && !isAdmin) {
      setShowFilterModal(true);
    } else {
      contextData?.setFirstSession(false);
    }
  }, [contextData?.firstSession]);

  useEffect(() => {
    if (role === "employer" && searchString != undefined) getList(false);
  }, [searchString]);

  useEffect(() => {
    if (!showSubscriptionListing) {
      setRedirectToSubscription(false);
    }
  }, [showSubscriptionListing]);
  const stringifyQuery = (filters: any) =>
    stringify(filters, { arrayFormat: "brackets", encode: false });

  //function to get selected profile details
  const getProfile = (id: any) => {
    if (role === "employer" || role === "candidate") {
      getUserDetails(id)
        .then((response) => {
          setSelectedProfile(response?.data);
        })
        .catch((err) => console.log(err));
      setShowProfile(true);
    }
  };

  //function to set favourite
  const setFavourite = (id: any, currentFavourite?: boolean) => {
    let data = {
      candidate_id: id,
      favourite: currentFavourite === true ? "0" : "1",
    };
    const initialState = candidateList;
    const elementIndex = candidateList.findIndex(
      (item: CandidateType) => item?.uid == id
    );
    let updatedDataList = candidateList;
    updatedDataList[elementIndex] = {
      ...updatedDataList[elementIndex],
      favourite: !currentFavourite,
    };
    setCandidateList(updatedDataList);
    if (selectedProfile?.user_id) {
      setSelectedProfile({
        ...selectedProfile,
        favourite_status: !selectedProfile?.favourite_status,
      });
    }
    try {
      addFavourite(data);
    } catch (error) {
      setCandidateList(initialState);
    }
  };

  //function to check if chat initiated and redirect to message screen
  const chatInitiated = (id: any) => {
    if (role === "candidate") {
      history.push("/message/" + id);
    } else if (role === "employer") {
      if (contextData?.userData?.is_subscribed === false) {
        setModalType(1);
        setShowProfile(false);
        setShowModalBox(true);
      }
      // else if (
      //   contextData?.userData?.is_subscribed &&
      //   !contextData?.userData?.profile_status &&
      //   contextData?.userData?.role === "employer"
      // ) {
      //   setShowProfile(false);
      //   setErrorText(t("Please_complete_your_profile"));
      //   setShowError(true);
      // }
      else {
        let data = {
          candidate_id: id,
        };
        chatStarted(data).then((res) => {
          history.push("/employer/message/" + id);
        });
      }
    }
  };
  //function to make api call to get candidate list based on filters

  const getList = (pagination?: boolean, filters?: {},getAll?:boolean) => {
    const currentLimit = getPageLimit() ?? 10;
    let currentFilters = filters ? filters : filterData;
    let currentDate = new Date();
    let age = [];
    if (filters != undefined && Object.keys(filters).length > 0) {
      age = currentFilters?.age;
    } else {
      age = savedAge;
    }
    // let minAge = currentDate.getFullYear() - 18;
    // let maxAge = currentDate.getFullYear() - 67;
    let minAge = 67;
    let maxAge = 18;
    if (age?.length != 0 && age != undefined) {
      minAge = age[1];
      maxAge = age[0];
    }
    // let birthdayMin = moment(new Date(`01/01/${maxAge}`)).format("DD-MM-YYYY");
    // let birthdayMax = moment(new Date(`12/31/${minAge}`)).format("DD-MM-YYYY");
    let birthdayMin = maxAge;
    let birthdayMax = minAge;
    let concatinatedPositions =
      currentFilters?.industry !== undefined
        ? currentFilters?.positions.concat(currentFilters?.industry)
        : []; //concatinating arrays
    let set = new Set(concatinatedPositions); //using Set to make sure no duplication
    concatinatedPositions = Array.from(set);
    let onlyPositionIds = concatinatedPositions.filter((eachItem:string)=> !currentFilters?.industry?.includes(eachItem)) ?? []
    let data = {
      ...(searchString != "" && { keywords: searchString }),
      ...(concatinatedPositions !== undefined &&
        concatinatedPositions.length > 0 && {
          preferred_job: concatinatedPositions,
        }),
      industry: currentFilters?.industry,
      location: currentFilters?.country,
      language: currentFilters?.language,
      availability: currentFilters?.availability,
      position_type: currentFilters?.workType,
      drivers_license: currentFilters?.drivingLicense,
      "birthday[max]": birthdayMax,
      "birthday[min]": birthdayMin,
      preferred_industry_position: onlyPositionIds,
    //   limit: currentLimit,
    //   page: pagination == true ? activePage + 1 : 1,
      ...(!getAll && {limit: currentLimit }),
      ...(!getAll && {page: pagination == true ? activePage + 1 : 1 })
    };
    let localDataObject = {
      age: age,
      positions: concatinatedPositions,
      industry: currentFilters?.industry ?? [],
      country: currentFilters?.country ??[],
      language: currentFilters?.language ?? [],
      availability: currentFilters?.availability ?? [],
      workType: currentFilters?.workType ?? [],
      drivingLicense: currentFilters?.drivingLicense ?? [],
      preferred_industry_position: onlyPositionIds
    };
    localStorage.setItem("filters", JSON.stringify(localDataObject));
    setFilterData(localDataObject);
    if (pagination) {
      getFilteredCandidates(data).then((res) => {
        let page = undefined;
        setPage(Number(res.current_page));
        if (res?.current_page < res?.total_pages) {
          //page = Math.floor(res?.total / 10);
          //setPage(page);
          //setTotalPageCount(Math.ceil(res?.total / 10));
          let list = candidateList.concat(res?.data);
          setCandidateList(pagination ? list : res.data);
          setMoreProfiles(true);
        } else {
          if (res?.current_page >= res?.total_pages) {
            let list = candidateList.concat(res?.data);
            setCandidateList(pagination ? list : res.data);
            setMoreProfiles(false);
          }
        }
        setLoader(false);
      });
    } else{
      getFilteredCandidates(data).then((res) => {
        setPage(Number(res.current_page))
        setCandidateList(res.data??[])
          setMoreProfiles(true)
          setLoader(false);
      });
    }
  };

  const handleErrorModalClose = () => {
    setShowError(false);
  };

  useEffect(() => {
    if (!showProfile) {
      setSelectedProfile({});
    }
  }, [showProfile]);
  const handleFilterButtonOnClick = () => {
    setShowFilter(true);
  };

  //calls the getList api which gets the candidate list based on filters
  const callFilterAPI = (values: Filters) => {
    if(selectMode){
      setSelectMode(false)
    }
    getList(false, values);
  };

  useEffect(() => {
    if (!selectMode) {
      form.reset();
    }
  }, [selectMode]);

  const handleProfilesSubmit = (values: any) => {
    if (values.length > 0) {
      setShowEmployerList(true);
    } else {
      setErrorText(t("please_select_a_candidate_to_proceed"));
      setShowError(true);
    }
  };
  const handleSelectionSubmit = (employerIds: string[]) => {
    if (employerIds.length != 0) {
      let selectedCandidateIds: string[] = [];
      form.values.selectedProfiles.forEach((profile: any) => {
        selectedCandidateIds.push(profile.uid);
      });
      let payload = {
        candidate_id: selectedCandidateIds,
        employer_id: employerIds,
        favourite: 1,
      };
      addFavouriteList(payload)
        .then((res) => {
          if (res.success) {
            setShowError(true);
            setErrorText(res.message);
          }
        })
        .catch((err) => {
          setShowError(true);
          setErrorText(t("default_error_message"));
        });
      form.reset();
      setShowEmployerList(false);
    } else {
      setErrorText(t("please_select_a_employer"));
      setShowError(true);
    }
  };
  return (
    <PrivateLayout
      typeOfLayout="homeFavourite"
      setShowFilter={handleFilterButtonOnClick}
      isFavouriteScreen={false}
      isFilterAtSide={isFilterAtSide}
    >
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => handleErrorModalClose()}
        zIndex={10000}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <SearchModal
            show={role === "candidate" ? false : showFilterModal}
            onHide={() => {
              setShowFilterModal(false);

              contextData?.setFirstSession(false);
            }}
            type={role === "candidate" ? 2 : 1}
            showFilter={() => setShowFilter(true)}
          />
          {role == "employer" && (
            <Filter
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterUpdate={callFilterAPI} //callback to call filter api
              currentSelectedFilters={filterData} //passing context filter values
              clearStates={false}
            />
          )}
          {userData?.mobile_verification_status === false && !isAdmin && (
            <MobileNotVerifiedWrapper>
              <NotVerifiedText>
                {currentString.trim() != ""
                  ? t(currentString)
                  : t(
                      "Your mobile has not yet been verified. Verify Now!"
                    )}{" "}
                <ClickHere onClick={() => setShowMobileVerify(true)}>
                  {t("Click_here_to_verify")}
                </ClickHere>
              </NotVerifiedText>
            </MobileNotVerifiedWrapper>
          )}
          {parseFloat(userData?.field_profile_percentage) < 100 &&
            userData?.role == "candidate" && (
              <ProfileCompletionWrapper>
                <BellIconWrapper>
                  <MemoBellicon fontSize={width > 1000 ? "24px" : "21px"} />
                </BellIconWrapper>
                <ProfileCompletionText>
                  <PercentageWrapper>
                    {userData?.field_profile_percentage}{" "}
                  </PercentageWrapper>
                  {t("profile_completion_text")}
                </ProfileCompletionText>
                <EditWrapper>
                  <EditText onClick={() => setShowUserProfile(true)}>
                    {t("Edit")}
                  </EditText>
                </EditWrapper>
              </ProfileCompletionWrapper>
            )}
          <HomeFavouriteComponent
            firstTime={contextData?.firstSession}
            searchString={searchString}
            setSearchString={setSearchString}
            setShowFilter={handleFilterButtonOnClick}
            setIsFilterAtSide={setIsFilterAtSide}
            isFilterAtSide={isFilterAtSide}
            isMessageScreen={false}
            selectMode={selectMode}
            setSelectMode={() => setSelectMode(!selectMode)}
            shouldShowManageButton={candidateList.length>0}
          />
          <form
            onSubmit={form.onSubmit((values) =>
              handleProfilesSubmit(values.selectedProfiles)
            )}
          >
            {selectMode && form.values.selectedProfiles.length>0 &&  (
              <SelectionModeButtonsWrapper>
                {/* <button onClick={() => handleSelectAll()}>getAll</button> */}
                <Button
                  variant="filled"
                  width="fit-content"
                  height="43px"
                  radius={10}
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="14px"
                  type="submit"
                >
                  {t("Submit")}
                </Button>
              </SelectionModeButtonsWrapper>
            )}
            <ProfileWrapper
              id="scrollableDiv"
              customHeight={
                userData?.mobile_verification_status === false ? "65vh" : ""
              }
            >
              {candidateList?.length == 0 || candidateList === undefined ? (
                role == "employer" ? (
                  <NoResultFoundWrapper
                    margin={
                      role == "employer" ? "0 auto 0 auto" : "0 auto 50px auto"
                    }
                  >
                    <NoResultFound
                      candidate={role === "employer" ? false : true}
                    />
                  </NoResultFoundWrapper>
                ) : (
                  <>
                    <NoResultsFoundLayout />
                  </>
                )
              ) : (
                <InfiniteScroll
                  dataLength={candidateList?.length}
                  next={() => (role === "employer" ? getList(true) : "")}
                  hasMore={moreProfiles}
                  loader={<></>}
                  scrollableTarget="scrollableDiv"
                  // endMessage={
                  //   <p style={{ textAlign: "center" }}>
                  //     <b>{t("Yay! You have seen it all")}</b>
                  //   </p>
                  // }
                >
                  <CardsWrapper>
                    {candidateList?.length > 0 &&
                      candidateList?.map((item: any, index) => {
                        return (
                          <Cards
                            key={`canidate${index}`}
                            favourite={item?.favourite}
                            cvstatus={item.cv_status == true}
                            type={role === "employer" ? 1 : 2}
                            onClick={() => {
                              if (!selectMode) {
                                setSelectedName(item?.name);
                                getProfile(item?.uid);
                              } else {
                                if (
                                  form.values.selectedProfiles.includes(item)
                                ) {
                                  let indexx =
                                    form.values.selectedProfiles.findIndex(
                                      (each: any) => each.uid == item.uid
                                    );
                                  form.removeListItem(
                                    "selectedProfiles",
                                    indexx
                                  );
                                } else {
                                  form.addListItem("selectedProfiles", item);
                                }
                              }
                            }}
                            borderColor={
                              form.values.selectedProfiles.includes(item) &&
                              selectMode
                                ? primaryColor
                                : ""
                            }
                            chatInitiated={() => {
                              setSelectedName(item?.first_name);
                              chatInitiated(item?.uid);
                            }}
                            setFavourite={() => {
                              setSelectedName(item?.first_name);
                              setFavourite(item?.uid, item?.favourite);
                              setIsFavourite(item?.favourite);
                            }}
                            profileImageURL={
                              item?.profile_picture != ""
                                ? item?.profile_picture
                                : ""
                            }
                            profileName={
                              contextData?.role === "employer"
                                ? item?.first_name
                                : item?.name
                            }
                            location={item?.location}
                            time={item?.date_of_birth}
                            description={item?.about}
                          />
                        );
                      })}
                  </CardsWrapper>
                </InfiniteScroll>
              )}
            </ProfileWrapper>
          </form>
          <Drawer
            position="right"
            padding={"0"}
            onClose={() => setShowEmployerList(false)}
            opened={showEmployerList}
          >
            <EmployerSelection
            title={t("please_select_employers")}
            onHide={() => {
              setShowEmployerList(false);
            }}
            dontHideDrawer={true}
            updateEmployersAndSubmit={handleSelectionSubmit}
            />
          </Drawer>
          <Drawer
            position="right"
            padding={"0"}
            onClose={() => setShowProfile(false)}
            opened={showProfile}
          >
            {role !== "candidate" ? (
              <UserProfileDisplay
                showSubscriptionModal={setShowModalBox}
                selectedUser={selectedProfile}
                onHideModel={setShowProfile}
                type={2}
                chatInitiated={() => {
                  chatInitiated(selectedProfile?.user_id);
                  setSelectedName(selectedProfile?.first_name);
                }}
                setFavourite={() => {
                  setSelectedName(selectedProfile?.first_name);
                  setFavourite(
                    selectedProfile?.user_id,
                    selectedProfile?.favourite_status
                  );
                }}
              />
            ) : (
              <CompanyProfileDisplay
                selectedUser={selectedProfile}
                onHideModel={setShowProfile}
                type={2}
                chatInitiated={() => chatInitiated(selectedProfile?.user_id)}
              />
            )}
          </Drawer>
        </>
      )}
      <ChatFavModal
        name={selectedName}
        type={modalType}
        show={showModaBox}
        onHide={setShowModalBox}
        buttonOnClick={setShowSubscriptionListing}
        favourite={isFavourite}
      />
      <SubscriptionListing
        setShowSubscriptionListing={setShowSubscriptionListing}
        showSubscriptionListing={showSubscriptionListing}
        setShowModalBox={setShowModalBox}
      />
    </PrivateLayout>
  );
};
export default AdminPanel;
