import React, { useContext, useState } from "react";
import JobParkLogoTransparent from "ui/icons/JobParkLogoTransparent";
import { Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import Button from "ui/buttons/Button";
import Password from "ui/inputs/Password";
import MemoLockIcon from "ui/icons/LockIcon";
import PublicLayout from "layouts/PublicLayout";
import BackButton from "ui/buttons/BackButton";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router-dom";
import { createNewPassword } from "services/user/User";
import { UserContext } from "context/state";
import { ErrorModal } from "ui/modals/ErrorModal";
import { login } from "services/user/User";

const Heading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #123b3d;
  margin: 30px 0 9px 0;
  @media only screen and (min-width: 1000px) {
    margin-top: 30px;
  }
`;
const FormContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
const StyleWrapper = styled.div`
  margin: 0px;
  @media only screen and (min-width: 1000px) {
    max-width: 335px;
  }
`;

const TextStyle = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6e6e6e;
  margin-bottom: 23px;
  @media only screen and (min-width: 1000px) {
    margin-top: 9px;
  }
`;
const BackButtonStyle = styled.span`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;
const StylePasswordButton = styled.div`
  margin: 23px 0 0 0;
  @media only screen and (min-width: 1000px) {
    margin: 34px 0 15px 0;
  }
`;

const StyleButtonDesktop = styled.div`
  /* position: fixed;
  bottom: 20px;
  left: auto;
  width: 90%; */
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
    position: relative;
    bottom: auto;
    width: 100%;
    top: auto;
    margin: 15px 0 0 0;
  }
`;
const StyleButtonMobile = styled.div`
  display: block;
  position: fixed;
  bottom: 20px;
  left: auto;
  width: 90%;
  @media only screen and (max-height: 500px) {
    position: sticky;
    top: 0;
    bottom: auto;
    width: 100%;
    left: auto;
  }
  @media only screen and (min-width: 1000px) {
    display: none;
    /* position: relative;
    bottom: auto;
    width: 100%;
    top: auto;
    margin: 15px 0 0 0; */
  }
`;

export default function CreateNewPassword() {
  const { t } = useTranslation();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { id, token } = useParams<{ id: string; token: string }>();
  const { role, setRole, setUserId, setFirstSession } = useContext(UserContext);
  const [phone, setPhone] = useState("");
  const [loginData, setLoginData] = useState({});
  const [showConfirmModal, setConfirmModal] = useState(false);

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("Enter_Password"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        t("Password_must_contain_8_characters")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("Passwords_must_match"))
      .required(t("Please_enter_valid_password")),
  });

  const handleLogin = (data: any) => {
    let uid = undefined;
    // let data = {
    //   email: email,
    //   password: password,
    //   role: role,
    //   type: 'basic',
    // }

    login(data)
      .then((response) => {
        //console.log(response)
        if (response?.success) {
          uid = response?.data?.current_user?.uid;
          setUserId(uid);
          // console.log(`token`, response?.data?.jwt_token);
          let user = JSON.stringify({ userId: uid, role: role });
          localStorage.setItem("token", response?.data?.jwt_token);
          localStorage.setItem("User", user);
          setFirstSession(response?.data?.current_user?.first_time_login);
          const currentUser = response?.data?.current_user;
          let loggedInUserRole = response?.data?.current_user?.role;
            switch (true) {
              case currentUser?.profile_status &&
                loggedInUserRole === "candidate": {
                history.push({ pathname: "/home", state: { uid } });
                break;
              }
              case currentUser?.emp_profile_completed &&
                loggedInUserRole === "employer": {
                history.push({ pathname: "/employer-home", state: { uid } });
                break;
              }
              case !currentUser?.profile_status &&
                loggedInUserRole === "candidate":
              case !currentUser?.emp_profile_completed &&
                loggedInUserRole === "employer":
                history.push({ pathname: "/profile-creation", state: { uid } });
                break;
              default:
                break;
            }
          // if (response?.data?.current_user?.profile_status) {
          //   if (role === "candidate") history.push({ pathname: "/home", state: { uid } });
          //   else history.push({ pathname: "/employer-home", state: { uid } });
          // } else {
          //   role === "candidate"
          //     ? history.push("/profile-creation")
          //     : history.push({ pathname: "/employer-home", state: { uid } });
          // }
        } else {
          //console.log(response?.response?.data?.message)
          if (response?.message) {
            if (response?.response?.data?.message) {
              setErrorMessage(response?.response?.data?.message[0]);
              setShowErrorModal(true);
            } else {
              setErrorMessage(response?.message);
              setShowErrorModal(true);
            }
          }
          if (response?.response?.data?.data?.phone) {
            setPhone(response?.response?.data?.data?.phone);
            setErrorMessage(t("Your_account_is_not_verified"));
            setShowErrorModal(true);
          }
          //localStorage.clear()
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const form = useForm({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    schema: yupResolver(schema),
  });
  const resetPassword = (values: any) => {
    let data = {
      id: id,
      token: token,
      password: values?.newPassword,
    };
    createNewPassword(data)
      .then((response) => {
        // console.log(`email --- >`, response)
        // console.log(`role`, response?.role)
        if (!response?.response?.data?.success) {
          setErrorMessage(response?.response?.data?.message);
          setShowErrorModal(true);
        }
        if (response?.email && response?.role) {
          setRole(response?.role);
          setLoginData({
            email: response?.email,
            password: values?.newPassword,
            role: response?.role,
            type: "basic",
          });
          setConfirmModal(true);
          setErrorMessage(response?.message);
          // handleLogin(
          //   response?.email,
          //   response?.role,
          //   values?.newPassword
          // )
        }
      })
      .catch((error) => {
        // console.log(`error`, error);
      });
    // console.log(role);
    // if (role === 'candidate')
    //   history.push('/login')
    // else
    //   history.push('/employer-login')
  };

  return (
    <>
      <PublicLayout>
        <ErrorModal
          opened={showErrorModal}
          message={errorMessage}
          onClose={() => setShowErrorModal(false)}
        />
        <ErrorModal
          opened={showConfirmModal}
          message={errorMessage}
          onClose={() => handleLogin(loginData)}
        />
        <FormContainer>
          <StyleWrapper>
            <BackButtonStyle>
              <BackButton>{t("Back")}</BackButton>
            </BackButtonStyle>
            <form onSubmit={form.onSubmit((values) => resetPassword(values))}>
              <div>
                <Heading>{t("Create_New_Password")}</Heading>
                <TextStyle>
                  {" "}
                  {t("Your_new_password_must_be_different_from_your_previous_passwords")}
                </TextStyle>
                <StylePasswordButton>
                  <Password
                    icon={<MemoLockIcon />}
                    // height='54px'
                    id='newPassword'
                    placeholder={t("Password")}
                    {...form.getInputProps("newPassword")}
                  />
                </StylePasswordButton>
                <Password
                  icon={<MemoLockIcon />}
                  id='confirmPassword'
                  placeholder={t("Confirm_Password")}
                  {...form.getInputProps("confirmPassword")}
                />
              </div>
              <StyleButtonDesktop>
                <Button
                  type='submit'
                  color='#FFFFFF'
                  fontWeight={700}
                  fontSize='18px'
                  lineHeight='100%'
                  variant='filled'>
                  {t("Reset_Login")}
                </Button>
              </StyleButtonDesktop>
              <StyleButtonMobile>
                <Button
                  type='submit'
                  color='#FFFFFF'
                  fontWeight={600}
                  fontSize='16px'
                  lineHeight='130%'
                  variant='filled'>
                  {t("Reset_Login")}
                </Button>
              </StyleButtonMobile>
            </form>
          </StyleWrapper>
        </FormContainer>
      </PublicLayout>
    </>
  );
}
