import PublicLayout from "layouts/PublicLayout";
import React from "react";
import CreateAccountVerifyForm from "screens/account-creation/CreateAccountVerifyForm";

export default function CreateAccountVerifyScreen() {

    return (
        <PublicLayout>
            {/* <CreateAccountVerifyForm /> */}
        </PublicLayout>
    );
}