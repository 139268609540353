import React from 'react'
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled'
import { Card, Image, Badge, Button, Group, useMantineTheme, Stack } from '@mantine/core';
import MemoSwitchOn from 'ui/icons/SwitchOn';
import MemoPaid from 'ui/icons/Paid';
import MemoPencil from 'ui/icons/Pencil';
import BackButton from 'ui/buttons/BackButton';
import Container from 'ui/layout/Container';


const Heading = styled.h1`

  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
  }
`
const CardHeading = styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: #123B3D;

  @media only screen and (min-width: 1000px) {
  }
`
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: #6E6E6E;

  @media only screen and (min-width: 1000px) {
  }
`
const DesktopView = styled.div`
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`
const Content = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: #6E6E6E;

  @media only screen and (min-width: 1000px) {
  }
`
export default function InvoiceDetails() {
  const { t } = useTranslation();
  return (
    <Container>
      <DesktopView>
        <BackButton />
      </DesktopView>
      <Stack spacing={5}>
        <Group position="apart" >
          <div>
            <Text>Invoice no: 89180180313</Text>
            <Text> 20 June 2022 </Text>
          </div>
          <MemoPaid width="63px" height="26px" />
        </Group>

        <Stack spacing={0}>
          <Heading>Dreamstine</Heading>
          <div>
            <Content>info@dreamstine</Content>
            <Content>P.O. Box 4863 Nydalen 0422 Oslo</Content>
          </div>
        </Stack>


        <Stack spacing={2}>
          <CardHeading>Billing Address</CardHeading>
          {/* <Text>P.O. Box 4863 Nydalen 0422 Oslo</Text>  */}
        </Stack>

        <Stack spacing={3}>
          <Text>Amount Paid</Text>
          <span style={{ fontSize: "20px", fontWeight: "700", color: "#2D8E4C", }}>€199</span>
        </Stack>
      </Stack>
    </Container>
  )
}