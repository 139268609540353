import { Switch as MTSwitch, SwitchProps } from '@mantine/core';

interface ToggleProps extends SwitchProps {
  label?: string,
  checked?: boolean,
  onChange?: any
}

const ToggleSwitch: React.FC<ToggleProps> = ({ label, checked, onChange, ...props }) => {
  const style = {
    input: {
      '&:checked': {
        color: "#4CAF50",
        backgroundColor: "#4CAF50",
        border: 'none !important'
      }
    }
  }
  return (
    <MTSwitch
      label={label}
      {...props}
      checked={checked}
      onChange={onChange}
      size={"md"}
      styles={(theme) => ({
        root: style
      })}
    />
  );
}

export default ToggleSwitch