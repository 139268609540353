import * as React from "react";

function PasswordAndSecurtity(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M21.75 14.667h-.708V13.25a3.543 3.543 0 00-7.084 0v1.417h-.708a1.42 1.42 0 00-1.416 1.416v7.084c0 .779.637 1.416 1.416 1.416h8.5a1.42 1.42 0 001.417-1.416v-7.084a1.42 1.42 0 00-1.417-1.416zm-4.25 6.375a1.42 1.42 0 01-1.416-1.417c0-.78.637-1.417 1.416-1.417.78 0 1.417.638 1.417 1.417a1.42 1.42 0 01-1.417 1.417zm2.196-6.375h-4.392V13.25c0-1.211.985-2.196 2.196-2.196 1.211 0 2.196.985 2.196 2.196v1.417z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoPasswordAndSecurtity = React.memo(PasswordAndSecurtity);
export default MemoPasswordAndSecurtity;
