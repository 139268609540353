import * as React from "react";

function Eye(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 19" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_3470_3358)">
        <path
          d="M16.885 9.892a.732.732 0 00-.018-.815c-2.57-3.533-5.347-5.324-8.254-5.324-4.93 0-8.356 5.134-8.498 5.351a.733.733 0 00.017.815c2.568 3.537 5.344 5.328 8.252 5.328 4.93 0 8.355-5.134 8.5-5.355zm-8.501 4.025c-2.422 0-4.782-1.49-7.023-4.42.878-1.16 3.667-4.414 7.252-4.414 2.422 0 4.781 1.489 7.023 4.42-.878 1.16-3.668 4.414-7.252 4.414z"
          fill="#6A6A6A"
        />
      </g>
      <path
        d="M8.5 6.5a3.026 3.026 0 00-3.022 3.02A3.026 3.026 0 008.5 12.54a3.026 3.026 0 003.022-3.02A3.026 3.026 0 008.5 6.5zm0 4.85a1.834 1.834 0 01-1.832-1.83c0-1.009.822-1.83 1.832-1.83 1.01 0 1.832.821 1.832 1.83 0 1.009-.822 1.83-1.832 1.83z"
        fill="#6A6A6A"
      />
      <defs>
        <clipPath id="prefix__clip0_3470_3358">
          <path fill="#fff" d="M0 0h17v19H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoEye = React.memo(Eye);
export default MemoEye;
