import { createStyles, keyframes } from '@mantine/core'
import MemoLoading_Icon from 'ui/icons/Loading_Icon'
import React from 'react'
import LoadIcon from 'ui/icons/Loading_Img.png'
import styled from '@emotion/styled';
import { useViewportSize } from '@mantine/hooks';

export const bounce = keyframes({
  '100%': { transform: 'rotate(0deg)' },
  '0%': { transform: 'rotate(360deg)' }
});
const useStyles = createStyles((theme) => ({
  loader: {
    width: "100px",
    height: "auto",
    background: "transparent",
    animation: `${bounce} 1s linear infinite`
  }
}))

const LoaderWrapper= styled.div<{margin?:string|undefined}>`
  position: absolute;
  top:19%;
  left:2%;
  z-index: 1000;
  @media only screen and (min-width: 1000px) {
    left:${props => props?.margin ? props?.margin : '2%'};
    top:19%;
  }
`
interface loaderProps{
  margin?:string
}

const DropzoneLoader:React.FC<loaderProps>=(props) =>{
  const { classes } = useStyles()
  let margin=props?.margin ?? undefined
  return (
    <LoaderWrapper margin={margin}><img src={LoadIcon} className={classes.loader} /></LoaderWrapper>
  )
}

export default DropzoneLoader