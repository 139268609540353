import React, { useContext } from "react";
import styled from "@emotion/styled";
import MemoGrayAlert from "ui/icons/GrayAlert";
import PrivateLayout from "layouts/PrivateLayout";
import { useTranslation } from "react-i18next";
import { SearchModalData } from "ui/modals/SearchModal";
import { UserContext } from "context/state";

interface NoResultFoundProps {
  backgroundImg?: any;
  position?: "top" | "center";
  setActiveMenu?: any;
  activeMenu?: number;
  usedIn?: string;
  candidate?: boolean;
  showText?:boolean;
}

const HeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #123b3d;
  margin: 12px 0 12px 0;
  @media only screen and (min-width: 1000px) {
    font-size: 16px;
    margin: 20px 0 20px 0;
  }
`;

const DescriptionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
  }
`;

const WrapperContent = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
  .desktopView {
    display: none;
    @media only screen and (min-width: 1000px) {
      display: block;
      width: 100%;
    }
  }
  .mobileView {
    display: block;
    width: 100%;
    @media only screen and (min-width: 1000px) {
      display: none;
    }
  }
  @media only screen and (min-width: 1000px) {
  }
`;

const Wrapper = styled.div`
  background: #efefef;
  border-radius: 20px;
  /* min-height: 50vh; */
  /* height: fit-content; */
  /* margin: 0 20px 0 20px; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 1000px) {
  }
`;

const NoResultFound: React.FC<NoResultFoundProps> = ({showText=true},props) => {
  const { t } = useTranslation();
  const { role } = useContext(UserContext);
  const handleModal = () => {};
  if (props?.candidate) {
    return SearchModalData(role === "candidate" ? 2 : 1, t, handleModal);
  }
  if (props?.usedIn === "favourites") {
    return (
      <Wrapper>
        <WrapperContent>
          <MemoGrayAlert className='desktopView' />
          <MemoGrayAlert className='mobileView' width={"40px"} height={"40px"} />
          <HeadText>{t("No_Favourites_found")}</HeadText>
          <DescriptionText>{t("add_as_fav")}</DescriptionText>
        </WrapperContent>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <WrapperContent>
        <MemoGrayAlert className='desktopView' />
        <MemoGrayAlert className='mobileView' width={"40px"} height={"40px"} />
        <HeadText>{t("No_Results")}</HeadText>
        {showText ? <DescriptionText>{t("No_item_match")}</DescriptionText> :<></>}
      </WrapperContent>
    </Wrapper>
  );
};
export default NoResultFound;
