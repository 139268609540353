import * as React from "react";

function BlueAdd(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 8" fill="none" {...props}>
      <path d="M4 0v8M8 4H0" stroke="#1395DE" strokeWidth={2} />
    </svg>
  );
}

const MemoBlueAdd = React.memo(BlueAdd);
export default MemoBlueAdd;
