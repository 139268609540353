import axiosInstance from "services/AxiosInstance";

type Favourite = {
  candidate_id: string;
  favourite: string;
};
export const addFavourite = (data: Favourite) => {
  return axiosInstance
    .post("add-favourite", data)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getFilteredCandidates = async (searchParams?: any) => {
  const res = await axiosInstance.get(`candidate-filter-list?`, {
    params: {
      ...searchParams,
    },
  });
  return res.data;
};

type user = {
  name: string;
  email: string;
};
export const addUser = (data: user) => {
  return axiosInstance
    .post("add-user", data)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

type chattypes = {
  candidate_id: string;
};
export const chatStarted = (data: chattypes) => {
  return axiosInstance
    .post("add-chat", data)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

export const getFavourites = (keywords: string | undefined,page:number |undefined,limit:number|null |undefined) => {
  return axiosInstance
    .get("employer-favourite-list", {
      params: {
        ...(keywords != "" && { keywords }),
        ...(page != undefined && { page }),
        ...(limit != undefined && { limit }),
      },
    })
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

export const sendCVToEmail = (data: {}) => {
  return axiosInstance
    .post("sendcvtoemail", data)
    .then((response) => response.data)
    .catch((error) => error.response.data);
};
