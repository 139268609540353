import React, { ReactNode, useEffect, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import CheckboxGroup from 'ui/inputs/CheckboxGroup'
import { AvailabilityStore } from 'stores/taxnomies/availability.store'
import { TypeOfPostionStore } from 'stores/taxnomies/type-of-postion.store'
import { useTranslation } from 'react-i18next'
interface WhatSearchProps {
  children?: ReactNode
  show?: boolean
  onHide?: any
  getData?: any
  passData?: any
  title?: string
  savedData?: any,
  setAddClickedFor?:any,
  dontHideDrawer?:boolean,
  buttonText?:string
}

const data = [
  {
    headerName: '',
    data: [
      {
        name: 'Full Time',
        checked: false,
        tid: '1',
      },
      {
        name: 'Part Time',
        checked: false,
        tid: '2',
      },
    ],
  },
]

export const PartTimeFullTimeSearch: React.FC<WhatSearchProps> = ({
  children,
  passData,
  title,
  savedData = [],
  setAddClickedFor,
  dontHideDrawer,
  buttonText,
  getData,
  ...props
}) => {
  //State to reset selected checkbox
  const [resetData, setRestData] = useState(false)

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)

  const [data, setData] = useState<any>([])
  let WhatStoreData = Array.from(TypeOfPostionStore.data, ([id, name]) => ({
    id,
    name,
  }))
  // Function to display saved data
  const savedDataHandler = (data: any) => {
    let flag = false
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true
    })
    return flag
  }

  let WhatStoreDataUpdated = [
    {
      headerName: '',
      data: WhatStoreData.map((obj) => ({
        ...obj,
        checked: savedData?.length != 0 ? savedDataHandler(obj) : false,
        show: true,
      })),
    },
  ]
  useEffect(() => {
    setData(passData)
    getData(WhatStoreDataUpdated)
  }, [])
  useEffect(()=>{
    setData(WhatStoreDataUpdated)
  },[savedData])
  const { t } = useTranslation()
  return (
    <Stack align={'center'}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={props.onHide}
        showSearch={false}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        setAddElements={setAddElements}
        children={
          <>
            {data?.map((dataGroup: any, index: any) => {
              return (
                <CheckboxGroup
                  checkAll={false}
                  data={passData?.length != 0 ? passData : data[index]?.data?.length ? data : []}
                  header={dataGroup.headerName}
                  getData={getData}
                  index={index}
                  resetData={resetData}
                  setRestData={setRestData}
                  addElements={addElements}
                  setAddElements={setAddElements}
                  showAddButton={data?.length == index + 1 ? true : false}
                  setShow={props.onHide}
                  setAddClickedFor={setAddClickedFor}
                  dontHideDrawer={dontHideDrawer}
                />
              )
            })}
          </>
        }
      />
    </Stack>
  )
}
