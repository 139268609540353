import { Box, Grid, Text, Title, TitleProps } from "@mantine/core";
import styled from "styled-components";
import RightSideBarSkeleton from "ui/data-display/RightSideBarSkeleton";
import Profile from "assets/images/Profile.png";
import MemoPhoneVerified from "ui/icons/PhoneVerified";
import MemoEmailVerified from "ui/icons/EmailVerified";
import Switch from "ui/inputs/Switch";
import DataCard from "ui/data-display/DataCard";
import MemoFb from "ui/icons/Fb";
import MemoLinkedin from "ui/icons/Linkedin";
import MemoInsta from "ui/icons/Insta";
import MemoTwitter from "ui/icons/Twitter";
import MemoDownload from "ui/icons/Download";
import MemoCheckLogo from "ui/icons/checkLogo";
import SendCVLogo from "ui/icons/SendCV.png";
import React, { useContext, useEffect, useState } from "react";
import MemoCheckLogoWhite from "ui/icons/checkLogoWhite";
import MemoEditIcon from "ui/icons/EditIcon";
import PDFIcon from "ui/icons/pdf.png";
import { PrivateContext, UserContext } from "context/state";
import { LanguageStore } from "stores/taxnomies/language.store";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { CountryStore } from "stores/taxnomies/country.store";
import { TypeOfPostionStore } from "stores/taxnomies/type-of-postion.store";
import { setProfileStatus } from "services/candidate/candidate";
import { getCV, getUserDetails } from "services/user/User";
import { DeactivatingModal } from "ui/modals/DeactivatingModal";
import { sendCVToEmail } from "services/employer/employer";
import { LicenseStore } from "stores/taxnomies/license.store";
import MemoCitizen from "ui/icons/Citizen";
import MemoNotCitizen from "ui/icons/NotCitizen";
import { Trans, useTranslation } from "react-i18next";
import { getValidUrl } from "./appendhttps";
import Loader from "ui/feedback/Loader";
import MemoOrangeEdit from "ui/icons/OrangeEdit";
import MemoGreyPhone from "ui/icons/GreyPhone";
import { DropZone } from "ui/inputs/Dropzone";
import MemoBlueAdd from "ui/icons/BlueAdd";
import { fontBlack, greyHeader, primaryColor } from "constants/colors";
import { PositionStore } from "stores/taxnomies/position.store";
import MemoEyeIcon from "ui/icons/EyeIcon";
import MemoProfileCompleted from "ui/icons/ProfileCompleted";
import MemoProfileIncomplete from "ui/icons/ProfileIncomplete";
import MemoOrangeEyeIcon from "ui/icons/OrangeEyeIcon";
import MemoGreyClose from "ui/icons/GreyClose";
import MemoOrangePhone from "ui/icons/OrangePhone";
import MemoOrangeEmail from "ui/icons/OrangeEmail";
import filterNewIndustries from "helpers/filter-new-industry"

const ProfilePictureContainer = styled(Box)`
  margin-top: -55px;
  display: block;
  background: transparent;
  height: 88px;
`;

const NameAgeWrapper = styled(Box)`
  display: block;
  position: relative;
  margin-left: 125px;
  font-family: Poppins;
  font-size: 16px;
`;

const Name = styled(Box)`
  color: #fff;
  padding: 18px 0 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  height: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 250px;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    line-height: 18px;
    max-width: 335px;
  }
`;

const Age = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */
  padding: 6px 0;
  color: #6e6e6e;
`;

const LanguageExperienceCountryWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  position: relative;
  padding-top: 21px;
`;
interface PaddingProps{
  toperPadding?:boolean
}

const VerifiedWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 29px 0 6px 0px;
  gap: 11px;
  flex-wrap: wrap;
`;

const EmailPhoneWrapper = styled(Box)`
  min-width: fit-content;
  min-height: 26px;
  height: fit-content;
  width: fit-content;
  background: #ffffff;
  border-radius: 30px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #4caf50;
  padding: 0 11px 0 11px;
  display: flex;
  justify-content: center;
`;
const ProfileActivation = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 355px; */
  width: 100%;
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 10px 35px -10px rgba(64, 59, 75, 0.1);
  border-radius: 20px;
  margin: 23px auto;
  /* padding: 10px 0 10px 22px; */
  position: relative;
`;

const ProfileActivationTextWrapper = styled(Box)`
  padding: 0 0 0 22px;
  font-size: 12px;
  line-height: 18px;
`;

const ProfileActivationTitle = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #123b3d;
`;

const ProfileActivationDescription = styled(Box)`
  font-family: "Poppins";
  font-weight: 400;
  color: #6e6e6e;
`;

const StyledTitle: React.FC<TitleProps> = styled(Title)`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${fontBlack};
  word-break: break-word;
`;

const CustomText = styled(Text)`
  font-family: "Poppins";
  font-weight: 400;
  color: #6e6e6e;
  font-size: 12px;
  margin: 19px 10px 0 0px;
  line-height: 18px;
`;

const CVButton = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  cursor: pointer;
`;
const CVButtonText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #123b3d;
`;

const RefText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6E6E6E;
  margin: 25px 0 0 20px;
  /* float: right; */
`;

const NoItemToDisplay = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #606060;
`;

interface StyleProps{
  customBackground?:string
}
const StyleProfile = styled.div<StyleProps>`
  position: absolute;
  width: 88px;
  height: 88px;
  margin: 0 12px 0 25px;
  background-color:${p=> p?.customBackground ? p.customBackground : "#FFF"};
  border-radius: 15px;
`;

const StyleAvatar = styled.img`
  position: absolute;
  width: 88px;
  height: 88px;
  border-radius: 15px;
  object-fit: contain;
`;

const StyleIndicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: #c4c4c4;
  border: 2px solid #ffffff;
  border-radius: 50%;
  bottom: 0px;
  right: -8px;
`;

const StylePDFIcon = styled.div`
  background: linear-gradient(0deg, #6e6e6e, #6e6e6e);
  box-shadow: 0px 10px 35px rgba(64, 59, 75, 0.1);
  border-radius: 11px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0px;
  cursor: pointer;
`;

const StyledExperienceWrapper = styled.div``;
const DataCardWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 10px;
`;

const NotVerifiedText = styled.text<{ addMargin?: boolean | undefined }>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-right: ${(props) => (props?.addMargin ? "62px" : "0px")};
  color: #838383;
  @media only screen and (min-width: 1000px) {
    margin-right: ${(props) => (props?.addMargin ? "62px" : "0px")};
  }
`;
const VerifyText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #1395de;
  margin-left: 5px;
`;

const VerificationWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  //margin: 32px 0 19px 0;
`;
const TitleWrapper = styled.div`
  margin: 32px 0 19px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ViewDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color:${primaryColor};
  cursor:pointer;
`;

const ContactDetailsWrapper = styled.div`
   margin: 17px 20px -3px 20px;
   background: #FFFFFF; 
   border-radius: 10px;
   position: relative;
   height: 68px;
`
const CrossButton = styled.button`
    position:absolute;
     top:7px;
     right:7px;
     border:none;
     border-radius:10px;
     background-color:white;
`
const TextSpan = styled.span`
 padding-left:10px;
 position:absolute;
  font-size: 13px;
`

interface userProfile {
  selectedUser?: {
    user_id?: string | null;
    profile_picture?: string;
    name?: string;
    age?: string;
    primary_language?: [];
    industry_type?: [];
    country_origin?: string;
    your_experience?: string;
    secondary_language?: [];
    availability?: [];
    part_full_time?: [];
    provide_references?: string;
    social_facebook_link?: string;
    social_instagram_link?: string;
    social_linkedin_link?: string;
    social_twitter_link?: string;
    user_account_status?: boolean;
    role?: string;
    is_subscribed?: boolean;
    license_type?: [];
    favourite_status?: boolean;
    about_yourself?: string;
    your_education?: string;
    citizen?: string;
    upload_cv?: string;
    first_name?: string;
    last_name?: string;
    mobile_verification_status?: boolean;
    preferred_job?: [];
    position_type?: [];
    preferred_country?:[];
    phone?:string,
    email?:string
  };
  type: number;
  showSocialmediaicons?: boolean;
  profileImageURL?: string;
  activeStatus?: boolean;
  onHideModel?: any;
  chatInitiated?: () => void;
  setFavourite?: () => void;
  showSubscriptionModal?: any;
  setShowMobileVerify?: any;
}

const UserProfileDisplay: React.FC<userProfile> = ({
  type = 2,
  showSocialmediaicons = true,
  profileImageURL = Profile,
  activeStatus = false,
  selectedUser = {},
  ...props
}) => {
  // type : 1 employer profile
  // type : 2 employee profile
  const context = useContext(UserContext);
  const [sendCVbyEmail, setSendCVbyEmail] = useState(false);
  const { userId, setUserData } = useContext(UserContext);
  const [downloadCV, setDownloadCV] = useState(false);
  const [profileActive, setProfileActive] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [candidateExperience,setCandidateExperience]= useState<any>([])
  const [candidatePreferredJob,setCandidatePreferredJob]= useState<any>([])
  let preferredJobFiltered = candidatePreferredJob.filter(filterNewIndustries)
  const contextData = useContext(UserContext);
  const { t } = useTranslation();
  const [previewMode,setPreviewMode] = useState(false)
  const sel_user_exper = selectedUser.industry_type
    ? selectedUser.industry_type
    : [];
  const [showContactDetails,setShowContactDetails] = useState(false)
  const {
    preferredJob,
    setShowPreferredJob,
    setShowUserProfile,
    setShowCountryOrigin,
    setShowExperience,
    setShowPrimaryLanguage,
    setShowSecondaryLanguage,
    setShowWorkType,
    setShowLicense,
    setShowAbout,
    setShowSocialMediaEdit,
    experience,
    setShowEUCitizen,
    setShowCVEdit,
    setShowPreferredCountry,
    setExperience,
    setPreferredJob,
    workType,setWorkType,
    setCountryOfOrigin,
    setCV,
    ...privateData
  } = useContext(PrivateContext);
  let el_user_exper = sel_user_exper
    .map((e) => ({ id: e, name: IndustryStore.get(e) }))
    .sort((a, b) => a.name?.localeCompare(b.name));
  
  const downloadResume = () => {
    if (
      contextData?.userData?.is_subscribed === false &&
      contextData?.role === "employer"
    ) {
      props.onHideModel();
      props?.showSubscriptionModal(true);
    } else {
      let data = undefined;
      if (selectedUser?.user_id) {
        data = parseInt(selectedUser?.user_id);
      }
      getCV(data).then((res) => {
        if (res?.cv) window.open(res?.cv);
      });
    }
  };
  useEffect(() => {
    if(selectedUser?.preferred_job ){
      updatePositionDataHandler()
    }
    if(selectedUser?.position_type ){
      updatePastPositionDataHandler()
    }
    if(selectedUser?.part_full_time){
      setPartTimeFullTime()
    }
      setOriginCountry()
  }, [selectedUser]);

  //sets country of origin data as checked or unchecked
  const setOriginCountry = ()=>{
    setCountryOfOrigin([
      {
        headerName: "",
        data: Array.from(CountryStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            [selectedUser?.country_origin]?.length != 0
              ? savedDataHandler(obj, [selectedUser?.country_origin])
              : false,
          show: true,
        })),
      },
    ]);
  }

  //sets part time or full time data as checked or unchecked
  const setPartTimeFullTime=()=>{
    setWorkType([
      {
        headerName: "",
        data: Array.from(TypeOfPostionStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            selectedUser?.part_full_time?.length != 0
              ? savedDataHandler(obj, selectedUser?.part_full_time)
              : false,
          show: true,
        })),
      },
    ]);
  }

    // Function to display saved data
    const savedDataHandler = (data: any, savedData: any) => {
      let flag = false;
      savedData?.map((savedID: string) => {
        if (savedID == data.id) flag = true;
      });
      return flag;
    };

  //function that updates positions a user is looking for
  const updatePositionDataHandler = () => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, selectedUser?.preferred_job),
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setCandidatePreferredJob(PositionStoreDataUpdatedFilter);
    if(preferredJob.length===0){
      setPreferredJob(PositionStoreDataUpdatedFilter)
    }
  };
  //function that updates positions a user has worked as
  const updatePastPositionDataHandler = () => {
    let pastExperience = selectedUser?.position_type
    // let set = new Set(pastExperience); //getting unique Ids only
    // pastExperience = Array.from(set);
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          parentId: positionType?.id,
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, pastExperience),
              show: val?.show,
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setCandidateExperience(PositionStoreDataUpdatedFilter);
    if(experience?.length ===0){
      setExperience(PositionStoreDataUpdatedFilter)
    }
  };

  //function sends CV to employer's email
  const sendCV = (uid: any) => {
    if (contextData?.userData?.is_subscribed === false) {
      props.onHideModel();
      props?.showSubscriptionModal(true);
    } else {
      let data = { uid };
      sendCVToEmail(data).then((res) => {});
    }
  };

  //function handles profile deactivation 
  const handleDeactivation = () => {
    if (selectedUser?.user_account_status === true) {
      setShowDeactivate(true);
    } else {
      let data = {
        user_account_status: selectedUser?.user_account_status ? 0 : 1,
      };
      deactivateApiCall(data);
    }
  };

  //function handles deactivate profile
  const handleDeactivate = (reasons: [], description: string) => {
    let selectedreasons = "";
    reasons.map((item: any, index) => {
      if (item?.checked) {
        selectedreasons += index > 0 && index < reasons.length - 1 ? "," : "";
        selectedreasons += item?.label;
      }
    });
    let data = {
      user_account_status: selectedUser?.user_account_status === true ? 0 : 1,
      why_deactivate: selectedreasons,
      description: description,
    };
    deactivateApiCall(data);
  };
  const deactivateApiCall = (data: {}) => {
    setProfileStatus(data).then((response) => {
      // console.log(response);
      getUserDetails(userId)
        .then((response) => {
          setUserData(response?.data);
        })
        .catch((err) => console.log(err));
      setShowDeactivate(false);
    });
  };

  let LicenseStoreData = Array.from(LicenseStore.data, ([id, name]) => ({
    id,
    name,
  }));
  let positions = LicenseStoreData.map((item) => item?.name?.positions);
  positions = [].concat.apply([], positions);
  //function gets name for a selected item
  const getLabel = (id: any) => {
    // console.log(id);
    let name = "";
    positions.forEach((item) => {
      if (item?.id === id) {
        name = item?.name;
      }
      return name;
    });
    return name;
  };
  if (Object.keys(selectedUser).length === 0) {
    return <Loader />;
  }

  //function triggers Edit drawer to show or hide based on selection
  const handleEdit=(forItem?:string)=>{
    setShowUserProfile(false);
    switch(forItem){
      case 'preferredJob':{
        
        setShowPreferredJob(true)
        break;
      }
      case 'countryOrigin':{
        setShowCountryOrigin(true)
        break;
      }
      case 'experience':{
        setShowExperience(true)
        break;
      }
      case 'primaryLanguage':{
        setShowPrimaryLanguage(true);
        break;
      }
      case 'secondaryLanguage':{
        setShowSecondaryLanguage(true);
        break;
      }
      case 'license':{
        setShowLicense(true);
        break;
      }
      case 'workType':{
        setShowWorkType(true);
        break;
      }
      case 'about':{
        setShowAbout(true)
        break;
      }
      case 'socialmedia':{
        setShowSocialMediaEdit(true)
        break;
      }
      case 'euCitizen':{
        setShowEUCitizen(true)
        break;
      }
      case 'cv':{
        setShowCVEdit(true)
        break;
      }
      case 'preferredCountry':{
        setShowPreferredCountry(true)
        break;
      }
      default:break;
    }
  }
  //function to check if references are availale
  const showReferences= ()=>{
    return(
      <Box style={{ paddingLeft: "0", marginBottom:previewMode && linksPresent() ===false?'10px':'0' }}>
            {selectedUser?.provide_references === "1" ? (
              <>
                <MemoCheckLogo style={{ float: "left", marginTop: "2px" }} />
                <RefText>
                  {t("References_available")}: <br />{" "}
                  {selectedUser?.first_name ? selectedUser?.first_name : ""}{" "}
                  {t("Maria_can_provide_references_upon_request")}
                </RefText>
              </>
            ) : (
              <>
                <RefText style={{ margin: "25px 0 0 0px" }}>
                  {t("References_not_available")}
                </RefText>
              </>
            )}
          </Box>
    )
  }

  //checking if any of these links are present
  const linksPresent = () => {
    if (
      selectedUser?.social_facebook_link !== "" ||
      selectedUser?.social_linkedin_link !== "" ||
      selectedUser?.social_instagram_link !== "" ||
      selectedUser?.social_twitter_link !== ""
    )
      return true;
    else return false;
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      {type == 1 ? (
        <RightSideBarSkeleton
          icon={
            previewMode ? (
              <MemoEditIcon fontSize="14px" style={{ marginRight: "3px" }} />
            ) : (
              <MemoEyeIcon fontSize="22px" style={{ marginRight: "6px" }} />
            )
          }
          onHide={props.onHideModel}
          previewMode={previewMode}
          setShowPreview={setPreviewMode}
          type={1}
        />
      ) : (
        <RightSideBarSkeleton
          isFavourite={selectedUser?.favourite_status}
          setFavourite={props?.setFavourite}
          selectedProfile={selectedUser?.role}
          onHide={props.onHideModel}
          type={2}
        />
      )}
      <ProfilePictureContainer>
        {type == 1 && !previewMode && (
          <>
            {selectedUser?.profile_picture ? (
              <StyleProfile>
                <DropZone
                  customHeight="90px"
                  customWidth="90px"
                  custom={true}
                  acceptType={"image"}
                  savedData={[selectedUser?.profile_picture]}
                />
                {/* <StyleAvatar
              src={
                selectedUser?.profile_picture
                  ? selectedUser?.profile_picture
                  : Profile
              }
            /> */}
              </StyleProfile>
            ) : (
              <StyleProfile>
                <DropZone
                  customHeight="90px"
                  customWidth="90px"
                  custom={true}
                  acceptType={"image"}
                />
              </StyleProfile>
            )}
          </>
        )}
        {(type == 2 || previewMode) && (
          <>
            <StyleProfile>
              <StyleAvatar
                src={
                  selectedUser?.profile_picture
                    ? selectedUser?.profile_picture
                    : Profile
                }
              />
            </StyleProfile>
          </>
        )}
        <NameAgeWrapper>
          <Name>
            {contextData?.role === "employer" &&
            !contextData?.userData?.is_subscribed
              ? selectedUser?.first_name
              : selectedUser?.name}
          </Name>
          <Age>
            {selectedUser?.age} {t("years")}
          </Age>
          {type==2 && !showContactDetails?<ViewDetailsWrapper onClick={()=>setShowContactDetails(true)}><MemoOrangeEyeIcon style={{width:"18px", height:"12px", marginRight:"3px"}} />{t("view_contact_details")}</ViewDetailsWrapper>:""}
        </NameAgeWrapper>
      </ProfilePictureContainer>
      {showContactDetails  &&
        <ContactDetailsWrapper>
          
          <Grid>
          {selectedUser.phone!="+0000000000"?
            <Grid.Col span={8} style={{paddingLeft:"15px",paddingBottom:"0px"}}>
              
              <MemoOrangePhone/>
         
             
              <TextSpan>{selectedUser.phone}</TextSpan>
              
            </Grid.Col>
            :""}
            <Grid.Col span={8} style={{paddingLeft:"15px",bottom:"10px"}}>
            <MemoOrangeEmail/><TextSpan>{selectedUser.email}</TextSpan>
            </Grid.Col>
          </Grid>
          <CrossButton onClick={()=>setShowContactDetails(false)}><MemoGreyClose fontSize={"11.67px"}/></CrossButton>
        </ContactDetailsWrapper>
      }
      <div style={{ margin: "0 20px 0 20px" }}>
        <VerifiedWrapper>
          <EmailPhoneWrapper>
            <MemoEmailVerified
              style={{ marginRight: "9px" }}
              width="14px"
              height="11px"
            />{" "}
            {t("Verified")}
          </EmailPhoneWrapper>
          {selectedUser?.mobile_verification_status ? (
            <EmailPhoneWrapper>
              <MemoPhoneVerified
                width="9px"
                height="15px"
                style={{ marginRight: "6px" }}
              />
              <text>{t("Verified")}</text>
            </EmailPhoneWrapper>
          ) : (
            <EmailPhoneWrapper>
              <MemoGreyPhone
                width="9px"
                height="15px"
                style={{ marginRight: "9px" }}
              />
              <NotVerifiedText
                addMargin={type == 1 && !previewMode ? true : false}
              >
                {t("Not_Verified")}
              </NotVerifiedText>
              {type === 1 && !previewMode && (
                <VerificationWrapper onClick={props?.setShowMobileVerify}>
                  <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                  <VerifyText>{t("Verify")}</VerifyText>
                </VerificationWrapper>
              )}
            </EmailPhoneWrapper>
          )}
          {
            <>
              {selectedUser?.citizen === "106" ? (
                <EmailPhoneWrapper>
                  <MemoCitizen
                    width="12px"
                    height="13.3px"
                    style={{ marginRight: "6px" }}
                  />
                  <span style={{ margin: "0 0px 0 0px" }}>
                    {t("EU_citizen1")}
                  </span>
                  {type == 1 && !previewMode && (
                    <EditWrapper onClick={() => handleEdit("euCitizen")}>
                      <MemoOrangeEdit
                        style={{ marginLeft: "11px" }}
                        fontSize={"14px"}
                        fill={"#1395DE"}
                      />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  )}
                </EmailPhoneWrapper>
              ) : (
                <EmailPhoneWrapper>
                  <MemoNotCitizen
                    width="12px"
                    height="13.3px"
                    style={{ marginRight: "6px", marginLeft: "10px" }}
                  />
                  <span style={{ margin: "0 9px 0 0", color: "#a1a1a1" }}>
                    {t("EU_citizen1")}
                  </span>
                  {type == 1 && !previewMode && (
                    <EditWrapper onClick={() => handleEdit("euCitizen")}>
                      <MemoOrangeEdit
                        style={{ marginLeft: "11px" }}
                        fontSize={"14px"}
                        fill={"#1395DE"}
                      />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  )}
                </EmailPhoneWrapper>
              )}
            </>
          }
          {type == 1 && (
            <>
              {parseFloat(contextData?.userData?.field_profile_percentage) <
              100 ? (
                <EmailPhoneWrapper>
                  <MemoProfileIncomplete
                    width="15px"
                    height="21.74px"
                    style={{ marginRight: "9px" }}
                  />
                  <NotVerifiedText>{t("profile_completed")}</NotVerifiedText>
                </EmailPhoneWrapper>
              ) : (
                <EmailPhoneWrapper>
                  <MemoProfileCompleted
                    width="15px"
                    height="21.74px"
                    style={{ marginRight: "9px" }}
                  />
                  {t("profile_completed")}
                </EmailPhoneWrapper>
              )}
            </>
          )}
        </VerifiedWrapper>
        {type == 1 && !previewMode && (
          <ProfileActivation>
            <ProfileActivationTextWrapper>
              <ProfileActivationTitle>
                {t("Profile_Activation")}
              </ProfileActivationTitle>
              <ProfileActivationDescription>
                {selectedUser?.user_account_status
                  ? t("Set_your_profile_inactive")
                  : t("Set_your_profile_active")}
              </ProfileActivationDescription>
            </ProfileActivationTextWrapper>
            <div style={{ position: "absolute", top: "24px", right: "4%" }}>
              <Switch
                checked={selectedUser?.user_account_status}
                onChange={() => {
                  handleDeactivation();
                }}
              />
            </div>
          </ProfileActivation>
        )}
        <DeactivatingModal
          opened={showDeactivate && selectedUser?.user_account_status === true}
          onClose={() => setShowDeactivate(false)}
          handleDeactivate={handleDeactivate}
        />
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.about_yourself !== "" ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.about_yourself
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("about")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("about")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <StyledExperienceWrapper>
                    <CustomText>{selectedUser?.about_yourself}</CustomText>
                  </StyledExperienceWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.about_yourself
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("about")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("about")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {type == 1 && previewMode && (
            <>
              {selectedUser?.about_yourself !== "" && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.about_yourself
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("about")}
                    </StyledTitle>
                  </TitleWrapper>
                  <StyledExperienceWrapper>
                    <CustomText>{selectedUser?.about_yourself}</CustomText>
                  </StyledExperienceWrapper>
                </>
              )}
            </>
          )}
          {type == 2 && (
            <>
              {selectedUser?.about_yourself !== "" && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.about_yourself
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      <Trans t={t} i18nKey="about_name">
                        About {{ name: selectedUser.first_name }}
                      </Trans>
                    </StyledTitle>
                  </TitleWrapper>
                  <StyledExperienceWrapper>
                    <CustomText>{selectedUser?.about_yourself}</CustomText>
                  </StyledExperienceWrapper>
                </>
              )}
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.preferred_job?.length !== 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.preferred_job
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("your_search")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("preferredJob")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {preferredJobFiltered?.map((industry: any) => {
                      return industry?.data?.map((position: any) => {
                        if (position?.checked) {
                          return <DataCard label={position?.name} />;
                        }
                      });
                    })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.preferred_job
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("your_search")}
                  </StyledTitle>
                  <EditWrapper
                    onClick={() => {
                      handleEdit("preferredJob");
                    }}
                  >
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {type == 1 && previewMode && (
            <>
              {selectedUser?.preferred_job?.length !== 0 && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.preferred_job
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("your_search")}
                    </StyledTitle>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {preferredJobFiltered?.map((industry: any) => {
                      return industry?.data?.map((position: any) => {
                        if (position?.checked) {
                          return <DataCard label={position?.name} />;
                        }
                      });
                    })}
                  </DataCardWrapper>
                </>
              )}
            </>
          )}
          {type == 2 && (
            <>
              {selectedUser?.preferred_job?.length !== 0 && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.preferred_job
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      <Trans t={t} i18nKey="your_search_name">
                        What {{ name: selectedUser.first_name }} is searching
                        for
                      </Trans>
                    </StyledTitle>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {preferredJobFiltered?.map((industry: any) => {
                      return industry?.data?.map((position: any) => {
                        if (position?.checked) {
                          return <DataCard label={position?.name} />;
                        }
                      });
                    })}
                  </DataCardWrapper>
                </>
              )}
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.primary_language?.length !== 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.primary_language
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("First_language")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("primaryLanguage")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.primary_language?.map((item: any) => {
                      return <DataCard label={LanguageStore.get(item)} />;
                    })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.primary_language
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("First_language")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("primaryLanguage")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) && (
            <>
              {selectedUser?.primary_language?.length !== 0 && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.primary_language
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("First_language")}
                    </StyledTitle>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.primary_language?.map((item: any) => {
                      return <DataCard label={LanguageStore.get(item)} />;
                    })}
                  </DataCardWrapper>
                </>
              )}
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.position_type?.length !== 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.position_type
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("Experience")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("experience")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {experience?.map((industry: any) => {
                      return industry?.data?.map((position: any) => {
                        if (position?.checked) {
                          return <DataCard label={position?.name} />;
                        }
                      });
                    })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.position_type
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("Experience")}
                  </StyledTitle>
                  <EditWrapper>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) && (
            <>
              {selectedUser?.position_type?.length !== 0 && (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.position_type
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("Experience")}
                    </StyledTitle>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {/* {selectedUser?.position_type?.map((item: any) => {
                  return <DataCard label={item} />;
                })} */}
                    {candidateExperience?.map((industry: any) => {
                      return industry?.data?.map((position: any) => {
                        if (position?.checked) {
                          return <DataCard label={position?.name} />;
                        }
                      });
                    })}
                  </DataCardWrapper>
                </>
              )}
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.country_origin !== "" ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.country_origin
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("country_of_origin")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("countryOrigin")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    <DataCard
                      label={CountryStore.get(selectedUser?.country_origin)}
                    />
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.country_origin
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("country_of_origin")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("countryOrigin")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {type == 2 && previewMode && selectedUser?.country_origin !== "" && (
            <>
              <TitleWrapper>
                <StyledTitle
                  style={{
                    color: selectedUser?.country_origin
                      ? fontBlack
                      : greyHeader,
                  }}
                >
                  {t("country_of_origin")}
                </StyledTitle>
              </TitleWrapper>
              <DataCardWrapper>
                <DataCard
                  label={CountryStore.get(selectedUser?.country_origin)}
                />
              </DataCardWrapper>
            </>
          )}
          {(type == 2 || previewMode) &&
            selectedUser?.country_origin !== "" && (
              <>
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.country_origin
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("country_of_origin")}
                  </StyledTitle>
                </TitleWrapper>
                <DataCardWrapper>
                  <DataCard
                    label={CountryStore.get(selectedUser?.country_origin)}
                  />
                </DataCardWrapper>
              </>
            )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.preferred_country?.length != 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: fontBlack,
                        width: "245px",
                      }}
                    >
                      {t("which_country_you_will_like_to_work")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("preferredCountry")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.preferred_country?.length != 0 &&
                      selectedUser?.preferred_country?.map((item: any) => {
                        return <DataCard label={CountryStore.get(item)} />;
                      })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: greyHeader,
                      width: "245px",
                    }}
                  >
                    {t("which_country_you_will_like_to_work")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("preferredCountry")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {type == 1 &&
            previewMode &&
            selectedUser?.preferred_country?.length != 0 && (
              <>
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: fontBlack,
                      width: "245px",
                    }}
                  >
                    {t("which_country_you_will_like_to_work")}
                  </StyledTitle>
                </TitleWrapper>
                <DataCardWrapper>
                  {selectedUser?.preferred_country?.length != 0 &&
                    selectedUser?.preferred_country?.map((item: any) => {
                      return <DataCard label={CountryStore.get(item)} />;
                    })}
                </DataCardWrapper>
              </>
            )}
          {type == 2 && selectedUser?.preferred_country?.length != 0 && (
            <>
              <TitleWrapper>
                <StyledTitle
                  style={{
                    color: fontBlack,
                    width: "245px",
                  }}
                >
                  <Trans
                    t={t}
                    i18nKey="which_country_you_will_like_to_work_name"
                  >
                    Country {{ name: selectedUser.first_name }} wants to work in
                  </Trans>
                </StyledTitle>
              </TitleWrapper>
              <DataCardWrapper>
                {selectedUser?.preferred_country?.length != 0 &&
                  selectedUser?.preferred_country?.map((item: any) => {
                    return <DataCard label={CountryStore.get(item)} />;
                  })}
              </DataCardWrapper>
            </>
          )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.part_full_time?.length != 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: selectedUser?.part_full_time
                          ? fontBlack
                          : greyHeader,
                      }}
                    >
                      {t("Type_of_position")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("workType")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.part_full_time?.map((item: any) => {
                      return <DataCard label={TypeOfPostionStore.get(item)} />;
                    })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.part_full_time
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("Type_of_position")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("workType")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) &&
            selectedUser?.part_full_time?.length != 0 && (
              <>
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: selectedUser?.part_full_time
                        ? fontBlack
                        : greyHeader,
                    }}
                  >
                    {t("Type_of_position")}
                  </StyledTitle>
                </TitleWrapper>
                <DataCardWrapper>
                  {selectedUser?.part_full_time?.map((item: any) => {
                    return <DataCard label={TypeOfPostionStore.get(item)} />;
                  })}
                </DataCardWrapper>
              </>
            )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.secondary_language?.length != 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color:
                          selectedUser?.secondary_language?.length != 0
                            ? fontBlack
                            : greyHeader,
                      }}
                    >
                      {t("Secondary_languages")}
                    </StyledTitle>
                    <EditWrapper
                      onClick={() => handleEdit("secondaryLanguage")}
                    >
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.secondary_language?.length != 0 &&
                      selectedUser?.secondary_language?.map((item: any) => {
                        return (
                          <div style={{ display: "inline-block" }}>
                            <DataCard label={LanguageStore.get(item)} />
                          </div>
                        );
                      })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color:
                        selectedUser?.secondary_language?.length != 0
                          ? fontBlack
                          : greyHeader,
                    }}
                  >
                    {t("Secondary_languages")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("secondaryLanguage")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) &&
            selectedUser?.secondary_language?.length != 0 && (
              <>
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color:
                        selectedUser?.secondary_language?.length != 0
                          ? fontBlack
                          : greyHeader,
                    }}
                  >
                    {t("Secondary_languages")}
                  </StyledTitle>
                </TitleWrapper>
                <DataCardWrapper>
                  {selectedUser?.secondary_language?.length != 0 &&
                    selectedUser?.secondary_language?.map((item: any) => {
                      return (
                        <div style={{ display: "inline-block" }}>
                          <DataCard label={LanguageStore.get(item)} />
                        </div>
                      );
                    })}
                </DataCardWrapper>
              </>
            )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.license_type?.length != 0 ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color:
                          selectedUser?.license_type?.length != 0
                            ? fontBlack
                            : greyHeader,
                      }}
                    >
                      {t("Driving_License")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("license")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <DataCardWrapper>
                    {selectedUser?.license_type?.length != 0 &&
                      selectedUser?.license_type?.map((item: any) => {
                        return <DataCard label={getLabel(item)} />;
                      })}
                  </DataCardWrapper>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color:
                        selectedUser?.license_type?.length != 0
                          ? fontBlack
                          : greyHeader,
                    }}
                  >
                    {t("Driving_License")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("license")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) &&
            selectedUser?.license_type?.length != 0 && (
              <>
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color:
                        selectedUser?.license_type?.length != 0
                          ? fontBlack
                          : greyHeader,
                    }}
                  >
                    {t("Driving_License")}
                  </StyledTitle>
                </TitleWrapper>
                <DataCardWrapper>
                  {selectedUser?.license_type?.length != 0 &&
                    selectedUser?.license_type?.map((item: any) => {
                      return <DataCard label={getLabel(item)} />;
                    })}
                </DataCardWrapper>
              </>
            )}
        </ItemWrapper>
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {selectedUser?.upload_cv != "" ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: fontBlack,
                      }}
                    >
                      {t("CV")}
                    </StyledTitle>

                    <EditWrapper onClick={() => handleEdit("cv")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <>
                    <StylePDFIcon onClick={() => downloadResume()}>
                      <img
                        width="56.64"
                        height="57.27"
                        src={PDFIcon}
                        alt="pdf"
                      />
                    </StylePDFIcon>
                  </>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: greyHeader,
                    }}
                  >
                    {t("CV")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("cv")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {type == 1 && previewMode && (
            <>
              {selectedUser?.upload_cv != "" ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: fontBlack,
                      }}
                    >
                      {t("CV")}
                    </StyledTitle>
                  </TitleWrapper>
                  <>
                    <StylePDFIcon onClick={() => downloadResume()}>
                      <img
                        width="56.64"
                        height="57.27"
                        src={PDFIcon}
                        alt="pdf"
                      />
                    </StylePDFIcon>
                  </>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {type == 2 && selectedUser?.upload_cv != "" && (
            <>
              <TitleWrapper>
                <StyledTitle
                  style={{
                    color: fontBlack,
                  }}
                >
                  {t("CV")}
                </StyledTitle>
              </TitleWrapper>
              <>
                <CVButton
                  style={{
                    backgroundColor:
                      sendCVbyEmail && contextData?.userData?.is_subscribed
                        ? "#399E5C"
                        : "",
                    margin: "0 0 11px 0",
                    pointerEvents: contextData?.userData?.is_subscribed
                      ? "auto"
                      : "none",
                  }}
                  onClick={() => {
                    sendCV(selectedUser?.user_id);
                    setSendCVbyEmail(true);
                  }}
                >
                  {sendCVbyEmail && (
                    <MemoCheckLogoWhite style={{ marginRight: "5px" }} />
                  )}
                  <CVButtonText
                    style={{
                      color: sendCVbyEmail ? "#FFFFFF" : "",
                    }}
                  >
                    {sendCVbyEmail
                      ? t("CV_is_Forwarded_to_Email")
                      : t("Send_CV_on_Email")}
                  </CVButtonText>
                  {!sendCVbyEmail && (
                    <div style={{ marginLeft: "6px" }}>
                      <img
                        src={SendCVLogo}
                        width="19px"
                        height="19px"
                        alt="send cv"
                      />
                    </div>
                  )}
                </CVButton>

                <CVButton
                  style={{
                    backgroundColor:
                      downloadCV && contextData?.userData?.is_subscribed
                        ? "#399E5C"
                        : "",
                    margin: "0 0 11px 0",
                    pointerEvents: contextData?.userData?.is_subscribed
                      ? "auto"
                      : "none",
                  }}
                  onClick={() => {
                    downloadResume();
                    setDownloadCV(true);
                  }}
                >
                  {downloadCV ? (
                    <MemoCheckLogoWhite style={{ marginRight: "5px" }} />
                  ) : (
                    <div style={{ marginRight: "8px" }}>
                      <MemoDownload />
                    </div>
                  )}
                  <CVButtonText
                    style={{
                      color: downloadCV ? "#FFFFFF" : "",
                    }}
                  >
                    {downloadCV ? t("CV_is_Downloaded") : t("Download_CV")}
                  </CVButtonText>
                </CVButton>
              </>
            </>
          )}
        </ItemWrapper>
        {type == 1 && !previewMode && (
          <Box style={{ paddingLeft: "0" }}>
            {selectedUser?.provide_references === "1" ? (
              <>
                <MemoCheckLogo style={{ float: "left", marginTop: "2px" }} />
                <RefText
                  style={{
                    marginBottom:
                      selectedUser?.social_facebook_link === "" &&
                      selectedUser?.social_linkedin_link === "" &&
                      selectedUser?.social_instagram_link === "" &&
                      selectedUser?.social_twitter_link === ""
                        ? "32px"
                        : "0",
                  }}
                >
                  {t("References_available")}: <br />{" "}
                  {selectedUser?.first_name ? selectedUser?.first_name : ""}{" "}
                  {t("Maria_can_provide_references_upon_request")}
                </RefText>
              </>
            ) : (
              <>
                <RefText
                  style={{
                    marginTop: "18px",
                    marginLeft: "0",
                    marginBottom:
                      selectedUser?.social_facebook_link === "" &&
                      selectedUser?.social_linkedin_link === "" &&
                      selectedUser?.social_instagram_link === "" &&
                      selectedUser?.social_twitter_link === ""
                        ? "32px"
                        : "0",
                  }}
                >
                  {t("References_not_available")}
                </RefText>
              </>
            )}
          </Box>
        )}
        {previewMode && showReferences()}
        <ItemWrapper>
          {type == 1 && !previewMode && (
            <>
              {linksPresent() === true ? (
                <>
                  <TitleWrapper>
                    <StyledTitle
                      style={{
                        color: linksPresent() === true ? fontBlack : greyHeader,
                      }}
                    >
                      {t("My_social_media")}
                    </StyledTitle>
                    <EditWrapper onClick={() => handleEdit("socialmedia")}>
                      <MemoOrangeEdit fontSize={"14px"} fill={"#1395DE"} />
                      <VerifyText>{t("Edit")}</VerifyText>
                    </EditWrapper>
                  </TitleWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      margin:
                        contextData?.role === "candidate"
                          ? "0 0 10px 0px"
                          : "0 0 0px 0px",
                    }}
                  >
                    {selectedUser?.social_facebook_link !== "" && (
                      <a
                        href={getValidUrl(selectedUser?.social_facebook_link)}
                        target="_blank"
                        style={{
                          paddingRight: "9px",
                          //pointerEvents: `${selectedUser?.social_facebook_link !== "" ? "auto" : "none"}`,
                        }}
                      >
                        <MemoFb fontSize="40px" />
                      </a>
                    )}
                    {selectedUser?.social_linkedin_link !== "" && (
                      <a
                        href={getValidUrl(selectedUser?.social_linkedin_link)}
                        target="_blank"
                        style={{
                          paddingRight: "9px",
                          //pointerEvents: `${selectedUser?.social_linkedin_link !== "" ? "auto" : "none"}`,
                        }}
                      >
                        <MemoLinkedin fontSize="40px" />
                      </a>
                    )}
                    {selectedUser?.social_instagram_link !== "" && (
                      <a
                        href={getValidUrl(selectedUser?.social_instagram_link)}
                        target="_blank"
                        style={{
                          paddingRight: "9px",
                          //pointerEvents: `${selectedUser?.social_instagram_link !== "" ? "auto" : "none"}`,
                        }}
                      >
                        <MemoInsta fontSize="40px" />
                      </a>
                    )}
                    {selectedUser?.social_twitter_link !== "" && (
                      <a
                        href={getValidUrl(selectedUser?.social_twitter_link)}
                        target="_blank"
                        style={{
                          paddingRight: "9px",
                          //pointerEvents: `${selectedUser?.social_twitter_link !== "" ? "auto" : "none"}`,
                        }}
                      >
                        <MemoTwitter fontSize="40px" />
                      </a>
                    )}
                  </Box>
                </>
              ) : (
                <TitleWrapper>
                  <StyledTitle
                    style={{
                      color: linksPresent() === true ? fontBlack : greyHeader,
                    }}
                  >
                    {t("My_social_media")}
                  </StyledTitle>
                  <EditWrapper onClick={() => handleEdit("socialmedia")}>
                    <MemoBlueAdd fontSize={"8px"} />
                    <VerifyText>{t("Add")}</VerifyText>
                  </EditWrapper>
                </TitleWrapper>
              )}
            </>
          )}
          {(type == 2 || previewMode) && linksPresent() === true && (
            <>
              <TitleWrapper>
                <StyledTitle
                  style={{
                    color: linksPresent() === true ? fontBlack : greyHeader,
                  }}
                >
                  {t("Check")} {selectedUser?.first_name} {t("Name_SM")}
                </StyledTitle>
              </TitleWrapper>
              <Box
                sx={{
                  display: "flex",
                  margin:
                    contextData?.role === "candidate"
                      ? "0 0 10px 0px"
                      : "0 0 0px 0px",
                }}
              >
                {selectedUser?.social_facebook_link !== "" && (
                  <a
                    href={getValidUrl(selectedUser?.social_facebook_link)}
                    target="_blank"
                    style={{
                      paddingRight: "9px",
                      //pointerEvents: `${selectedUser?.social_facebook_link !== "" ? "auto" : "none"}`,
                    }}
                  >
                    <MemoFb fontSize="40px" />
                  </a>
                )}
                {selectedUser?.social_linkedin_link !== "" && (
                  <a
                    href={getValidUrl(selectedUser?.social_linkedin_link)}
                    target="_blank"
                    style={{
                      paddingRight: "9px",
                      //pointerEvents: `${selectedUser?.social_linkedin_link !== "" ? "auto" : "none"}`,
                    }}
                  >
                    <MemoLinkedin fontSize="40px" />
                  </a>
                )}
                {selectedUser?.social_instagram_link !== "" && (
                  <a
                    href={getValidUrl(selectedUser?.social_instagram_link)}
                    target="_blank"
                    style={{
                      paddingRight: "9px",
                      //pointerEvents: `${selectedUser?.social_instagram_link !== "" ? "auto" : "none"}`,
                    }}
                  >
                    <MemoInsta fontSize="40px" />
                  </a>
                )}
                {selectedUser?.social_twitter_link !== "" && (
                  <a
                    href={getValidUrl(selectedUser?.social_twitter_link)}
                    target="_blank"
                    style={{
                      paddingRight: "9px",
                      //pointerEvents: `${selectedUser?.social_twitter_link !== "" ? "auto" : "none"}`,
                    }}
                  >
                    <MemoTwitter fontSize="40px" />
                  </a>
                )}
              </Box>
            </>
          )}
        </ItemWrapper>
        {type == 2 && showReferences()}
        {type == 2 && (
          <div />
        )}
      </div>
    </Box>
  );
};

export default UserProfileDisplay;
