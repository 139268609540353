import getBrowserLanguage from "helpers/get-browser-language";

export const IndustryStore = {
  data: new Map(),
  set(key:any, value:string){
     return this.data.set(key, value);
  },
 
  get(key:any){
      return this.data.get(key);
  },
  getAll(checkedItems:any){
    let updatedData= Array.from(IndustryStore.data, ([id, name]) => ({
      id,
      name,
    })).sort((a: any, b: any) =>
    a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
      ignorePunctuation: true,
    })
  ).map((obj) => ({
      ...obj,
      checked:
        checkedItems?.length != 0
          ? savedDataHandler(obj, checkedItems)
          : false,
      show: true,
    }))
    return[{
      headerName:'',
      data:updatedData
    }]
  }
}

const savedDataHandler = (data: any, savedData: any) => {
  let flag = false;
  if(savedData.includes(data.id)) flag= true
  return flag;
};