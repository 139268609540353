import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useListState, randomId } from '@mantine/hooks'
import { Checkbox,Accordion,createStyles } from '@mantine/core'
import { Title, Box } from '@mantine/core'
import styled from 'styled-components'
import WhiteCheckBox from 'ui/icons/whiteCheckbox.svg'
import OrangeCheckBox from 'ui/icons/orangeCheckbox.svg'
import { useTranslation } from 'react-i18next'
import Button from 'ui/buttons/Button'
import MemoChevronDown from 'ui/icons/ChevronDown'
import MemoBlackChevronDown from 'ui/icons/BlackChevronDown'
import isNone from 'helpers/isNone'
import { UserContext } from 'context/state'

const initialValues = [{ name: 'No Data', checked: false, id: randomId() }]

const Header = styled(Title)`
  margin: 10px 0 0 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #123b3d;
`

const ElementsStyling = styled(Box)`
  margin-bottom: 14px;
`
const useStyles = createStyles((theme) => ({
  item: {
    background: "#transparent !important",
    borderRadius: "0",
    //margin: "7px 20px 7px 20px",
    position: "relative",
    minHeight: "27px",
    maxWidth:"336px",
    borderBottom:"1px solid #D5D5D5 !important",
    "& :hover": {
      borderRadius: "0 !important",
      backgroundColor: "transparent !important",
    },
  },
  icon: {
    display: "none  !important",
    transform: "none !important",
  },
}));

const StyleIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  @media only screen and (min-width: 1000px) {
  }
`;


const StyleHeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #123b3d;
  margin: 0 30px 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;

const AddButtonWrapper = styled.div`
  margin-top: 0px;
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  z-index: 2000;
  text-align: center;
`;
interface CheckboxProps {
  checkAll?: boolean
  label?: string
  value?: string[]
  data?: any
  onChange?: (event: any) => void
  sx?: any
  header?: string
  getData?: any
  index?: any
  singleSelect?: boolean
  resetData?: any
  setRestData?: any
  searchString?: any
  setSearchString?: any
  addElements?: boolean
  setAddElements?: any
  multiObject?: boolean
  languageCheck?: boolean
  selectedIds?: any
  showAddButton?: boolean
  setShow?: any
  addElementHandler?: any,
  showAccordion?:boolean,
  setAddClickedFor?:Dispatch<SetStateAction<void>>,
  dontHideDrawer?:boolean
  primaryLanguage?:boolean
  noneClicked?:boolean
  setNoneClicked?:any,
  noneIndex?:number|null,
  unCheckNone?:boolean,
  setUncheckNone?:Dispatch<SetStateAction<boolean>>,
  secondaryLanguage?:boolean,
  submitButtonText?:string
}

const CheckboxGroup: React.FC<CheckboxProps> = ({
  checkAll = false,
  singleSelect = false,
  label,
  data,
  index,
  value,
  header='',
  getData,
  searchString,
  setSearchString,
  multiObject = false,
  languageCheck = false,
  selectedIds = [],
  showAddButton = false,
  setShow,
  addElementHandler,
  showAccordion=false,
  setAddClickedFor,
  dontHideDrawer,
  noneIndex=null,
  secondaryLanguage=false,
  submitButtonText='',
  ...props
}) => {
  // const itemToBeRemoved = {name:'None'}
  // const [noneChecked, setNoneChecked] = useState(false);
  // if(props.primaryLanguage==true){
  //   data[0]?.data.splice(data[0].data.findIndex((e:any) => e.name === itemToBeRemoved.name) , 1)
  // }
  
  const [values, handlers] = useListState(
    data != undefined ? (data[index]?.data?.length ? data[index]?.data : initialValues) : []
  )
  const {isAdmin} = useContext(UserContext)
  const { classes } = useStyles();

  const { t } = useTranslation()
  const returnIds = (data: any, obj: any) => {
    let flag = false
    data?.map((id: any) => {
      if (id == obj.id) flag = true
    })
    return flag
  }

  const allChecked = languageCheck
    ? values
        .filter((obj: any) => {
          return !returnIds(selectedIds, obj)
        })
        .filter((obj:any)=> !isNone(obj.name))
        .every((val: any) => val?.checked)
    : values.every((val: any) => val?.checked)

   
    
  const indeterminate = values.some((val: any) => val.checked) && !allChecked
  // Multi-Object State
  const [multiObjectData, setMultiObjectData] = useState(data ? data : [])

  // Function to display selected data (Used for Language)
  const selectedDataHandler = (data: any, savedData: any) => {
    let flag = false
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true
    })
    return flag
  }

  // Display objects based on Search
  const searchCheckHandler = (values: any) => {
    let checkSearch = false
    values?.map((showData: any) => {
      if (showData.show) {
        checkSearch = true
      }
    })
    return checkSearch
  }
  // To Reset Checked element
  useEffect(() => {
    if (props.resetData){
      handlers.setState((current) => 
      current.map((val: any) => ({
          ...val,
          checked:false,
        }))
      )
        props?.setRestData(false)
    }
  }, [props.resetData])

  //sets none to true and rest of the items to false
  useEffect(() => {
    if (props.noneClicked){
      handlers.setState((current) => 
      current.map((val: any) => ({
          ...val,
          checked: !isNone(val.name)?false:true,
        }))
      )
    }
  }, [props.noneClicked])

  useEffect(() => {
    if (props.unCheckNone){
      handlers.setState((current) => 
      current.map((val: any) => ({
          ...val,
          checked:!isNone(val.name)?val.checked:false,
        }))
      )
    }
  }, [props.unCheckNone])

  // Display Object => Array based on Search
  useEffect(() => {
    // console.log(`Search`, searchString);
    if (!searchString)
      handlers.setState((current) =>
        current.map((val: any) => ({
          ...val,
          show: true,
        }))
      )
    else {
      handlers.setState((current) =>
        current.map((item: any) => {
          if (!item?.name?.toLowerCase().includes(searchString?.toLowerCase())) {
            // console.log(item?.name?.toLowerCase().includes(searchString?.toLowerCase()));
            return { ...item, show: false }
          }
          return { ...item, show: true }
        })
      )
    }
  }, [searchString])

  interface itemType {
    id: string;
    name: string;
    checked: boolean;
    show?: boolean;
  }

  const SingleSelectHandler = (selectedItem: any) => {
    handlers.setState((current) =>
      current.map((val: any) => ({
        ...val,
        checked: val.id == selectedItem.id ? true : false,
      }))
    )
  }
  const NoneSelectHandler = (selectedItem: any) => {
    handlers.setState((current) =>
      current.map((val: any) => ({
        ...val,
        checked: val.id !== selectedItem.id ? false:{} ,
      }))
    ) 
  }
  const NoneOtherHandler = () => {
    handlers.setState((current) =>
    current.map((val: any) => (
      {
       
        ...val,
        checked: isNone(val.name) ? false : val.checked,
        
      }))
    )
  }

  useEffect(() => {
    if (props?.addElements) {
      // let updatedData: any = data?.map((dataGroup: any, indexdataGrounp: any) => {
      //   if (indexdataGrounp == index) {
      //     let updatedData = {
      //       headerName: dataGroup.headerName,
      //       data: values,
      //     }
      //     return updatedData
      //   } else {
      //     return dataGroup
      //   }
      // })
      // getData(updatedData)
      props.setRestData(false)
      props?.setAddElements(false)
    }
  }, [props?.addElements])

  

  useEffect(() => {
    if (multiObject) {
      let updatedData: any = data?.map((dataGroup: any, indexdataGrounp: any) => {
        if (indexdataGrounp == index) {
          let updatedData = {
            headerName: dataGroup.headerName,
            data: values,
            ...(dataGroup?.parentId && {parentId: dataGroup?.parentId})
          }

          return updatedData
        } else {
          return dataGroup
        }
      })
      let none = false;
      updatedData?.forEach((item: any) => {
        if (item.data?.find((obj: any) => isNone(obj.name) && obj.checked)) {
          none = true;
          if(props.setUncheckNone)props.setUncheckNone(false)
        }
      });
      if(props.setNoneClicked)props.setNoneClicked(none)
      let itemSelected = false;
    updatedData?.forEach((item: any) => {
      if (item.data?.find((obj: any) => !isNone(obj.name) && obj.checked)) {
        itemSelected = true;
      }
    });
    if(itemSelected && props.setUncheckNone){
      props.setUncheckNone(true)
    }
      getData(updatedData)
      props.setRestData(false)
      props?.setAddElements(false)
    }
    //console.log("Values",values)
  }, [values])

  const AllCheckWithoutAccordion =()=>(
    <>
    <ElementsStyling>
        {!isNone(header)?(
     <Checkbox
     size='lg'
     styles={{
       root: { paddingBottom: '0px' },
       input: {
         backgroundRepeat: 'no-repeat',
         background: `url(${WhiteCheckBox})`,
         //background: `url(${GreyCheckbox})`,
         backgroundSize: '30px 30px',
         border: 'none !important',
         cursor: 'pointer',
         borderRadius: '4px',
         '&:checked': {
           backgroundRepeat: 'no-repeat',
           background: `url(${OrangeCheckBox})`,
           // background: `url(${OrangeCheckbox})`,
           backgroundSize: '30px 30px',
           border: 'none !important',
         },
       },
       icon: {
         display: 'none',
       },
       label: {
         fontFamily: 'Poppins',
         fontStyle: 'normal',
         fontSize: '14px',
         color: '#123B3D',
       },
     }}
     checked={allChecked}
     label={t('All')}
     transitionDuration={0}
     onChange={() => {
      if (allChecked && languageCheck) {
        handlers.setState((current) =>
          current.map((val: any) => ({
            ...val,
            checked: !allChecked,
          }))
        )
      } 
      else if(!allChecked && languageCheck){
        handlers.setState((current) =>
          current.map((val: any) => ({
            ...val,
            checked: isNone(val.name)? false:selectedIds?.length
            ? !selectedDataHandler(val, selectedIds): !allChecked,
          }))
        )
      }
      else
        handlers.setState((current) =>
          current.map((val: any) => ({
            ...val,
            checked: selectedIds?.length
              ? !selectedDataHandler(val, selectedIds)
              : !allChecked,
          }))
        )
    }}
   />
    ):""}
      </ElementsStyling>
    </>
  )

  const items = values.map((value: any, index) => (
    <>
      {value?.show && !selectedDataHandler(value, selectedIds) && (
        <ElementsStyling>
          <Checkbox
            size='lg'
            styles={{
              root: { paddingBottom: '0px' },
              input: {
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                background: `url(${WhiteCheckBox})`,
                //background: `url(${GreyCheckbox})`,
                backgroundSize: '30px 30px',
                border: 'none !important',
                borderRadius: '4px',
                '&:checked': {
                  backgroundRepeat: 'no-repeat',
                  background: `url(${OrangeCheckBox})`,
                  //background: `url(${OrangeCheckbox})`,
                  backgroundSize: '30px 30px',
                  border: 'none !important',
                },
              },
              icon: {
                display: 'none',
              },
              label: {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontSize: '14px',
                color: '#123B3D',
              },
            }}
            label={value.name}
            key={value.id}
            checked={value.checked}
            onChange={(event: any) => {
              if (singleSelect) {
                SingleSelectHandler(value)
              }
              else{
                if(isNone(value.name)){
                    handlers.setState((current) =>
                    current.map((val: any) => ({
                      ...val,
                      checked: val.id == value.id ? !val.checked:false ,
                    }))
                  )
                  
                }
                else{
                  handlers.setState((current) =>
                    current.map((val: any) => ({
                      ...val,
                      checked: isNone(val.name) ? false:val.checked ,
                    }))
                  )
                }
                handlers.setItem(index, {
                  ...value,
                  checked: event.currentTarget.checked,
                })
                //NoneOtherHandler()
              }
              
            }}
          />
        </ElementsStyling>
      )}
      {isNone(value.name) && languageCheck && noneIndex===0 &&
        <AllCheckWithoutAccordion/>
      }
    </>
  ))
  // console.log(`selectedIds`, selectedIds, values);
  const checkBoxData = (
    <div>
      {header && !isNone(header)?(
        <ElementsStyling>
          <Header>{header}</Header>
        </ElementsStyling>
      ):""}
      {checkAll && !searchString && selectedIds.length != values.length && (
        <>
        { !secondaryLanguage && <AllCheckWithoutAccordion />}
        </>
      )}
      {items}
      {isNone(header) && <hr />}
    </div>
  )

  const CheckboxDataWithAccordion =(
    <div>
      <Accordion
            transitionDuration={500}
            iconPosition='right'
            multiple={true}
            styles={{
              control:{
                paddingLeft:'0px !important'
              },
              contentInner:{
                paddingLeft:'0px !important'
              }
            }}
            classNames={{
              item: classes.item,
              icon: classes.icon,
            }}>
              <Accordion.Item
                      label={
                        <>
                          <StyleHeadText>{header}</StyleHeadText>
                          <StyleIcon>
                            <MemoBlackChevronDown />
                          </StyleIcon>
                        </>
                      }>
      {/* {header && (
        <ElementsStyling>
          <Header>{header}</Header>
        </ElementsStyling>
      )} */}
      {checkAll && !searchString && selectedIds.length != values.length && (
        <ElementsStyling>
          {!isNone(header)?
          <Checkbox
            size='lg'
            styles={{
              root: { paddingBottom: '0px' },
              input: {
                backgroundRepeat: 'no-repeat',
                background: `url(${WhiteCheckBox})`,
                //background: `url(${GreyCheckbox})`,
                backgroundSize: '30px 30px',
                border: 'none !important',
                cursor: 'pointer',
                borderRadius: '4px',
                '&:checked': {
                  backgroundRepeat: 'no-repeat',
                  background: `url(${OrangeCheckBox})`,
                  // background: `url(${OrangeCheckbox})`,
                  backgroundSize: '30px 30px',
                  border: 'none !important',
                },
              },
              icon: {
                display: 'none',
              },
              label: {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontSize: '14px',
                color: '#123B3D',
              },
            }}
            checked={allChecked}
            label={t('All')}
            transitionDuration={0}
            onChange={() => {
              if (allChecked && languageCheck) {
                handlers.setState((current) =>
                  current.map((val: any) => ({
                    ...val,
                    checked: !allChecked,
                  }))
                )
              } else
                handlers.setState((current) =>
                  current.map((val: any) => ({
                    ...val,
                    checked: selectedIds?.length
                      ? !selectedDataHandler(val, selectedIds)
                      : !allChecked,
                  }))
                )
            }}
          />
        :""}
        </ElementsStyling>
      )}
      {items}
      </Accordion.Item>
      </Accordion>
    </div>
  )
  // function returns text to be displayed by button
  const getButtonText = ()=>{
    let buttonText=''
    switch(true){
      case submitButtonText!='': buttonText=submitButtonText
      break;
      case dontHideDrawer: buttonText =t("Save")
      break;
      default: buttonText=t(`Add`)
    }
    return buttonText
  }
  return (
    <>
      {searchCheckHandler(values) && <>{showAccordion? CheckboxDataWithAccordion:checkBoxData }</>}
      {showAddButton && (
        <AddButtonWrapper>
        <Button
          variant='filled'
          children={getButtonText()}
          width='335px'
          height='54px'
          onClick={() => {
            props?.setAddElements(true)
            let updatedData: any = data?.map((dataGroup: any, indexdataGrounp: any) => {
              if (indexdataGrounp == index) {
                let updatedData = {
                  headerName: dataGroup.headerName,
                  data: values,
                }
                return updatedData
              } else {
                return dataGroup
              }
            })
            getData(updatedData)
            if (multiObject) addElementHandler()
            // props.setRestData(false)
            // props?.setAddElements(false)
            if(dontHideDrawer !== true){
              setShow(false)
            }
            if(setAddClickedFor!==undefined) setAddClickedFor()
          }}
        />
        </AddButtonWrapper>
      )}
    </>
  )
}

export default CheckboxGroup
