import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "translations/en/translations";
import { TRANSLATIONS_NO } from "translations/no/translations";
import { TRANSLATIONS_PL } from "translations/pl/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      nb: {
        translation: TRANSLATIONS_NO,
      },
      pl: {
        translation: TRANSLATIONS_PL,
      },
    },
    fallbackLng: "en",
    lng: `${navigator?.language == "no" ? "nb" : navigator?.language}`,
  });

// i18n.changeLanguage('pl');
