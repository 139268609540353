import axiosInstance from "services/AxiosInstance"

export const getAllSubscriptionListing = () => {
    return axiosInstance.get('subscription/plan')
        .then((response) => response.data)
        .catch((error) => error)
}

export const subscriptionOrder = (data: any) => {
    return axiosInstance.post('subscription/order', data)
        .then((response) => response.data)
        .catch((error) => error)
}

export const activate_DeactivateSubscription = (data: number) => {
    const passData = {
        subscription_status: data,
    }
    return axiosInstance.put(`deactivate-subscription`, passData)
        .then((response) => response.data)
        .catch((error) => error)
}