export const PositionStore = {
  data: new Map(),
  set(key:any, value:string|{}){
     return this.data.set(key, value);
  },
 
  get(key:any){
      return this.data.get(key);
  },
  getAll(){
    let PositionStoreData:object[] = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          parentId: positionType?.id,
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: false,
              show: val?.show,
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter:object[] = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    return(Array.from(PositionStoreDataUpdatedFilter))
  }
}