import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "screens/LoginScreenScreen";

import ForgotPassword from "screens/forgot-password/ForgotPasswordScreen";

import CreateAccountScreen from "screens/account-creation/CreateAccountScreen"
import Message from "screens/MessageScreen";
import Settings from "screens/settings-screen/Settings";
import EditProfile from "screens/EditProfileScreen";
import FAQScreen from "screens/FAQScreen";
import BillingScreen from "screens/billing-invoice/BillingScreen";
import ChatScreen from "screens/ChatScreen";
import ResetPassword from "screens/ResetPasswordScreen";
import PaymentScreen from "screens/PaymentScreen";
import ProfileScreen from "screens/ProfileScreen";
import FavouritesScreen from "screens/FavouritesScreen";
import ContactUs from "screens/ContactUsScreen";
import UnsubscribeMail from "screens/UnsubscribeMail";
import ProfileCreation from "screens/profile-creation/ProfileCreationScreen";
import CheckEmail from "./screens/forgot-password/CheckEmail";
import CreateNewPassword from "./screens/forgot-password/CreateNewPassword";
import VerifyAccountScreen from "./screens/verify-phone/VerifyAccountScreen"
import CreateAccountVerifyScreen from "screens/CreateAccountVerifyScreen";
import VerifiyOTPLoader  from "screens/verify-phone/VerifiyOTPLoader";
import VerifyPhoneSuccess  from "screens/verify-phone/VerifyPhone";
import EmailVerificationForm from "screens/account-creation/EmailVerificationForm";

import InvoiceDetails from "screens/billing-invoice/InvoiceDetails";
import HomeScreen from "screens/home-screen/HomeScreen";
import PrivateRoute from "PrivateRoute";
import { lazy, Suspense } from "react";
import Loader from "ui/feedback/Loader";
import AdminPanel from "screens/admin-panel/AdminPanel";
import SavedSearchScreen from "screens/SavedSearchScreen";


// const Login = lazy(() => import("screens/LoginScreenScreen"));
// const CreateAccountScreen = lazy(() => import("screens/account-creation/CreateAccountScreen"));
// const HomeScreen = lazy(() => import("screens/home-screen/HomeScreen"));
// const Message = lazy(() => import("screens/MessageScreen"));
// const Settings =lazy(() => import("screens/settings-screen/Settings"));
// const EditProfile = lazy(() => import("screens/EditProfileScreen"));
// const FAQScreen =lazy(() => import("screens/FAQScreen"));
// const BillingScreen = lazy(() => import("screens/billing-invoice/BillingScreen"));
// const ChatScreen =lazy(() => import("screens/ChatScreen"));
// const ResetPassword = lazy(() => import("screens/ResetPasswordScreen"));
// const PaymentScreen = lazy(() => import("screens/PaymentScreen"));
// const ProfileScreen =lazy(() => import("screens/ProfileScreen"));
// const FavouritesScreen = lazy(() => import("screens/FavouritesScreen"));
// const ContactUs =lazy(() => import("screens/ContactUsScreen"));
// const ProfileCreation = lazy(() => import("screens/profile-creation/ProfileCreationScreen"));
// const InvoiceDetails =lazy(() => import("screens/billing-invoice/InvoiceDetails"));
// const ForgotPassword =lazy(() => import("screens/forgot-password/ForgotPasswordScreen"));
// const CheckEmail = lazy(() => import("./screens/forgot-password/CheckEmail"));
// const CreateNewPassword = lazy(() => import("./screens/forgot-password/CreateNewPassword"));
// const VerifyAccountScreen =lazy(() => import("./screens/verify-phone/VerifyAccountScreen"));
// const CreateAccountVerifyScreen = lazy(() => import("screens/CreateAccountVerifyScreen"));
// const VerifiyOTPLoader =lazy(() => import("screens/verify-phone/VerifiyOTPLoader"));
// const VerifyPhoneSuccess = lazy(() => import("screens/verify-phone/VerifyPhone"));
// const EmailVerificationForm =lazy(() => import("screens/account-creation/EmailVerificationForm"));

function AppRouter() {
  // const userObject = localStorage.getItem("User")
  // const parsedUser=userObject ? JSON.parse(userObject):{}
  // const [authenticated,setAuthenticated ]= Object.keys(parsedUser).length === 0 ? false:true
  // console.log("in" , authenticated,parsedUser?.token?.length,parsedUser?.token,parsedUser)

  return (
    <Router>
      {/* <Suspense fallback={<Loader />}> */}
      <Switch>
        <Route exact path="/" component={CreateAccountScreen} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/forgot-password/check-email">
          <CheckEmail />
        </Route>
        <Route exact path="/create-password/:id/:token">
          <CreateNewPassword />
        </Route>
        <Route path="/create-account/:code?/:email?/:phone?/:firstname?/:lastname?">
          <CreateAccountScreen />
        </Route>
        <Route path="/create-account/:paramsEmail?/:paramsRole?">
          <CreateAccountScreen />
        </Route>
        <Route path="/jobseeker">
          <CreateAccountScreen />
        </Route>
        <Route path="/create-account-verification">
          <CreateAccountVerifyScreen />
        </Route>
        <Route path="/create-account-verify">
          <VerifiyOTPLoader />
        </Route>
        <Route path="/create-account-verified">
          <VerifyPhoneSuccess />
        </Route>
        <Route path="/create-account-verify-email">
          <EmailVerificationForm />
        </Route>
        <Route path="/verify-account">
          <VerifyAccountScreen />
        </Route>
        <PrivateRoute path="/message/:id?" component={Message} />
        <PrivateRoute  path="/setting" component={Settings} />
        <PrivateRoute  path="/reset-password" component={ResetPassword} />
        <PrivateRoute  path="/faq" component={FAQScreen} />
        <PrivateRoute  path="/saved-search" component={SavedSearchScreen} />
        
        <Route path="/invoice">
          <InvoiceDetails />
        </Route>
        <Route path="/chat">
          <ChatScreen />
        </Route>
        <Route path="/employeer-profile">
          <ProfileScreen />
        </Route>
        <PrivateRoute  path="/home" component={HomeScreen} />
        <Route path="/contactUs">
          <ContactUs />
        </Route>
        <PrivateRoute  path="/profile-creation" component={ProfileCreation} />
        {/* Employer Routes */}
        {/* <Route path="/employer-login">
          <Login />
        </Route> */}
        {/* Won't be needing specialized route will be using same /message route defined above */}
        <PrivateRoute path="/employer/message/:id" component={Message} />
        <PrivateRoute  path="/employer/reset-password" component={ResetPassword} />
        <Route path="/employer/edit-profile">
          <EditProfile />
        </Route>
        <Route exact path="/employer/billing">
          <BillingScreen />
        </Route>
        <Route path="/employer/invoice">
          <InvoiceDetails />
        </Route>
        <Route path="/employer/FAQ">
          <FAQScreen />
        </Route>
        <Route path="/employer/payment">
          <PaymentScreen />
        </Route>
        <Route path="/employer/chat">
          <ChatScreen />
        </Route>
        <PrivateRoute  path="/employer-home/:data?" component={HomeScreen} /> { /*pass AdminPanel component here to test Admin panel changes  */ }
        <PrivateRoute  path="/favourites" component={FavouritesScreen} />
        <PrivateRoute  path="/edit-profile" component={EditProfile} />
        <PrivateRoute  path="/billing" component={BillingScreen} />
        <PrivateRoute path="/admin-home" component={AdminPanel} /> { /*route for admin */ }
        <Route path="/contactUs">
          <ContactUs />
        </Route>
        <Route path="/employer">
          <CreateAccountScreen />
        </Route>
        <Route path="/unsubscribedMail">
          <UnsubscribeMail />
        </Route>
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
      {/* </Suspense> */}
    </Router>
  );
}

export default AppRouter;