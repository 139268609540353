import * as React from "react";

function I_Big(props) {
    return (
        <svg width={props?.width ? props.width : "68px"} height={props?.height ? props.height : "68px"} viewBox="0 0 68 68" fill="none" {...props}>
            <path
                d="M34 0C15.232 0 0 15.232 0 34C0 52.768 15.232 68 34 68C52.768 68 68 52.768 68 34C68 15.232 52.768 0 34 0ZM37.4 51H30.6V30.6H37.4V51ZM37.4 23.8H30.6V17H37.4V23.8Z"
                fill="#FF3000"
            />
        </svg>

    );
}

const MemoI_Big = React.memo(I_Big);
export default MemoI_Big;