function Message() {

  return (
    <h2>
      404 Not Found
    </h2>
  )
}

export default Message;
