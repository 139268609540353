import React, {
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  LegacyRef,
} from "react";
import MemoDownload from "../icons/Download";
import { useDropzone } from "react-dropzone";
import MemoDeleteIcon from "../icons/DeleteIcon";
import styled from "styled-components";
import { deleteFiles, uploadData } from "services/user/User";
import { UserContext } from "context/state";
import PDFIcon from "ui/icons/pdf.png";
import AvatarEditor from "react-avatar-editor";
import { Modal } from "@mantine/core";
import Button from "ui/buttons/Button";
import moment from "moment";
import { ErrorModal } from "ui/modals/ErrorModal";
import Loader from "ui/feedback/Loader";
import DropzoneLoader from "ui/feedback/DropzoneLoader";
import { getUserDetails } from "services/user/User";
import { useTranslation } from "react-i18next";
import { fontGrey } from "constants/colors";
import MemoDeleteIcon2 from "ui/icons/DeleteIcon2";
import MemoWhiteDownloadIcon from "ui/icons/WhiteDownloadIcon";

import MemoInfo from "ui/icons/Info";
import { ProfilCreationAlertModel } from "ui/data-display/ProfilCreationAlertModel";
interface DropZoneProps {
  description?: string;
  label?: string;
  acceptType?: string;
  savedData?: any; // Pass the Saved Data
  form?: any;
  customWidth?: string;
  customHeight?: string;
  custom?:boolean,
  infoText?:string;
  hideInfo?:boolean;
  customCV?:boolean
}

const StyleUploadImage = styled.div<{ CV?: boolean,customWidth?:string, customHeight?:string }>`
  width: ${(p) => (p.customWidth ? p.customWidth : "100px")};
  height: ${(p) => (p.customHeight ? p.customHeight : "100px")};
  background: ${(p) => (p.CV ? "#6E6E6E" : p.customHeight? "#C9C9C9" :"#FFFFFF")};
  box-shadow: 0px 10px 35px rgba(64, 59, 75, 0.1);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(p) => (p.customHeight ? "0px" : "20px")};
  cursor: pointer;
`;

const StyleLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${fontGrey};
`;

const StyleDescription = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6e6e6e;
  position: absolute;
  top: 68px;
  left: 120px;
  width: 143px;
`;

const StyleContainer = styled.div`
  position: relative;
  margin-bottom: 28px;
  margin-left: 0;
  @media only screen and (min-width: 1000px) {
    margin-left: 0px;
    margin-bottom: 19px;
    width: 100%;
  }
`;

const StyleDeleteIcon = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #FFFFFF;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
  width: 28px;
  height: 28px;
  border-radius: 56px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyleDeleteIconContainer = styled.div`
  position: relative;
  width: 100px;
`;

const UploadText = styled.div`
width: 80px;
height: 32px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
text-align: center;
color: #FFFFFF;
word-wrap: break-word;
`

export const DropZone: React.FC<DropZoneProps> = ({
  acceptType,
  description,
  savedData = [],
  label,
  customWidth,
  customHeight,
  custom,
  hideInfo,
  customCV,
  ...props
}) => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState();
  const [files, setFiles] = useState([]);
  const { userId, role, setUserData, userData } = useContext(UserContext);
  const [uploadResponse, setUploadResponse] = useState<{ [key: string]: any }>({});
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState<File>();
  const [modal, setShowModal] = useState(false);
  const editor = useRef<any>(null);
  const [errorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [editFiles, setEditFiles] = useState<[]>(savedData);
  const [showInfo, setShowInfo] = useState(false);
  // console.log(`savedData`, savedData, uploadResponse, acceptType);
  //Saved Data
  useEffect(() => {
    if (savedData.length != 0 && acceptType == "image") {
      setUploadResponse({
        type: "LOGO",
        url: savedData[0],
      });
    }
    if (savedData.length != 0 && acceptType == "file") {
      setUploadResponse({
        type: "CV",
        url: savedData[0],
      });
    }
  }, [savedData]);

  // useEffect(() => {
  //     if (savedData.length != 0 && acceptType == 'image') {
  //         props?.form?.setFieldValue('profilePicture', uploadResponse)
  //     }
  //     if (savedData.length != 0 && acceptType == 'file') {
  //         props?.form?.setFieldValue('uploadCV', uploadResponse)
  //     }
  // }, [uploadResponse])

  useEffect(() => {
    let acceptImage: any = {
      onDrop,
      accept: {
        image: [".jpeg", ".jpg", ".png"],
      },
      multiple: false,
    };
    let acceptVideo: any = {
      onDrop,
      accept: {
        "video/*": [],
      },
    };
    let acceptFile: any = {
      onDrop,
      accept: {
        "application/pdf": [],
      },
      multiple: false,
    };

    let defaultType: any = {
      onDrop,
    };

    switch (acceptType) {
      case "image":
        setInputType(acceptImage);
        break;
      case "video":
        setInputType(acceptVideo);
        break;
      case "file":
        setInputType(acceptFile);
        break;
      default:
        //;
        break;
    }
  }, [acceptType]);

  const onDrop = useCallback((acceptedFiles: any, rejected: any) => {
    if (rejected != null && rejected.length > 0) {
      // console.log("Invalid file format outside", rejected);
      if (acceptType === "file") {
        // console.log("Invalid file format in pdf");
        setErrorMessage(t("Invalid_file_format_pdf"));
        setShowErrorModal(true);
        return;
      }
      if (acceptType === "image") {
        // console.log("Invalid file format in image");
        setErrorMessage(t("Invalid_file_format_jpg"));
        setShowErrorModal(true);
        return;
      }
    }
    setFiles(acceptedFiles);
    // console.log("file?", acceptedFiles);
    let fileType = "";
    if (acceptType === "image" && role === "candidate") {
      if (
        acceptedFiles[0]?.type === "image/png" ||
        acceptedFiles[0]?.type === "image/jpeg" ||
        acceptedFiles[0]?.type === "image/jpg"
      ) {
        setShowModal(true);
        setImage(acceptedFiles);
      } else {
        // console.log("invalid image");
        setErrorMessage(t("Invalid_image_format"));
        setShowErrorModal(true);
      }
    } else {
      setLoader(true);
      switch (acceptType) {
        case "image": {
          if (role === "candidate") fileType = "DP";
          else fileType = "LOGO";
          break;
        }
        case "video":
          fileType = "VIDEO";
          break;
        case "file":
          fileType = "CV";
          break;
        default:
          console.log(acceptType);
          break;
      }
      let data = {
        file: acceptedFiles[0],
        id: userId,
        type: fileType,
      };
      fileUpload(data);
    }
  }, []);

  const handleImage = () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editor.current.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.current.getImageScaledToCanvas();
      let dataURL = canvasScaled.toDataURL();
      let blobBin = atob(dataURL.split(",")[1]);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      let blob = new Blob([new Uint8Array(array)], { type: "image/png" });
      let file = new File(
        [blob],
        `profile-picture-${userId}-${moment(new Date()).format("DD-MM-YY-HH-MM-SS")}.png`
      );
      // console.log(file);
      setCroppedImage(file);
      let filetype = role === "candidate" ? "DP" : "LOGO";
      let data = {
        file: file,
        id: userId,
        type: filetype,
      };
      fileUpload(data);
      setShowModal(false);
      setLoader(true);
    }
  };
  const fileUpload = (data: {}) => {
    uploadData(data)
      .then((res) => {
        //console.log(res)
        setUploadResponse(res?.data);
        if (acceptType == "image") {
          props?.form?.setFieldValue("profilePicture", res?.data?.url);
          if(custom){
            getUserDetails(userData?.user_id).then((res) => {
              if (res?.success) {
                setUserData(res?.data);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(res?.data ?? "")
                );
              }
            });
          }
        }
        if (acceptType == "file") {
          if(customCV){
            getUserDetails(userData?.user_id).then((res) => {
              if (res?.success) {
                setUserData(res?.data);
                localStorage.setItem(
                  "userData",
                  JSON.stringify(res?.data ?? "")
                );
              }
            });
          }
          props?.form?.setFieldValue("uploadCV", res?.data?.url);
        }
        if(!res?.response?.data.success){
          setErrorMessage(res.response.data.message)
          setShowErrorModal(true)
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)});
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone(inputType);
  //console.log(`File`, files)
  const whatToDisplay = () => {
    //console.log("response=>",uploadResponse)
    if (!loader) {
      if (
        (uploadResponse?.type === "DP" || uploadResponse?.type === "LOGO") &&
        acceptType === "image" &&
        files
      )
        return (
          <img
            style={{ width:customWidth?customWidth: "100px", height: customHeight?customHeight: "100px", borderRadius: "11px", objectFit: "contain" }}
            src={
              uploadResponse?.thumbnail_url ? uploadResponse?.thumbnail_url : uploadResponse?.url
            }
            alt='Uploaded'
          />
        );
      if (uploadResponse?.type === "VIDEO" && acceptType === "video" && files)
        return (
          <img
            style={{ width: "100px", height: "100px", borderRadius: "11px", objectFit: "contain" }}
            src={uploadResponse?.thumbnail_url}
            alt='Uploaded'
          />
        );
      if (uploadResponse?.type === "CV" && acceptType === "file" && files)
        return (
          <img
            style={{ width: "64px", height: "64px", objectFit: "contain" }}
            src={PDFIcon}
            alt='Uploaded'
          />
        );
      if (custom) {
        return (
          <>
            {" "}
            <div>
              <MemoWhiteDownloadIcon width={"32px"} height={"21px"} />
            </div>
            <UploadText><span style={{display:'block', textAlign:'center'}}>{t("upload")}</span><span style={{display:'block', textAlign:'center'}}>{t("profile_image")}</span></UploadText>
          </>
        );
      } 
      else return <MemoDownload />;
    }
  };
  const handleDelete = () => {
    let fileType = "";
    switch (acceptType) {
      case "image": {
        if (role === "candidate") fileType = "DP";
        else fileType = "LOGO";
        break;
      }
      case "video":
        fileType = "VIDEO";
        break;
      case "file":
        fileType = "CV";
        break;
      default:
        console.log("");
        break;
    }
    let data = {
      uid: userId,
      type: fileType,
    };
    deleteFiles(data).then((res) => {
      // console.log(res);
      if (acceptType == "image") {
        props?.form?.setFieldValue("profilePicture", "");
      }
      if (acceptType == "file") {
        props?.form?.setFieldValue("uploadCV", "");
      }
      getUserDetails(userId)
        .then((res) => {
          if (res?.success) {
            setUserData(res?.data);
            localStorage.setItem(
              "userData",
              JSON.stringify(res?.data ?? "")
            );
          }
        })
        .catch((err) => console.log(err));
    });
    setFiles([]);
    setUploadResponse({});
    setEditFiles([]);
  };

  //console.log(`check del icon`, files, loader, editFiles, uploadResponse?.url);

  return (
    <>
      <ErrorModal
        opened={errorModal}
        onClose={() => {
          setShowErrorModal(false);
          setErrorMessage("");
        }}
        message={errorMessage}
      />
      <Modal
        opened={modal}
        onClose={() => setShowModal(false)}
        centered
        {...props}
        overlayOpacity={0.55}
        overlayBlur={5}
        // size={400}
        styles={{
          modal: {
            borderRadius: "20px",
            height: "auto",
            width: "auto",
            //minWidth: '360px',
            minWidth: "300px",
            margin: "10px",
          },
          close: { display: "none" },
          title: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <div>
          <AvatarEditor
            image={
              image
                ? image[0]
                : "https://source.unsplash.com/user/c_v_r/1900x800"
            }
            width={200}
            height={200}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
            ref={editor}
          />
        </div>
        <Button
          style={{
            wordWrap: "break-word",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onClick={() => handleImage()}
          variant="filled"
          height="54px"
          fontSize="18px"
        >
          {t("Save")}
        </Button>
      </Modal>
      <StyleContainer style={{marginBottom:hideInfo?"9px":''}}>
        {(!custom && !hideInfo ) && <><StyleLabel>{label}</StyleLabel>
        <ProfilCreationAlertModel
          opened={showInfo}
          infoText={props?.infoText}
          onClose={() => setShowInfo(false)}
        />
        <MemoInfo
          height="15px"
          width="15px"
          style={{ alignSelf: "center", paddingLeft: "5px" }}
          {...props}
          onClick={() => setShowInfo(true)}
        />
        <StyleDescription>{description}</StyleDescription>
        </>}
        
        <StyleDeleteIconContainer style={{ width: "100px" }}>
          <div {...getRootProps()} tabIndex={-1}>
            <input {...getInputProps()}  />
            {loader ? (
              <StyleUploadImage
                customHeight={customHeight}
                customWidth={customWidth}
              >
                <DropzoneLoader margin={custom ? "-3%" :""} />
              </StyleUploadImage>
            ) : (
              <StyleUploadImage
                CV={uploadResponse?.type === "CV" ? true : false}
                customHeight={customHeight}
                customWidth={customWidth}
              >
                {whatToDisplay()}
              </StyleUploadImage>
            )}
          </div>
          {((files.length > 0 && !loader && uploadResponse?.url) ||
            (editFiles?.length > 0 && uploadResponse?.url)) && (
            <>
              <StyleDeleteIcon
                style={{ right: custom ? "2.5px" : "-10px",cursor:'pointer' }}
                onClick={() => handleDelete()}
                
              >
                <MemoDeleteIcon2  width="11px" height="12px"  />
              </StyleDeleteIcon>
            </>
          )}
        </StyleDeleteIconContainer>
      </StyleContainer>
    </>
  );
};
