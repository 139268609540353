//function returns number of items to be displayed per page
function getPageLimit() {
  let currentLimit = null;
  const parentWidth = Math.round((71 * window.innerWidth) / 100);
  const parentHeight = Math.round((71 * window.innerHeight) / 100);
  const areaOfEachCard = 345 * 146;
  let areaofParent = null;
  areaofParent = parentWidth * parentHeight;
  currentLimit = Math.round(areaofParent / areaOfEachCard);
  if(currentLimit<8){
    currentLimit=8 //for mobile devices 
  }
  return currentLimit;
}

export default getPageLimit;
