import PublicLayout from "layouts/PublicLayout";
import React, { useEffect, useState, useContext } from "react";
import StepIndicator from "./StepIndicator";
import { UserProfileStep3 } from "screens/profile-creation//UserProfileStep3";
import UserProfileStep1 from "screens/profile-creation//UserProfileStep1";
import UserProfileStep2 from "screens/profile-creation//UserProfileStep2";
import UserProfileStep4 from "screens/profile-creation//UserProfileStep4";
import UserProfileStep5 from "screens/profile-creation/UserProfileStep5";
import { getAllFilters } from "services/taxonomies/taxonomies";
import { CountryStore } from "stores/taxnomies/country.store";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { LanguageStore } from "stores/taxnomies/language.store";
import { LicenseStore } from "stores/taxnomies/license.store";
import { PositionStore } from "stores/taxnomies/position.store";
import { AvailabilityStore } from "stores/taxnomies/availability.store";
import { CitizenStore } from "stores/taxnomies/citizen.store";
import { TypeOfPostionStore } from "stores/taxnomies/type-of-postion.store";
import { UserContext } from "context/state";
import styled from "@emotion/styled";
import Loader from "ui/feedback/Loader";
import getBrowserLanguage from "helpers/get-browser-language";
import { getUserDetails } from "services/user/User";
import Hand from "ui/icons/Hand.png";
import { useTranslation } from "react-i18next";
import EmployerOnboardingStep from "./EmployerOnboardingStep";

const StyleSpacing = styled.div`
  margin: 24px 0 0 0;
  @media only screen and (min-width: 1000px) {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;
const CreateProfile = styled.div<{marginTop?:string,marginBottom?:string}>`
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
color: #123B3D;
margin-bottom: 24px;
margin-top:44px;

@media only screen and (min-width: 1000px){
  text-align: center;
  margin-top: ${props=> props?.marginTop ? props?.marginTop:0};
  margin-bottom: ${props=> props?.marginBottom ? props?.marginBottom:'33px'};
}
`

export default function ProfileCreation() {
  const contextData = useContext(UserContext);
  const [loader, setLoader] = useState({
    taxonomies:true,
    userDetails:true
  });

  const [profileCreationStep1, setProfileCreationStep1] = useState({});
  const [profileCreationStep2, setProfileCreationStep2] = useState({});
  const [profileCreationStep3, setProfileCreationStep3] = useState({});
  const [profileCreationStep4, setProfileCreationStep4] = useState({});
  const [profileCreationStep5, setProfileCreationStep5] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [showValidation0, setShowValidation0] = useState(false);
  const [showValidation1, setShowValidation1] = useState(false);
  const [savedPositionIds, setSavedPositionIds] = useState([]);
  const [savedIndustryIds, setSavedIndustryIds] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAllFilters()
      .then((response: any) => {
        response?.data?.country
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(
              b?.name,
              `${getBrowserLanguage()}`,
              {
                ignorePunctuation: true,
              }
            )
          )
          ?.map((item: any) => CountryStore.set(item.id, item.name));
        //response.data.language.map((item: any) => LanguageStore.set(item.id, item.name));

        response?.data?.language
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(
              b?.name,
              `${getBrowserLanguage()}`,
              {
                ignorePunctuation: true,
              }
            )
          )
          ?.map((item: any) => LanguageStore.set(item.id, item.name));
        response.data.availability.map((item: any) => AvailabilityStore.set(item.id, item.name));
        response.data.citizen.map((item: any) => CitizenStore.set(item.id, item.name));
        response.data.position_type.map((item: any) => TypeOfPostionStore.set(item.id, item.name));
        response.data.industry_type.map((item: any) => {
          let value = {
            name: item?.name,
            positions: item?.positions,
          };
          IndustryStore.set(item.id, item.name);
          PositionStore.set(item.id, value);
        });
        response.data.license_type.map((item: any) => {
          let value = {
            name: item?.name,
            positions: item?.positions,
          };
          LicenseStore.set(item.id, value);
        });
        setLoader((prev:any)=>({...prev,taxonomies:false}));
      })
      .catch((error: any) => {
        console.log(error);
      });
      getUserDetails(contextData?.userId)
      .then((response) => {
        contextData?.setUserData(response?.data);
        localStorage.setItem("userData", JSON.stringify(response?.data ?? ""));
        let firstname=""
        let lastname =""
        if(response?.data?.first_name){
          firstname =response?.data?.first_name
        }
        if(response?.data?.last_name){
          lastname = response?.data?.last_name
        }
        setProfileCreationStep1((prev:any)=>({...prev,firstName:firstname,lastName:lastname}))
        setLoader((prev:any)=>({...prev,userDetails:false}));
        }
      )
      .catch((err) => console.log(err));
  }, []);

  // Saved Posiiton IDs saved by the user.
  useEffect(() => {
    setSavedPositionIds(contextData?.userData?.position_type);
  }, [contextData?.userData?.position_type]);

  // Saved Industry IDs saved by the user.
  useEffect(() => {
    setSavedIndustryIds(contextData?.userData?.industry_type);
  }, [contextData?.userData?.industry_type]);

  const dataSteps = [
    <UserProfileStep1
      data={profileCreationStep1}
      setIsValid={setIsValid}
      setData={setProfileCreationStep1}
      showValidation={showValidation0}
      savedPositionIds={savedPositionIds}
      setSavedPositionIds={setSavedPositionIds}
      savedIndustryIds={savedIndustryIds}
      setSavedIndustryIds={setSavedIndustryIds}
    />,
    <UserProfileStep2
      data={profileCreationStep2}
      setIsValid={setIsValid}
      setData={setProfileCreationStep2}
      showValidation={showValidation1}
    />,
    // <UserProfileStep3
    //   data={profileCreationStep3}
    //   setIsValid={setIsValid}
    //   setData={setProfileCreationStep3}
    //   showValidation={showValidation0}
    // />,
    // <UserProfileStep4
    //   data={profileCreationStep4}
    //   setIsValid={setIsValid}
    //   setData={setProfileCreationStep4}
    //   showValidation={showValidation1}
    // />,
    // <UserProfileStep5
    //   data={profileCreationStep5}
    //   setIsValid={setIsValid}
    //   setData={setProfileCreationStep5}
    //   showValidation={showValidation1}
    // />,
  ];
  
  if (loader.taxonomies || loader.userDetails) {
    return <Loader />;
  }
  //console.log(`Data:`, profileCreationStep1, profileCreationStep2, profileCreationStep3, profileCreationStep4, profileCreationStep5)
  // console.log(`savedData profileCreationStep3 Data`, profileCreationStep3)
  return (
    <PublicLayout viewType='profile' position='top'>
      {contextData?.role==="candidate" ?(
      <><CreateProfile>{t("Create Profile")} <img src={Hand} style={{ marginBottom: "0px", marginLeft: "0px" }} alt="Welcome" /></CreateProfile>
      <StyleSpacing>
        <StepIndicator
          isValid={isValid}
          step1Data={profileCreationStep1}
          step2Data={profileCreationStep2}
          step3Data={profileCreationStep3}
          step4Data={profileCreationStep4}
          step5Data={profileCreationStep5}
          dataSteps={dataSteps}
          setShowValidation0={setShowValidation0} //To show validation on Even steps on click of next
          setShowValidation1={setShowValidation1} //To show validation on Odd steps on click of next
        />
      </StyleSpacing></>):(
        <>
        <CreateProfile marginTop="15px" marginBottom="45px">{t("create_company_profile")} <img src={Hand} style={{ marginBottom: "0px", marginLeft: "0px" }} alt="Welcome" /></CreateProfile>
        <EmployerOnboardingStep />
        </>
      )}
    </PublicLayout>
  );
}
