import * as React from "react";

function Chat(props) {
    return (
        <svg width={props?.size ? props.size : "36"} height={props?.size ? props.size : "36"} viewBox="0 0 36 36" fill="none" {...props}>
            <g filter="url(#filter0_d_2995_3308)">
                <path d="M22 28C29.732 28 36 21.732 36 14C36 6.26801 29.732 0 22 0C14.268 0 8 6.26801 8 14C8 21.732 14.268 28 22 28Z" fill="white" />
            </g>
            <path d="M32 13.4387C32 18.0992 27.7464 21.8773 22.5 21.8773C21.5591 21.8786 20.6221 21.7546 19.7129 21.5084C19.0194 21.8653 17.427 22.55 14.748 22.996C14.5105 23.0346 14.33 22.7839 14.4238 22.5596C14.8442 21.5518 15.2242 20.2089 15.3382 18.9841C13.8835 17.5013 13 15.5604 13 13.4387C13 8.77811 17.2536 5 22.5 5C27.7464 5 32 8.77811 32 13.4387ZM18.9375 13.4387C18.9375 13.1189 18.8124 12.8123 18.5897 12.5862C18.367 12.3601 18.0649 12.2331 17.75 12.2331C17.4351 12.2331 17.133 12.3601 16.9103 12.5862C16.6876 12.8123 16.5625 13.1189 16.5625 13.4387C16.5625 13.7584 16.6876 14.065 16.9103 14.2911C17.133 14.5172 17.4351 14.6442 17.75 14.6442C18.0649 14.6442 18.367 14.5172 18.5897 14.2911C18.8124 14.065 18.9375 13.7584 18.9375 13.4387ZM23.6875 13.4387C23.6875 13.1189 23.5624 12.8123 23.3397 12.5862C23.117 12.3601 22.8149 12.2331 22.5 12.2331C22.1851 12.2331 21.883 12.3601 21.6603 12.5862C21.4376 12.8123 21.3125 13.1189 21.3125 13.4387C21.3125 13.7584 21.4376 14.065 21.6603 14.2911C21.883 14.5172 22.1851 14.6442 22.5 14.6442C22.8149 14.6442 23.117 14.5172 23.3397 14.2911C23.5624 14.065 23.6875 13.7584 23.6875 13.4387ZM27.25 14.6442C27.5649 14.6442 27.867 14.5172 28.0897 14.2911C28.3124 14.065 28.4375 13.7584 28.4375 13.4387C28.4375 13.1189 28.3124 12.8123 28.0897 12.5862C27.867 12.3601 27.5649 12.2331 27.25 12.2331C26.9351 12.2331 26.633 12.3601 26.4103 12.5862C26.1876 12.8123 26.0625 13.1189 26.0625 13.4387C26.0625 13.7584 26.1876 14.065 26.4103 14.2911C26.633 14.5172 26.9351 14.6442 27.25 14.6442Z" fill="#4CA6A8" />
            <defs>
                <filter id="filter0_d_2995_3308" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2995_3308" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2995_3308" result="shape" />
                </filter>
            </defs>
        </svg>

    );
}

const MemoChat = React.memo(Chat);
export default MemoChat;
