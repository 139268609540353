import React, { ReactNode, useState, useEffect, useContext } from 'react'
import { Box, Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import CheckboxGroup from 'ui/inputs/CheckboxGroup'
import styled from '@emotion/styled'
import { CountryStore } from 'stores/taxnomies/country.store'
import { useTranslation } from 'react-i18next'

interface IndustrySearchProps {
  children?: ReactNode
  show?: boolean
  onHide?: any
  getData?: any
  passData?: any
  singleSelect?: boolean
  title?: string
  savedData?: any // Pass the Saved Data
  checkAll?: boolean
  bottomMargin?: string
  setAddClickedFor?:any,
  dontHideDrawer?:boolean
}

const CheckBoxWrapper = styled(Stack)`
  width: auto;
  height: auto;
  /* overflow-y: auto; */
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
`

const CountrySearch: React.FC<IndustrySearchProps> = ({
  children,
  passData,
  title,
  singleSelect,
  savedData = [],
  checkAll,
  setAddClickedFor,
  dontHideDrawer,
  ...props
}) => {
  const [data, setData] = useState<any>([])
  const [searchString, setSearchString] = useState('')

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)

  //State to reset selected checkbox
  const [resetData, setRestData] = useState(false)

  const { t } = useTranslation()

  // Function to display saved data
  const savedDataHandler = (data: any) => {
    let flag = false
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true
    })
    return flag
  }

  let CountryStoreData = Array.from(CountryStore.data, ([id, name]) => ({
    id,
    name,
  }))

  let CountryStoreDataUpdated = [
    {
      headerName: '',
      data: CountryStoreData.map((obj) => ({
        ...obj,
        checked: savedData?.length != 0 ? savedDataHandler(obj) : false,
        show: true,
      })),
    },
  ]

  useEffect(() => {
    setData(CountryStoreDataUpdated);
  }, []);

  useEffect(()=>{
    setData(CountryStoreDataUpdated);
  },[savedData])

  return (
    <Stack
      align={'center'}
      sx={{ minHeight: '100%' }}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={props.onHide}
        showSearch={true}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        searchString={searchString}
        setSearchString={setSearchString}
        setAddElements={setAddElements}
        bottomMargin={props?.bottomMargin}>
        <CheckBoxWrapper>
          {data?.map((dataGroup: any, index: any) => {
            return (
              <CheckboxGroup
                key={`${index + ' ' + dataGroup.headerName}`}
                data={passData?.length != 0 ? passData : data[index]?.data.length ? data : []}
                header={dataGroup.headerName}
                getData={props.getData}
                index={index}
                singleSelect={singleSelect === false ? false : true}
                resetData={resetData}
                setRestData={setRestData}
                searchString={searchString}
                setSearchString={setSearchString}
                addElements={addElements}
                setAddElements={setAddElements}
                checkAll={checkAll}
                showAddButton={data?.length == index + 1 ? true : false}
                setShow={props.onHide}
                setAddClickedFor={setAddClickedFor}
                dontHideDrawer={dontHideDrawer}
              />
            )
          })}
        </CheckBoxWrapper>
      </SearchAndSelectAbstraction>
    </Stack>
  )
}
export default CountrySearch
