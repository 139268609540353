import * as React from "react";

function Search(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M16.792 15.167h-.856l-.303-.293a7.01 7.01 0 001.7-4.582 7.041 7.041 0 10-7.041 7.041 7.01 7.01 0 004.582-1.7l.293.303v.856l5.416 5.405 1.615-1.614-5.406-5.416zm-6.5 0a4.868 4.868 0 01-4.875-4.875 4.868 4.868 0 014.875-4.875 4.868 4.868 0 014.875 4.875 4.868 4.868 0 01-4.875 4.875z"
        fill="#000"
      />
    </svg>
  );
}

const MemoSearch = React.memo(Search);
export default MemoSearch;
