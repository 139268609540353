import * as React from "react";

function StepperIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 .583a6.417 6.417 0 100 12.834A6.417 6.417 0 007 .583zM9.78 5.915a.583.583 0 10-.896-.747l-2.508 3.01-1.298-1.299a.583.583 0 00-.825.825l1.75 1.75a.583.583 0 00.86-.039l2.917-3.5z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

const MemoStepperIcon = React.memo(StepperIcon);
export default MemoStepperIcon;
