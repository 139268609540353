import * as React from "react";

function Paid(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 71 34" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d_2998_3308)">
        <rect x={4} width={63} height={26} rx={13} fill="#399E5C" />
      </g>
      <path
        d="M25.467 12.809v-2.233h1.221c.869 0 1.254.418 1.254 1.122 0 .682-.385 1.111-1.254 1.111h-1.221zm4.059-1.111c0-1.331-.924-2.376-2.772-2.376h-2.827V17h1.54v-2.948h1.287c1.98 0 2.772-1.188 2.772-2.354zm.697 2.233c0 1.903 1.232 3.168 2.772 3.168.968 0 1.661-.462 2.024-.99V17h1.551v-6.094h-1.55v.869c-.364-.506-1.035-.968-2.014-.968-1.55 0-2.783 1.221-2.783 3.124zm4.796.022c0 1.155-.77 1.793-1.617 1.793-.825 0-1.606-.66-1.606-1.815 0-1.155.781-1.771 1.606-1.771.847 0 1.617.638 1.617 1.793zM38.074 17h1.54v-6.094h-1.54V17zm.781-6.82c.54 0 .946-.396.946-.902s-.407-.902-.946-.902c-.55 0-.946.396-.946.902s.396.902.946.902zm1.885 3.751c0 1.903 1.232 3.168 2.783 3.168.957 0 1.639-.44 2.002-1.001V17h1.562V8.86h-1.562v2.893c-.418-.572-1.188-.946-1.991-.946-1.562 0-2.794 1.221-2.794 3.124zm4.796.022c0 1.155-.77 1.793-1.617 1.793-.825 0-1.606-.66-1.606-1.815 0-1.155.781-1.771 1.606-1.771.847 0 1.617.638 1.617 1.793z"
        fill="#fff"
      />
      <defs>
        <filter
          id="prefix__filter0_d_2998_3308"
          x={0}
          y={0}
          width={71}
          height={34}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2998_3308"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2998_3308"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

const MemoPaid = React.memo(Paid);
export default MemoPaid;
