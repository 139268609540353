export const TRANSLATIONS_EN = {
  Find_the_Perfect_Job_Match: "Find the Perfect Job Match",
  Providing_the_best_solutions_to_find_the_people_you_need_to_take_your_business_to_the_next_level:
    "Providing the best solutions to find the people you need to take your business to the next level.",
  I_am: "I am",
  Yes: "Yes",
  No: "No",
  Search: "Search",
  Type_of_work: "Type of work you're seeking",
  Driving_License: "Drivers license",
  Select_Sec_lang: "Select Secondary Language",
  Select_Pri_lang: "First Language",
  select_the_below_option: "(select the below option)",
  Looking_to_Hire: "Employer",
  Looking_for_Work: "Job seeker",
  Country_you_like: "Country you'd like to work in",
  Login: "Login",
  Back: "Back",
  Age: "Age",
  Email_Address: "Email Address",
  Password: "Password",
  Already_Have_Account_Log_In: "Already have an Account? ",
  Forgot_Password: "Forgot Password?",
  Log_in: "Log in",
  Continue: "Continue",
  Or_Continue_with: "Or Continue with",
  New_User_Create_Account: "New User? ",
  Create_Account: "Create Account",
  We_provide_the_best_solution_to_fill_the_workplace_with_the_right_people:
    "Connecting employers and job seekers in minutes!",
  Send_Email: "Send Email",
  Check_your_email: "Check your email",
  We_have_sent_password_recovery_instructions_to_your_email:
    "We have sent password recovery instructions to your email",
  Okay: "Okay",
  Create_New_Password: "Create New Password",
  Your_new_password_must_be_different_from_your_previous_passwords:
    "Your new password must be different from your previous passwords",
  Reset_Login: "Reset & Login",
  Confirm_Password: "Confirm Password",
  Create_an_Account: "Create an Account",
  Phone: "Phone Number",
  Verify_Phone: "Verify Phone",
  Please_enter_the_verification_code_sent_to:
    "Please enter the verification code sent to ",
  Didnt_receive_the_code: "Didn't receive the code?",
  Resend: "Resend",
  Verify: "Verify",
  Please_Wait_Verifying_OTP: "Please Wait Verifying OTP",
  Your_mobile_number_is_verified: "Your mobile number is verified!",
  Verify_Email: "Verify Email",
  We_have_sent_an_email: "We have sent an email to you at ",
  We_have_sent_an_email1: "",
  Please_click_the_link_to_verify:
    ". Please click the link to verify that this is your email address. Remember to check spam.",
  Proceed: "Proceed",
  Didnt_receive_the_link: "Didn't receive the link?",
  Congratulations: "Congratulations!",
  You_are_now_ready_to_find_you_first_candidates: "You are now ready to find you first candidates!",
  Filter: "Filter",
  Refine_your_search_with_extra_filters_to_pinpoint_the_people_you_need:
    "Refine your search with extra filters to pinpoint the people you need.",
  Favourites: "Favourites",
  Find_the_talent_you_have_saved_organize_them_and_hire:
    "Find the talent you have saved, organize them and hire!",
  Chat: "Chat",
  Find_all_your_messages_in_one_place:
    "Find all your messages in one place. Reply and scheldule a meeting!",
  Start_Filtering: "Start Filtering",
  Reset_Filter: "Reset Filter",
  Start_by_filtering_your_requirements: "Start by filtering your requirements to find the right match",
  Where_is_the_job_located: "Where is the job located?",
  Employees_needed_from_which_industry: "Employees needed from which industry?",
  What_position_do_you_need_to_fill: "What position do you need to fill?",
  Required_language: "Required language",
  When_should_the_position_be_filled: "When should the position be filled?",
  Drivers_license_required: "Driver's license required?",
  Apply_filter: "Apply filter",
  Clear_All: "Clear All",
  What_industry_do_you_seek_employees: "What industry do you seek employees?",
  Add: "Add",
  What_position_need_to_beemployed: "What position need to be employed?",
  Required_drivers_license: "Required drivers license",
  What_position_need_to_be_employed: "Type of position",
  Home: "Home",
  Message: "Message",
  Settings: "Settings",
  Welcome: "Welcome!",
  Filter_By: "Filter By",
  years: "years",
  Primary_language: "Primary language",
  Experience: "Experience",
  Country: "Country",
  Verified: "Verified",
  Info_Experience: "About",
  Secondary_languages: "Secondary languages",
  Secondary_languages_no_data: "Secondary language not available",
  License_no_data: "Driving license not available",
  Drivers_license: "Drivers license",
  Notice_Period: "Notice Period",
  Availability: "Availability",
  CV: "CV",
  Send_CV_on_Email: "Send CV on Email",
  Download_CV: "Download CV",
  References_available: "References available",
  Maria_can_provide_references_upon_request: "can provide references upon request.",
  Chat_Now: "Chat Now",
  Start_talking: "Start talking!",
  To_chat_with_name: "To chat with",
  please_select_subscription: "please select a subscription!",
  Select_subscription: "Select Subscription",
  Add_to_favourites: "Add to favourites!",
  to_add: "To add",
  to_fav: "to favourites, please select a subscription!",
  To_add_NAME_til_favourites: "To add *NAME* to favourites, please select a subscription!", //Please use to_add and to_fav
  Edit_Profile: "Edit Profile",
  Password_and_securtity: "Password and Security",
  Billing_and_payments: "Billing and payments",
  FAQs: "FAQs",
  FAQ: "FAQ",
  Email_Notifications: "Email Notifications",
  Logout: "Logout",
  Support: "Support",
  Get_in_touch_contact: "Get in touch",
  Privacy_Policy: "Privacy Policy",
  Terms_of_use: "Terms of use",
  Current_Password: "Current Password",
  Update_Password: "Update Password",
  Billing_Details: "Billing Details",
  Invoice: "Invoice",
  Subscription_Details: "Subscription Details",
  Continue_to_Checkout: "Continue to Checkout",
  Disable_subscription: "Disable subscription",
  month: "month",
  Expiration_date: "Expiration date:",
  Transactions: "Transactions",
  Lite_Subscription_paid: "Lite Subscription paid",
  Transaction_ID: "Transaction ID :",
  Invoice_Details: "Invoice Details",
  Invoice_no: "Invoice no:",
  Paid: "Paid",
  Billing_Address: "Billing Address",
  Amount_Paid: "Amount Paid",
  Notifications: "Notifications",
  send_you_message: "Richar Maxwell has send you message",
  _Days_Ago: "2 Days Ago",
  Subscription: "Subscription",
  Choose_a_plan: "Choose a plan",
  Currency: "Currency",
  Contact_Us: "Contact Us",
  Or: "Or",
  Name: "Name",
  Email: "Email",
  Company_name: "Company Name",
  Submit: "Submit",
  Address: "Address",
  Billing_email: "Billing e-mail",
  City: "City",
  Zip: "Zip",
  Different_billing_information: "Different billing information",
  I_accept_the_Terms_and_Conditions_for_using_Jobpark:
    "I accept the Terms and Conditions for using Jobpark",
  Place_order: "Place order",
  Payment: "Payment",
  Are_you_Sure: "Are you Sure?",
  Are_you_sure_you_want_to_disable_the_subscriptions:
    "Are you sure you want to disable the subscriptions",
  Confirm: "Confirm",
  Cancel: "Cancel",
  Complete_company_profile: "Complete company profile",
  Company_logo: "Company logo (optional)",
  _300_x_300_pixels_for_best_quality:
    "300 x 300 pixels for best quality. JPG, JPEG or PNG supported.",
  Social_Media_Links: "Social Media Links",
  Facebook_Link: "Facebook Link",
  Twitter_Link: "Twitter Link",
  Linkedin_Link: "Linkedin Link",
  Instagram_Link: "Instagram Link",
  Select_your_industry: "Select your Industry",
  Link_to_website: "Link to website",
  Description: "Description",
  Save: "Save",
  Skip: "Skip",
  location: "Location",
  Invite_2_New_Users_: "Invite 2 New Users - for FREE",
  Add_New_User: "Add New User",
  A_notification_will_be_send_to_the_users_email_address:
    "A notification will be send to the users email address",
  Type_a_message: "Type a message",
  No_Conversation_yet: "No Conversation yet!",
  We_will_let_you_know_when_someone_needs_your_help:
    "We will let you know when someone needs your help",
  Available: "Available",
  Type_of_position: "Type of position",
  Name_SM: " on social media",
  Check: "Check ",
  CV_is_Forwarded_to_Email: "CV is Forwarded to Email",
  CV_is_Downloaded: "CV is Downloaded",
  Link: "Link",
  Social_media: "Social media",
  An_email_has_been_sent:
    "An email has been sent to your email address xyz@gmail.com please click on that link to verify your email address.",
  Tell_us_about_yourself: "Congrats Tell us about you",
  Your_name: "Your name",
  Birthday: "Birthday",
  Positions_held: "Positions held",
  Select_Position: "Select Position",
  Select_Industry: "Select Industry",
  Where_are_you_from: "Where you live",
  Select_Country: "Select Country",
  Next: "Next",
  Will_expect_new_document: "Okay, Got it!",
  EU_citizen: "EU/EEA-citizen?",
  EU_citizen1: "EU/EEA-citizen",
  Search__Select: "Search & Select",
  Select: "Select",
  Profile_picture_: "Profile picture (optional)",
  Video_: "Video (optional)",
  Aspect_ratio_: "Aspect ratio 4:3 or 16:9. MOV, MP4 supported.",
  Upload_CV_: "Upload CV (optional)",
  Supported_file_pdf: "Supported file: PDF",
  Can_you_provide_refrences_upon_request: "Can you provide refrences upon request?",
  Write_about_yourself: "Write about yourself",
  Write_about_your_experience: "Write about your experience",
  Tell_us_about_your_education: "Tell us about your education",
  Last_Step: "Last Step",
  Ok_so_tell_us_what_you_are_searching:
    "Ok, so tell us what you are searching for and where you want to work - then we take care of the rest!",
  What_work_do_you_seek: "What work do you seek?",
  What_country_do_you_seek_to_work_in: "What country do you seek to work in?",
  When_are_you_available_to_start: "When can you start?",
  Now: "Now",
  Other: "Other",
  Full_time_or_part_time: "Full-time or part-time?",
  Welcome_to_JobPark: "Welcome to Jobpark!",
  How_it_works: "How it works",
  You_are_now_registered_and_your_profile_will_be_displayed_for_employers:
    "You are now registered and your profile is visible to employers.",
  Find_all_your_messages_in_oneplace:
    "Find all your messages in one place. Reply and talk to employers!",
  Use_settings_to_change_your_profile_and_what_employers_you_are_exposed_to:
    "Use settings to change your profile and what employers you are exposed to.",
  Lets_Go: "Let's Go",
  Welcome_Back: "Welcome Back!",
  Industry_Experience: "Industry Experience",
  Businesss_Type: "Businesss Type",
  Profile_Activation: "Profile Activation",
  Set_your_profile_inactive: "Set your profile inactive",
  Why_are_you_deactivating: "Why are you deactivating?",
  Please_tell_us_more_so_we_can_make_Jobpark_better:
    "Please tell us more so we can make Jobpark better",
  I_was_hired_through_Jobpark: "I was hired through Jobpark",
  I_was_hired_through_another_platform: "I was hired through another platform",
  Jobpark_didnt_work_for_me: "Jobpark didn't work for me",
  I_dont_want_to_use_Jobpark: "I dont want to use Jobpark",
  Use_settings_to_change_your_profile:
    "Use settings to change your profile and which employers can see it.",
  Youll_find_all_your_messages:
    "You'll find all your messages in one place, where you can reply and talk to employers!",
  to_remove_fav: "To make changes to favorites, please select a subscription!",
  new_password: "New Password",
  Select_country: "Select your country",
  Cancel_subscription: "Cancel/Pause subscription",
  Expires_end: "Expires end",
  No_active_subscription: "No active subscription",
  No_Favourites_found: "No Favourites found",
  add_as_fav: "Please add candidates as favorites from Home",
  No_Results: "No Search Results found!",
  No_item_match:
    "We can't find any results on your filtering criterias - please try another search.",
  First_language: "First language",
  Second_language: "Secondary languages",
  Where_do_you_work: "Where do you want to work?",
  About_me: "About me",
  Education: "Education",
  References_not_available: "References not available",
  My_social_media: "My social media",
  BusinessType: "Business Type",
  Connect_media: "Social media",
  lite_plan_description: "For small businesses that need to find the right candidate",
  premium_plan_description: "For businesses that hire a few times a year",
  enterprise_plan_description: "For recruitment agencies and enterprises",
  OTP_verified_successfully: "Phone successfully verified!",
  This_section_is: "This section is only available for paid subscribers with an active plan.",
  Please_select_Subscription_Plan: "Please select subscription plan.",
  Name_required_field: "Please enter your name.",
  Please_enter_phone_number: "Please enter phone number",
  Phone_number_is_not_valid: "Phone number not valid. Add country code.",
  Company_Nameis_a_required_field: "Please enter company name",
  Address_is_a_required_field: "Please enter address.",
  Country_cannot_be_empty: "Country can not be empty.",
  City_is_a_required_field: "Please enter city.",
  Only_alphabets_are_allowed_for_this_field: "Only letters are allowed in this field.",
  Zip_is_a_required_field: "Please enter zip code.",
  Enter_valid_zip_code_with_no_spaces: "Enter valid zip, with no spaces.",
  Please_Select_Country: "Please select country.",
  Please_Select_Location: "Please select location",
  Enter_Password: "Enter password.",
  Password_must_contain_8_characters:
    "Password must contain minimum 8 characters. 1 upper case, 1 number and 1 special character.",
  Passwords_must_match: "Password must match.",
  Please_enter_valid_password: "Please enter correct password.",
  Please_enter_name: "Please enter name.",
  Please_complete_your_profile: "Please complete your profile to proceed.",
  Name_should_have_at_least_2_letters: "Name must have minimun 2 letters.",
  Please_Select_First_Language: "Please select your first language.",
  Please_Select_Secondary_Language: "Please select secondary language.",
  Please_Select_Driving_License: "Please select driving license.",
  Enter_valid_Link: "Enter valid link.",
  Please_select_a_reason: "Please select an option.",
  Invalid_file_format_jpg: "Invalid file format. Use .jpg, .jpeg or .png file.",
  Please_enter_password: "Please enter password.",
  Enter_valid_link_URl: "Please enter a valid URL",
  Enter_valid_facebook_URl: "Please enter valid Facebook URL",
  Enter_valid_instagram_URl: "Please enter valid Instagram URL",
  Enter_valid_linkedin_URl: "Please enter valid LinkedIn URL",
  Enter_valid_twitter_URl: "Please enter valid Twitter URL",
  Must_be_a_valid_email_address: "Must be a valid email.",
  Email_must_be_less_than_100_characters: "Email must be less that 100 characters.",
  Please_enter_valid_email: "Must be a valid email.",
  Date_of_Birth_cannot_be_empty: "Date of birth can not be empty.",
  Please_select_Positions: "Please Select Positions held",
  Please_select_time_frame: "Please select time frame",
  Please_verify_email_to_proceed: "Please verify email to proceed.",
  Please_enter_email_address: "Please enter a valid email",
  Invalid_email_address: "Invalid email address.",
  Form_Submitted: "Form submitted.",
  Invalid_file_format_pdf: "Invalid file format. Upload .pdf only.",
  Invalid_image_format: "Invalid image format",
  Please_select_Industry_exp: "Please select Industry experience.",
  Please_select_work_schedule: "Please select work schedule",
  Experience_from_industry_alert:
    "Choose what industry you have experience from. You can choose multiple industries if needed.",
  What_positions_have_you_worked_in_alert:
    "Tell us what positions you have worked in before. This helps employers and recruiters find you.",
  Where_are_you_from_alert: "Tell us where you're from.",
  EU_EEA_citizen_alert: "It´s important for employers to know if your are an EU/EEA-citizen.",
  Primary_language_alert:
    "Please check your native language so employers and recruiters easily can match criteria.",
  Secondary_language_alert:
    "Please check off secondary languages you speak. You can add more. This is languages that you can make yourself understood in.",
  Drives_license_alert:
    "Please check off all your licenses so employers and recruiters easily can match criteria.",
  Profile_picture_alert: "Upload and fit your profile picture. This is optional.", // key not set
  Upload_Video_alert: "Max length 60 seconds and 50MB. This is optional", // key not set
  Upload_CV_alert:
    "Upload CV so employers and recruiters can download your CV directly from your profile. This is optional", // key not set
  Write_about_yourself_alert:
    "We recommend writing in English. Write a short description of who you are and what you can contribute too on a workplace. If you want to work in a specific city in a country you can add this here.", // ket not set
  Write_about_your_experience_alert:
    "We recommend writing in English. Elaborate on your work experience.", // key not set
  Tell_us_about_your_education_alert: "We recommend writing in English. ", // key not set
  What_work_do_you_seek_alert:
    "Select what industry you want to work in, and what kind of positions you are seeking. We will match you with the correct employers!",
  What_country_do_you_seek_to_work_in_alert:
    "Tell us where in Europe you want to work. Are you open for everything? Check them all off!",
  When_are_you_available_to_start_alert:
    "Tell us when you are available to start in a new job. This will show in your profile as well.",
  Full_time_or_part_time_alert: "Tell us if you want full time or part time jobs?",
  First_Name: "First Name",
  Last_Name: "Last Name",
  First_Name_should_have_at_least_2_letters: "First Name should have at least 2 letters",
  Company_Name_should_have_at_least_2_letters: "Company name should have at least 2 letters",
  Last_Name_should_have_at_least_2_letters: "Last Name should have at least 2 letters",
  Invalid_phone_number: "Invalid phone number.",
  You_are_now_registered:
    "You are now registered as a job seeker and your profile is now visible to employers in the countries you´ve selected.",
  When_an_employer_contact: "When an employer contact you they will appear on this screen",
  Keep_profile_updated: "Keep your profile updated to be contacted by employers.",
  Find_all_your_messages: "Find all your messages in one place. Reply and talk to employers!",
  Use_settings_to_change:
    "Use settings to change your profile and what employers you are exposed to.",
  Your_email_was_successfully_verified: "Your email was successfully verified.",
  Please_select_country_code: "Please select country-code",
  Set_your_profile_active: "Set your profile active",
  Austria: "Austria",
  Belgium: "Belgium",
  Bulgaria: "Bulgaria",
  Croatia: "Croatia",
  Cyprus: "Cyprus",
  Czech_Republic: "Czech Republic",
  Denmark: "Denmark",
  Netherlands: "Netherlands",
  United_Kingdom: "United Kingdom",
  Estonia: "Estonia",
  Finland: "Finland",
  France: "France",
  Germany: "Germany",
  Greece: "Greece",
  Hungary: "Hungary",
  Iceland: "Iceland",
  Ireland: "Ireland",
  Italy: "Italy",
  Latvia: "Latvia",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Malta: "Malta",
  Norway: "Norway",
  Poland: "Poland",
  Portugal: "Portugal",
  Romania: "Romania",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  Spain: "Spain",
  Sweden: "Sweden",
  Ukraine: "Ukraine",
  Please_select_Industry: "Please select industry",
  Your_account_is_not_verified: "Your account is not verified. Please proceed to verify.",
  All: "All",
  By_registering: "By registering I agree with Jobpark´s",
  Terms_of_Use: "Terms of Use",
  information_in_accordance: "and that Jobpark uses my information in accordance with our",
  privacy_policy: "privacy policy.",
  platformy_Jobpark: "",
  Invalid_Day: "Invalid day",
  Day_cannot_be_empty: "Day cannot be empty",
  Month_cannot_be_empty: "Month cannot be empty",
  Year_cannot_be_empty: "Year cannot be empty",
  Remember_me: "Remember me",
  default_error_message: "Something went wrong. Please try again.",
  when_can_you_start:
    "Example: when can you start in the new job, why are you seeking new experiences etc. Write in English if you are seeking work outside of your home country.",
  Get_OTP:"Get SMS code",
  Not_Verified:"Not Verified",
  Click_here_to_verify:"Click here to verify.",
  "Your mobile has not yet been verified. Verify Now!":"Your mobile has not yet been verified. Verify Now!",
  "Get notified when employers contact you. Verify phone!":"Get notified when employers contact you. Verify phone!",
  "Never miss a notification. Verify mobile now!":"Never miss a notification. Verify mobile now!",
  "Stay updated on all messages. Verify phone number!":"Stay updated on all messages. Verify phone number!",
  "Missing out on notifications from potential employers? Verify phone number!":"Missing out on notifications from potential employers? Verify phone number!",
  "Get notifications on your phone when a candidate answers. Verify phone now!":"Get notifications on your phone when a candidate answers. Verify phone now!",
  "Missing out on your chat notifications? Verify phone now!":"Missing out on your chat notifications? Verify phone now!",
  Delete_account:"Delete Account",
  Are_you_sure:"Are you Sure?",
  are_you_sure_you_want_to_delete_the_account_all_the_account_data_will_be_deleted:"Are you sure you want to Delete the Account ? All the account data will be deleted.",
  please_wait_till_we_delete_your_account_permanently:"Please wait till we delete your account permanently.",
  "Yay! You have seen it all":"Yay! You have seen it all",
  "Create Profile":"Create Profile",
  basic_information:"Basic Information",
  "Select Experience":"Select Experience",
  "Select your experience":"Select your experience",
  "What industry you're looking at?":"What industry you're looking at?",
  "Position held":"Position held",
  your_search:"What you are searching for",
  your_search_name:"What {{name}} is searching for",
  about:"About",
  about_name:"About {{name}}",
  which_country_you_will_like_to_work:"Country you'd like to work in",
  which_country_you_will_like_to_work_name:"Country {{name}} wants to work in",
  country_of_origin:"Country of origin",
  Enter_Bio:"Example: when can you start in the new job, why are you seeking new experiences etc. Write in English if you are seeking work outside of your home country.",
  upload:"Upload",
  profile_image:'Profile Picture',
  Edit:'Edit',
  profile_completion_text:' of profile completed. To gain more interest from employers, please fill in all the details needed in your profile.',
  preview:'Preview',
  contact_person:"Contact Person",
  what_industries_you_re_looking_for_people_in:	"What industries you're looking for people in?",
  country_you_re_looking_for_people_in:	"Country you’re looking for people in?",
  create_company_profile:"Create Company Profile",
  Industry:"Industry",
  Contact_Person_should_have_at_least_2_letters:"Contact person must have at least 2 letters.",
  phone:"Phone",
  profile_completed:"Profile Completed",
  Website:"Website",
  where_is_your_business_located:"Where is your business located?",
  what_are_you_looking_to_do:"What are you looking to do?",
  find_a_job:"Find a job",
  find_candidates_and_hire:"Find candidates and hire",
  select_only_3_industries :"Select up to 3 industries",
  please_select_only_3_industries:"You have selected to many industries, please select max 3 industries.",
  view_contact_details:"View contact info",
  company_information:"Company Information",
  where_is_the_business_located:"Where is the Business located",
  i_want_to:"I want to:",
  get_hired:"Get hired",
  want_to_find_candidates_instead: "Do you want to find candidates instead?",
  find_candidates : "Find candidates",
  want_to_get_hired_instead: "Do you want to get hired instead?",
  click_here:	"Click here.",
  save_search:"Save filters to get daily or weekly emails of matching candidates.",
  save_filter_get_notified_of_new_matching_results:"Save filter and get notified of matching candidates.",
  do_you_want_to_receive_email_for_new_candidate_for_this_filter:"Do you want to receive email for new candidate for this filter",
  save_filter_as:"Save filter as",
  save_filter_successfully:"Filter saved successfully",
  save_filter_failed:"Filter was not saved, please try again.",
  saved_filters_plus_additional_filters_will_be_added_to_the_existing_filter:"Changes and updates will be added to this filter.",
  update_the_filter:"Update the filter?",
  saved_filter:"Save Filter",
  save_deleted_successfully:"Filter deleted successfully",
  search_name_already_exist:"Search Name already exist.",
  search_already_exist:"Search already exist.",
  filter_saved_successfully:"Filter saved successfully",
  daily:"Daily",
  weekly:"Weekly",
  delete:"Delete",
  manage:"Manage",
  please_select_a_candidate_to_proceed:"Please select a candidate to proceed",
  please_select_a_employer:"Please select a employer.",
  please_select_employers:"Please select Employers",
  send:"Send",
  saved_filters:"Saved Filters",
  name_should_be_less_than_60_characters:"Name should be less than 60 characters",
  search_name_cannot_be_empty:"Search name cannot be empty"
};
