import React,{useContext} from "react";
import { useTranslation } from "react-i18next";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import { Stack, Group } from "@mantine/core";
import styled from "@emotion/styled";
import { fontBlack } from "constants/colors";
import Button from "ui/buttons/Button";
import { useForm, yupResolver } from "@mantine/form";
import InputField from "ui/inputs/Inputfield";
import { PrivateContext, UserContext } from "context/state";
import * as Yup from "yup";

interface DrawerProps {
  show?: boolean;
  onHide?: any;
  getData?: any;
  passData?: any;
  title?: string;
  setAddClickedFor?:any
}
const Title = styled.div`
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
margin-top: 26px;
color: ${fontBlack};
max-width: 298px;
`

const StyleSpacing = styled.div`
  margin: 0px 0px 15px 0px;
  width: 100%;
  margin-bottom: 16px;
  @media only screen and (min-width: 1000px) {
    margin: 0px 0px 15px 0px;
  }
`;
const Wrapper = styled.div`
    margin:27px auto 77px auto;
`

const AddButtonWrapper = styled.div`
  margin-top: 0px;
  position: absolute;
  bottom: 20px;
  left:0;
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  z-index: 2000;
  text-align: center;
`;
const SocialMedaiDrawer: React.FC<DrawerProps> = ({ show, onHide, title, setAddClickedFor }) => {
  const { t } = useTranslation();
  const {socialMediaLinks, setSocialMediaLinks} = useContext(PrivateContext)
  const {userData} = useContext(UserContext)
  const regexForURL =/^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const schema = Yup.object().shape({
    facebook: Yup.string().matches(regexForURL,t("Enter_valid_Link")),
    instagram: Yup.string().matches(regexForURL,t("Enter_valid_Link")),
    linkedin: Yup.string().matches(regexForURL,t("Enter_valid_Link")),
    twitter: Yup.string().matches(regexForURL,t("Enter_valid_Link")),
  });
  const form = useForm({
    schema: yupResolver(schema),
    initialValues:{
        facebook:userData?.social_facebook_link ?? '',
        instagram:userData?.social_instagram_link ?? '',
        linkedin:userData?.social_linkedin_link ?? '',
        twitter:userData?.social_twitter_link ??''
    }
  })
  const handleSocialMediaSubmit = (values:any)=>{
    setSocialMediaLinks({
        facebook:values?.facebook,
        instagram:values?.instagram,
        linkedin:values?.linkedin,
        twitter:values?.twitter
    })
    setAddClickedFor()
  }
  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        setShow={onHide}
        showSearch={false}
        showClear={false}
        buttonText={t(`Add`)}
      >
        <form
          id="social-media-drawer"
          onSubmit={form.onSubmit((values) => handleSocialMediaSubmit(values))}
        >
          <Title>{t("Social_Media_Links")}</Title>
          <Wrapper>
            <StyleSpacing>
              <InputField
                id="facebook"
                placeholder="Facebook Link"
                width="100%"
                height="auto"
                {...form.getInputProps("facebook")}
              />
            </StyleSpacing>
            <StyleSpacing>
              <InputField
                id="twitter"
                placeholder="Twitter Link"
                width="100%"
                height="auto"
                {...form.getInputProps("twitter")}
              />
            </StyleSpacing>
            <StyleSpacing>
              <InputField
                id="linkedin"
                placeholder="LinkedIn Link"
                width="100%"
                height="auto"
                {...form.getInputProps("linkedin")}
              />
            </StyleSpacing>
            <StyleSpacing>
              <InputField
                id="instagram"
                placeholder="Instagram Link"
                width="100%"
                height="auto"
                {...form.getInputProps("instagram")}
              />
            </StyleSpacing>
            
            
          </Wrapper>
          <AddButtonWrapper>
            <Button
              type="submit"
              variant="filled"
              children={t("Save")}
              width="335px"
              height="54px"
            />
          </AddButtonWrapper>
        </form>
      </SearchAndSelectAbstraction>
    </Stack>
  );
};

export default SocialMedaiDrawer;
