import { Container as MantineContainer } from '@mantine/core';
import styled from "styled-components";


const Styles = styled.div`
    background: #F7F7F7;
    /* padding: 22px; */
    /* min-height:100vh ;
    height: auto; */

  @media only screen and (min-width: 1000px) {
    margin: 0;

  }
`
export default function Container(props: any) {
  return (
    <Styles>
      <MantineContainer {...props} />
    </Styles>
  )
} 
