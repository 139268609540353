const filterNewIndustries = (item:any) => {
    if (!item.name && item.headerName) {
        item.name = item.headerName
    }
    switch (item.name) {
        case 'None':
        case 'Żaden':
        case 'Ingen':
            return true;

        case 'Cleaning':
        case 'Sprzątanie':
        case 'Renhold':
            return true;

        case 'Hotel and restaurant':
        case 'Hotele i restauracje':
        case 'Hotell og restaurant':
            return true;

        default:
            return false;
    }
}

export default filterNewIndustries
