import React, { Dispatch, SetStateAction } from "react";
import styled from '@emotion/styled';
import { Box } from '@mantine/core';
import OtpInput from "react18-input-otp";


interface OTPInputFieldProps {
    setOTP: Dispatch<SetStateAction<string>>,
    otp: string,
};


const StyleOTP = styled.div`
   textarea:focus, input:focus{
    outline: none;
}
`;

const OTPInputField: React.FC<OTPInputFieldProps> = ({ setOTP, otp, }) => {

    let inputstyles = {
        background: '#FFFFFF',
        borderRadius: '12px',
        width: '65px',
        height: '54px',
        fontFamily: 'Poppins !important',
        fontStyle: 'normal',
        fonWeight: '400',
        fontSize: '28px',
        textAlign: 'center',
        padding: '0px',
        border: 'none',
        ':focus': {
            outline: 'none',
            border: 'none'
        },
        ':active': {
            outline: 'none',
            border: 'none'
        }
    }
    let containerStyles = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '14px'
    }

    

    return (
        <>
            <Box>
                <StyleOTP>
                    <OtpInput
                        containerStyle={containerStyles}
                        inputStyle={inputstyles}
                        value={otp}
                        isInputNum={true}
                        onChange={(otp: string) => setOTP(otp)} 
                        numInputs={4}
                    />
                </StyleOTP>
            </Box>
        </>
    );
}
export default OTPInputField;