import * as React from "react";

function OrangeEmail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 11" fill="none" {...props}>
      <path
        d="M12.6 0H1.4C.63 0 .007.619.007 1.375L0 9.625C0 10.381.63 11 1.4 11h11.2c.77 0 1.4-.619 1.4-1.375v-8.25C14 .619 13.37 0 12.6 0zm0 2.75L7 6.188 1.4 2.75V1.375L7 4.813l5.6-3.438V2.75z"
        fill="#FF3000"
      />
    </svg>
  );
}

const MemoOrangeEmail = React.memo(OrangeEmail);
export default MemoOrangeEmail;
