import React, { useContext, useEffect, useState, Dispatch, SetStateAction, ReactNode } from "react";
import CountrySearch from "helpers/search-select/CountrySearch";
import Drawer from "ui/data-display/Drawer";
import { FilterScreen } from "screens/home-screen/filter/FilterScreen";
import { IndustrySearch } from "helpers/search-select/IndustrySearch";
import LanguageSearch from "helpers/search-select/LanguageSearch";
import { PositionSearch } from "helpers/search-select/PositionSearch";
import { SelectDrivingLicense } from "helpers/search-select/SelectDrivingLicense";
import { PartTimeFullTimeSearch } from "helpers/search-select/PartTimeFullTimeSearch";
import { CountryStore } from "stores/taxnomies/country.store";
import { LanguageStore } from "stores/taxnomies/language.store";
import { TypeOfPostionStore } from "stores/taxnomies/type-of-postion.store";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { PositionStore } from "stores/taxnomies/position.store";
import { LicenseStore } from "stores/taxnomies/license.store";
import { UserContext } from "context/state";
import { useTranslation } from "react-i18next";
import isNone from "helpers/isNone";
import {Countries, Filters, Industries, Languages, Licenses, Positions, WorkType} from 'modules/imports/interfaces/interfaces'

interface filterProps{
  filterFooter?:ReactNode
  showFilter: boolean;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  filterUpdate: (values:Filters)=>void;
  currentSelectedFilters:Filters
  showSaveSearchFeatures?:boolean;
  filterSubmitButtonText?:string
  clearStates?:boolean
}



const Filter: React.FC<
  filterProps
> = ({
  showFilter,
  setShowFilter,
  filterUpdate,
  showSaveSearchFeatures,
  currentSelectedFilters={
    age: [18, 67],
    positions: [],
    industry: [],
    country: [],
    language: [],
    availability: [],
    workType: [],
    drivingLicense: [],
    preferredIndustryPosition: [],
  },
  filterFooter=<></>,
  filterSubmitButtonText='',
  clearStates=true
}) => {
  const { role, setSavedAge, userData,savedAge } = useContext(UserContext);
  //Reset State in Filter Reset
  const [resetData, setRestData] = useState(false);
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [showIndustrySearch, setShowIndustrySearch] = useState(false);
  const [showPositionSearch, setShowPositionSearch] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showLicense, setShowLicense] = useState(false);
  const [showWorkType, setShowWorkType] = useState(false);

  //Data Storing State
  const [countryData, setCountryData] = useState<Countries[]>([]);
  const [industryData, setIndustryData] = useState<Industries[]>([]);
  const [positionData, setPositionData] = useState<Positions[]>([]);
  const [language, setLanguage] = useState<Languages[]>([]);
  const [licenses, setLicenses] = useState<Licenses[]>([]);
  const [workType, setWorkType] = useState<WorkType[]>([]);

  // checked elements
  const [selectedCountries, setSelectedCountries] = useState<string[]>();
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>();
  const [selectedPositions, setSelectedPositions] = useState<string[]>();
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>();
  const [selectedLicenses, setSelectedLicenses] = useState<string[]>();
  const [whenCount, setWhenCount] = useState<string[]>();
  const [selectedWorkType, setSelectedWorkType] = useState<string[]>();

  const [filterData, setFilterData] = useState<Filters>({
    age: [18, 67],
    positions: [],
    industry: [],
    country: [],
    language: [],
    availability: [],
    workType: [],
    drivingLicense: [],
    preferredIndustryPosition: [],
  })

  //Function to get list of checked IDs
  const getCheckedIds = (data: any) => {
    let checkedIds: any = [];
    data?.forEach((objectData: any) => {
      objectData?.data?.forEach((checkboxData: any) => {
        if (checkboxData?.checked) {
          checkedIds.push(checkboxData.id);
        }
      });
    });
    return checkedIds;
  };

  //function sets all the selected items from taxonomies as checked
  //Note right now we are converting hashmaps to array lists and setting the states
  const setAllChecked = () => {
    if (
      Array.from(IndustryStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
        filterData?.industry?.length > 0
    ) {
      let industryVariable:Industries[] =[
        {
          headerName: "",
          data: Array.from(IndustryStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              filterData?.industry?.length != 0
                ? savedDataHandler(obj, filterData.industry)
                : false,
            show: true,
          })),
        },
      ]
      setIndustryData(industryVariable);
      updatePositionDataHandler(industryVariable);
    }
    if (
      Array.from(CountryStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      countryData.length == 0 &&
      filterData?.country?.length > 0
    ) {
      setCountryData([
        {
          headerName: "",
          data: Array.from(CountryStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              filterData?.country?.length != 0
                ? savedDataHandler(obj, filterData?.country)
                : false,
            show: true,
          })),
        },
      ]);
    }

    if (
      Array.from(LanguageStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
        filterData?.language?.length > 0
    ) {
      let languageVariable:Languages[] = [
        {
          headerName: "",
          data: Array.from(LanguageStore.data, ([id, name]) => ({
            id,
            name,
          })).filter((obj)=>!isNone(obj.name)).map((obj) => ({
            ...obj,
            checked:
              filterData?.language?.length != 0
                ? savedDataHandler(obj, filterData?.language)
                : false,
            show: true,
          })),
        },
      ]
      setLanguage(languageVariable);
    }
    if (
      Array.from(TypeOfPostionStore.data, ([id, name]) => ({ id, name }))
        .length != 0 &&
      workType.length == 0 &&
      filterData?.workType?.length > 0
    ) {
      setWorkType([
        {
          headerName: "",
          data: Array.from(TypeOfPostionStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              filterData?.workType?.length != 0
                ? savedDataHandler(obj, filterData?.workType)
                : false,
            show: true,
          })),
        },
      ]);
    }

    if (
      Array.from(LicenseStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      licenses.length == 0 &&
      filterData?.drivingLicense?.length > 0
    ) {
      let updatedLicenseData:Licenses[] = Array.from(LicenseStore.data, ([id, name]) => ({ id, name })).map(
        (vehicleType: any) => {
          let licenceData:Licenses = {
            headerName:'',
            data:[]
          };
          licenceData = {
            headerName: vehicleType?.name?.name,
            data: vehicleType?.name?.positions?.map((val: any) => {
              let updatedData: any = {
                id: val?.id,
                name: val?.name,
                checked:
                  filterData?.drivingLicense?.length != 0
                    ? savedDataHandler(val, filterData?.drivingLicense)
                    : false,
                show: true,
              };
              return updatedData;
            }),
          };
          return licenceData;
        }
      )
      updatedLicenseData= updatedLicenseData.filter((item:any)=> !isNone(item.headerName))
      setLicenses(updatedLicenseData);
    }
  };
  //to update count displayed on screen on first load
  useEffect(()=>{
    setAllChecked()
    setSavedAge(filterData.age);
  },[])

  // the following useEffects with filterData dependencies are used
  // to keep track of selected ids from taxonomies
  useEffect(() => {
    if (filterData?.country?.length > 0) setSelectedCountries(filterData?.country);
    else setSelectedCountries([])
  }, [filterData?.country]);

  useEffect(() => {
    if (filterData?.industry?.length > 0) {
      setSelectedIndustries(filterData?.industry)
      //the following code updates positions as per change in industry ids for filter
    }
    else {
      setSelectedPositions([]);
      setSelectedIndustries([]);
    }
    let industryVariable:Industries[] = [
      {
        headerName: "",
        data: Array.from(IndustryStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
          filterData?.industry?.length != 0 ? savedDataHandler(obj, filterData?.industry) : false,
          show: true,
        })),
      },
    ] 
    updatePositionDataHandler(industryVariable)
  }, [filterData?.industry]);

  useEffect(() => {
    if (filterData?.positions?.length > 0) {
      let positions = filterData?.positions?.filter(
        (val: any) => !filterData?.industry.includes(val)
      );
      setSelectedPositions(positions);
    } else {
      setSelectedPositions([]);
    }
  }, [filterData?.positions]);

  useEffect(() => {
    if (filterData?.language?.length > 0) setSelectedLanguages(filterData?.language);
    else setSelectedLanguages([])
  }, [filterData?.language]);

  useEffect(() => {
    if (filterData?.workType?.length > 0) setSelectedWorkType(filterData?.workType);
    else setSelectedWorkType([])
  }, [filterData?.workType]);
  useEffect(() => {
    if (filterData?.drivingLicense?.length > 0) setSelectedLicenses(filterData?.drivingLicense);
    else setSelectedLicenses([])
  }, [filterData?.drivingLicense]);

  //gets selected industry ids and updates respective industry filters
  useEffect(() => {
    if(industryData.length>0){let checkedids = getCheckedIds(industryData);
    setSelectedIndustries(checkedids);
    setFilterData((prevState: any) => ({ ...prevState, industry: checkedids }));
    if (checkedids?.length == 0 && industryData?.length > 0) {
      setPositionData([]);
      setFilterData((prevState: any) => ({
        ...prevState,
        positions: [],
        preferredIndustryPosition: [],
      }));
    }
    let industryVariable:Industries[] = [
      {
        headerName: "",
        data: Array.from(IndustryStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            checkedids?.length != 0 ? savedDataHandler(obj, checkedids) : false,
          show: true,
        })),
      },
    ];
    updatePositionDataHandler(industryVariable);}
  }, [industryData]);

  // this function handles dependency between selected industry and positions
  // whatever industries are selected in filter only those inudtries' positions are
  //shown under positions drawer for filters
  const updatePositionDataHandler = (industryVariable: Industries[]) => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData.map(
      (positionType: any) => {
        let Data = {};
        industryVariable[0]?.data?.map((item: any) => {
          if (item?.id == positionType?.id && item?.checked == true) {
            Data = {
              headerName: positionType?.name?.name,
              parentId: positionType?.id,
              data: positionType?.name?.positions?.map((val: any) => {
                let updatedData: any = {
                  parentId: positionType?.id,
                  id: val?.id,
                  name: val?.name,
                  checked: filterData?.positions.length
                    ? savedDataHandler(val, filterData?.positions)
                    : false,
                  show: true,
                };
                return updatedData;
              }),
            };
          }
        });
        return Data;
      }
    );
    let PositionStoreDataUpdatedFilter:any = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    positionData?.map((checkExistingData: any) => {
      PositionStoreDataUpdatedFilter?.map((positionDataFilter: any) => {
        if (checkExistingData.headerName == positionDataFilter.headerName) {
          positionDataFilter.data = checkExistingData.data;
        }
      });
    });
    setPositionData(PositionStoreDataUpdatedFilter);
  };
  const getCheckedPositions = (data: any) => {
    let checkedId: any = [];
    data?.forEach((objectData: any) => {
      let checkedData: boolean = false;
      let parentId = objectData?.parentId;
      objectData?.data?.forEach((checkboxData: any) => {
        if (parentId === undefined) parentId = checkboxData?.parentId ?? null;
        if (checkboxData.checked) {
          checkedData = true;
          checkedId.push(checkboxData?.id);
        }
      });
      if (!checkedData) {
        if (parentId) checkedId.push(parentId);
        checkedData = false;
      }
    });
    return checkedId;
  };
    // the following taxonomy state data updates selected position from states and sets filter Ids
    useEffect(() => {
      let checkedids = getCheckedIds(positionData);
      let industryPositions = getCheckedPositions(positionData);
      setSelectedPositions(checkedids);
      setFilterData((prevState: any) => ({
        ...prevState,
        positions: checkedids,
        preferredIndustryPosition: industryPositions,
      }));
    }, [positionData]);
  
    useEffect(() => {
      let checkedids = getCheckedIds(countryData);
      setFilterData((prevState: any) => ({ ...prevState, country: checkedids }));
    }, [countryData]);
  
    useEffect(() => {
      if(language.length>0){
        let checkedids = getCheckedIds(language);
      setFilterData((prevState: any) => ({ ...prevState, language: checkedids }));
      }
    }, [language]);
  
    useEffect(() => {
      let checkedids = getCheckedIds(licenses);
      setFilterData((prevState: any) => ({
        ...prevState,
        drivingLicense: checkedids,
      }));
    }, [licenses]);
  
    useEffect(() => {
      let checkedids = getCheckedIds(workType);
      setFilterData((prevState: any) => ({ ...prevState, workType: checkedids }));
    }, [workType]);

  //Reseting all Filter Data
  useEffect(() => {
    if (resetData) {
      setCountryData([]);
      setIndustryData([]);
      setPositionData([]);
      setLanguage([]);
      setLicenses([]);
      //setWhen([]);
      setWorkType([]);
      setSelectedCountries([]);
      setSelectedIndustries([]);
      setSelectedPositions([]);
      setSelectedLanguages([]);
      setSelectedLicenses([]);
      setWhenCount([]);
      setSelectedWorkType([]);
      //setFilterData({})
      setSavedAge([18, 67]);
      //filterUpdate()
      setShowFilter(true);
      setRestData(false)
      let data:Filters={
        age: [18, 67],
        positions: [],
        industry: [],
        country: [],
        language: [],
        availability: [],
        workType: [],
        drivingLicense: [],
        preferredIndustryPosition: [],
      }
      setFilterData(data)
      localStorage.setItem("filters",JSON.stringify(data))
    }
  }, [resetData]);

  //the function handles opening on closing of drawers based on filter selection
  const handleClick = (description: any) => {
    setShowFilter(false);
    switch (description) {
      case "1": {
        setShowCountrySearch(true);
        setShowIndustrySearch(false);
        setShowPositionSearch(false);
        setShowLanguage(false);
        setShowLicense(false);
        setShowWorkType(false);
        break;
      }
      case "2": {
        setShowCountrySearch(false);
        setShowIndustrySearch(true);
        setShowPositionSearch(false);
        setShowLanguage(false);
        setShowLicense(false);
        setShowWorkType(false);
        break;
      }
      case "3": {
        setShowCountrySearch(false);
        setShowIndustrySearch(false);
        setShowPositionSearch(true);
        setShowLanguage(false);
        setShowLicense(false);
        setShowWorkType(false);
        break;
      }
      case "4": {
        setShowIndustrySearch(false);
        setShowPositionSearch(false);
        setShowCountrySearch(false);
        setShowLanguage(true);
        setShowLicense(false);
        setShowWorkType(false);
        break;
      }
      case "5": {
        setShowIndustrySearch(false);
        setShowPositionSearch(false);
        setShowCountrySearch(false);
        setShowLanguage(false);
        setShowLicense(false);
        setShowWorkType(false);
        break;
      }
      case "6": {
        setShowIndustrySearch(false);
        setShowPositionSearch(false);
        setShowCountrySearch(false);
        setShowLanguage(false);
        setShowLicense(true);
        setShowWorkType(false);
        break;
      }
      case "7": {
        setShowIndustrySearch(false);
        setShowPositionSearch(false);
        setShowCountrySearch(false);
        setShowLanguage(false);
        setShowLicense(false);
        setShowWorkType(true);
        break;
      }
    }
  };
  const savedDataHandler = (data: any, savedData: any) => {
    let flag = false;
    if(savedData.includes(data.id)) flag= true
    return flag;
  };
  const { t } = useTranslation();

  //callback to parent api call function and sending selected values as props
  const callBackToParent=()=>{
    filterUpdate(filterData)
  }
  useEffect(()=>{
    setFilterData((prev:any)=>({...prev,age:savedAge}))
  },[savedAge])
  useEffect(()=>{
    setAllChecked()
    setSavedAge(currentSelectedFilters.age)
    setFilterData(currentSelectedFilters)
  },[currentSelectedFilters])
  const resetFilters =()=>{
    //resetting filters state only in saved filters
    //want to persist the selection in homescreen
    if(clearStates){
      let data:Filters={
        age: [18, 67],
        positions: [],
        industry: [],
        country: [],
        language: [],
        availability: [],
        workType: [],
        drivingLicense: [],
        preferredIndustryPosition: [],
      }
      setFilterData(data)
    }
  }
  return (
    <>
      <Drawer padding={1} position='right' onClose={() => {setShowFilter(false);resetFilters()}} opened={showFilter}>
        <FilterScreen
          setShowFilter={setShowFilter}
          handleClick={handleClick}
          onHide={() => {setShowFilter(false);resetFilters()}}
          countryDataCount={selectedCountries?.length}
          industryDataCount={selectedIndustries?.length}
          positionDataCount={selectedPositions?.length}
          languageCount={selectedLanguages?.length}
          liceneseCarCount={selectedLicenses?.length}
          whenCount={whenCount?.length}
          workTypeCount={selectedWorkType?.length}
          setRestData={ setRestData}
          filterUpdate={callBackToParent}
          showSaveSearchFeatures ={showSaveSearchFeatures}
          currentFilters={filterData}
          filterFooter={filterFooter}
          filterSubmitButtonText={filterSubmitButtonText}
        />
      </Drawer>
      <Drawer
        position='right'
        padding={"0"}
        onClose={() => {
          setShowCountrySearch(false);
          setShowFilter(true);
        }}
        opened={showCountrySearch}>
        <CountrySearch
          onHide={() => {
            setShowCountrySearch(false);
            setShowFilter(true);
          }}
          getData={setCountryData}
          passData={countryData}
          singleSelect={false}
          title={t("Where_is_the_job_located")}
          savedData={filterData?.country.length != 0 ? filterData?.country : []}
          bottomMargin='23px'
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position='right'
        onClose={() => {
          setShowIndustrySearch(false);
          setShowFilter(true);
        }}
        opened={showIndustrySearch}>
        <IndustrySearch
          onHide={() => {
            setShowIndustrySearch(false);
            setShowFilter(true);
          }}
          getData={setIndustryData}
          passData={industryData}
          title={t("What_industry_do_you_seek_employees")}
          savedData={filterData?.industry.length != 0 ? filterData?.industry : []}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position='right'
        onClose={() => {
          setShowPositionSearch(false);
          setShowFilter(true);
        }}
        opened={showPositionSearch}>
        <PositionSearch
          onHide={() => {
            setShowPositionSearch(false);
            setShowFilter(true);
          }}
          getData={setPositionData}
          passData={positionData}
          filterDataRef={industryData}
          dependent={true}
          title={t("What_position_need_to_beemployed")}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position='right'
        opened={showLanguage}
        onClose={() => setShowLanguage(false)}>
        <LanguageSearch
          passData={language}
          title={t("Required_language")}
          getData={setLanguage}
          onHide={() => {
            setShowLanguage(false);
            setShowFilter(true);
          }}
          savedData={
            filterData?.language?.length != 0
              ? filterData?.language
              : // : userData?.apply_filters == false
                // ? ["104"]
                []
          }
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position='right'
        onClose={() => setShowLicense(false)}
        opened={showLicense}>
        <SelectDrivingLicense
          title={t("Required_drivers_license")}
          onHide={() => {
            setShowLicense(false);
            setShowFilter(true);
          }}
          getData={setLicenses}
          passData={licenses}
          savedData={filterData?.drivingLicense.length != 0 ? filterData?.drivingLicense : []}
          showNone={false}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position='right'
        onClose={() => {
          setShowWorkType(false);
        }}
        opened={showWorkType}>
        <PartTimeFullTimeSearch
          title={t("What_position_need_to_be_employed")}
          onHide={() => {
            setShowWorkType(false);
            setShowFilter(true);
          }}
          getData={setWorkType}
          passData={workType}
          savedData={filterData?.workType.length != 0 ? filterData?.workType : []}
        />
      </Drawer>
    </>
  );
};

export default Filter;
