import React, { useContext, useEffect, useState } from "react";
import { createStyles, Stack, Title, Space } from "@mantine/core";
import Button from "ui/buttons/Button";
import InputField from "ui/inputs/Inputfield";
import Password from "ui/inputs/Password";
import MemoLockIcon from "ui/icons/LockIcon";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import PublicLayout from "layouts/PublicLayout";
import MemoEmail_icon from "ui/icons/Email_icon";
import MemoFacebook from "ui/icons/Facebook";
import MemoGoogle from "ui/icons/Google";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import BackButton from "ui/buttons/BackButton";
import SocialButton from "helpers/social/SocialButton";
import axios from "axios";
import GoogleLogin from "helpers/social/GoogleLogin";
import { createOTP, getUserDetails, login } from "services/user/User";
import { useTranslation } from "react-i18next";
import { UserContext } from "context/state";
import { ErrorModal } from "ui/modals/ErrorModal";
import authenticate from "helpers/authentication/authenticate";
import { parse } from "qs";
import { Helmet } from "react-helmet";
import Checkbox from "ui/inputs/Checkbox";
import { encode, decode } from "js-base64";

const FormContainer = styled.div`
  a:link {
    text-decoration: none;
  }
  /* padding-top: 5; */
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Heading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #123b3d;

  @media only screen and (min-width: 1000px) {
  }
`;
const Text = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6e6e6e;
  text-align: right;

  @media only screen and (min-width: 1000px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #6e6e6e;
    text-align: right;
  }
`;

const StyleWrapper = styled.div`
  margin: 5px;
  @media only screen and (min-width: 1000px) {
    min-width: 335px;
    max-width: 335px;
  }
`;
const StyleElement = styled.div`
  margin-top: 30px;
  @media only screen and (min-width: 1000px) {
  }
`;
const StyleInputField = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  @media only screen and (min-width: 1000px) {
  }
`;
const SocialText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #6e6e6e;
  margin-bottom: 30px;
  margin-top: 20px;
  @media only screen and (min-width: 1000px) {
  }
`;
const NewUserText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #123b3d !important;
  a:link {
    text-decoration: none;
  }

  @media only screen and (min-width: 1000px) {
    font-size: 18px;
  }
`;

const RememberMeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 21px;
`;

export default function LoginScreen() {
  const { t } = useTranslation();
  let history = useHistory();
  const [facebookData, setFacebookData] = useState<{ [key: string]: any }>({});
  const [googleData, setGoogleData] = useState<{ [key: string]: any }>({});
  const { role, setRole, setUserId, setFirstSession, setLoginType, loginType,setIsAdmin } =
    useContext(UserContext);
  const location = useLocation();
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const [phone, setPhone] = useState("");
  const [responseEmail, setResponseEmail] = useState("");
  const parsedParams = parse(location?.search.slice(1));

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_email_address"))
      .required(t("Please_enter_email_address")),
    password: Yup.string().required(t("Please_enter_password")),
  });

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    // if (location.pathname === "/login") setRole(() => "candidate");
    // else setRole(() => "employer");
    let getRememberMe = localStorage.getItem("rememberMe") ?? null;
    let rememberMeObject =
      getRememberMe !== undefined && getRememberMe !== null
        ? JSON.parse(getRememberMe)
        : null;
    if (
      rememberMeObject?.email &&
      rememberMeObject?.password &&
      rememberMeObject?.rememberMeCheck
    ) {
      form.setFieldValue("email", rememberMeObject?.email);
      form.setFieldValue("password", decode(rememberMeObject?.password));
      let rememberFlag = false
      if(rememberMeObject.rememberMeCheck){
        rememberFlag = rememberMeObject.rememberMeCheck
      }
      setRememberMe(rememberFlag);
    }
    setRole("");
  }, []);

  useEffect(() => {
    if (parsedParams?.verified) {
      // console.log("Email",parsedParams?.verified)
      setErrorText(t("Your_email_was_successfully_verified"));
      setShowError(true);
      // let stringifiedEmail = parsedUserDetails?.email.toString()
      // form.setFieldValue("email", stringifiedEmail);
    }
  }, [parsedParams]);
  useEffect(() => {
    //console.log("google", googleData)
    if (Object.keys(googleData).length !== 0) {
      let google = {
        email: googleData?.email,
        type: "google",
        access_token: googleData?.access_token,
      };
      setLoginType(google);
      handleLogin(google);
    }
  }, [googleData]);
  useEffect(() => {
    if (Object.keys(facebookData).length !== 0) {
      let facebook = {
        type: "facebook",
        ...facebookData,
      };
      setLoginType(facebook);
      handleLogin(facebook);
    }
  }, [facebookData]);
  useEffect(() => {}, [loginType]);
  const handleSocialLogin = (user: any) => {
    //console.log(user);
    let data = {
      access_token: user?._token?.accessToken,
    };
    setFacebookData(data);
  };

  const handleSocialLoginFailure = (err: any) => {
    console.error(err);
  };
  const handleBasicLogin = (values: any) => {
    let data = {
      email: values?.email,
      password: values?.password,
      type: "basic",
    };
    handleLogin(data);
  };

  const handleLogin = (data: any) => {
    let uid = undefined;
    if (authenticate()) {
      //const token = localStorage.getItem("token")
      const userJson = localStorage.getItem("User");
      let userobj = userJson ? JSON.parse(userJson) : "";
      let pathName = "";
      switch (userobj?.role) {
        case "employer":
          pathName = "/employer-home";
          break;
        case "candidate":
          pathName = "/home";
          break;
        case "administrator":
          pathName = "/admin-home";
          break;
        default:
          pathName = "/";
          break;
      }
      //setLoader(false)
      if(userobj?.role==="administrator"){
        setRole("employer") //setting role as employer as the account has employer privileges as well
        setIsAdmin(true)
      }
      else{
        setRole(userobj?.role);
      }
      history.push(pathName);
    } else {
      login(data)
        .then((response) => {
          if (response?.success) {
            type currentUserType={
              email:string
              emp_profile_completed:boolean
              first_time_login:boolean
              phone:string
              profile_status:boolean
              role:string
              status:number
              uid:string
              verification_status:boolean
            }
            const currentUser:currentUserType= response.data.current_user;
            uid = currentUser.uid;
            let loggedInUserRole = currentUser.role;
            setUserId(uid);
            let user = JSON.stringify({
              userId: uid,
              role: loggedInUserRole,
              token: response.data.jwt_token,
            });
            let rememberMeData = {
              rememberMeCheck: rememberMe,
              email: form.values.email,
              password: encode(form.values.password),
            };
            if (rememberMe) {
              localStorage.setItem(
                "rememberMe",
                JSON.stringify(rememberMeData)
              );
            } else {
              localStorage.removeItem("rememberMe");
            }
            localStorage.setItem("token", response.data.jwt_token);
            localStorage.setItem("User", user);
            setFirstSession(currentUser.first_time_login);
            if(loggedInUserRole === "administrator"){
              setRole("employer") //setting role as employer as the account has employer privileges as well
              setIsAdmin(true)
            }
            else{
            setRole(loggedInUserRole);
            }
            switch (true) {
              case currentUser.profile_status &&
                loggedInUserRole === "candidate": {
                history.push({ pathname: "/home", state: { uid } });
                break;
              }
              case currentUser.emp_profile_completed &&
                loggedInUserRole === "employer": {
                history.push({ pathname: "/employer-home", state: { uid } });
                break;
              }
              case !currentUser.profile_status &&
                loggedInUserRole === "candidate":
              case !currentUser.emp_profile_completed &&
                loggedInUserRole === "employer":
                history.push({ pathname: "/profile-creation", state: { uid } });
                break;
              case loggedInUserRole === "administrator": {
                history.push({ pathname: "/admin-home", state: { uid } });
                break;
              }
              default:
                history.push("/login")
                break;
            }
          } else {
            if (response.message) {
              if (response.response.data.message) {
                setErrorText(response.response.data.message[0]);
                setShowError(true);
              } else {
                setErrorText(response.message);
                setShowError(true);
              }
            }
            if (response.response.data.data.phone) {
              setPhone(response.response.data.data.phone);
              if (response.response.data.data.email)
                setResponseEmail(response.response.data.data.email);
              setErrorText(t("Your_account_is_not_verified"));
              setShowError(true);
            }
            //localStorage.clear()
          }
        })
        .catch((error) => {
          setErrorText(t('default_error_message'))
          setShowError(true)
        });
    }
  };

  const handleErrorModalClose = () => {
    if (phone && responseEmail) {
      let data = {
        phone: phone,
      };
      let queryEmail =
        form.values.email != "" ? form.values.email : responseEmail;
      //createOTP(data)
      history.push({
        pathname: "/create-account-verify-email",
        state: {
          //phone: phone,
          email: queryEmail,
          password: form.values.password,
        },
      });
    }
    setShowError(false);
    if (parsedParams?.verified) {
      history.push("/login");
    }
  };
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>Jobpark - Job Opportunities at your Fingertips</title>
        <meta
          name="description"
          content="Jobpark is your source for jobs and career opportunities. Search for jobs, get career adivice and get hired in minutes."
        />
        <link rel="canonical" href="https://app.jobpark.com/login" />
      </Helmet>
      <PublicLayout position="top">
        <FormContainer>
          <StyleWrapper>
            <ErrorModal
              opened={showError}
              message={errortext}
              onClose={() => handleErrorModalClose()}
            />
            <div style={{ width: "fit-content" }}>
              <Link to="/">
                <BackButton goBackStatus={true}> {t("Back")}</BackButton>
              </Link>
            </div>
            <Stack spacing={0} style={{ marginTop: "25px" }}>
              <Heading>{t("Login")}</Heading>
              <form
                onSubmit={form.onSubmit((values) => handleBasicLogin(values))}
              >
                <Stack spacing={0}>
                  <StyleInputField style={{ marginBottom: "20px" }}>
                    <InputField
                      id="email"
                      placeholder={t("Email_Address")}
                      icon={<MemoEmail_icon width="19.23" height="17.12px" />}
                      {...form.getInputProps("email")}
                    />
                  </StyleInputField>
                  <Password
                    icon={<MemoLockIcon width="18px" height="23.4px" />}
                    id="password"
                    placeholder={t("Password")}
                    {...form.getInputProps("password")}
                  />

                  <RememberMeWrapper>
                    <Checkbox
                      width="100%"
                      height="auto"
                      id="remember-me"
                      paddingBottom="0px"
                      paddingLeftLabel="19px"
                      checked={rememberMe}
                      fontSizeLabel="14px"
                      label={t("Remember_me")}
                      onChange={() => setRememberMe(!rememberMe)}
                    />

                    <Text>
                      <Link to="/forgot-password">{t("Forgot_Password")}</Link>
                    </Text>
                  </RememberMeWrapper>

                  <Button
                    type="submit"
                    fontWeight={700}
                    fontSize="18px"
                    lineHeight="110%"
                    variant="filled"
                  >
                    {t("Log_in")}
                  </Button>
                  {/* <RememberMeWrapper>
                    <Checkbox
                      width='100%'
                      height='auto'
                      id='remember-me'
                      checked={rememberMe}
                      fontSizeLabel='14px'
                      label={t("Remember_me")}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                  </RememberMeWrapper> */}
                </Stack>
              </form>
              <Stack spacing={10} sx={{ marginTop: "40px" }}>
                {/* <SocialText>
                  <StyleElement style={{ marginTop: "20px" }}>
                    - {t("Or_Continue_with")} -
                  </StyleElement>
                  <StyleElement>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <GoogleLogin setData={setGoogleData} />
                      <Space w='lg' />
                      <SocialButton
                        provider='facebook'
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        logo='facebook'
                      />
                    </div>
                  </StyleElement>
                </SocialText> */}
                <Link to="/">
                  <NewUserText style={{ marginTop: "40px" }}>
                    <div>
                      {t("New_User_Create_Account")}
                      <div style={{ display: "inline", fontWeight: "600" }}>
                        {t("Create_Account")}
                      </div>
                    </div>
                  </NewUserText>
                </Link>
              </Stack>
            </Stack>
          </StyleWrapper>
        </FormContainer>
      </PublicLayout>
    </>
  );
}
