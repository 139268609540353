import React, { useContext } from "react";
import JobParkLogoTransparent from "ui/icons/JobParkLogoTransparent";
import { Stack } from "@mantine/core";
import Button from "ui/buttons/Button";
import PublicLayout from "layouts/PublicLayout";
import BackButton from "ui/buttons/BackButton";
import { useHistory } from "react-router-dom";
// import styled from 'styled-components'
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { UserContext } from "context/state";

const Heading = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
  }
`;
const Container = styled.div`
  width: "100%";
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyleWrapper = styled.div`
  margin: 0px;
  @media only screen and (min-width: 1000px) {
    max-width: 335px;
  }
`;
const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
  }
`;
const StyleButton = styled.div`
  position: fixed;
  bottom: 20px;
  left: auto;
  width: 90%;
  @media only screen and (min-width: 1000px) {
    position: relative;
    bottom: auto;
    width: 100%;
    top: 20px;
  }
`;
export default function CheckEmail() {
  let history = useHistory();
  const { t } = useTranslation();
  const { role } = useContext(UserContext);
  const path = "/login";
  return (
    <>
      <PublicLayout>
        <Container>
          <StyleWrapper>
            <BackButton> {t("Back")}</BackButton>
            <Stack spacing={0} style={{ marginTop: "25px" }}>
              <Heading>{t("Check_your_email")}</Heading>
              <Text>{t("We_have_sent_password_recovery_instructions_to_your_email")}</Text>
            </Stack>
            <StyleButton>
              <Button
                onClick={() => {
                  history.push(path);
                }}
                color='#FFFFFF'
                fontWeight={700}
                fontSize='18px'
                lineHeight='100%'
                variant='filled'>
                {t("Okay")}
              </Button>
            </StyleButton>
          </StyleWrapper>
        </Container>
      </PublicLayout>
    </>
  );
}
