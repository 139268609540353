import { Box, Divider, Text, Title } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { privacyPolicy, termsOfUse } from "constants/links";

const Header = styled(Title)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #123B3D;
  @media only screen and (min-width: 1000px) {
    margin: 40px 0 0 0;
  }
`;

const CustomText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  color: #123B3D;
  margin: 15px 0 20px 0;
  @media only screen and (min-width: 1000px) {
  }
`;
const CustomTextBold = styled.div`
  /* float: right; */
  display: inline;
  margin-left: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0px 0 0px;
  color: #123B3D;
  &:hover {
    color: #ff3000;
  }
`;
const StyleDivider = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 7px 0 7px;
  margin: 0 7px 0 0;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0px 0 0px;
  color: #123B3D;
  &:hover {
    color: #ff3000;
  }
`


function Support() {
  const { t } = useTranslation();
  return (
    <Box style={{ marginLeft: "15px", marginRight: "15px" }}>
      <Header>{t("Support")}</Header>
      <CustomText>
        {t("Get_in_touch_contact")}
        <CustomTextBold>{"contact@jobpark.com"}</CustomTextBold>
      </CustomText>
      <Box
        sx={{
          display: "flex",
          height: "auto",
          justifyContent: "left",
          margin: "0 0 0 -6px",
        }}>
        <StyleDivider>
          <StyledAnchor href={privacyPolicy} target='_blank'>{t("Privacy_Policy")}</StyledAnchor>
        </StyleDivider>
        {/* <Divider color={'rgba(0, 0, 0, 0.1)'} orientation={'vertical'} sx={{ height: 'auto' }} size={'sm'} /> */}
        {/* <StyledLink to={"/termsofuse"}>{t("Terms_of_use")}</StyledLink> */}
        <StyledAnchor href={termsOfUse} target='_blank'>{t("Terms_of_use")}</StyledAnchor>
      </Box>
    </Box>
  );
}

export default Support;
