import React, { ReactNode, useContext } from "react";
// import JobParkLogoTransparent from 'ui/icons/JobParkLogoTransparent'
import JobParkLogoTransparent from "assets/images/JobPark-logo.png";
import { Text, createStyles } from "@mantine/core";
import HERO_IMAGE from "assets/images/JobPark_Hero_img.jpg";
import Container from "ui/layout/Container";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { UserContext } from "context/state";
import { useTranslation } from "react-i18next";
import Jobseeker from "assets/images/Jobseeker.jpg";
import Employer from "assets/images/Employer.jpg";

const LeftPane = styled.div`
  margin: 0;
  background-color: #f7f7f7;
  height: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (min-width: 1000px) {
    float: left;
    position: relative;
    height: 100vh;
    width: 44%;
    min-width: 400px;
    background: #f7f7f7;
  }
`;
const RightPane = styled.div<{backgroundX:string}>`
  margin: 0;
  position: relative;
  width: 100%;
  background: rgba(196, 196, 196, 0.2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  mix-blend-mode: darken;
  overflow: auto;

  @media only screen and (min-width: 1000px) {
    /* position: relative; */
    height: 100vh;
    width: 56%;
    margin: 0;
    background-size: cover;
    background-color: rgba(196, 196, 196, 0.2);
    background-position-x: ${props=> props.backgroundX ? props?.backgroundX :"45%"};
  }
`;
const LogoProfile = styled.div`
  position: relative;
  border-radius: 0px;
  margin: 17px 0 0 0;
  @media only screen and (min-width: 1000px) {
    position: relative;
    width: 550px;
    margin: 30px auto 0 83px;
  }
  @media only screen and (min-width: 1920px) {
    width: 615px;
    margin-left: 22%;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    width: 590px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1439px) {
    width: 585px;
  }
`;
const Logo = styled.div`
  position: relative;
  border-radius: 0px;
  margin: 17px 0 0 0;
  @media only screen and (min-width: 1000px) {
    position: absolute;
    width: auto;
    /* margin: 30px 0 0 13%; */
  }
  @media only screen and (min-width: 2251px) and (max-width: 4000px) {
    position: absolute;
    width: auto;
    margin: 30px 0 0 15%;
  }
  @media only screen and (min-width: 1920px) and (max-width: 2250px) {
    position: absolute;
    width: auto;
    margin: 30px 0 0 22%;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    position: absolute;
    width: auto;
    margin: 30px 0 0 14%;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1439px) {
    position: absolute;
    width: auto;
    margin: 30px 0 0 10%;
  }
`;

const StyleWrapperCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;
const StyleLogoImage = styled.img`
  width: 106px;
  height: 28px;
  margin-left:5px;
  cursor: pointer;
  @media only screen and (min-width: 1000px) {
    width: 112px;
    height: 30px;
  }
`;
interface props {
  editprofile?: boolean;
}
const StyleWrapperTop = styled.div<props>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    position: relative;
    top: ${(p) => (p?.editprofile ? "30px" : "100px")};
    height: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
interface element {
  height: string;
}
const StyleWrapperElement = styled.div<element>`
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    /* min-width: 80%; */
    height: ${(p) => p?.height};
  }
`;

const IntroText = styled.h1`
  width: 429px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  position: absolute;
  left: 10%;
  bottom: 5%;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
`;

interface PublicLayoutProps {
  children: ReactNode;
  backgroundImg?: any;
  position?: "top" | "center";
  dontShowRightPane?: boolean;
  viewType?: string;
}
const PublicLayout: React.FC<PublicLayoutProps> = ({
  backgroundImg,
  children,
  position = "top",
  dontShowRightPane = false,
  viewType = "login",
}) => {
  const history = useHistory();
  const {
    role,
    showDropdown,
    setShowDropdown,
    showSelectDay,
    setShowSelectDay,
    showSelectMonth,
    setShowSelectMonth,
    showSelectYear,
    setShowSelectYear,
  } = useContext(UserContext);
  const { t } = useTranslation();
  let bannerImage = undefined;
  if (role === "employer") {
    bannerImage = Employer;
  } else {
    bannerImage = Jobseeker;
  }
  return (
    <>
      <LeftPane
        onClick={(e: any) => {
          //For closing the DropDown when click on Left Wrapper
          if (showDropdown) setShowDropdown(false);
          if (showSelectDay) setShowSelectDay(false);
          if (showSelectMonth) setShowSelectMonth(false);
          if (showSelectYear) setShowSelectYear(false);
        }}>
        <Container>
          {viewType == "profile" ? (
            <LogoProfile>
              <StyleLogoImage
                src={JobParkLogoTransparent}
                onClick={() =>  history.push("/")}
              />
            </LogoProfile>
          ) : (
            <Logo>
              <StyleLogoImage
                src={JobParkLogoTransparent}
                onClick={() =>  history.push("/")}
              />
            </Logo>
          )}
          {/* <LogoProfile onClick={() => role === "candidate" ? history.push('/') : history.push('/')}>
            <img src={JobParkLogoTransparent} width="106px" height="28px" />
          </LogoProfile> */}
          {position == "center" ? (
            <StyleWrapperCenter>
              <StyleWrapperElement height={viewType == "profile" ? "100%" : "auto"}>
                {children}
              </StyleWrapperElement>
            </StyleWrapperCenter>
          ) : (
            <StyleWrapperTop editprofile={viewType == "profile" ? true : false}>
              <StyleWrapperElement height={viewType == "profile" ? "100%" : "auto"}>
                {children}
              </StyleWrapperElement>
            </StyleWrapperTop>
          )}
        </Container>
      </LeftPane>
      {dontShowRightPane ? (
        <></>
      ) : (
        <RightPane
          style={{
            backgroundImage: backgroundImg ? `url(${backgroundImg})` : `url(${bannerImage})`,
            backgroundSize: backgroundImg ? "56% auto" : "cover",
            backgroundPosition: backgroundImg ? "center bottom" : "",
          }}
          backgroundX={role ==='employer'? '20%': '45%'}
          >
          {!backgroundImg && (
            <IntroText>
              {t("We_provide_the_best_solution_to_fill_the_workplace_with_the_right_people")}
            </IntroText>
          )}
        </RightPane>
      )}
    </>
  );
};

export default PublicLayout;
