import React, { ReactNode, useContext, useState } from "react";
import { Modal, Stack } from "@mantine/core";
import styled from "styled-components";
import { Grid } from "@mantine/core";
import Button from "ui/buttons/Button";
import { Box } from "@mantine/core";
import { Text } from "@mantine/core";
import ThumbsUp from "ui/icons/ThumbsUp.png";
import MemoFavouriteIcon from "ui/icons/FavouriteIcon";
import MemoChat from "ui/icons/ChatIcon";
import MemoFilterIcon from "ui/icons/Filter";
import { useTranslation } from "react-i18next";
import { UserContext } from "context/state";
import MemoOrangeSetting from "ui/icons/OrangeSetting";
import OrangeSetting from "assets/images/OrangeSetting.png";
import FilterIcon from "assets/images/FilterIcon.png";

interface SearchModalProps {
  children?: ReactNode;
  show?: any;
  onHide?: any;
  type?: number;
  showFilter?: any;
}

const StyleModalContainer = styled(Grid)``;

const StyleModalContent = styled.div`
  max-height: 150px;
  justify-content: center;
  align-items: stretch;
  margin: auto;
  text-align: center;
`;
const StyleModalContentMobile = styled(Box)`
  justify-content: center;
  align-items: left;
  margin: auto;
  text-align: center;
`;
const StyleThembsUpPNG = styled.img`
  width: 70px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 0 auto;
`;
const StyleMessage = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #123b3d;
  /* line-height: 30px; */
  @media only screen and (min-width: 1000px) {
    font-weight: 600;
  }
`;
const StyleText = styled.p`
  font-family: "Poppins";
  /* size: md; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #123b3d;
  margin-bottom: 30px;
  /* margin-top: -20px; */
  max-width: 300px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    font-weight: 400;
    max-width: 410px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
  }
`;

const StyleMainDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  /* size: md; */
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #6e6e6e;
  margin-bottom: 52px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    max-width: 295px;
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
const StyleDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  size: md;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
    max-width: 217px;
    margin: auto;
  }
`;
const StyleLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #123b3d;
`;
const StylContainerDesktop = styled.div`
  display: none;
  padding: 20px;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;
const StylContainerMobile = styled.div`
  padding: 0 15px 0 0;
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;
const StyleEmployerText = styled.p`
  font-family: "Poppins";
  /* size: md; */
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #6e6e6e;
  margin-bottom: 30px;
  margin-top: -10px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    max-width: 300px;
  }
`;
interface wrapperprop {
  backgroundColor?: string;
  borderRadius?: string;
  height?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
  role?: number;
  paddingTop?:string
}
const Wrapper = styled.div<wrapperprop>`
  background-color: ${(prop) => prop?.backgroundColor};
  border-radius: ${(prop) => prop?.borderRadius};
  height: ${(prop) => (prop?.role == 2 ? "fit-content" : "auto")};
  display: ${(prop) => prop?.display};
  align-items: ${(prop) => prop?.alignItems};
  justify-content: ${(prop) => prop?.justifyContent};
  width: ${(prop) => prop?.width};
  padding-top: ${(prop) => prop?.paddingTop };
  padding-bottom: ${(prop) => prop?.paddingTop };
  @media only screen and (min-width: 1000px) {
    height: ${(prop) => prop?.height};
    padding-top: 0;
    padding-bottom: 0;
  }
  @media only screen and (max-height: 730px) {
    height: ${(prop) => (prop?.role == 2 ? "fit-content" : "auto")};
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const SearchModalData = (type: any, t: any, handleModal: any) => {
  return (
    <Wrapper
      backgroundColor={type == 2 ? "#FFFFFF" : ""}
      borderRadius={type == 2 ? "20px" : ""}
      height={type == 2 ? "100%" : "auto"}
      display={type == 2 ? "flex" : "block"}
      alignItems={type == 2 ? "center" : ""}
      justifyContent={type == 2 ? "center" : ""}
      width={type == 2 ? "100%" : "auto"}
      paddingTop={type == 2 ? "10px" : "0"}
      role={type}>
      <div style={{ width: type == 2 ? "700px" : "auto" }}>
        <div>
          {/* <StyleModalContainer columns={10} style={{ paddingBottom: '0px' }}>
                    <StyleModalContainer.Col span={2}></StyleModalContainer.Col>
                    <StyleModalContainer.Col span={6}> */}
          <StyleThembsUpPNG src={ThumbsUp} />
          <StyleMessage>{t("Congratulations")}</StyleMessage>
          {type == 2 ? (
            <>
              <StyleText>
                {t("Welcome_to_JobPark")} <br /> {t("How_it_works")}
              </StyleText>
            </>
          ) : (
            <StyleEmployerText>
              {t("You_are_now_ready_to_find_you_first_candidates")}
            </StyleEmployerText>
          )}
          {/* <StyleText > {type == 2 ? t("Welcome_to_JobPark") : t("You_are_now_ready_to_find_you_first_candidates")}</StyleText> */}
          {type == 2 && (
            <StyleMainDescription>
              {t("You_are_now_registered_and_your_profile_will_be_displayed_for_employers")}
            </StyleMainDescription>
          )}
          {/* </StyleModalContainer.Col>
                    <StyleModalContainer.Col span={2}></StyleModalContainer.Col>
                </StyleModalContainer> */}
        </div>
        <StylContainerDesktop>
          <StyleModalContainer columns={type == 2 ? 2 : 3} align='center'>
            {type === 1 && (
              <>
                <StyleModalContainer.Col span={1}>
                  <StyleModalContent>
                    <img
                      src={FilterIcon}
                      alt='filter'
                      width='50px'
                      height='50px'
                      style={{ marginBottom: "-15px", padding: 0 }}
                    />
                    <StyleLabel>{t("Filter")}</StyleLabel>
                    <StyleDescription>
                      {t("Refine_your_search_with_extra_filters_to_pinpoint_the_people_you_need")}
                    </StyleDescription>
                  </StyleModalContent>
                </StyleModalContainer.Col>

                <StyleModalContainer.Col span={1}>
                  <StyleModalContent>
                    <MemoFavouriteIcon size='50px' style={{ marginBottom: "-20px" }} />
                    <StyleLabel>{t("Favourites")}</StyleLabel>
                    <StyleDescription>
                      {t("Find_the_talent_you_have_saved_organize_them_and_hire")}
                    </StyleDescription>
                  </StyleModalContent>
                </StyleModalContainer.Col>
              </>
            )}
            <StyleModalContainer.Col span={1}>
              <StyleModalContent>
                <MemoChat size='50px' style={{ marginBottom: "-20px" }} />
                <StyleLabel>{t("Chat")}</StyleLabel>
                <StyleDescription>
                  {type == 2
                    ? t("Youll_find_all_your_messages")
                    : t("Find_all_your_messages_in_one_place")}
                </StyleDescription>
              </StyleModalContent>
            </StyleModalContainer.Col>
            {type == 2 && (
              <StyleModalContainer.Col span={1}>
                <StyleModalContent>
                  <img
                    src={OrangeSetting}
                    alt='setting'
                    width='50px'
                    height='50px'
                    style={{ marginBottom: "-20px", padding: 0 }}
                  />
                  <StyleLabel>{t("Setting")}</StyleLabel>
                  <StyleDescription>{t("Use_settings_to_change_your_profile")}</StyleDescription>
                </StyleModalContent>
              </StyleModalContainer.Col>
            )}
          </StyleModalContainer>
        </StylContainerDesktop>

        <StylContainerMobile>
          <StyleModalContainer columns={type == 2 ? 2 : 3}>
            {type == 1 && (
              <>
                <StyleModalContainer.Col sm={1}>
                  <StyleModalContentMobile>
                    <StyleModalContainer columns={10}>
                      <StyleModalContainer.Col span={4}>
                        <div style={{ position: "relative", top: "30%" }}>
                          <img
                            src={FilterIcon}
                            alt='filter'
                            width='50px'
                            height='50px'
                            style={{ padding: 0 }}
                          />
                        </div>
                      </StyleModalContainer.Col>
                      <StyleModalContainer.Col span={6}>
                        <StyleLabel style={{ textAlign: "left", marginBottom: "0px" }}>
                          {t("Filter")}
                        </StyleLabel>
                        <StyleDescription style={{ textAlign: "left" }}>
                          {t(
                            "Refine_your_search_with_extra_filters_to_pinpoint_the_people_you_need"
                          )}
                        </StyleDescription>
                      </StyleModalContainer.Col>
                    </StyleModalContainer>
                  </StyleModalContentMobile>
                </StyleModalContainer.Col>

                <StyleModalContainer.Col sm={1}>
                  <StyleModalContentMobile>
                    <StyleModalContainer columns={10}>
                      <StyleModalContainer.Col span={4}>
                        <div style={{ position: "relative", top: "30%" }}>
                          <MemoFavouriteIcon size='50px' />
                        </div>
                      </StyleModalContainer.Col>
                      <StyleModalContainer.Col span={6}>
                        <StyleLabel style={{ textAlign: "left", marginBottom: "0px" }}>
                          {t("Favourites")}
                        </StyleLabel>
                        <StyleDescription style={{ textAlign: "left" }}>
                          {t("Find_the_talent_you_have_saved_organize_them_and_hire")}
                        </StyleDescription>
                      </StyleModalContainer.Col>
                    </StyleModalContainer>
                  </StyleModalContentMobile>
                </StyleModalContainer.Col>
              </>
            )}
            <StyleModalContainer.Col sm={1}>
              <StyleModalContentMobile>
                <StyleModalContainer columns={10}>
                  <StyleModalContainer.Col span={4}>
                    <div style={{ position: "relative", top: "30%" }}>
                      <MemoChat size='50px' />
                    </div>
                  </StyleModalContainer.Col>
                  <StyleModalContainer.Col span={6}>
                    <StyleLabel style={{ textAlign: "left", marginBottom: "0px" }}>
                      {t("Chat")}
                    </StyleLabel>
                    <StyleDescription style={{ textAlign: "left" }}>
                      {type == 2
                        ? t("Youll_find_all_your_messages")
                        : t("Find_all_your_messages_in_one_place")}
                    </StyleDescription>
                  </StyleModalContainer.Col>
                </StyleModalContainer>
              </StyleModalContentMobile>
            </StyleModalContainer.Col>
            {type == 2 && (
              <StyleModalContainer.Col sm={1}>
                <StyleModalContentMobile>
                  <StyleModalContainer columns={10}>
                    <StyleModalContainer.Col span={4}>
                      <div style={{ position: "relative", top: "30%" }}>
                        <img src={OrangeSetting} alt='setting' width='50px' height='50px' />
                      </div>
                    </StyleModalContainer.Col>
                    <StyleModalContainer.Col span={6}>
                      <StyleLabel style={{ textAlign: "left", marginBottom: "0px" }}>
                        {t("Setting")}
                      </StyleLabel>
                      <StyleDescription style={{ textAlign: "left" }}>
                        {t("Use_settings_to_change_your_profile")}
                      </StyleDescription>
                    </StyleModalContainer.Col>
                  </StyleModalContainer>
                </StyleModalContentMobile>
              </StyleModalContainer.Col>
            )}
          </StyleModalContainer>
        </StylContainerMobile>
        {type == 1 && (
          <Button
            style={{
              wordWrap: "break-word",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              minWidth: "300px",
              marginBottom: "50px",
              marginTop: "50px",
            }}
            variant='filled'
            children={type == 2 ? t("Lets_Go") : t("Start_Filtering")}
            height='50px'
            fontSize='16px'
            width='fit-content'
            onClick={() => {
              handleModal();
            }}
          />
        )}
      </div>
    </Wrapper>
  );
};
export const SearchModal: React.FC<SearchModalProps> = ({
  type, // 1 - employee & 2 - candidate
  children,
  show,
  onHide,
  showFilter,
}) => {
  const { t } = useTranslation();
  const { role } = useContext(UserContext);

  const handleModal = () => {
    onHide();
    if (role === "employer") showFilter();
  };

  return (
    <>
      <Modal
        centered
        opened={show}
        onClose={() => onHide}
        overlayOpacity={0.55}
        size={800}
        overlayBlur={5}
        styles={{
          inner:{'@media only screen and (min-height: 790px)' :{
            overflowY: 'hidden',
            height:'fit-content'
          }},
          modal: {
            borderRadius: "20px",
            height: "auto",
            background: "#FFFFFF",
            padding: 0,
          },
          close: { display: "none" },
          title: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}>
        {SearchModalData(type, t, handleModal)}
      </Modal>
    </>
  );
};
