import {  Box, Divider, Text, Title } from "@mantine/core";
import styled from "styled-components";
import RightSideBarSkeleton from "ui/data-display/RightSideBarSkeleton";
import CompanyLogo from "assets/images/CompanyLogo.png";
import MemoPhoneVerified from "ui/icons/PhoneVerified";
import MemoEmailVerified from "ui/icons/EmailVerified";
import MemoFb from "ui/icons/Fb";
import MemoLinkedin from "ui/icons/Linkedin";
import MemoInsta from "ui/icons/Insta";
import MemoTwitter from "ui/icons/Twitter";
import Button from "ui/buttons/Button";
import MemoChatNow from "ui/icons/ChatNow";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { CountryStore } from "stores/taxnomies/country.store";
import { useTranslation } from "react-i18next";
import { getValidUrl } from "./appendhttps";
import MemoGreyPhone from "ui/icons/GreyPhone";
import MemoOrangeEdit from "ui/icons/OrangeEdit";
import { fontBlack } from "constants/colors";
import DataCard from "ui/data-display/DataCard";

const ProfilePictureContainer = styled(Box)`
  margin-top: -52px;
  display: block;
  background: transparent;
  height: 88px;
`;
const NameAgeWrapper = styled(Box)`
  display: block;
  position: relative;
  margin-left: 125px;
  font-family: Poppins;
  font-size: 16px;
`;
const Name = styled(Box)`
  color: #fff;
  padding: 0 0 18px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  height: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 250px;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    line-height: 18px;
    max-width: 335px;
  }
`;
const Age = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */
  padding: 6px 0;
  color: #6e6e6e;
`;
const LanguageExperienceCountryWrapper = styled(Box)`
  display: flex;
  /* justify-content:space-around; */
  position: relative;
  padding-top: 20px;
`;

const TypographyTitle = styled(Box)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6e6e;
`;

const TypographyValue = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #123b3d;
  overflow-wrap: break-word;
`;

const VerifiedWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: auto;
  padding: 34px 0 6px 0px;
  gap: 11px;
  flex-wrap: wrap;
`;

const EmailPhoneWrapper = styled(Box)`
  min-width: fit-content;
  min-height: 26px;
  height: fit-content;
  width: fit-content;
  background: #ffffff;
  border-radius: 30px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #4caf50;
  padding: 0 11px 0 11px;
  display: flex;
  justify-content: center;
`;

const StyledTitle = styled(Title)`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #123b3d;
  margin: 30px 0 19px 0px;
`;
const CustomText = styled(Text)`
  font-family: "Poppins";
  font-weight: 400;
  color: #6e6e6e;
  font-size: 12px;
  margin: 0 5px 0 20px;
`;
const StyleProfile = styled(Box)`
  position: absolute;
  width: 88px;
  height: 88px;
  margin: 0 12px 0 25px;
  background-color: #fff;
  border-radius: 15px;
`;

const StyleAvatar = styled.img`
  position: absolute;
  width: 88px;
  height: 88px;
  border-radius: 15px;
  object-fit: contain;
`;

const StyleIndicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: #c4c4c4;
  border: 2px solid #ffffff;
  border-radius: 50%;
  bottom: 0px;
  right: -8px;
`;

const NotVerifiedText = styled.text<{ addMargin?: boolean | undefined }>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-right: ${(props) => (props?.addMargin ? "19px" : "0px")};
  color: #838383;
  @media only screen and (min-width: 1000px) {
    margin-right: ${(props) => (props?.addMargin ? "44px" : "0px")};
  }
`;
const VerifyText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ff5500;
  margin-left: 9px;
`;

const VerificationWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LinkWrapper =styled.div`
  margin: 20px 0 0 0;
`
const CustomLabel =styled.div`
  font-family: "Poppins";
  font-weight: 600;
  color: ${fontBlack};
  font-size: 18px;
  margin: 0px 10px 0 0px;
  line-height: 18px;
`
const PlainText= styled.div`
      font-family: "Poppins";
    font-weight: 400;
    color: #6e6e6e;
    font-size: 12px;
    margin: 19px 0px 0 0px;
    line-height: 18px;
`
const DataCardWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 10px;
  margin: 19px 10px 0 0px;
`;
interface userProfile {
  selectedUser?: {
    company_logo?: string;
    name?: string;
    location?: [];
    link_to_website?: string;
    industry_type?: [];
    description?: string;
    social_facebook_link?: string;
    social_instagram_link?: string;
    social_linkedin_link?: string;
    social_twitter_link?: string;
    mobile_verification_status?: boolean;
  };
  type: number;
  showSocialmediaicons?: boolean;
  profileImageURL?: string;
  activeStatus?: boolean;
  onHideModel?: any;
  chatInitiated?: any;
  setShowMobileVerify?: any;
}

const CompanyProfileDisplay: React.FC<userProfile> = ({
  type = 1,
  profileImageURL = CompanyLogo,
  activeStatus = false,
  selectedUser,
  ...props
}) => {
  // type : 1 employer company profile
  // type : 2 employee compony profile
  const sel_user_exper = selectedUser?.location
    ? selectedUser.location
    : [];
  let user_exper = sel_user_exper
    .map((e) => ({ id: e, name: CountryStore.get(e) }))
    .sort((a, b) => a.name?.localeCompare(b.name));
  const { t } = useTranslation();

  return (
    <Box sx={{ background: "#F7F7F7" }}>
      <RightSideBarSkeleton onHide={props.onHideModel} type={2} />
      <ProfilePictureContainer>
        <StyleProfile>
          <StyleAvatar
            alt="profile picture"
            src={
              selectedUser?.company_logo
                ? selectedUser?.company_logo
                : CompanyLogo
            }
          />
          {/* {type == 1 && (
            <StyleIndicator style={{ backgroundColor: activeStatus ? "#4CAF50" : "" }} />
          )} */}
        </StyleProfile>
        <NameAgeWrapper>
          <Name>{selectedUser?.name}</Name>
          {/* <Age>{CountryStore.get(selectedUser?.location)}</Age> */}
        </NameAgeWrapper>
        {/* <MemoFavouriteIcon size='38px' /> */}
      </ProfilePictureContainer>
      <div style={{ margin: "0 20px 0 20px" }}>
        <VerifiedWrapper>
          <EmailPhoneWrapper>
            <MemoEmailVerified
              style={{ marginRight: "9px" }}
              width="14px"
              height="11px"
            />{" "}
            {t("Verified")}
          </EmailPhoneWrapper>
          {selectedUser?.mobile_verification_status ? (
            <EmailPhoneWrapper>
              <MemoPhoneVerified
                width="9px"
                height="15px"
                style={{ marginRight: "6px" }}
              />
              <text>{t("Verified")}</text>
            </EmailPhoneWrapper>
          ) : (
            <EmailPhoneWrapper>
              <MemoGreyPhone
                width="9px"
                height="15px"
                style={{ marginRight: "9px" }}
              />
              <NotVerifiedText addMargin={type == 1 ? true : false}>
                {t("Not_Verified")}
              </NotVerifiedText>
              {type === 1 && (
                <VerificationWrapper onClick={props?.setShowMobileVerify}>
                  <MemoOrangeEdit fontSize={"14px"} />
                  <VerifyText>{t("Verify")}</VerifyText>
                </VerificationWrapper>
              )}
            </EmailPhoneWrapper>
          )}
        </VerifiedWrapper>
        <LanguageExperienceCountryWrapper>
          {selectedUser?.link_to_website && (
            <>
              <div
                style={{
                  margin: "0 20px 0 0",
                  overflow: "hidden",
                  maxWidth: "39%",
                }}
              >
                <TypographyTitle>{t("Link")}</TypographyTitle>
                <a
                href={getValidUrl(selectedUser?.link_to_website)}
                target="_blank"
                style={{textDecoration:"none"}}
              >
                <TypographyValue>
                  {selectedUser?.link_to_website}
                </TypographyValue>
                </a>
              </div>
              <Divider
                size={"md"}
                color={"rgba(0, 0, 0, 0.1)"}
                orientation={"vertical"}
                sx={{ height: "auto" }}
              />
            </>
          )}
          <div
            style={{
              margin:selectedUser?.link_to_website ? "0 0 0 20px" :"0",
              overflow: "hidden",
              maxWidth: selectedUser?.link_to_website ?"50%" :"100%",
            }}
          >
            <TypographyTitle>{t("where_is_the_business_located")}</TypographyTitle>
            <div>
              {selectedUser?.location?.map((item: any, index: any) => {
                return (
                  <TypographyValue
                    style={{
                      display: "inline",
                    }}
                  >
                    {`${CountryStore.get(item)}${
                      selectedUser?.location?.length != index + 1
                        ? ","
                        : "."
                    } ` + " "}
                  </TypographyValue>
                );
              })}
            </div>
          </div>
        </LanguageExperienceCountryWrapper>
        {selectedUser?.description && (
          <LinkWrapper>
            <CustomLabel>{t("company_information")}</CustomLabel>
            <PlainText>{selectedUser?.description} Tatad</PlainText>
          </LinkWrapper>
        )}
        {(selectedUser?.social_facebook_link !== "" ||
          selectedUser?.social_linkedin_link !== "" ||
          selectedUser?.social_instagram_link !== "" ||
          selectedUser?.social_twitter_link !== "") && (
          <>
            <StyledTitle>
              {type == 2 ? t("Connect_media") : t("Social_media")}
            </StyledTitle>
            <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
              {selectedUser?.social_facebook_link !== "" && (
                <a
                  href={getValidUrl(selectedUser?.social_facebook_link)}
                  target="_blank"
                  style={{
                    paddingRight: "9px",
                    //pointerEvents: `${selectedUser?.social_facebook_link !== "" ? "auto" : "none"}`,
                  }}
                >
                  <MemoFb fontSize="40px" />
                </a>
              )}
              {selectedUser?.social_linkedin_link !== "" && (
                <a
                  href={getValidUrl(selectedUser?.social_linkedin_link)}
                  target="_blank"
                  style={{
                    paddingRight: "9px",
                    //pointerEvents: `${selectedUser?.social_linkedin_link !== "" ? "auto" : "none"}`,
                  }}
                >
                  <MemoLinkedin fontSize="40px" />
                </a>
              )}
              {selectedUser?.social_instagram_link !== "" && (
                <a
                  href={getValidUrl(selectedUser?.social_instagram_link)}
                  target="_blank"
                  style={{
                    paddingRight: "9px",
                    //pointerEvents: `${selectedUser?.social_instagram_link !== "" ? "auto" : "none"}`,
                  }}
                >
                  <MemoInsta fontSize="40px" />
                </a>
              )}
              {selectedUser?.social_twitter_link !== "" && (
                <a
                  href={getValidUrl(selectedUser?.social_twitter_link)}
                  target="_blank"
                  style={{
                    paddingRight: "9px",
                    //pointerEvents: `${selectedUser?.social_twitter_link !== "" ? "auto" : "none"}`,
                  }}
                >
                  <MemoTwitter fontSize="40px" />
                </a>
              )}
            </Box>
          </>
        )}
        {type == 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0 10px 20px 20px",
            }}
          >
          </div>
        )}
      </div>
    </Box>
  );
};

export default CompanyProfileDisplay;
