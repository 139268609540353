

function getBrowserLanguage() {
    let language =""
    switch(navigator?.language){
        case "no": language = "nb";
                    break;
        case "pl":
        case "pl-PL":
                language = "pl";
                break;
        case "nb":
        case "nb-NO":
                language="nb";
                break;
        default: language="en";
                break;                
    }
    return language
}

export default getBrowserLanguage