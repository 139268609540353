import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import BackButton from "ui/buttons/BackButton";
import Subscription from "./Subscription";
import Invoice from "./Invoice";
import Container from "ui/layout/Container";
import PrivateLayout from "layouts/PrivateLayout";
import { Tabs as MantineTabs, TabsVariant } from "@mantine/core";
import { UserContext } from "context/state";

const Heading = styled.h1`
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  color: #123b3d;

  @media only screen and (min-width: 1000px) {
    position: absolute;
    top: -13px;
    left: 25px;
  }
`;

const Wrapper = styled.div`
  margin: 0 22px 0 22px;
  @media only screen and (min-width: 1000px) {
  }
`;

export default function BillingScreen() {
  const { t } = useTranslation();
  const contextData = useContext(UserContext);
  // console.log(`contextData`, contextData.userData);
  const { setActiveTap } = useContext(UserContext);
  useEffect(() => {
    setActiveTap(4);
  }, []);
  return (
    <PrivateLayout showBottomMenu={false} showMobileViewLogo={false}>
      <Wrapper>
        <div style={{ position: "relative" }}>
          <BackButton />
          <Heading>{t("Billing_and_payments")}</Heading>
        </div>
        <div className='billing'>
          <div className='button' style={{ width: "100%" }}>
            {/* <MantineTabs
              variant="unstyled"
              grow
              styles={{
                tabLabel: {
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '16px',
                },
                tabControl: {
                  backgroundColor: '#FFFFFF',
                  color: '#123B3D',
                  height: "53px",
                  borderRadius: "11px",
                  borderColor: '#FFFFFF',
                  boxShadow: '0px 10px 35px rgba(64, 59, 75, 0.1)',
                  '@media only screen and (min-width: 1000px)': {
                    maxWidth: '160px',
                    marginTop: '20px',
                  }
                },
                tabActive: {
                  backgroundColor: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  color: 'red',
                },
                tabsList: {
                  gap: "11px",
                },
              }}
            >
              <MantineTabs.Tab label="Billing Details">
                <Subscription />
              </MantineTabs.Tab>
              <MantineTabs.Tab label="Invoice">
                <Invoice />
              </MantineTabs.Tab>
            </MantineTabs> */}
            <Subscription />
          </div>
        </div>
      </Wrapper>
    </PrivateLayout>
  );
}
