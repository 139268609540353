
import PublicLayout from "layouts/PublicLayout";
import { VerifyPhoneOTP } from "screens/verify-phone/VerifyPhoneOTP";
import { useLocation } from "react-router-dom";

export default function VerifyAccountScreen(props:any) {
    const location = useLocation<{phone:string,email:string,password:string}>()
    let contact={
        phone:location?.state?.phone,
        email:location?.state?.email,
        password:location?.state?.password
    }
    return (
        <PublicLayout>
            <VerifyPhoneOTP contactDetails={contact} />
        </PublicLayout>
    );
}