import * as React from "react";

function FavouriteIcon(props) {
  return (
    <svg width={props?.size ? props.size : "1em"} height={props?.size ? props.size : "1em"} viewBox="0 0 36 36" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d_2995_3307)">
        <path
          d="M22 28c7.732 0 14-6.268 14-14S29.732 0 22 0 8 6.268 8 14s6.268 14 14 14z"
          fill="#fff"
        />
      </g>
      <path
        d="M20.167 7.585c.068-1.011.993-1.793 1.99-1.536l.272.072c.482.125.91.493 1.055 1.043.229.883.555 2.716.064 4.877.154-.022.308-.04.462-.055.744-.07 1.74-.078 2.623.227.54.187 1.037.737 1.252 1.377a2.118 2.118 0 01-.245 1.874c.061.129.108.262.144.392.08.292.118.614.118.926 0 .313-.037.634-.118.926-.04.146-.093.295-.166.437.176.418.111.885-.003 1.241-.118.351-.29.68-.51.974.057.165.08.338.08.503 0 .33-.093.676-.264.986-.348.634-1.04 1.151-2.016 1.151h-3.65c-.63 0-1.115-.088-1.528-.236a4.962 4.962 0 01-1.011-.523l-.05-.033c-.526-.331-1.042-.658-2.156-.78-.799-.089-1.51-.757-1.51-1.672V15.43c0-.919.714-1.548 1.415-1.746.885-.25 1.64-.851 2.223-1.525.583-.678.953-1.384 1.083-1.772.207-.622.371-1.664.446-2.8v-.002z"
        fill="red"
      />
      <defs>
        <filter
          id="prefix__filter0_d_2995_3307"
          x={0}
          y={0}
          width={36}
          height={36}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-4} dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2995_3307"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2995_3307"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

const MemoFavouriteIcon = React.memo(FavouriteIcon);
export default MemoFavouriteIcon;
