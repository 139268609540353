import * as React from "react";

function PhoneIcon(props) {
  return (
    <svg width={props?.width} height={props?.height} viewBox="0 0 18 27" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M5.073 1h7.857a4.13 4.13 0 012.983 1.325 4.18 4.18 0 011.105 3.087v15.44a4.181 4.181 0 01-1.105 3.086 4.13 4.13 0 01-2.983 1.325H5.073C2.64 25.263 1 23.283 1 20.852V5.412C1 2.98 2.64 1 5.073 1z"
        stroke="#6A6A6A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path stroke="#C3C3C3" d="M6 21.5h6" />
    </svg>
  );
}

const MemoPhoneIcon = React.memo(PhoneIcon);
export default MemoPhoneIcon;
