//import { Form, Button as BSButton, Row, Col } from "react-bootstrap";
import React, { ReactNode } from "react";
import { Button as MTButton, ButtonVariant, SharedButtonProps } from "@mantine/core";

interface ButtonProps extends SharedButtonProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  variant?: ButtonVariant | undefined;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  background?: string;
  onClick?: () => void;
  backgroundColor?:string;
  border?:string
}

const Button: React.FC<ButtonProps> = ({
  width,
  height,
  children,
  variant,
  fontSize,
  color,
  radius,
  fontWeight,
  lineHeight,
  background,
  backgroundColor,
  border='1px solid #000',
  ...props
}) => {
  let styles = {
    width: width ?? "100%",
    height: height ?? "54px",
    fontSize: fontSize ?? "16px",
    color: color,
    borderRadius: radius ?? "52px",
    background: background ?? "transparent",
    "&:hover": {
      background: background ?? "transparent",
    },
    fontWeight: fontWeight ?? 600,
    fontFamily: "Poppins",
    lineHeight: lineHeight,
    cursor: "pointer",
    border:border
  };

  switch (variant) {
    case "filled":
      styles = {
        ...styles,
        color: color ?? "#fff",
        border: "none",
        background: "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
        "&:hover": {
          background: "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
        },
        fontWeight: fontWeight ?? 600,
      };
      break;
    case "light": // if Button is Disabled
      styles = {
        ...styles,
        color: color ?? "#fff",
        border: "none",
        background: backgroundColor ?? "#6E6E6E",
        fontWeight: fontWeight ?? 600,
        "&:hover": {
          background: backgroundColor ??  "#6E6E6E",
        },
      };
      break;
    case "default":
      styles = {
        ...styles,
        color: color ?? "#000",
        //border: "1px solid #000",
        background: "#FFF",
        "&:hover": {
          background: "#FFF",
        },
        fontWeight: fontWeight ?? 600,
      };
      break;
    case "subtle":
      styles = {
        ...styles,
        color: color ?? "#123B3D",
        borderRadius: "none",
        border: "none",
        background: "transparent",
        "&:hover": {
          background: "transparent",
        },
      };
      break;
  }

  return (
    <MTButton
      variant={variant}
      color={color}
      styles={(theme) => ({
        root: {
          ...styles,
          ...(variant==='light' && {pointerEvents:'none'})
        },
      })}
      {...props}>
      {children}
    </MTButton>
  );
};
export default Button;
