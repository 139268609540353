import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import MemoSuccessIcon from 'ui/icons/SuccessIcon'
import { useTranslation } from "react-i18next";

interface VerifyPhoneSuccessProps {
    children?: ReactNode,
    show?: boolean,
    onHide?: () => void
}

const StyleHeader = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #123B3D;
    z-index: 20;
    margin: 20px;
    align-self: center;
    width: 246px;
`;

const StyleImageContainer = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 1000px) {
         position: relative;
    }
`;

const StyleWrapper = styled.div`
    //height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70%;
 @media only screen and (min-width: 1000px) {
        padding-top: 0;
    }
`;


const StyleIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
     @media only screen and (min-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;


const VerifyPhoneSuccess: React.FC<VerifyPhoneSuccessProps> = (props) => {

    const { t } = useTranslation();

    return (
        <StyleWrapper>
            <StyleImageContainer>
                <StyleIcon>
                    <MemoSuccessIcon className="image" />
                </StyleIcon>
                <StyleHeader>{t("Your_mobile_number_is_verified")}</StyleHeader>
            </StyleImageContainer>
        </StyleWrapper>
    )
}
export default VerifyPhoneSuccess