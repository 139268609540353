import { Box, Grid, Group, Stack, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "context/state";
import * as Yup from "yup";
import { ProfilCreationAlertModel } from "ui/data-display/ProfilCreationAlertModel";
import LabelwithInfo from "ui/data-display/LabelwithInfo";
import { MultiSelectButton } from "ui/inputs/MultiSelectButton";
import Drawer from "ui/data-display/Drawer";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { PositionSearch } from "helpers/search-select/PositionSearch";
import { PositionStore } from "stores/taxnomies/position.store";
import { TypeOfPostionStore } from "stores/taxnomies/type-of-postion.store";
import { CountryStore } from "stores/taxnomies/country.store";
import CountrySearch from "helpers/search-select/CountrySearch";
import filterNewIndustries from "helpers/filter-new-industry"
import { ChipInput } from "ui/inputs/ChipInput";
import { CommonPageStepLabel } from "./CommonPageStepLabel";
import { fontBlack } from "constants/colors";
import { ErrorModal } from "ui/modals/ErrorModal";

const StyleSpacing = styled.div`
  //margin-top: 20px;
  @media only screen and (min-width: 1000px) {
    margin-top: 2.5%;
  }
`;

const StyleError = styled.span`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  font-family: Poppins;
`;

const StyledText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${fontBlack};
  margin: 34px 0 34px 0;

  @media only screen and (min-width: 1000px) {
    text-align: center;
  }
`;

const labelStyles = {
  fontSize: "16px",
  fontWeight: 400,
  color: "#6E6E6E",
  letterSpacing: 0.3,
};

function UserProfileStep2(props: any) {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    industry: Yup.string().required(t("Country_cannot_be_empty")),
    country: Yup.string().required(),
    timeofwork: Yup.string().required(),
    pastIndustryExperience: Yup.string().required(),
  });

  const { userData } = useContext(UserContext);

  const [showInfo, setShowInfo] = useState(false);

  //sprint 3 changes
  const [showPositionSearch, setShowPositionSearch] = useState(false);
  const [positionData, setPositionData] = useState(
    props?.data?.industry ? props?.data?.industry : []
  );
  const [showExperience, setShowExperience] = useState(false);
  const [pastIndustryExperience, setPastIndustryExperience] = useState(
    props?.data?.pastIndustryExperience
      ? props?.data?.pastIndustryExperience
      : []
  );
  let pastIndustryExperienceFiltered = pastIndustryExperience.filter(filterNewIndustries)
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [countryData, setCountryData] = useState(
    props?.data?.country ? props?.data?.country : []
  );
  const [infoText, setInfoText] = useState("");
  const [addClickedFor, setAddClickedFor] = useState("");
  const [showEditError,setShowEditError] = useState(false)
  const [errortext, setErrorText] = useState("");

  let TypeOfPostionStoreData = Array.from(
    TypeOfPostionStore.data,
    ([id, name]) => ({ id, name })
  );

  const updatePositionDataHandler = () => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          parentId: positionType?.id,
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, userData?.preferred_job),
              show: val?.show,
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setPositionData(PositionStoreDataUpdatedFilter);
  };
  const updatePastPositionDataHandler = () => {
    let pastExperience = userData?.industry_type.concat(
      userData?.position_type
    );
    let set = new Set(pastExperience); //getting unique Ids only
    pastExperience = Array.from(set);
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          parentId: positionType?.id,
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, pastExperience),
              show: val?.show,
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setPastIndustryExperience(PositionStoreDataUpdatedFilter);
  };

  const TransformDataHandler = (values: any) => {
    const convertedData = values.map((data: any) => {
      let newData = {
        value: data.id,
        label: data.name,
      };
      return newData;
    });
    return convertedData;
  };
  //end
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      euCitizen: props?.data?.euCitizen
        ? props?.data?.euCitizen
        : userData?.citizen
        ? userData?.citizen
        : "106",
      drivingLicense: props?.data?.drivingLicense
        ? props?.data?.drivingLicense
        : [],
      primaryLanguage: props?.data?.primaryLanguage
        ? props?.data?.primaryLanguage
        : [],
      secondaryLanguage: props?.data?.secondaryLanguage
        ? props?.data?.secondaryLanguage
        : [],
      industry: props?.data?.industry ? props?.data?.industry : [],
      country: props?.data?.country ? props?.data?.country : [],
      timeofwork: props?.data?.timeofwork?.length
        ? props?.data?.timeofwork
        : userData?.part_full_time?.length
        ? userData?.part_full_time
        : ["203"],
      pastIndustryExperience: props?.data?.pastIndustryExperience
        ? props?.data?.pastIndustryExperience
        : [],
    },
  });

  // Function to display saved data
  const savedDataHandler = (data: any, savedData: any) => {
    let flag = false;
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true;
    });
    return flag;
  };

  // Function to Get all the checked IDs
  const getCheckedIds = (data: any) => {
    let checkedIds: any = [];
    data?.forEach((objectData: any) => {
      objectData?.data?.forEach((checkboxData: any) => {
        if (checkboxData?.checked) {
          checkedIds.push(checkboxData.id);
        }
      });
    });
    return checkedIds;
  };
  
  //Validation State
  useEffect(() => {
    props.setIsValid(false);
    if (positionData.length == 0) updatePositionDataHandler();
    if (pastIndustryExperience.length == 0) updatePastPositionDataHandler();
    if (
      Array.from(CountryStore?.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      countryData.length == 0
    ) {
      setCountryData([
        {
          headerName: "",
          data: Array.from(CountryStore?.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              userData?.preferred_country?.length != 0
                ? savedDataHandler(obj, userData?.preferred_country)
                : false,
            show: true,
          })),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    form.setFieldValue("country", countryData);
  }, [countryData]);

  useEffect(() => {
    let industryType: any = [];
    positionData.forEach((type: any) => {
      industryType.push(...getCheckedParent(type?.data));
    });
    let set1 = new Set(industryType);
    industryType = Array.from(set1);
    if (industryType.length > 3) {
      setErrorText(t("please_select_only_3_industries"));
      setShowEditError(true);
      updatePositionDataHandler();
    } else {
      form.setFieldValue("industry", positionData);
      if (!showEditError) setShowPositionSearch(false);
    }
  }, [positionData]);
  const getCheckedParent = (data: any) => {
    let checked: any = [];
    data?.forEach((item: any) =>
      item?.checked ? checked.push(item?.parentId) : ""
    );
    return checked;
  };
  useEffect(() => {
    let industryType: any = [];
    pastIndustryExperience.forEach((type: any) => {
      industryType.push(...getCheckedParent(type?.data));
    });
    let set1 = new Set(industryType);
    industryType = Array.from(set1);
    if (industryType.length > 3) {
      setErrorText(t("please_select_only_3_industries"));
      setShowEditError(true);
      updatePastPositionDataHandler();
      setShowExperience(true);
    } else {
      form.setFieldValue("pastIndustryExperience", pastIndustryExperience);
      if (!showEditError) setShowExperience(false);
    }
  }, [pastIndustryExperience]);

  // Validation Check on Click on 'Next'
  useEffect(() => {
    props.setData(form.values);
    if (
      getCheckedIds(form.values.industry).length != 0 &&
      getCheckedIds(form.values.country).length != 0 &&
      getCheckedIds(form.values.pastIndustryExperience).length != 0
    ) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, [form.values]);

  return (
    <form id="F1" onSubmit={form.onSubmit((values) => {})}>
      <ErrorModal
      zIndex={10000}
        opened={showEditError}
        message={errortext}
        onClose={() => {setShowEditError(false);}}
      />
      <CommonPageStepLabel step="2" />
      <StyledText>{t("Last_Step")}</StyledText>
      <Grid>
        <Grid.Col lg={6}>
          <MultiSelectButton
            data={pastIndustryExperienceFiltered}
            header={t("Select your experience")}
            type="out"
            setData={setPastIndustryExperience}
            buttonLabel={t("Select Experience")}
            onClick={() => setShowExperience(true)}
            infoText={t("Experience_from_industry_alert")}
            errorMessage={
              props?.showValidation &&
              form?.errors?.pastIndustryExperience &&
              getCheckedIds(pastIndustryExperience).length == 0 &&
              t("Please_select_Industry")
            }
          />

          <Drawer
            position="right"
            padding={"0px"}
            onClose={() => setShowExperience(false)}
            opened={showExperience}
          >
            <PositionSearch
              title={t("Positions_held")}
              dependent={false}
              getData={setPastIndustryExperience}
              passData={pastIndustryExperience}
              onHide={() => setShowExperience(false)}
              showNone={true}
              setAddClickedFor={() => setAddClickedFor("experience")}
              dontHideDrawer ={true}
            />
          </Drawer>
        </Grid.Col>
        <Grid.Col lg={6}>
          <MultiSelectButton
            data={positionData}
            header={t("What industry you're looking at?")}
            type="out"
            setData={setPositionData}
            buttonLabel={t("Select_Industry")}
            onClick={() => setShowPositionSearch(true)}
            infoText={t("What_positions_have_you_worked_in_alert")}
            errorMessage={
              props?.showValidation &&
              form?.errors?.industry &&
              getCheckedIds(positionData).length == 0 &&
              t("Please_select_Industry")
            }
          />

          <Drawer
            position="right"
            padding={"0px"}
            onClose={() => setShowPositionSearch(false)}
            opened={showPositionSearch}
          >
            <PositionSearch
              title={t("What_work_do_you_seek")}
              dependent={false}
              getData={setPositionData}
              passData={positionData}
              onHide={() => setShowPositionSearch(false)}
              setAddClickedFor={() => setAddClickedFor("preferredJob")}
              dontHideDrawer ={true}
            />
          </Drawer>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col lg={6}>
          <StyleSpacing>
            <MultiSelectButton
              data={countryData}
              header={t("Country_you_like")}
              type="in"
              setData={setCountryData}
              buttonLabel={t("Search__Select")}
              onClick={() => setShowCountrySearch(true)}
              infoText={t("What_country_do_you_seek_to_work_in_alert")}
              errorMessage={
                props?.showValidation &&
                form?.errors?.country &&
                getCheckedIds(countryData)?.length == 0 &&
                t("Please_Select_Country")
              }
            />
          </StyleSpacing>
          <Drawer
            position="right"
            padding={"0px"}
            onClose={() => setShowCountrySearch(false)}
            opened={showCountrySearch}
          >
            <CountrySearch
              title={t("Where_do_you_work")}
              singleSelect={false}
              getData={setCountryData}
              passData={countryData}
              onHide={() => setShowCountrySearch(false)}
              savedData={
                userData?.preferred_country?.length != 0
                  ? userData?.preferred_country
                  : []
              }
              checkAll={true}
            />
          </Drawer>
        </Grid.Col>
        <Grid.Col lg={6}>
          <Text
            align="left"
            sx={{ ...labelStyles, padding: "5px 0", marginTop: "0" }}
          >
            <LabelwithInfo
              label={t("Full_time_or_part_time")}
              onClick={() => {
                setInfoText(t("Full_time_or_part_time_alert"));
                setShowInfo(true);
              }}
            />
          </Text>
          <ChipInput
            {...form.getInputProps("timeofwork")}
            data={TransformDataHandler(TypeOfPostionStoreData)}
          />
          {props?.showValidation &&
            form?.errors?.timeofwork &&
            form?.values?.timeofwork.length == 0 && (
              <StyleError>{t("Please_select_work_schedule")}</StyleError>
            )}
        </Grid.Col>
      </Grid>
      <ProfilCreationAlertModel
        opened={showInfo}
        onClose={() => setShowInfo(false)}
        infoText={t(infoText)}
      />
    </form>
  );
}

export default UserProfileStep2;
