import React, { ReactNode, useState, useEffect } from "react";
import { Stack } from "@mantine/core";
import { Box } from "@mantine/core";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import styled from "styled-components";
import MemoArrow from "ui/icons/arrow";
import { Divider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Slider from "ui/inputs/Slider";
import { Filters } from "modules/imports/interfaces/interfaces";

interface FilterProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: any;
  setShowFilter: any;
  handleClick: any;
  countryDataCount?: number;
  industryDataCount?: number;
  positionDataCount?: number;
  languageCount?: number;
  liceneseCarCount?: number;
  whenCount?: number;
  workTypeCount?: number;
  setRestData?: any;
  filterUpdate?: any;
  showSaveSearchFeatures?:boolean;
  currentFilters?:Filters,
  filterFooter?:ReactNode
  filterSubmitButtonText?:string
}


const StyleButton = styled(Box)<{ onClick: any; children: any }>`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #123b3d;
  text-align: left;
  padding: 10px 0 10px 0;
  cursor: pointer;
`;

const StyleArrow = styled(Box)`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 0 10px 0;
  margin: 3px 0 10px 0;
`;

const StyleCount = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50%;
  right: 20px;
  top: 0px;
  margin: 10px 0 10px 0;
`;

export const FilterScreen: React.FC<FilterProps> = ({
  children,
  setShowFilter,
  handleClick,
  filterUpdate,
  showSaveSearchFeatures,
  currentFilters,
  filterFooter=<></>,
  filterSubmitButtonText='',
  ...props
}) => {
  const dataListing = [
    {
      label: "Where_is_the_job_located",
      description: "1",
      count: props?.countryDataCount ? props?.countryDataCount : false,
    },
    {
      label: "Employees_needed_from_which_industry",
      description: "2",
      count: props?.industryDataCount ? props?.industryDataCount : false,
    },
    {
      label: "What_position_do_you_need_to_fill",
      description: "3",
      count: props?.positionDataCount ? props?.positionDataCount : false,
    },
    {
      label: "Required_language",
      description: "4",
      count: props?.languageCount ? props?.languageCount : false,
    },
    // {
    //   label: "When_should_the_position_be_filled",
    //   description: "5",
    //   count: props?.whenCount ? props?.whenCount : false,
    // },
    {
      label: "Drivers_license_required",
      description: "6",
      count: props?.liceneseCarCount ? props?.liceneseCarCount : false,
    },
    {
      label: "What_position_need_to_beemployed",
      description: "7",
      count: props?.workTypeCount ? props?.workTypeCount : false,
    },
  ];
  const { t } = useTranslation();

  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        title={t("Filter")}
        setShow={props.onHide}
        showSearch={false}
        clearAll={() => true}
        showFilter_Clear={1}
        buttonText={filterSubmitButtonText!=''? filterSubmitButtonText: t("Apply_filter")}
        description={t("Start_by_filtering_your_requirements")}
        route='/employeer-profile'
        resetFilter={props.setRestData}
        filterUpdate={filterUpdate}
        showSaveSearchFeatures ={showSaveSearchFeatures}
        currentFilters={currentFilters}
        children={
          <>
            <Slider labelText={t("Age")} />
            <Box sx={{ overflowY: "auto" }}>
              {dataListing.map((data, index) => {
                return (
                  <Box mx={"auto"}>
                    <Divider my='sm' />
                    <StyleButton onClick={() => handleClick(data.description)}>
                      {t(data.label)}

                      {data.count && <StyleCount>{data.count}</StyleCount>}

                      <StyleArrow>
                        <MemoArrow />{" "}
                      </StyleArrow>
                    </StyleButton>
                    {dataListing.length == index + 1 && <Divider my='sm' />}
                  </Box>
                );
              })}
            </Box>
            {filterFooter}
          </>
        }
      />
    </Stack>
  );
};
