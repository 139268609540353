import React, { ReactNode, useEffect, useState } from "react";
import { Box, Stack } from "@mantine/core";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import CheckboxGroup from "ui/inputs/CheckboxGroup";
import { IndustryStore } from "stores/taxnomies/industry.store";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import isNone from "helpers/isNone";
import getBrowserLanguage from "helpers/get-browser-language";
import filterNewIndustries from "helpers/filter-new-industry"
interface IndustrySearchProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: any;
  getData?: any;
  passData?: any;
  title?: string;
  savedData?: any; // Pass the Saved Data
  showNone?:boolean
}

const CheckBoxWrapper = styled(Stack)`
  width: auto;
  height: auto;
  /* overflow-y: auto; */
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
`

export const IndustrySearch: React.FC<IndustrySearchProps> = ({
  children,
  passData,
  title,
  savedData = [],
  showNone=false,
  ...props
}) => {
  const [data, setData] = useState<any>([]);

  //State to reset selected checkbox
  const [resetData, setRestData] = useState(false);

  const [searchString, setSearchString] = useState("");

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false);

  // Function to display saved data
  const savedDataHandler = (data: any) => {
    let flag = false;
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true;
    });
    return flag;
  };
  let IndustryStoreData = Array.from(IndustryStore.data, ([id, name]) => ({
    id,
    name,
  }));

  IndustryStoreData = IndustryStoreData.filter(filterNewIndustries)

  let IndustryStoreDataUpdated = [
    {
      headerName: "",
      data: IndustryStoreData.sort((a: any, b: any) =>
      a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
        ignorePunctuation: true,
      })
    ).map((obj) => ({
        ...obj,
        checked: savedData?.length != 0 ? savedDataHandler(obj) : false,
        show: true,
      })).filter((obj:any)=> !isNone(obj.name)),
    },
  ];

  useEffect(() => {
    // IndustryStoreDataUpdated =IndustryStoreDataUpdated.map((item:any)=>{
    //   return item.data.filter((obj:any) => obj.name!="None")
    // })
    setData(IndustryStoreDataUpdated);
  }, []);
  useEffect(() => {
    setData(IndustryStoreDataUpdated);
  }, [savedData]);
  const { t } = useTranslation();
  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        title={title ? title : "Title"}
        setShow={props.onHide}
        showSearch={true}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        searchString={searchString}
        setSearchString={setSearchString}
        setAddElements={setAddElements}>
        <CheckBoxWrapper>
          {data?.map((dataGroup: any, index: any) => {
            return (
              <CheckboxGroup
                checkAll={true}
                data={data[index]?.data.length ? data : []}
                header={dataGroup.headerName}
                getData={props.getData}
                index={index}
                resetData={resetData}
                setRestData={setRestData}
                searchString={searchString}
                setSearchString={setSearchString}
                addElements={addElements}
                setAddElements={setAddElements}
                showAddButton={data?.length == index + 1 ? true : false}
                setShow={props.onHide}
              />
            );
          })}
        </CheckBoxWrapper>
      </SearchAndSelectAbstraction>
    </Stack>
  );
};