import * as React from "react";

function Fb(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx={20} cy={20} r={20} fill="#fff" />
      <path
        d="M23.633 11.692v2.652h-1.577c-.576 0-.965.12-1.165.361-.201.241-.302.603-.302 1.085v1.899h2.943l-.391 2.973h-2.552v7.624h-3.073v-7.624h-2.562v-2.973h2.562v-2.19c0-1.246.348-2.21 1.044-2.893.697-.69 1.624-1.035 2.783-1.035.984 0 1.747.04 2.29.121z"
        fill="#F50"
      />
    </svg>
  );
}

const MemoFb = React.memo(Fb);
export default MemoFb;
