import * as React from "react";

function Insta(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx={20} cy={20} r={20} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.534 11c-2.317 0-2.608.01-3.518.052-.909.041-1.529.185-2.072.396a4.179 4.179 0 00-1.511.984 4.19 4.19 0 00-.985 1.511c-.212.543-.356 1.164-.397 2.072-.04.91-.051 1.2-.051 3.518 0 2.318.01 2.608.052 3.518.041.909.185 1.529.396 2.071.218.561.51 1.038.984 1.512.474.475.95.767 1.511.985.543.21 1.164.355 2.072.396.91.042 1.2.052 3.518.052 2.318 0 2.608-.01 3.518-.052.908-.041 1.53-.185 2.072-.396a4.175 4.175 0 001.51-.985 4.19 4.19 0 00.986-1.512c.21-.542.353-1.163.396-2.07.041-.91.052-1.201.052-3.519 0-2.317-.011-2.608-.052-3.518-.043-.909-.187-1.529-.396-2.071a4.19 4.19 0 00-.985-1.512 4.17 4.17 0 00-1.511-.984c-.544-.211-1.165-.355-2.073-.396-.91-.042-1.2-.052-3.518-.052h.002zm-.765 1.538h.765c2.279 0 2.549.008 3.448.049.832.038 1.284.177 1.585.294.398.154.682.34.98.638.299.299.484.583.639.981.117.3.256.752.294 1.584.04.9.05 1.17.05 3.447 0 2.278-.01 2.548-.05 3.448-.038.832-.177 1.283-.294 1.584-.155.398-.34.681-.639.98a2.64 2.64 0 01-.98.638c-.3.117-.753.256-1.585.294-.9.041-1.17.05-3.448.05s-2.549-.009-3.448-.05c-.832-.038-1.284-.177-1.585-.294a2.642 2.642 0 01-.981-.638 2.644 2.644 0 01-.639-.981c-.116-.3-.256-.752-.293-1.584-.041-.9-.05-1.17-.05-3.449s.009-2.547.05-3.447c.038-.832.177-1.283.293-1.584.155-.398.34-.683.639-.982.299-.298.583-.483.981-.638.301-.117.753-.256 1.585-.294.787-.036 1.092-.047 2.683-.048v.002zm5.32 1.417a1.024 1.024 0 100 2.047 1.024 1.024 0 000-2.047zm-4.555 1.196a4.382 4.382 0 100 8.765 4.382 4.382 0 000-8.765zm0 1.538a2.845 2.845 0 110 5.689 2.845 2.845 0 010-5.69z"
        fill="#F50"
      />
    </svg>
  );
}

const MemoInsta = React.memo(Insta);
export default MemoInsta;
