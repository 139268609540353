import React, { ReactNode, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import MemoWhiteChevronLeft from "ui/icons/WhiteChevronLeft";
import Select from "ui/inputs/Select";
import AccordionListing from "./Accordian";
import Button from "ui/buttons/Button";
import { getAllSubscriptionListing } from "services/subscription-plan/subscription";
import { ErrorModal } from "ui/modals/ErrorModal";

interface ListingProps {
  children?: ReactNode;
  show?: boolean;
  onHideModel?: any;
  setShowContactUs?: any;
  setShowInvoice?: any;
  setSelectedPlanData?: any;
  setCurrencySelected?: any;
  currencySelected?: string;
  selectedPlanData?: any;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const HeaderBox = styled.div`
  background: linear-gradient(180deg, #ff3000 -6.93%, #ff5500 93.07%);
  border-radius: 0 0 48px 48px;
  width: auto;
  height: 180px;
  top: 0;
  @media only screen and (min-width: 1000px) {
    height: 180px;
  }
`;

const TitleText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  margin: 0 0 0 20px;
`;

const SubscriptionWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 20px;
`;

const DropdownSelect = styled.div`
  max-width: 144px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;
  z-index: 20;
`;

const DropdownSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin: 35px 0 0 0;
`;

const HeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const ContentBox = styled.div`
  z-index: 10;
  margin: -30px 0 0 0;
  /* padding: 0 0 40px 0; */
  @media only screen and (min-width: 1000px) {
    margin: -25px 0 0 0;
  }
`;
interface props {
  pos?: string;
}
const StyleButtonWrapper = styled.div<props>`
  margin: auto 22px 22px 22px;
  position: absolute;
  bottom: 0;
  width: 90%;
  height: 5%;
  @media only screen and (min-width: 1000px) {
    margin: auto 22px 22px 22px;
    position: absolute;
    height: auto;
    bottom: 0px;
  }
  @media only screen and (max-height: 795px) {
    margin: auto 22px 22px 22px;
    position: ${(p) => p?.pos};
    height: auto;
    bottom: 0px;
  }
`;

const Listing: React.FC<ListingProps> = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [pricingList, setPricingList] = useState<any>([]);
  const [showError, setShowError] = useState<boolean>(false);

  //Transform Pricing Data
  const TransformCurrencyData = (currency: any) => {
    let dataForSelect: any = [];
    currency?.map((currencyData: any) => {
      dataForSelect.push({ value: currencyData, label: currencyData });
    });
    return dataForSelect;
  };

  //Pricing Listing Function
  const PricingListingHandler = (value: any) => {
    value?.map((elements: any) => {
      setPricingList(Object.keys(elements.pricing));
    });
  };

  //Get DATA API call....
  useEffect(() => {
    getAllSubscriptionListing()
      .then((response) => {
        setData(response.data.subscription);
        PricingListingHandler(response.data.subscription);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Wrapper>
      <HeaderBox>
        <SubscriptionWrapper>
          <MemoWhiteChevronLeft
            width='21px'
            height='21px'
            style={{ cursor: "pointer" }}
            onClick={() => props.onHideModel(false)}
          />
          <TitleText>{t("Subscription")}</TitleText>
        </SubscriptionWrapper>
        <DropdownSelectWrapper>
          <HeadText>{t("Choose_a_plan")}</HeadText>
          <DropdownSelect>
            <Select
              value={props?.currencySelected}
              placeholder='Currency'
              data={TransformCurrencyData(pricingList)}
              onChange={(value: any) => props?.setCurrencySelected(value.target.id)}
            />
          </DropdownSelect>
        </DropdownSelectWrapper>
      </HeaderBox>
      <ContentBox>
        {props?.currencySelected && (
          <AccordionListing
            currencySelected={props?.currencySelected}
            data={data}
            setShowContactUs={props?.setShowContactUs}
            setSelectedPlanData={props?.setSelectedPlanData}
          />
        )}
      </ContentBox>
      <StyleButtonWrapper
        style={{
          pointerEvents: props?.selectedPlanData?.id == "207" ? "none" : "auto",
        }}
        pos={props?.selectedPlanData?.name ? "sticky" : "absolute"}>
        <Button
          style={{
            backgroundColor: props?.selectedPlanData?.id == "207" ? "#6E6E6E" : "",
          }}
          height={"54px"}
          variant={props?.selectedPlanData?.id == "207" ? "light" : "filled"}
          onClick={() => {
            if (props?.selectedPlanData) props?.setShowInvoice(true);
            else setShowError(true);
          }}>
          {t("Continue_to_Checkout")}
        </Button>
      </StyleButtonWrapper>
      <ErrorModal
        opened={showError}
        message={t("Please_select_Subscription_Plan")}
        onClose={() => setShowError(false)}
      />
    </Wrapper>
  );
};
export default Listing;
