import React, { useContext, useEffect } from "react";
import FAQscreenComponent from 'helpers/f-a-q/FAQscreen'
import { UserContext } from "context/state";

export default function FAQScreen() {
    const {setActiveTap} = useContext(UserContext)
  useEffect(()=>{
    setActiveTap(4)
  },[])
    return (
        <FAQscreenComponent />
    );
}