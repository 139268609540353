import * as React from "react";

function LockIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 25" fill="none" {...props}>
      <path
        d="M5.8 8.405H5a4 4 0 00-4 4V20.2a4 4 0 004 4h10a4 4 0 004-4v-7.795a4 4 0 00-4-4h-.8m-8.4 0V5A4.2 4.2 0 0110 .8v0A4.2 4.2 0 0114.2 5v3.405m-8.4 0h8.4"
        stroke="#6A6A6A"
        strokeWidth={1.5}
      />
      <ellipse
        cx={10}
        cy={16.302}
        rx={3}
        ry={2.925}
        stroke="#6A6A6A"
        strokeWidth={1.5}
      />
    </svg>
  );
}

const MemoLockIcon = React.memo(LockIcon);
export default MemoLockIcon;
