import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Talk from "talkjs";
import styled from "@emotion/styled";
import MobileLayout from "./private-layout-components/MobileLayout";
import DesktopLayout from "./private-layout-components/DesktopLayout";
import JobParkLogoTransparent from "assets/images/JobPark-logo.png";
import { PrivateContext, UserContext } from "context/state";
import Drawer from "ui/data-display/Drawer";
import UserProfileDisplay from "screens/user-company-profile/UserProfileDisplay";
import CompanyProfileDisplay from "screens/user-company-profile/CompanyProfileDisplay";
import CompanyLogo from "assets/images/CompanyLogo.png";
import Profile from "assets/images/Profile.png";
import { getAllFilters } from "services/taxonomies/taxonomies";
import getBrowserLanguage from "helpers/get-browser-language";
import { CountryStore } from "stores/taxnomies/country.store";
import { LanguageStore } from "stores/taxnomies/language.store";
import { AvailabilityStore } from "stores/taxnomies/availability.store";
import { CitizenStore } from "stores/taxnomies/citizen.store";
import { TypeOfPostionStore } from "stores/taxnomies/type-of-postion.store";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { PositionStore } from "stores/taxnomies/position.store";
import { LicenseStore } from "stores/taxnomies/license.store";
import AddPhone from "screens/mobile-verification/AddPhone";
import { ErrorModal } from "ui/modals/ErrorModal";
import { useTranslation } from "react-i18next";
import { PositionSearch } from "helpers/search-select/PositionSearch";
import CountrySearch from "helpers/search-select/CountrySearch";
import LanguageSearch from "helpers/search-select/LanguageSearch";
import { SelectDrivingLicense } from "helpers/search-select/SelectDrivingLicense";
import { PartTimeFullTimeSearch } from "helpers/search-select/PartTimeFullTimeSearch";
import AboutDrawer from "./private-layout-components/AboutDrawer";
import SocialMedaiDrawer from "./private-layout-components/SocialMediaDrawer";
import { updateProfile } from "services/candidate/candidate";
import { getUserDetails } from "services/user/User";
import EUCitizenDrawer from "./private-layout-components/EUCitizenDrawer";
import Loader from "ui/feedback/Loader";
import CVDrawer from "./private-layout-components/CVDrawer";
import isNone from "helpers/isNone";

const LeftPane = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
    float: left;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    position: fixed;
    height: 100vh;
    width: 20%;
  }
`;
const RightPane = styled.div`
  margin: 0;
  position: relative;
  width: 100%;
  background-color: #f7f7f7;
  overflow: auto;
  overflow-x: none;
  @media only screen and (min-width: 1000px) {
    background-color: #f7f7f7;
    height: 100vh;
    width: 80%;
    margin: 0;
    margin-left: 20%;
  }
`;
interface logo {
  width?: string;
}
const StyleLogoMobile = styled.div<logo>`
  position: relative;
  border-radius: 0px;
  width: 335px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: 20px;
  cursor: pointer;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;
const StyleLogoMobileHome = styled.div<logo>`
  position: absolute;
  border-radius: 0px;
  width: 335px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: 0px;
  cursor: pointer;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const StyleWrapperTop = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100px;
    height: auto;
    width: 90%;
  }
`;

const StyleWrapperElement = styled.div`
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 1000px) {
    min-width: 80%;
    height: auto;
  }
`;

const StyleLogoImage = styled.img`
  width: 106px;
  height: 28px;
  @media only screen and (min-width: 1000px) {
    width: 112px;
    height: 30px;
  }
`;

const HomeScreenStyling = styled.div`
  width: 334px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 40px;
  @media only screen and (min-width: 1000px) {
  }
`;

const StyleHeadIconsDesktopWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 1000px) {
  }
`;

interface PrivateLayoutProps {
  children: ReactNode;
  showBottomMenu?: boolean;
  showMobileViewLogo?: boolean;
  setShowFilter?: any;
  isFavouriteScreen?: any;
  isFilterAtSide?: any;
  typeOfLayout?: string;
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  showBottomMenu = true,
  showMobileViewLogo = true,
  setShowFilter,
  isFavouriteScreen = true,
  isFilterAtSide,
  typeOfLayout = "default",
}) => {
  const { t } = useTranslation();
  const {
    userData,
    setNumberOfUnreadMessages,
    showMobileVerify,
    setShowMobileVerify,
    showDropdown,
    setShowDropdown,
    setUserData,
    showUserProfile, setShowUserProfile,
    taxonomyLoaded,
    setTaxonomyLoaded
  } = useContext(UserContext);
  const [displayIcon, setDisplayIcon] = useState(null);
  const { role } = useContext(UserContext);
  const history = useHistory();
  const [talkLoaded, markTalkLoaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");

  const { id } = useParams<{ id: string }>();

  //new changes
  const [countryOfOrigin, setCountryOfOrigin] = useState<any>([]);
  const [primaryLanguage, setPrimaryLanguage] = useState<any>([]);
  const [license, setLicense] = useState<any>([]);
  const [workType, setWorkType] = useState<any>([]);
  const [preferredJob, setPreferredJob] = useState<any>([]);
  const [experience, setExperience] = useState<any>([]);
  const [secondaryLanguage, setSecondaryLanguage] = useState<any>([]);
  const [showPreferredJob, setShowPreferredJob] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const [showPrimaryLanguage, setShowPrimaryLanguage] = useState(false);
  const [showSecondaryLanguage, setShowSecondaryLanguage] = useState(false);
  const [showWorkType, setShowWorkType] = useState(false);
  const [showLicense, setShowLicense] = useState(false);
  const [showCountryOrigin, setShowCountryOrigin] = useState(false);
  const [addClickedFor, setAddClickedFor] = useState("");
  const [about, setAbout] = useState(userData?.about_yourself ?? "");
  const [showAbout, setShowAbout] = useState(false);
  const [showCVEdit, setShowCVEdit] = useState(false);
  const [showSocialMediaEdit, setShowSocialMediaEdit] = useState(false);
  const [euCitizen, setEUCitizen] = useState(userData?.citizen ?? "107");
  const [showEUCitizen, setShowEUCitizen] = useState(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    facebook: userData?.social_facebook_link ?? "",
    instagram: userData?.social_instagram_link ?? "",
    linkedin: userData?.social_linkedin_link ?? "",
    twitter: userData?.social_twitter_link ?? "",
  });
  const [showEditError,setShowEditError] = useState(false)
  const [loader,setLoader] = useState(false)
  //const [taxonomyLoader,setTaxonomyLoader] = useState(true)
  const [cv, setCV] = useState(userData?.upload_cv ?? "")
  const [references, setReferences] = useState(userData?.provide_references ?? "0")
  const [preferredCountry, setPreferredCountry] = useState<any>([])
  const [showPreferredCountry, setShowPreferredCountry] = useState(false)
  //end new changes
  const contextValues = {
    preferredJob,
    setPreferredJob,
    countryOfOrigin,
    setCountryOfOrigin,
    primaryLanguage,
    setPrimaryLanguage,
    license,
    setLicense,
    workType,
    setWorkType,
    experience,
    setExperience,
    secondaryLanguage,
    setSecondaryLanguage,
    showPreferredJob,
    setShowPreferredJob,
    setShowUserProfile,
    setShowCountryOrigin,
    setShowExperience,
    setShowPrimaryLanguage,
    setShowSecondaryLanguage,
    setShowWorkType,
    setShowLicense,
    socialMediaLinks, setSocialMediaLinks,
    about, setAbout,
    setShowAbout,
    setShowSocialMediaEdit,
    setShowEUCitizen,
    euCitizen, setEUCitizen,
    cv, setCV,
    references, setReferences,
    setShowCVEdit,
    setShowPreferredCountry
  };

  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));
    getAllFilters()
      .then((response) => {
        response?.data?.country
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
              ignorePunctuation: true,
            })
          )
          ?.map((item: any) => CountryStore.set(item.id, item.name));
          let languages = response?.data?.language
          const indexOfNone=languages.findIndex((item:any)=> isNone(item.name))
          let none:any =[]
          if(indexOfNone!=null  && indexOfNone>-1){
            none = languages.splice(indexOfNone,1)
          }
        languages
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
              ignorePunctuation: true,
            })
          )
          if(none.length>0)languages.unshift(none[0])
          languages.map((item: any) => LanguageStore.set(item.id, item.name));

        response.data.availability.map((item: any) =>
          AvailabilityStore.set(item.id, item.name)
        );
        response.data.citizen.map((item: any) =>
          CitizenStore.set(item.id, item.name)
        );
        response.data.position_type.map((item: any) =>
          TypeOfPostionStore.set(item.id, item.name)
        );

        response?.data?.industry_type
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
              ignorePunctuation: true,
            })
          )
          ?.map((item: any) => {
            let value = {
              name: item?.name,
              positions: item?.positions,
            };
            IndustryStore.set(item.id, item.name);
            PositionStore.set(item.id, value);
          });

        response.data.license_type.map((item: any) => {
          let value = {
            name: item?.name,
            positions: item?.positions,
          };
          LicenseStore.set(item.id, value);
        });
        setTaxonomyLoaded(true)
      })
      .catch((error) => {
        console.log(error);
        setTaxonomyLoaded(false)
      });
      if(Object.keys(userData).length === 0){
      }
      else{
        setAllChecked()
      }
  }, []);

  // Function to display saved data
  const savedDataHandler = (data: any, savedData: any) => {
    let flag = false;
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true;
    });
    return flag;
  };

  const updatePositionDataHandler = () => {
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, userData?.preferred_job),
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setPreferredJob(PositionStoreDataUpdatedFilter);
  };
  const updatePastPositionDataHandler = () => {
    let pastExperience = userData?.position_type
    let PositionStoreData = Array.from(PositionStore.data, ([id, name]) => ({
      id,
      name,
    }));
    let PositionStoreDataUpdated = PositionStoreData?.map(
      (positionType: any) => {
        let positionData = {};
        positionData = {
          parentId: positionType?.id,
          headerName: positionType?.name?.name,
          data: positionType?.name?.positions?.map((val: any) => {
            let updatedData: any = {
              id: val?.id,
              parentId: positionType?.id,
              name: val?.name,
              checked: savedDataHandler(val, pastExperience),
              show: val?.show,
            };
            return updatedData;
          }),
        };
        return positionData;
      }
    );

    let PositionStoreDataUpdatedFilter = PositionStoreDataUpdated.filter(
      (value) => Object.keys(value).length !== 0
    );
    setExperience(PositionStoreDataUpdatedFilter);
  };
  const setAllChecked = () => {
    if (
      Array.from(IndustryStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      experience.length == 0 &&
      userData?.preferred_job?.length > 0
    ) {
      updatePositionDataHandler();
    }
    if (
      Array.from(CountryStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      countryOfOrigin.length == 0 &&
      userData?.country_origin?.length > 0
    ) {
      setOriginCountry()
    }

    if (
      Array.from(LanguageStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      userData?.primary_language?.length > 0
    ) {
      setLanguage()
    }
    if (
      Array.from(LanguageStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      userData?.secondary_language?.length > 0
    ) {
      setSecondaryLanguage([
        {
          headerName: "",
          data: Array.from(LanguageStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              userData?.secondary_language?.length != 0
                ? savedDataHandler(obj, userData?.secondary_language)
                : false,
            show: true,
          })),
        },
      ]);
    }
    if (
      Array.from(TypeOfPostionStore.data, ([id, name]) => ({ id, name }))
        .length != 0 &&
      workType.length == 0 &&
      userData?.part_full_time?.length > 0
    ) {
      setPartTimeFullTime()
    }

    if (
      Array.from(LicenseStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      license.length == 0 &&
      userData?.license_type?.length > 0
    ) {
      setLicense(
        Array.from(LicenseStore.data, ([id, name]) => ({ id, name })).map(
          (vehicleType: any) => {
            let licenceData = {};
            licenceData = {
              headerName: vehicleType?.name?.name,
              data: vehicleType?.name?.positions?.map((val: any) => {
                let updatedData: any = {
                  id: val?.id,
                  name: val?.name,
                  checked:
                    userData?.license_type?.length != 0
                      ? savedDataHandler(val, userData?.license_type)
                      : false,
                  show: true,
                };
                return updatedData;
              }),
            };
            return licenceData;
          }
        )
      );
    }
    if (
      Array.from(IndustryStore.data, ([id, name]) => ({ id, name })).length !=
        0 &&
      experience.length == 0 &&
      userData?.position_type?.length > 0
    ) {
      updatePastPositionDataHandler();
    }
    if (
      Array.from(CountryStore?.data, ([id, name]) => ({ id, name })).length != 0 &&
      preferredCountry.length == 0
    ) {
      setPreferredCountryFunction()
    }
  };

  const setLanguage = ()=>{
    setPrimaryLanguage([
      {
        headerName: "",
        data: Array.from(LanguageStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            userData?.primary_language?.length != 0
              ? savedDataHandler(obj, userData?.primary_language)
              : false,
          show: true,
        })),
      },
    ]);
  }

  const setPartTimeFullTime=()=>{
    setWorkType([
      {
        headerName: "",
        data: Array.from(TypeOfPostionStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            userData?.part_full_time?.length != 0
              ? savedDataHandler(obj, userData?.part_full_time)
              : false,
          show: true,
        })),
      },
    ]);
  }

  const setOriginCountry = ()=>{
    setCountryOfOrigin([
      {
        headerName: "",
        data: Array.from(CountryStore.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            [userData?.country_origin]?.length != 0
              ? savedDataHandler(obj, [userData?.country_origin])
              : false,
          show: true,
        })),
      },
    ]);
  }

  const setPreferredCountryFunction =()=>{
    setPreferredCountry([
      {
        headerName: "",
        data: Array.from(CountryStore?.data, ([id, name]) => ({
          id,
          name,
        })).map((obj) => ({
          ...obj,
          checked:
            userData?.preferred_country?.length != 0
              ? savedDataHandler(obj, userData?.preferred_country)
              : false,
          show: true,
        })),
      },
    ]);
  }

  useEffect(() => {
    if(Object.keys(userData).length !== 0){
      setAllChecked()
    }
  }, [userData]);
  useEffect(() => {
    updatePositionDataHandler()
  }, [userData?.preferred_job]);
  useEffect(() => {
    updatePastPositionDataHandler()
  }, [userData?.position_type]);
  
  useEffect(() => {
    setPreferredCountryFunction()
  }, [userData?.preferred_country]);

  useEffect(() => {
    setOriginCountry()
  }, [userData?.country_origin]);
  useMemo(()=>{
    switch(true){
      case (role=="candidate" && userData.profile_picture!=''):{
        setDisplayIcon(userData?.profile_picture);
        break;
      }
      case (role=="employer" && userData.company_logo!=''):{
        setDisplayIcon(userData?.company_logo);
        break;
      }
      default : setDisplayIcon(null)
    }
  },[userData.profile_picture,userData.company_logo,role])

  useEffect(() => {
    if (talkLoaded && Object.keys(userData).length>0) {
      let photoUrl = "";
      if (userData?.role === "candidate") {
        photoUrl =
          userData?.profile_picture !== ""
            ? userData?.profile_picture
            : "https://jobpark-bucket.s3.eu-north-1.amazonaws.com/Profile.png";
      } else {
        photoUrl =
          userData?.company_logo !== ""
            ? userData?.company_logo
            : "https://jobpark-bucket.s3.eu-north-1.amazonaws.com/CompanyLogo.png";
      }
      var currentUser = new Talk.User({
        id: userData.user_id,
        name: userData.name !== "" ? userData.name : "Temp",
        photoUrl: photoUrl,
        role: userData?.role==="administrator"?"employer" :userData.role,
        welcomeMessage: null,
      });
    }
  }, [talkLoaded, userData]);

  useEffect(() => {
    
    let putData={}
    switch (addClickedFor) {
      case "preferredJob": {
        let checked: any = [];
        let industryType:string[]=[]
        preferredJob.forEach((type: any) => {
          industryType.push(...getCheckedIds(type?.data, false, true));
        });
        let set1 = new Set(industryType);
        industryType = Array.from(set1);
        preferredJob.forEach((type: any) => {
          checked.push(...getCheckedIds(type?.data, true, false));
        });
        let uniqueSet = new Set(checked);
        checked = Array.from(uniqueSet);
        if (checked.length !== 0 && industryType.length<=3 ) {
          putData = {
            preferred_job: checked,
          };
          setShowPreferredJob(false);
          setShowUserProfile(true);
          setLoader(true)
        } 
        else if(industryType.length>3){
          setErrorText(t("please_select_only_3_industries"));
          setShowEditError(true);
          updatePastPositionDataHandler()
        }
        else {
          setErrorText(t("Please_select_Industry"));
          setShowEditError(true);
          updatePositionDataHandler()
        }
        break;
      }
      case "countryOrigin": {
        let originCountry = getCheckedIds(countryOfOrigin[0]?.data);
        if(originCountry.length!=0){
          putData = {
            country_origin: originCountry[0],
          };
          setShowCountryOrigin(false);
          setShowUserProfile(true);
          setLoader(true)
        }
        else{
          setErrorText(t("Please_Select_Country"));
          setShowEditError(true);
        }
        setOriginCountry()
        break;
      }
      case "preferredCountry": {
        let preferred = getCheckedIds(preferredCountry[0]?.data);
        if(preferred.length!=0){
          putData = {
            preferred_country: preferred,
          };
          setShowPreferredCountry(false);
          setShowUserProfile(true);
          setLoader(true)
        }
        else{
          setErrorText(t("Please_Select_Country"));
          setShowEditError(true);
          setPreferredCountryFunction()
        }
        break;
      }
      case "experience": {
        let industryType: any = [];
        let positionType: any = [];
        experience.forEach((type: any) => {
          industryType.push(...getCheckedIds(type?.data, false, true));
        });
        let set1 = new Set(industryType);
        industryType = Array.from(set1);
        experience.forEach((type: any) => {
          positionType.push(...getCheckedIds(type?.data, false, false));
        });
        let set2 = new Set(positionType);
        positionType = Array.from(set2);
         if (industryType.length !== 0 && industryType.length<=3 && positionType.length !== 0) {
          putData = {
            industry_type: industryType,
            position_type: positionType,
          };
          setShowExperience(false);
          setShowUserProfile(true);
          setLoader(true)
        }
        else if(industryType.length>3){
          setErrorText(t("please_select_only_3_industries"));
          setShowEditError(true);
          updatePastPositionDataHandler()
        }       
        else {
          setErrorText(t("Please_select_Industry"));
          setShowEditError(true);
          updatePastPositionDataHandler()
        }

        break;
      }
      case "primaryLanguage": {
        let firstLanguage = getCheckedIds(primaryLanguage[0]?.data);
        if (firstLanguage.length !== 0) {
          putData = {
            primary_language: firstLanguage,
          };
          setShowPrimaryLanguage(false);
          setShowUserProfile(true);
          setLoader(true)
        } else {
          setErrorText(t("Please_Select_First_Language"));
          setShowEditError(true);
          setLanguage()
        }
        break;
      }
      case "secondaryLanguage": {
        let secondLanguage = getCheckedIds(secondaryLanguage[0]?.data);
        putData = {
          secondary_language: secondLanguage ?? [],
        };
        setShowSecondaryLanguage(false);
        setShowUserProfile(true);
        setLoader(true)
        break;
      }
      case "license": {
        let drivingLicense: any = [];
        license.forEach((type: any) => {
          drivingLicense.push(...getCheckedIds(type?.data));
        });
        putData={
          license_type:drivingLicense
        }
        setShowLicense(false)
        setShowUserProfile(true);
        setLoader(true)
        break;
      }
      case "workType": {
        let selected =getCheckedIds(workType[0]?.data)
        if (selected.length !== 0) {
          putData = {
            part_full_time: selected,
          };
          setShowWorkType(false);
          setShowUserProfile(true);
          setLoader(true)
        } else {
          setErrorText(t("Please_select_work_schedule"));
          setShowEditError(true);
          setPartTimeFullTime()
        }
        break;
      }
      case "about": {
        putData = {
          about_yourself: about,
        };
        setShowAbout(false)
        setShowUserProfile(true)
        setLoader(true)
        break;
      }
      case "socialmedia": {
        putData = {
          social_facebook_link:
            socialMediaLinks?.facebook ?? "https://facebook.com",
          social_instagram_link: socialMediaLinks?.instagram ?? "",
          social_linkedin_link: socialMediaLinks?.linkedin ?? "",
          social_twitter_link: socialMediaLinks?.twitter ?? "",
        };
        setShowSocialMediaEdit(false)
        setShowUserProfile(true)
        setLoader(true)
        break;
      }
      case "euCitizen":{
        putData={
          citizen:euCitizen
        }
        setShowEUCitizen(false)
        setShowUserProfile(true)
        setLoader(true)
        // putData={
        //   citizen:euCitizen
        // }
        break;
      }
      case "cv":{
        putData={
          provide_references:references
        }
        setShowCVEdit(false)
        setShowUserProfile(true)
        setLoader(true)
        break;}
      default:
        break;
    }
    if(Object.keys(putData).length !== 0){
      updateProfile(userData?.user_id,putData)
      .then(res=> {
        if(res?.success){
          getUserDetails(userData?.user_id)
          .then(res=> {
            if(res?.success){
              setUserData(res?.data)
              localStorage.setItem("userData", JSON.stringify(res?.data ?? ""));
              setLoader(false)
            }
          })
        }
      })
    }
    setAddClickedFor('')
    
  }, [addClickedFor]);

  const handleProfileClick = () => {
    // if (
    //   userData?.is_subscribed &&
    //   !userData?.profile_status &&
    //   userData?.role === "employer"
    // ) {
    //   setShowUserProfile(false);
    //   setErrorText(t("Please_complete_your_profile"));
    //   setShowError(true);
    // } else {
    //   setShowUserProfile(true);
    // }
    setShowUserProfile(true)
  };

  const StyleHeadIconsDesktop = styled.div`
    position: absolute;
    top: 12px;
    right: 20px;
    display: ${showBottomMenu ? "block" : "none"};
    @media only screen and (min-width: 1000px) {
      display: block;
      position: fixed;
      top: 31px;
      right: 94px;
    }
  `;

  const StyleHeadIconsDesktopHome = styled.div`
    position: absolute;
    top: 15px;
    right: 0px;
    display: ${showBottomMenu ? "block" : "none"};
    @media only screen and (min-width: 1000px) {
      display: block;
      position: fixed;
      top: 31px;
      right: 94px;
    }
  `;
  // Function to Get all the checked IDs
  const getCheckedIds = (data: [], preferredJob?: boolean | undefined,industryOnly?: boolean | undefined) => {
    let checked: any = [];
    if (preferredJob) {
      data?.forEach((item: any) => (item?.checked ? checked.push(item?.id, item?.parentId) : ""));
    } 
    else if(industryOnly){
      data?.forEach((item: any) => (item?.checked ? checked.push(item?.parentId) : ""));
    }
    else {
      data?.forEach((item: any) => (item?.checked ? checked.push(item?.id) : ""));
    }
    return checked;
  };
  const checkDropdown =()=>{
    if(showDropdown) setShowDropdown(false)
  }

  if(!taxonomyLoaded) return <Loader/>
  return (
    <PrivateContext.Provider value={contextValues}>
      <LeftPane onClick={()=>  checkDropdown()}>
        <DesktopLayout />
      </LeftPane>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowUserProfile(false)}
        opened={showUserProfile}
      >
        {role === "candidate" ? (
          <>
          {loader ? 
          <Loader/>:
          <UserProfileDisplay
          selectedUser={userData}
          onHideModel={setShowUserProfile}
          type={1}
          setShowMobileVerify={() => {
            setShowMobileVerify(true);
            setShowUserProfile(false);
          }}
        />
          }
          </>
        ) : (
          <CompanyProfileDisplay
            selectedUser={userData}
            onHideModel={setShowUserProfile}
            type={1}
            setShowMobileVerify={() => {
              setShowMobileVerify(true);
              setShowUserProfile(false);
            }}
          />
        )}
      </Drawer>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowMobileVerify(false)}
        opened={showMobileVerify}
        onClick={(e: any) => {
          //For closing the DropDown when click on Left Wrapper
          if (showDropdown) setShowDropdown(false);
        }}
      >
        <AddPhone
          setShowMobileVerify={() => {
            setShowMobileVerify(false);
            setShowUserProfile(false);
          }}
        />
      </Drawer>
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => setShowError(false)}
        callFunction={() => history.push("/edit-profile")}
      />
      <ErrorModal
      zIndex={10000}
        opened={showEditError}
        message={errortext}
        onClose={() => {setShowEditError(false);}}
      />
      <RightPane>
        {typeOfLayout == "default" && (
          <>
            <StyleLogoMobile>
              {showMobileViewLogo && (
                <StyleLogoImage
                  src={JobParkLogoTransparent}
                  onClick={() =>
                    role === "candidate"
                      ? history.push("/home")
                      : history.push("/employer-home")
                  }
                />
              )}
            </StyleLogoMobile>
            {(role === "candidate" ||
              (role === "employer" && userData?.is_subscribed)) && (
              <StyleHeadIconsDesktop>
                {/* <MemoNotification height='28px' width='23px' /> */}
                <div
                  style={{
                    float: "right",
                    marginLeft: "9px",
                  }}
                >
                  <img
                    alt="profile picture"
                    src={
                      displayIcon != null
                        ? displayIcon
                        : role === "employer"
                        ? CompanyLogo
                        : Profile
                    }
                    onClick={() => {
                      handleProfileClick();
                    }}
                    width="28px"
                    height="28px"
                    style={{
                      borderRadius: "56px",
                      cursor: "pointer",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </StyleHeadIconsDesktop>
            )}
          </>
        )}
        {typeOfLayout == "homeFavourite" && (
          <HomeScreenStyling>
            <StyleLogoMobileHome>
              {showMobileViewLogo && (
                <StyleLogoImage
                  src={JobParkLogoTransparent}
                  onClick={() =>
                    role === "candidate"
                      ? history.push("/home")
                      : history.push("/employer-home")
                  }
                />
              )}
            </StyleLogoMobileHome>
            {(role === "candidate" ||
              (role === "employer" && userData?.is_subscribed)) && (
              <StyleHeadIconsDesktopWrapper>
                <StyleHeadIconsDesktopHome>
                  {/* <MemoNotification height='28px' width='23px' /> */}
                  <div
                    style={{
                      float: "right",
                      marginLeft: "9px",
                    }}
                  >
                    <img
                      src={
                        displayIcon != null
                          ? displayIcon
                          : role === "employer"
                          ? CompanyLogo
                          : Profile
                      }
                      onClick={() => {
                        handleProfileClick();
                      }}
                      alt="profile picture"
                      width="28px"
                      height="28px"
                      style={{
                        borderRadius: "56px",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </StyleHeadIconsDesktopHome>
              </StyleHeadIconsDesktopWrapper>
            )}
          </HomeScreenStyling>
        )}
        {showBottomMenu && (
          <>
            <MobileLayout
              setShowFilter={setShowFilter}
              isFavouriteScreen={isFavouriteScreen}
              isFilterAtSide={isFilterAtSide}
            />
          </>
        )}
        <StyleWrapperTop>
          <StyleWrapperElement>{children}</StyleWrapperElement>
        </StyleWrapperTop>
      </RightPane>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowPreferredJob(false)}
        opened={showPreferredJob}
      >
        <PositionSearch
          title={t("your_search")}
          dependent={false}
          getData={setPreferredJob}
          passData={preferredJob}
          onHide={() => {setShowPreferredJob(false);setShowUserProfile(true)}}
          setAddClickedFor={() => setAddClickedFor("preferredJob")}
          dontHideDrawer ={true}
        />
      </Drawer>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowExperience(false)}
        opened={showExperience}
      >
        <PositionSearch
          title={t("Positions_held")}
          dependent={false}
          getData={setExperience}
          passData={experience}
          onHide={() => {setShowExperience(false);setShowUserProfile(true)}}
          setAddClickedFor={() => setAddClickedFor("experience")}
          dontHideDrawer ={true}
          showNone={true}
        />
      </Drawer>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowCountryOrigin(false)}
        opened={showCountryOrigin}
      >
        <CountrySearch
          onHide={() => {setShowCountryOrigin(false);setShowUserProfile(true)}}
          getData={setCountryOfOrigin}
          passData={countryOfOrigin}
          title={t("Where_are_you_from")}
          singleSelect={true}
          savedData={
            [userData?.country_origin]?.length != 0
              ? [userData?.country_origin]
              : []
          }
          setAddClickedFor={() => setAddClickedFor("countryOrigin")}
          dontHideDrawer ={true}
        />
      </Drawer>
      <Drawer
        position="right"
        padding={"0"}
        onClose={() => setShowPreferredCountry(false)}
        opened={showPreferredCountry}
      >
        <CountrySearch
          onHide={() => {setShowPreferredCountry(false);setShowUserProfile(true)}}
          getData={setPreferredCountry}
          passData={preferredCountry}
          title={t("Country")}
          savedData={userData?.preferred_country?.length != 0 ? userData?.preferred_country : []}
          checkAll={true}
          singleSelect={false}
          setAddClickedFor={() => setAddClickedFor("preferredCountry")}
          dontHideDrawer ={true}
        />
      </Drawer>
      <Drawer
        padding={"0px"}
        position="right"
        opened={showPrimaryLanguage}
        onClose={() => setShowPrimaryLanguage(false)}
      >
        <LanguageSearch
          title={t("First_language")}
          passData={primaryLanguage}
          getData={setPrimaryLanguage}
          onHide={() => {setShowPrimaryLanguage(false);setShowUserProfile(true)}}
          savedData={
            userData?.primary_language?.length != 0
              ? userData?.primary_language
              : []
          }
          selectedPrimarySecondaryLanguageIds={userData?.secondary_language}
          setAddClickedFor={() => setAddClickedFor("primaryLanguage")}
          dontHideDrawer ={true}
          primaryLanguage={true}
        />
      </Drawer>
      <Drawer
        padding={"0px"}
        position="right"
        opened={showSecondaryLanguage}
        onClose={() => setShowSecondaryLanguage(false)}
      >
        <LanguageSearch
          title={t("Second_language")}
          passData={secondaryLanguage}
          getData={setSecondaryLanguage}
          onHide={() => {setShowSecondaryLanguage(false);setShowUserProfile(true)}}
          savedData={
            userData?.secondary_language?.length != 0
              ? userData?.secondary_language
              : []
          }
          selectedPrimarySecondaryLanguageIds={userData?.primary_language}
          setAddClickedFor={() => setAddClickedFor("secondaryLanguage")}
          dontHideDrawer ={true}
          showNone={true}
        />
      </Drawer>
      <Drawer
        padding={"0px"}
        position="right"
        onClose={() => setShowLicense(false)}
        opened={showLicense}
      >
        <SelectDrivingLicense
          title={t("Required_drivers_license")}
          onHide={() => {setShowLicense(false);setShowUserProfile(true)}}
          getData={setLicense}
          passData={license}
          savedData={
            userData?.license_type?.length != 0 ? userData?.license_type : []
          }
          setAddClickedFor={() => setAddClickedFor("license")}
          dontHideDrawer ={true}
          showNone={true}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position="right"
        onClose={() => {
          setShowWorkType(false);
        }}
        opened={showWorkType}
      >
        <PartTimeFullTimeSearch
          title={t("What_position_need_to_be_employed")}
          onHide={() => {
            setShowWorkType(false);
            setShowUserProfile(true)
          }}
          getData={setWorkType}
          passData={workType}
          savedData={userData?.part_full_time != 0 ? userData?.part_full_time : []}
          setAddClickedFor={() => setAddClickedFor("workType")}
          dontHideDrawer ={true}
          buttonText={t("Save")}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position="right"
        onClose={() => {
          setShowAbout(false);
        }}
        opened={showAbout}
      >
        <AboutDrawer
        title={t("about")}
        onHide={() => {
          setShowAbout(false);
          setShowUserProfile(true)
        }}
        setAddClickedFor={() => setAddClickedFor("about")}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position="right"
        onClose={() => {
          setShowSocialMediaEdit(false);
          setShowUserProfile(true)
        }}
        opened={showSocialMediaEdit}
      >
        <SocialMedaiDrawer
        title={t("about")}
        onHide={() => {
          setShowSocialMediaEdit(false);
          setShowUserProfile(true)
        }}
        setAddClickedFor={() => setAddClickedFor("socialmedia")}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position="right"
        onClose={() => {
          setShowEUCitizen(false);
        }}
        opened={showEUCitizen}
      >
        <EUCitizenDrawer
        //title={t("about")}
        onHide={() => {
          setShowEUCitizen(false);
          setShowUserProfile(true)
        }}
        setAddClickedFor={() => setAddClickedFor("euCitizen")}
        />
      </Drawer>
      <Drawer
        padding={"0"}
        position="right"
        onClose={() => {
          setShowCVEdit(false);
        }}
        opened={showCVEdit}
      >
        <CVDrawer
        //title={t("about")}
        onHide={() => {
          setShowCVEdit(false);
          setShowUserProfile(true)
        }}
        setAddClickedFor={() => setAddClickedFor("cv")}
        />
      </Drawer>
    </PrivateContext.Provider>
  );
};

export default PrivateLayout;
