import axiosInstance from "services/AxiosInstance"

export const createSaveFilter = ( data:{}) => {
    return axiosInstance.post(`searches`, data)
        .then((response) => response.data)
        .catch((error) => error.response.data)
}

// get all save filters
export const getSavedFilters= async (searchParams?: any) => {
    const res = await axiosInstance.get(`searches`, {
      params: {
        ...searchParams,
      },
    });
    return res.data;
  };

export const deleteSavedFilter = (id:number)=>{
    return axiosInstance.delete(`searches/${id}`)
    .then((response)=> response.data)
    .catch((error)=>error)
}
// get filter
export const getFilter = (id:number)=>{
  return axiosInstance.get(`searches/${id}`)
  .then((response)=> response.data)
  .catch((error)=>error)
}
//update filter
export const updateFilter=(id:number,data:{})=>{
  return axiosInstance.put(`searches/${id}`, data)
      .then((response) => response.data)
      .catch((error) => error.response.data)
}