import React, { ReactNode, useState, useEffect } from "react";
import styled from "styled-components";
import { Box, SimpleGrid, Space, Text } from "@mantine/core";
import MemoGrayArrow from "ui/icons/GrayArrow";
import Labelwithinfo from "ui/data-display/LabelwithInfo";
import { ProfilCreationAlertModel } from "ui/data-display/ProfilCreationAlertModel";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";

interface MultiSelectButtonProps {
  children?: ReactNode;
  show?: boolean;
  setData?: any;
  data: any;
  buttonLabel?: string;
  type: string;
  onClick: any;
  header?: string;
  showAll?: any;
  languageIds?: any;
  infoText?: string;
  errorMessage?: string;
  placeholder?:string
  //customHeight?: string;
}

const StyleContainer = styled(Box)`
  //margin-top: 50px;
`;

const StyledSelectedItemsOut = styled(Box)`
  background: #e4e3e3;
  border-radius: 10px;
  padding: 10.5px 10px 10.5px 10px;
  width: fit-content;
  height: fit-content;
  max-width: 200px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 21px; //increased as per tester
  color: #6e6e6e;
  border: 1px solid #fff;
  display: inline-block;
  margin: 0px 10px 10px 0;
  //padding-right: 30px;
  position: relative;
  border: none;
  @media only screen and (min-width: 1000px) {
    margin: 0px 10px 10px 7px;
  }
`;
const StyleErrorOut = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: -10px;
  word-break: break-word;
  color: #f03e3e;
  font-family: "Poppins";
  display: flex;
`;

const StyleErrorIn = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  font-family: "Poppins";
  display: flex;
`;

const StyledSelectedItemsIn = styled(Box)`
  background: #ff5500;
  border-radius: 10px;
  padding: 9.5px 10px 9.5px 10px;
  width: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  border: 1px solid #fff;
  //display: inline-block;
  margin: 3px;
  //z-index: 20;
  //position: relative;
  display: flex;
  height: fit-content;
  @media only screen and (min-width: 1000px)  {
    height: auto;
  }
`;

const StyleSelectButtonIn = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e6e6e;
  cursor: pointer;
  padding: 7px 5px;
  justify-content: center;
  width: auto;
`;

const StyleSelectButtonOut = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e6e6e;
  cursor: pointer;
  padding: 5px 5px 5px 12px;
  justify-content: center;
  width: 100%;
  margin-right: 30px;
`;

const StyleIcon = styled.div`
  float: right;
  margin-right: 10px;
  transform: rotate(90deg);
`;

interface StyleProps{
  firstLanguage?:boolean
}
const StyleTextButtonIn = styled.div<StyleProps>`
  /* ${p=> p?.firstLanguage==true ? "display:block;text-align:left;margin-top:8px;margin-left:10px" : "display:inline-block;margin-right:10px;"}; */
  margin-left: 3px;
  display: inline-block;
`;

const StyleHead = styled(Text)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  padding: 0px;
  color: #123b3d;
  margin-left: 1px;
  margin-bottom: 5px;
   white-space: pre-wrap ;
  /* padding-top: 20px; */
  @media only screen and (min-width: 1000px) and (max-width: 1280px) {
    //margin-top: -10px;
    // height: 40px;
  }
`;

const StyleCancelButtonOut = styled.div`
  color: #6e6e6e;
  margin-left: 30px;
  /* cursor: pointer; */
  /* display: inline-block; */
  /* margin: 10.5px 10px 0px 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: start; */
`;

const StyleCancelButtonIn = styled.div`
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 10px;
`;

const StyleWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 15px;
`;

export const MultiSelectButton: React.FC<MultiSelectButtonProps> = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();
  const {width} = useViewportSize()
  const [boxStyles,setBoxStyles] =useState<object>({
    width: "100%",
    minHeight: "54px",
    margin: "0px",
    display:"flex",
    flexWrap:"wrap"
  })
  const returnIds = (data: any, obj: any) => {
    let flag = false;
    data?.map((id: any) => {
      if (id == obj.id) flag = true;
    });
    return flag;
  };

  const checkAllTagsSelectedHandler = () => {
    let flag = true;
    // console.log(`Tab ids`, props?.languageIds);
    props?.data?.map((element: any) => {
      element?.data?.map((checkBoxData: any) => {
        if (
          !checkBoxData.checked &&
          !returnIds(props?.languageIds, checkBoxData)
        ) {
          flag = false;
        }
      });
    });
    return flag;
  };

  const unSelectElementHandler = (deleteData: any, parentId?: any) => {
    let deletedData: any = [];
    if (parentId) {
      props?.data.map((element: any) => {
        let updatedData: any = {};
        if (element?.parentId) {
          updatedData["parentId"] = element?.parentId;
        }
        updatedData["headerName"] = element.headerName;
        updatedData["data"] = element?.data.map((checkBoxData: any) => {
          let objectData = {};
          if (element?.parentId == parentId) {
            objectData = {
              name: checkBoxData.name,
              id: checkBoxData.id,
              checked: false,
              show: checkBoxData.show,
              parentId:element?.parentId
            };
          } else {
            objectData = {
              name: checkBoxData.name,
              id: checkBoxData.id,
              checked: checkBoxData.checked,
              show: checkBoxData.show,
              parentId:element?.parentId
            };
          }
          return objectData;
        });
        deletedData.push(updatedData);
      });
    } else {
      props?.data.map((element: any) => {
        let updatedData: any = {};
        if (element?.parentId) {
          updatedData["parentId"] = element?.parentId;
        }
        updatedData["headerName"] = element.headerName;
        updatedData["data"] = element?.data.map((checkBoxData: any) => {
          
          let objectData = {};
          if (deleteData == checkBoxData) {
            objectData = {
              name: checkBoxData.name,
              id: checkBoxData.id,
              checked: false,
              show: checkBoxData.show,
              parentId:element?.parentId
            };
          } else {
            objectData = {
              name: checkBoxData.name,
              id: checkBoxData.id,
              checked: checkBoxData.checked,
              show: checkBoxData.show,
              parentId:element?.parentId
            };
          }
          return objectData;
        });
        deletedData.push(updatedData);
      });
    }

    props.setData(deletedData);
  };

  const cancelAllTagsHandler = () => {
    let deletedAllData: any = [];
    props?.data.map((element: any) => {
      let updatedData: any = {};
      updatedData["headerName"] = element.headerName;
      updatedData["data"] = element?.data?.map((checkBoxData: any) => {
        let objectData = {
          name: checkBoxData.name,
          id: checkBoxData.id,
          checked: false,
          show: checkBoxData.show,
          parentId:element?.parentId
        };
        return objectData;
      });
      deletedAllData.push(updatedData);
    });
    props.setData(deletedAllData);
  };

  const getHeader = (elements: any) => {
    let headerName = "";
    elements?.data?.forEach((data: any) => {
      if (data?.checked === true) {
        headerName = elements?.headerName;
      }
    });
    return headerName;
  };
  useEffect(() => {
    if (width > 1000) {
      setBoxStyles({
        ...boxStyles,
        minHeight: "40px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      });
    } else {
      setBoxStyles({
        ...boxStyles,
        width: "100%",
        minHeight: "108px",
        margin: "0px",
        placeContent:"flex-start"
      });
    }
  }, [width]);
  return (
    <>
      <StyleContainer>
        {props.type == "out" ? (
          <>
            <StyleHead>
              <Labelwithinfo
                label={`${props.header}`}
                onClick={() => setShowInfo(true)}
                showInfoIcon={props?.infoText!='' && props.infoText ? true :false}
              />
            </StyleHead>
            <ProfilCreationAlertModel
              opened={showInfo}
              infoText={props?.infoText}
              onClose={() => setShowInfo(false)}
            />
            <StyleWrapper>
              <StyleSelectButtonOut onClick={props.onClick}>
                <p style={{ fontSize: "16px" }}>
                  {props.buttonLabel}{" "}
                  <StyleIcon>
                    <MemoGrayArrow />
                  </StyleIcon>
                </p>
              </StyleSelectButtonOut>
            </StyleWrapper>
            <StyleErrorOut>{props?.errorMessage}</StyleErrorOut>
            <Box
              style={{
                minWidth: "300px",
                margin: "10px 0 8px 0px",
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "fit-content",
              }}
            >
              {props?.data?.length > 0 &&
                props?.data?.map((elements: any) => {
                  // props?.data?.sort(function (a: any, b: any) {
                  //     if (a.headerName < b.headerName) { return -1; }
                  //     if (a.headerName > b.headerName) { return 1; }
                  //     return 0;
                  // })
                  // elements?.data?.sort(function (a: any, b: any) {
                  //     if (a.name < b.name) { return -1; }
                  //     if (a.name > b.name) { return 1; }
                  //     return 0;
                  // })
                  // return<SelectedItems elements={elements} />
                  // getHeader(elements)
                  let count = 0;
                  let filteredElements = elements.data?.filter(
                    (obj: any) => obj.checked === true
                  );
                  return (
                    <>
                      {getHeader(elements) && (
                        <StyledSelectedItemsOut>
                          <div style={{display:"flex", justifyContent:"space-between",fontWeight: "600"}}>
                          {getHeader(elements)}
                          <br />
                          <StyleCancelButtonOut
                            id={`${elements?.id}`}
                            onClick={() =>
                              unSelectElementHandler({}, elements?.parentId)
                            }
                          >
                            <div style={{ cursor: "pointer" }}>X</div>
                          </StyleCancelButtonOut>
                          </div>
                          <div style={{display:'flex', flexWrap:'wrap'}}>
                          {filteredElements.map((data: any, index: number) => {
                            return (
                              <>
                                {data?.name}
                                {filteredElements.length != index + 1
                                  ? ", "
                                  : ""}
                              </>
                            );
                          })}
                          </div>
                        </StyledSelectedItemsOut>
                      )}
                    </>
                  );
                  // return elements?.data?.map((data: any) => {
                  //   if (data?.checked == true) {
                  //     return (
                  //       <>
                  //       <StyledSelectedItemsOut>
                  //         {elements?.headerName}<br/>
                  //         {data?.name}
                  //         <StyleCancelButtonOut onClick={() => unSelectElementHandler(data)}>
                  //           <div style={{ cursor: "pointer" }}>X</div>
                  //         </StyleCancelButtonOut>
                  //       </StyledSelectedItemsOut>
                  //       </>
                  //     );
                  //   }
                  // });
                })}
            </Box>
          </>
        ) : (
          <>
            <StyleHead>
              <Labelwithinfo
                label={`${props.header}`}
                onClick={() => setShowInfo(true)}
                showInfoIcon={props?.infoText!='' && props.infoText ? true :false}
              />
            </StyleHead>
            <ProfilCreationAlertModel
              opened={showInfo}
              infoText={props?.infoText}
              onClose={() => setShowInfo(false)}
            />
            <StyleSelectButtonIn onClick={props.onClick} >
              <Box
                style={boxStyles}
              >
                {props?.data?.length > 0 &&
                  props?.data?.map((elements: any) => {
                    let showAllTab = true;
                    return elements?.data?.map((data: any, index: any) => {
                      if (data?.checked == true) {
                        if (props?.showAll) {
                          if (checkAllTagsSelectedHandler() && showAllTab) {
                            // Flag is return as true if all are checked
                            showAllTab = false;
                            return (
                              <StyledSelectedItemsIn>
                                {t("All")}
                                <StyleCancelButtonIn
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    cancelAllTagsHandler(); // Will clear all the selected flag to false
                                  }}
                                >
                                  X
                                </StyleCancelButtonIn>
                              </StyledSelectedItemsIn>
                            );
                          } else if (!checkAllTagsSelectedHandler()) {
                            return (
                              <StyledSelectedItemsIn>
                                <div>
                                {data?.name}
                                </div>
                                <StyleCancelButtonIn
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    unSelectElementHandler(data);
                                  }}
                                >
                                  X
                                </StyleCancelButtonIn>
                              </StyledSelectedItemsIn>
                            );
                          }
                        } else {
                          return (
                            <StyledSelectedItemsIn>
                              <div>
                              {data?.name}
                              </div>
                              <StyleCancelButtonIn
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unSelectElementHandler(data);
                                }}
                              >
                                X
                              </StyleCancelButtonIn>
                            </StyledSelectedItemsIn>
                          );
                        }
                      }
                    });
                  })}
                <StyleTextButtonIn firstLanguage={props.header==t("Select_Pri_lang")?true:false}>{props.placeholder ?t(props.placeholder): t("Search__Select")}</StyleTextButtonIn>
              </Box>
            </StyleSelectButtonIn>
            <StyleErrorIn>{props?.errorMessage}</StyleErrorIn>
          </>
        )}
      </StyleContainer>
    </>
  );
};
