import React, { ReactNode, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import MemoWhiteChevronLeft from "ui/icons/WhiteChevronLeft";
import InputField from "ui/inputs/Inputfield";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { subscriptionOrder } from "services/subscription-plan/subscription";
import { ErrorModal } from "ui/modals/ErrorModal";

interface ContactUsFormProps {
  children?: ReactNode;
  show?: boolean;
  onHideModel?: any;
  setShowContactUs?: any;
  setShowSubscriptionListing?: any;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const HeaderText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #123b3d;
  margin: 0 0 30px 0;
`;

const BackButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 22px 0 0 20px;
  cursor: pointer;
`;

const ContentBox = styled.div`
  margin: 45px 20px auto 20px;
`;

const MarginBox = styled.div`
  margin: 0 0 14px 0;
`;

const StyleButtonWrapper = styled.div`
  margin: auto 22px 12px 22px;
  position: absolute;
  bottom: 0px;
  width: 90%;
  cursor: pointer;
  @media only screen and (max-height: 520px) {
    position: sticky;
  }
`;

const ButtonSubmit = styled.button`
  background: linear-gradient(90deg, #ff3000 6.7%, #ff5500 95.4%);
  border-radius: 52px;
  border: none;
  min-height: 54px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
`;

const ContactUsForm: React.FC<ContactUsFormProps> = (props) => {
  const { t } = useTranslation();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalMessage, setErrorShowModalMessage] = useState("");

  const schema = Yup.object().shape({
    name: Yup.string().required(t("Name_required_field")),
    email: Yup.string().email(t("Invalid_email_address")).required(t("Please_enter_email_address")),
    // phone: Yup.string().required('Phone is a required field'),
    phone: Yup.string()
      .required(t("Please_enter_phone_number"))
      .matches(/^\+[1-9]{1}[0-9]{3,14}$/, t("Phone_number_is_not_valid")),
    company_name: Yup.string().required(t("Company_Nameis_a_required_field")),
  });

  // API Call for Contact Us
  const contactUsAPIHandler = (data: any) => {
    subscriptionOrder(data)
      .then((response) => {
        // console.log(`res contact us`, response);
        // props.setShowSubscriptionListing(false);
        if (response?.success) {
          setErrorShowModalMessage(response?.message);
          setShowErrorModal(true);
        }
      })
      .catch((error) => {
        props.setShowSubscriptionListing(true);
      });
  };

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      type: "contact_us",
      name: "",
      email: "",
      phone: "",
      company_name: "",
    },
  });

  return (
    <Wrapper>
      <ErrorModal
        opened={showErrorModal}
        message={showErrorModalMessage}
        onClose={() => {
          props.setShowSubscriptionListing(false);
          props.onHideModel(false);
          setShowErrorModal(false);
        }}
      />
      <BackButtonWrapper>
        <MemoWhiteChevronLeft
          width='10px'
          height='21px'
          stroke={"#1A1D1E"}
          onClick={() => props.onHideModel(false)}
        />
      </BackButtonWrapper>
      <ContentBox>
        <HeaderText>{t("Contact_Us")}</HeaderText>
        <form id='ContactUs_Form' onSubmit={form.onSubmit((values) => contactUsAPIHandler(values))}>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='name'
              placeholder={t("Name")}
              {...form.getInputProps("name")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='email'
              placeholder={t("Email")}
              {...form.getInputProps("email")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='Phone'
              placeholder={t("Phone")}
              {...form.getInputProps("phone")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='company_name'
              placeholder={t("Company_name")}
              {...form.getInputProps("company_name")}
            />
          </MarginBox>
        </form>
      </ContentBox>
      <StyleButtonWrapper>
        <ButtonSubmit style={{ cursor: "pointer" }} type={"submit"} form={"ContactUs_Form"}>
          {t("Submit")}
        </ButtonSubmit>
      </StyleButtonWrapper>
    </Wrapper>
  );
};
export default ContactUsForm;
