interface countryCode {
  label: string;
  value: string;
}

export function getCountryCodes(){
  let countryCodes: countryCode[] = [
    { label: "--", value: "--" },
    { label: "Austria", value: "+43" },
    { label: "Belgium", value: "+32" },
    { label: "Bulgaria", value: "+359" },
    { label: "Croatia", value: "+385" },
    { label: "Cyprus", value: "+357" },
    { label: "Czech_Republic", value: "+420" },
    { label: "Denmark", value: "+45" },
    { label: "Netherlands", value: "+31" },
    { label: "United_Kingdom", value: "+44" },
    { label: "Estonia", value: "+372" },
    { label: "Finland", value: "+358" },
    { label: "France", value: "+33" },
    { label: "Germany", value: "+49" },
    { label: "Greece", value: "+30" },
    { label: "Hungary", value: "+36" },
    { label: "Iceland", value: "+354" },
    { label: "Ireland", value: "+353" },
    { label: "Italy", value: "+39" },
    { label: "Latvia", value: "+371" },
    { label: "Lithuania", value: "+370" },
    { label: "Luxembourg", value: "+352" },
    { label: "Malta", value: "+356" },
    { label: "Norway", value: "+47" },
    { label: "Poland", value: "+48" },
    { label: "Portugal", value: "+351" },
    { label: "Romania", value: "+40" },
    { label: "Slovakia", value: "+421" },
    { label: "Slovenia", value: "+386" },
    { label: "Spain", value: "+34" },
    { label: "Sweden", value: "+46" },
    { label: "Ukraine", value: "+380" },
  ];
  if (
    process.env.REACT_APP_ENV == "staging" ||
    process.env.REACT_APP_ENV == "dev"
  ) {
    countryCodes.push({ label: "India", value: "+91" });
  }
  return countryCodes;
};
