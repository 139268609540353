import styled from "@emotion/styled";
import React from "react";
import MemoInfo from "ui/icons/Info";

interface labelProps {
  label: string;
  onClick: (event: any) => void;
  showInfoIcon?:boolean
}

const StyledLabel = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #123b3d;
`;
const LabelwithInfo: React.FC<labelProps> = (props) => {
  return (
    <>
      <StyledLabel>{props.label}</StyledLabel>
      {props?.showInfoIcon && (
        <MemoInfo
          height="15px"
          width="15px"
          style={{ alignSelf: "center", paddingLeft: "5px" }}
          {...props}
        />
      )}
    </>
  );
};

export default LabelwithInfo;
