import axiosInstance from "services/AxiosInstance"

export const getEmployers=()=>{
    return axiosInstance.get('company-list?_format=json')
    .then((response)=> response.data)
    .catch((error)=>error)
}

export const setProfileStatus=(reason:{})=>{
    return axiosInstance.put(`deactivate-profile`,reason)
    .then((response)=> response.data)
    .catch((error)=>error)
}

export const updateProfile =(userId:string,data:{})=>{
    return axiosInstance.put(`profile-complete/${userId}`, data)
        .then((response) => response.data)
        .catch((error) => error)
}