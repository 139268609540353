import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Modal as MTModal } from "@mantine/core";
import styled from "styled-components";
import Button from "ui/buttons/Button";
import { Box } from "@mantine/core";
import MemoChatModalIcon from "ui/icons/ChatModalIcon";
import MemoFavModalIcon from "ui/icons/FavModalIcon";
import { UserContext } from "context/state";
import { useTranslation } from "react-i18next";

interface ChatFavModalProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: any;
  buttonOnClick?: any;
  type: 1 | 2; // 1 - Chat & 2 - Favourites //todo define this as enum
  name?: string;
  favourite?: boolean;
}

const StyleElement = styled(Box)`
  margin: 50px 0 0 0;
`;

const StyleHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #123b3d;
  margin: 25px 0 0 0;
`;

const StyleDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #6e6e6e;
`;

export const ChatFavModal: React.FC<ChatFavModalProps> = ({
  children,
  show = false,
  type,
  onHide,
  buttonOnClick,
  name,
  favourite,
}) => {
  // const [opened, setOpened] = useState(true);
  const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${type == 1 ? "40px" : "17px"} 0 0 0;
  `;
  const contextData = useContext(UserContext);
  const getFavouriteText = () => {
    //todo mapper for userData
    //console.log("Favorites", contextData?.userData?.is_subscribed, favourite);
    if (!contextData?.userData?.is_subscribed && !favourite)
      return `${t("to_add")} ${name ? name : "user"} ${t("to_fav")}`;
    else {
      if (navigator?.language == "nb" || navigator?.language == "no")
        return `${t("to_remove")}${name ? name : "user"}${t("from_fav")}`;
      else {
        return `${t("to_remove_fav")}`;
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <MTModal
        opened={show}
        padding={20}
        radius={30}
        onClose={() => onHide(false)}
        title={
          <>
            <LogoWrapper>{type == 1 ? <MemoChatModalIcon /> : <MemoFavModalIcon />}</LogoWrapper>
            <StyleHead>{type == 1 ? t("Start_talking") : t("Add_to_favourites")}</StyleHead>
            <StyleDescription>
              {type == 1 //todo make this a component type as param
                ? `${t("To_chat_with_name")} ${name ? name : "user"} ${t(
                    "please_select_subscription"
                  )}`
                : getFavouriteText()}
            </StyleDescription>
          </>
        }
        styles={{
          close: {
            display: "none",
          },
          modal: {
            maxWidth: "339px",
            width: "100%",
          },
        }}>
        <StyleElement>
          <Button
            variant='filled'
            style={{
              height: "54px",
            }}
            onClick={() => {
              buttonOnClick(true);
              onHide(false);
            }}>
            {t("Select_subscription")}
          </Button>
          <Button
            variant='subtle'
            style={{
              height: "54px", //todo not required
            }}
            onClick={() => onHide(false)}>
            {t("Cancel")}
          </Button>
        </StyleElement>
      </MTModal>
    </>
  );
};
