import { useContext, useEffect, useState } from "react";
import { Stack } from "@mantine/core";
import Button from "ui/buttons/Button";
import InputField from "ui/inputs/Inputfield";
import Password from "ui/inputs/Password";
import MemoLockIcon from "ui/icons/LockIcon";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Link, useLocation, useParams } from "react-router-dom";
import MemoEmail_icon from "ui/icons/Email_icon";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { createAccount, createOTP } from "services/user/User";
import { UserContext } from "context/state";
import { ErrorModal } from "ui/modals/ErrorModal";
import { useViewportSize } from "@mantine/hooks";
import { parse } from "qs";
import { privacyPolicy, termsOfUse } from "constants/links";
import EmployeeIcon from "ui/icons/Employee";
import EmployerIcon from "ui/icons/Employer";
import CheckedOrangeIcon from "ui/icons/CheckedOrange";
import ChekedDefaultIcon from "ui/icons/CheckedDefault";
import { fontBlack, fontGrey } from "constants/colors";
import { primaryColor } from "constants/colors";

const FormContainer = styled.div`
  padding-top: 5px;
  color: #123b3d;
  a:link {
    text-decoration: none;
  }
  @media only screen and (min-width: 1000px) {
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-height: 695px) {
  }
`;
const Heading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #123b3d;
  margin-bottom: 28px;
  @media only screen and (min-width: 1000px) {
    margin-top: 0;
  }
`;
const Text = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #6e6e6e;
`;
const SocialText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #6e6e6e;
  /* margin-bottom: 10px; */
`;
const NewUserText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #123b3d !important;
  a:link {
    text-decoration: none;
  }

  @media only screen and (min-width: 1000px) {
    font-size: 18px;
  }
`;

const StyleWrapper = styled.div`
  margin: 5px;
  @media only screen and (min-width: 1000px) {
    margin: 0px;
    min-width: 335px;
    max-width: 335px;
  }
`;
const StyleSpacing = styled.div`
  margin: 0 0 24px 0;
  @media only screen and (min-width: 1000px) {
  }
`;

const StyleElement = styled.div`
  margin-top: 30px;
  @media only screen and (min-width: 1000px) {
  }
`;

const TabsWrapper = styled.div<{ marginBottom?: string }>`
  //background-color: #d9d9d9;
  //border-radius: 40px;
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin-bottom: ${(props) => props?.marginBottom ?? "14px"};
  /* @media only screen and (min-width: 1000px) {
    margin-bottom: 10px;
  } */
`;
const Tab = styled.div`
  width: 100%;
  height: 68px;
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    width: 335px;
  }
`;

const Question = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;
  margin-bottom: 14px;
`;

const TabText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #123b3d;
  margin-left: 9px;
`;

const StyleTerms = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  color: #4d4d4d;
  @media only screen and (min-width: 1000px) {
  }
`;

const StyleLink = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff3000;
  cursor: pointer;
  text-decoration: underline !important;
  @media only screen and (min-width: 1000px) {
  }
`;

const IWantTo = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: ${fontGrey};
  margin-bottom: 2px;
`;
const HireOrGetHired = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: ${fontBlack};
  margin-bottom: 12px;
`;
const RedirectionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: ${fontBlack};
`;
const RedirectTo= styled.text`
  color:${primaryColor};
  text-decoration: underline;
  cursor: pointer;
`
const TitleWrapper= styled.div`
  margin-top:-3px;
  margin-bottom: 26px;
  @media only screen and (min-width: 1000px) {
    margin-top:1px;
    margin-bottom: 29px;
  }
`
const CreateAccountForm = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [facebookData, setFacebookData] = useState<{ [key: string]: any }>({});
  const [googleData, setGoogleData] = useState<{ [key: string]: any }>({});
  const [showVerify, setShowVerify] = useState(false);
  const { role, setRole, showDropdown, setShowDropdown } =
    useContext(UserContext);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const location = useLocation<any>();
  const parsedUserDetails = parse(location?.search.slice(1));
  // console.log("Params",location,parsedUserDetails)
  const { paramsEmail, paramsRole } = useParams<{
    paramsEmail: string;
    paramsRole: string;
  }>();
  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_email_address"))
      .required(t("Please_enter_email_address")),
    password: Yup.string()
      .required(t("Enter_Password"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        t("Password_must_contain_8_characters")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Passwords_must_match"))
      .required(t("Please_enter_valid_password")),
  });
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      showVerify: showVerify,
      firstName: "",
      lastName: "",
    },
  });
  const { width } = useViewportSize();
  let fontProperties = {
    weight: 600,
    size: "16px",
    lineheight: "130%",
  };
  if (width > 1000) {
    fontProperties = {
      weight: 700,
      size: "18px",
      lineheight: "110%",
    };
  }

  useEffect(() => {
    if (parsedUserDetails?.email) {
      let stringifiedEmail = parsedUserDetails?.email.toString();
      form.setFieldValue("email", stringifiedEmail);
    }
    if (parsedUserDetails?.firstname) {
      let stringified = parsedUserDetails?.firstname.toString();
      form.setFieldValue("firstName", stringified);
    }
    if (parsedUserDetails?.lastname) {
      let stringified = parsedUserDetails?.lastname.toString();
      form.setFieldValue("lastName", stringified);
    }
    const currentPath = window.location.pathname
    switch (currentPath){
      case "/jobseeker": setRole("candidate")
                        break;
      case "/employer":setRole("employer")
                        break;
      default : setRole('')
    }
  }, []);

  useEffect(() => {
    if (Object.keys(googleData).length !== 0) {
      form.setFieldValue("email", googleData?.email);
      setShowVerify(true);
      form.setFieldValue("showVerify", true);
    }
  }, [googleData]);
  useEffect(() => {
    if (Object.keys(facebookData).length !== 0) {
      form.setFieldValue("email", facebookData?._profile?.email);
      setShowVerify(true);
      form.setFieldValue("showVerify", true);
    }
  }, [facebookData]);
  const handleSocialLogin = (user: any) => {
    setFacebookData(user);
  };

  const handleSocialLoginFailure = (err: any) => {
    console.error(err);
  };
  const handleSubmit = (values: any) => {
    let data = {
      email: values?.email,
      role: role,
      type: "basic",
      password: values.password,
      ...(role === "candidate" &&
        values?.firstName && { first_name: values?.firstName }),
      ...(role === "candidate" &&
        values?.lastName && { last_name: values?.lastName }),
    };
    if (values?.showVerify) {
      if (Object.keys(googleData).length !== 0) {
        let google = {
          type: "google",
          access_token: "123",
          social_uid: googleData?.sub,
        };
        data = { ...data, ...google };
      }
      if (Object.keys(facebookData).length !== 0) {
        let facebook = {
          type: "facebook",
          access_token: "123",
          social_uid: facebookData?._profile?.id,
        };
        data = { ...data, ...facebook };
      }
    }
    createAccount(data)
      .then((response) => {
        if (response.success === true) {
          let data = {
            phone: `${values?.code}${values?.phone}`,
          };
          history.push({
            pathname: "/create-account-verify-email",
            state: {
              // phone: `${form.values.code}${form.values.phone}`,
              email: form.values.email,
              password: form.values.password,
            },
          });
        } else {
          setShowError(true);
          let errorArray = response?.response?.data?.message;
          let error = "";
          for (let i = 0; i < errorArray.length; i++)
            error = error + errorArray[i] + " ";
          setErrorText(error);
        }
      })
      .catch((error) => {
        setErrorText(t("default_error_message"));
        setShowError(true);
        console.log(error);
      });
  };
  const DefaultTitle = () => {
    return (
      <>
        <Question>{t("what_are_you_looking_to_do")}</Question>
        <TabsWrapper marginBottom={role != "" ? "14px" : "24px"}>
          <Tab onClick={() => setRole("candidate")}>
            {/* <EmployeeIcon fontSize="44px" style={{ marginLeft: "9px" }} /> */}
            <TabText>{t("find_a_job")}</TabText>
            {role === "candidate" ? (
              <CheckedOrangeIcon
                fontSize="21px"
                style={{ marginLeft: "auto", marginRight: "22px" }}
              />
            ) : (
              <ChekedDefaultIcon
                fontSize="21px"
                style={{ marginLeft: "auto", marginRight: "22px" }}
              />
            )}
          </Tab>
          <Tab onClick={() => setRole("employer")}>
            {/* <EmployerIcon fontSize="44px" style={{ marginLeft: "9px" }} /> */}
            <TabText>{t("find_candidates_and_hire")}</TabText>
            {role === "employer" ? (
              <CheckedOrangeIcon
                fontSize="21px"
                style={{ marginLeft: "auto", marginRight: "22px" }}
              />
            ) : (
              <ChekedDefaultIcon
                fontSize="21px"
                style={{ marginLeft: "auto", marginRight: "22px" }}
              />
            )}
          </Tab>
        </TabsWrapper>
      </>
    );
  };

  enum roles {
    candidate = "candidate",
    employer = "employer",
  }
  interface PathBasedTitle {
    currentRole: roles;
  }
  const PathBasedHeader: React.FC<PathBasedTitle> = ({ currentRole }) => {
    return (
      <TitleWrapper>
        <IWantTo>{t("i_want_to")}</IWantTo>
        {currentRole == "candidate" && (
          <>
            <HireOrGetHired>{t("get_hired")}</HireOrGetHired>
            <RedirectionText>
              {t("want_to_find_candidates_instead")}{" "}
              <RedirectTo
                onClick={() => {
                  history.push("/employer");
                  setRole("employer");
                }}
              >
                {t("click_here")}
              </RedirectTo>
            </RedirectionText>
          </>
        )}
        {currentRole == "employer" && (
          <>
            <HireOrGetHired>{t("find_candidates")}</HireOrGetHired>
            <RedirectionText>
              {t("want_to_get_hired_instead")}{" "}
              <RedirectTo
                onClick={() => {
                  history.push("/jobseeker");
                  setRole("candidate");
                }}
              >
                {t("click_here")}
              </RedirectTo>
            </RedirectionText>
          </>
        )}
      </TitleWrapper>
    );
  };
  return (
    <FormContainer>
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => setShowError(false)}
      />
      <StyleWrapper>
        {/* <BackButton>Back</BackButton> */}
        <Stack spacing={0} style={{ marginTop: "0px" }}>
          <Heading>{t("Create_an_Account")}</Heading>
          <form
            id={`form-${role}`}
            onSubmit={form.onSubmit((values) => handleSubmit(values))}
          >
            <Stack spacing={0}>
              {window.location.pathname == "/" && <DefaultTitle />}
              {(window.location.pathname == "/employer" ||
                window.location.pathname == "/jobseeker") && (
                <PathBasedHeader currentRole={role} />
              )}
              {role != "" && (
                <>
                  <StyleSpacing>
                    <InputField
                      id="email"
                      placeholder={t("Email_Address")}
                      icon={
                        <MemoEmail_icon
                          width="16.03px"
                          height="14.26px"
                          style={{ zIndex: "1" }}
                        />
                      }
                      {...form.getInputProps("email")}
                    />
                  </StyleSpacing>
                  <StyleSpacing style={{ margin: "0 0 10px 0" }}>
                    <Password
                      icon={<MemoLockIcon width="16px" height="20.28px" />}
                      id="password"
                      placeholder={t("Password")}
                      {...form.getInputProps("password")}
                    />
                  </StyleSpacing>
                  <StyleSpacing style={{ margin: "0 0 10px 0" }}>
                    <Password
                      icon={<MemoLockIcon width="16px" height="20.28px" />}
                      id="confirmPassword"
                      placeholder={t("Confirm_Password")}
                      {...form.getInputProps("confirmPassword")}
                    />
                  </StyleSpacing>
                </>
              )}
              <Button
                type="submit"
                fontWeight={fontProperties?.weight}
                fontSize={fontProperties?.size}
                lineHeight={fontProperties?.lineheight}
                variant={role != "" ? "filled" : "light"}
                backgroundColor="#c4c4c4"
              >
                {t("Create_an_Account")}{" "}
              </Button>
              <StyleElement>
                <Link to="/login">
                  <NewUserText>
                    <div>
                      {t("Already_Have_Account_Log_In")}
                      <div
                        style={{
                          display: "inline",
                          fontWeight: "600",
                          color: "#FF3000",
                        }}
                      >
                        {t("Log_in")}
                      </div>
                    </div>
                  </NewUserText>
                </Link>
              </StyleElement>
            </Stack>
          </form>
          {/* {!showVerify && (
            <SocialText>
              <StyleElement>- {t("Or_Continue_with")} -</StyleElement>
              <StyleElement>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <GoogleLogin setData={setGoogleData} />
                  <Space w='lg' />
                  <SocialButton
                    provider='facebook'
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                    logo='facebook'
                  />
                </div>
              </StyleElement>
            </SocialText>
          )} */}
          <StyleElement>
            <StyleTerms style={{ margin: "0 0 20px 0" }}>
              {t("By_registering")}{" "}
              <StyleLink href={termsOfUse} target="_blank">
                {t("Terms_of_Use")}
              </StyleLink>{" "}
              {t("information_in_accordance")}{" "}
              <StyleLink href={privacyPolicy} target="_blank">
                {t("privacy_policy")}
                {t("platformy_Jobpark")}
              </StyleLink>
            </StyleTerms>
          </StyleElement>
        </Stack>
      </StyleWrapper>
    </FormContainer>
  );
};

export default CreateAccountForm;
