import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Card, Image, Badge, Group, useMantineTheme } from "@mantine/core";
import MemoSwitchOn from "ui/icons/SwitchOn";
import DataCard from "ui/data-display/DataCard";
import Transactions from "./Transactions";
import ToggleSwitch from "ui/inputs/Switch";
import { activate_DeactivateSubscription } from "services/subscription-plan/subscription";
import { getUserDetails } from "services/user/User";
import { UserContext } from "context/state";
import { ErrorModal } from "ui/modals/ErrorModal";
import { Modal } from "ui/modals/Modal";
import Button from "../../ui/buttons/Button";

const ElementWrapper = styled.div`
  width: 100%;
  margin: 20px;
  @media only screen and (min-width: 1000px) {
  }
`;
const RightWrapper = styled.div`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 10px;
  height: auto;
  bottom: 0px;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;
const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #6a6a6a;
  }
`;
const CardHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1a1d1e;
  }
`;
const Text = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #6a6a6a;
  }
`;
const StyleElement = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    width: 50%;
    float: left;
    overflow: auto;
  }
`;

const StyleElementTransactions = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    width: 50%;
    float: left;
    overflow: auto;
    margin-top: -75px;
  }
`;

const StyleSpacing = styled.div`
  @media only screen and (min-width: 1000px) {
    max-width: 355px;
  }
`;

const Content = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: #6e6e6e;

  @media only screen and (min-width: 1000px) {
  }
`;

const ModalText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e6e6e;
  margin: 0 0 60px 0;
  @media only screen and (min-width: 1000px) {
  }
`;

export default function Subscription(props: any) {
  const { t } = useTranslation();
  const { userId, userData } = useContext(UserContext);

  const [deactiveSubscription, setDeactiveSubscription] = useState(
    userData?.is_subscribe_paused ? false : true
  );
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [data, setData] = useState<any>({});

  // To activate and deactivate subscription
  const activate_DeactivateSubscriptionHandler = () => {
    activate_DeactivateSubscription(deactiveSubscription ? 0 : 1)
      .then((response) => {
        // console.log(`res`, response);
        setResponseMessage(response?.message);
        setShowError(true);
        setShowModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // API call to check Subscription
  const getUserDetailshandler = () => {
    getUserDetails(userId)
      .then((response) => {
        setData({
          name: response?.data?.plan_name,
          plan_expiration_date: response?.data?.plan_expiration_date,
          plan_detail: response?.data?.plan_detail,
        });
        setDeactiveSubscription(response?.data?.is_subscribe_paused ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // if (!deactiveSubscription)
    getUserDetailshandler();
  }, [deactiveSubscription]);

  useEffect(() => {
    getUserDetailshandler();
  }, []);
  // console.log(`is sub`, deactiveSubscription);
  return (
    <>
      <StyleElement>
        <ErrorModal
          opened={showError}
          message={responseMessage}
          onClose={() => setShowError(false)}
        />
        <Modal
          showOpenButton={false}
          show={showModal}
          onHide={setShowModal}
          title={t("Are_you_Sure")}>
          <ModalText>{t("Are_you_sure_you_want_to_disable_the_subscriptions")}</ModalText>
          <Button
            style={{
              wordWrap: "break-word",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "16px",
            }}
            onClick={() => {
              setDeactiveSubscription(false);
              activate_DeactivateSubscriptionHandler();
            }}
            variant='filled'
            height='54px'
            lineHeight='130%'>
            {t(`Confirm`)}
          </Button>
          <Button
            style={{
              wordWrap: "break-word",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "16px",
            }}
            onClick={() => setShowModal(false)}
            variant='subtle'
            height='54px'
            color='#123B3D'
            lineHeight='130%'>
            {t(`Cancel`)}
          </Button>
        </Modal>
        <StyleSpacing>
          <Heading>{t("Subscription_Details")}</Heading>
          {
            // userData?.is_subscribed &&
            data?.name ? (
              <Card
                style={{
                  width: "100%",
                  height: "125px",
                  margin: "auto",
                  background: " #FFFFFF",
                  borderRadius: "20px",
                  boxShadow: "0px 10px 35px rgba(64, 59, 75, 0.1)",
                }}>
                <Group position='apart'>
                  <CardHeading>{data?.name}</CardHeading>
                  <Text>{t("Cancel_subscription")}</Text>
                </Group>
                <Group position='apart'>
                  <p
                    style={{
                      color: "#6A6A6A",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: "#2D8E4C",
                      }}>
                      {`${
                        data?.plan_detail?.currency == "EUR"
                          ? "€"
                          : data?.plan_detail?.currency == "NOK"
                          ? "kr"
                          : "$"
                      }`}
                    </span>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: "#2D8E4C",
                      }}>
                      {`${data?.plan_detail?.price}`}
                    </span>
                    /{t("month")}
                  </p>
                  <ToggleSwitch
                    checked={deactiveSubscription}
                    onChange={(event: any) => {
                      if (deactiveSubscription) {
                        setShowModal(true);
                        // activate_DeactivateSubscriptionHandler();
                      }
                    }}
                  />
                </Group>
                <Group style={{ margin: "10" }}>
                  <Text>{`${t("Expires_end")} ${
                    new Date(data?.plan_expiration_date).getMonth() + 1
                  }/${String(new Date(data?.plan_expiration_date).getFullYear()).slice(-2)}`}</Text>
                </Group>
              </Card>
            ) : (
              <Text>{t("No_active_subscription")}</Text>
            )
          }
        </StyleSpacing>
      </StyleElement>
      <StyleElementTransactions>{/* <Transactions /> */}</StyleElementTransactions>
    </>
  );
}
