import React from "react";
import { Checkbox as MTCheckbox, CheckboxProps } from "@mantine/core";
import GreyCheckbox from "ui/icons/Grey-checkbox.svg";
import OrangeCheckbox from "ui/icons/Orange-Checkbox.svg";
import WhiteCheckBox from "ui/icons/whiteCheckbox.svg";
import OrangeCheckBox from "ui/icons/orangeCheckbox.svg";
import { fontBlack } from "constants/colors";

interface CustomCheckboxProps extends CheckboxProps {
  label?: string;
  onClick?: () => void;
  value?: string | undefined;
  fontSizeLabel?: string;
  paddingBottom?:string,
  paddingLeftLabel?:string,
  customLabelColor?:string
}

const Checkbox: React.FC<CustomCheckboxProps> = ({
  label = "label",
  value,
  fontSizeLabel,
  paddingBottom,
  paddingLeftLabel,
  customLabelColor,
  ...props
}) => {
  return (
    <>
      <MTCheckbox
        styles={{
          root: { paddingBottom: paddingBottom? paddingBottom: "30px" },
          input: {
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
            background: `url(${WhiteCheckBox})`,
            // background: `url(${GreyCheckbox})`,
            backgroundSize: "28px 28px",
            border: "none !important",
            borderRadius: "4px",
            width: "28px",
            height: "28px",
            "&:checked": {
              backgroundRepeat: "no-repeat",
              background: `url(${OrangeCheckBox})`,
              //background: `url(${OrangeCheckbox})`,
              backgroundSize: "28px 28px",
              border: "none !important",
            },
          },
          icon: {
            display: "none",
          },
          label: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontSize: fontSizeLabel ? fontSizeLabel : "14px",
            fontWeight: 400,
            lineHeight: "130%",
            color:customLabelColor? customLabelColor: fontBlack,
            paddingLeft: paddingLeftLabel? paddingLeftLabel: "24px",
            cursor:'pointer'
          },
        }}
        label={label}
        value={value}
        {...props}
      />
    </>
  );
};

export default Checkbox;
