import authorized from "helpers/authentication/authenticate";
import React, { useContext } from "react";
import { Route, Redirect,} from "react-router-dom";
import { UserContext } from "./context/state";


const PrivateRoute = ({ component: Component, ...rest }) => {
  const contextData = useContext(UserContext)
  let pathName = ''
  switch (contextData?.role) {
    case "employer": pathName = "/login"; break;
    case "candidate": pathName = "/login"; break;
    default: pathName = "/"; break;
  }
  return (
    <Route
      {...rest}
      render={props =>

        authorized() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={pathName}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
