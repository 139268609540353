import * as React from "react";

function SaveSearchIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 13" fill="none" {...props}>
      <path
        d="M1 1.5v9.902a.5.5 0 00.91.285l2.68-3.861a.5.5 0 01.82 0l2.68 3.86a.5.5 0 00.91-.284V1.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5z"
        stroke="#F50"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSaveSearchIcon = React.memo(SaveSearchIcon);
export default MemoSaveSearchIcon;
