import * as React from "react";

function FilterArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 10" fill="none" {...props}>
      <path d="M6.5 5l-6 4.763V.237L6.5 5z" fill="#000" />
    </svg>
  );
}

const MemoFilterArrow = React.memo(FilterArrow);
export default MemoFilterArrow;
