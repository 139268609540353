import * as React from "react";

function WhiteChevronLeft(props) {
  return (
    <svg width={props?.width ? props?.width : "1em"} height={props?.height ? props?.height : "1em"} viewBox="0 0 12 19" fill="none" {...props}>
      <path
        d="M10 17L2 9.5 10 2"
        stroke={props?.stroke ? props?.stroke : "#FFF"}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoWhiteChevronLeft = React.memo(WhiteChevronLeft);
export default MemoWhiteChevronLeft;
