import React, { ReactNode, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import MemoWhiteChevronLeft from "ui/icons/WhiteChevronLeft";
import Button from "ui/buttons/Button";
import InputField from "ui/inputs/Inputfield";
import Drawer from "ui/data-display/Drawer";
import CountrySearch from "helpers/search-select/CountrySearch";
import MemoGrayArrow from "ui/icons/GrayArrow";
import Checkbox from "ui/inputs/Checkbox";
import { subscriptionOrder } from "services/subscription-plan/subscription";
import { ErrorModal } from "ui/modals/ErrorModal";
import { termsOfUse } from "constants/links";

interface InvoiceFormProps {
  children?: ReactNode;
  show?: boolean;
  onHideModel?: any;
  setShowContactUs?: any;
  setShowInvoice?: any;
  setSelectedPlanData?: any;
  setCurrencySelected?: any;
  currencySelected?: string;
  form?: any;
  type: 1 | 2;
  checkForm1?: any;
  checkForm2?: any;
  selectedPlanData?: any;
  setShowSubscriptionListing?: any;
  setShowInvoice1?: any;
  setShowModalBox?: any;
}

const Wrapper = styled.div`
  position: relative;
  height: auto;
  min-height: 100%;
`;

const HeaderBox = styled.div`
  background: linear-gradient(180deg, #ff3000 -6.93%, #ff5500 93.07%);
  border-radius: 0 0 48px 48px;
  width: auto;
  height: 150px;
  top: 0;
  @media only screen and (min-width: 1000px) {
    height: 180px;
  }
`;

const TitleText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  margin: 0 0 0 20px;
`;

const InvoiceWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 20px;
`;

const HeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 50px 0 0 22px;
`;

const HeadNote = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  color: #ffffff;
  margin: 0 0 0 22px;
`;

const ContentBox = styled.div`
  margin: 28px 20px 0px 20px;
  padding: 0px 0px 30px 0px;
`;

const MarginBox = styled.div`
  margin: 0 0 15px 0;
`;

const StyleButtonWrapper = styled.div`
  margin: auto 22px 12px 22px;
  position: absolute;
  bottom: 0px;
  width: 90%;
`;

const StyleWrapper = styled.div`
  margin-right: 10px;
`;

const StyleSelectButtonOut = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e6e6e;
  cursor: pointer;
  padding: 5px;
  justify-content: center;
  width: 100%;
  margin-right: 30px;
`;

const StyleIcon = styled.div`
  float: right;
  margin-right: 10px;
`;

const StyleError = styled.span`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  font-family: Poppins;
`;

const StyleButton = styled.button`
  background: linear-gradient(90deg, #ff3000 6.7%, #ff5500 95.4%);
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  height: 54px;
  border: none;
  width: 100%;
  cursor: pointer;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #123b3d;
  margin: 0 0px 0 0px;
  color: #4d4d4d;
  &:hover {
    color: #ff3000;
  }
`;

const InvoiceForm: React.FC<InvoiceFormProps> = (props) => {
  const { t } = useTranslation();

  //Show/Hide Drawer
  const [showCountrySearch1, setShowCountrySearch1] = useState(false);
  const [showCountrySearch2, setShowCountrySearch2] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState("");

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalMessage, setErrorShowModalMessage] = useState("");

  //Country Data
  const [countryData1, setCountryData1] = useState(
    props?.checkForm1.values.country.length != 0 ? props?.checkForm1.values.country : []
  );

  const [countryData2, setCountryData2] = useState(
    props?.checkForm1.values.country1.length != 0 ? props?.checkForm1.values.country1 : []
  );

  // Function to Get all the checked Country
  const getCheckedCountry = (data: any) => {
    let checkedCountry: any = [];
    if (data) {
      data?.forEach((objectData: any) => {
        objectData?.data?.forEach((checkboxData: any) => {
          if (checkboxData.checked) {
            checkedCountry.push(checkboxData.name);
          }
        });
      });
      return checkedCountry;
    } else {
      return false;
    }
  };

  // Function to Get Price
  const getPrice = (data: any) => {
    let pricing: any = [];
    for (const [currency, price] of Object.entries(data)) {
      if (currency == props?.currencySelected) {
        pricing = price;
      }
    }
    return pricing;
  };
  //Remove Empty elements
  const removeEmpty = (obj: any) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  };

  // Validation
  function validURL(str: string, condition: string) {
    var patternCity = new RegExp(/^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/);
    var patternZip = new RegExp(/^\w+$/);
    var patternEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (condition == "city") {
      return !!patternCity.test(str);
    } else if (condition == "zip") {
      return !!patternZip.test(str);
    } else if (condition == "email") {
      return !!patternEmail.test(str);
    }
  }

  //Transform POST Data
  const TransformPostDataHandler = (plan: any, currency: any, form1: any, form2: any) => ({
    type: "order",
    plan_name: plan.name,
    plan_price: getPrice(plan.pricing),
    plan_currency: currency,
    name: "NAME",
    company_name: form1.company_name,
    address: form1.address,
    billing_email: form1.email,
    country: getCheckedCountry(form1.country)[0],
    city: form1.city,
    zip: form1.zip,
    diff_bill_info: form1.differentBill,
    diff_company_name: form1.differentBill ? form1?.company_name1 : null,
    diff_address: form1.differentBill ? form1?.address1 : null,
    diff_billing_email: form1.differentBill ? form1?.email1 : null,
    diff_country: form1.differentBill ? getCheckedCountry(form1?.country1)[0] : null,
    diff_city: form1.differentBill ? form1?.city1 : null,
    diff_zip: form1.differentBill ? form1?.zip1 : null,
    accept_tc: form1?.termsConditions,
  });

  //POST API for Subscription
  const postDataHandler = () => {
    subscriptionOrder(
      // removeEmpty(
      TransformPostDataHandler(
        props?.selectedPlanData,
        props?.currencySelected,
        props?.checkForm1?.values,
        props?.checkForm2?.values
      )
    )
      // )
      .then((response) => {
        // console.log(`res`, response);
        if (response.success) {
          setShowModalMessage(t("Form_Submitted"));
          if (response.message) {
            setShowModalMessage(response.message);
            setShowModal(true);
          }
          props?.checkForm1.reset();
          setCountryData1([]);
          setCountryData2([]);
        }
        if (response?.response?.data?.success == false) {
          if (response?.response?.data?.message[0]) {
            setErrorShowModalMessage(response?.response?.data?.message[0]);
            setShowErrorModal(true);
          }
        }
      })
      .catch((error) => {
        // console.log(`error`, error);
        // if (error.message) {
        //     setShowModalMessage(error.message);
        //     setShowModal(true)
        // }
      });
  };

  useEffect(() => {
    props?.checkForm1.setFieldValue("country", countryData1);
  }, [countryData1]);

  useEffect(() => {
    props?.checkForm1.setFieldValue("country1", countryData2);
  }, [countryData2]);

  useEffect(() => {
    if (props?.checkForm1?.values?.differentBill) {
      // let errorLogs = props?.checkForm1?.errors;
      // delete errorLogs?.["country1"];
      props?.checkForm1.setErrors({});
    }
  }, [props?.checkForm1?.values?.differentBill]);

  //Reset Form on change plan
  useEffect(() => {
    props?.checkForm1.reset();
    setCountryData1([]);
    setCountryData2([]);
  }, [props?.selectedPlanData?.id]);

  return (
    <Wrapper>
      <HeaderBox>
        <InvoiceWrapper>
          <MemoWhiteChevronLeft
            width='21px'
            height='21px'
            style={{ cursor: "pointer" }}
            onClick={() => {
              props?.checkForm1.reset();
              setCountryData1([]);
              setCountryData2([]);
              props.onHideModel(false);
            }}
          />
          <TitleText>{t("Payment")}</TitleText>
        </InvoiceWrapper>
        <HeadText>{t("Invoice")}</HeadText>
        {/* <HeadNote>1-3 days access</HeadNote> */}
      </HeaderBox>
      <form
        id={"Invoice1"}
        onSubmit={props?.checkForm1?.onSubmit((values: any) => console.log(values))}>
        <ContentBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='company_name'
              placeholder={t("Company_name")}
              {...props?.checkForm1.getInputProps("company_name")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='address'
              placeholder={t("Address")}
              {...props?.checkForm1.getInputProps("address")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='email'
              placeholder={t("Billing_email")}
              {...props?.checkForm1.getInputProps("email")}
            />
          </MarginBox>
          <MarginBox>
            <StyleWrapper>
              <StyleSelectButtonOut onClick={() => setShowCountrySearch1(true)}>
                <p
                  style={{
                    marginLeft: "10px",
                    color: getCheckedCountry(countryData1)[0] ? "#000" : "#6E6E6E",
                    fontSize: "16px",
                  }}>
                  {getCheckedCountry(countryData1)[0]
                    ? getCheckedCountry(countryData1)[0]
                    : t("Country")}
                  <StyleIcon>
                    <MemoGrayArrow />
                  </StyleIcon>
                </p>
              </StyleSelectButtonOut>
            </StyleWrapper>
            {props?.checkForm1?.errors?.country && getCheckedCountry(countryData1).length == 0 && (
              <StyleError>{t("Please_Select_Country")}</StyleError>
            )}
            <Drawer
              position='right'
              padding={"0px"}
              onClose={() => setShowCountrySearch1(false)}
              opened={showCountrySearch1}>
              <CountrySearch
                onHide={() => setShowCountrySearch1(false)}
                getData={setCountryData1}
                passData={countryData1}
                title={t("Select_Country")}
              />
            </Drawer>
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='city'
              placeholder={t("City")}
              {...props?.checkForm1.getInputProps("city")}
            />
          </MarginBox>
          <MarginBox>
            <InputField
              width='100%'
              height='auto'
              id='zip'
              placeholder={t("Zip")}
              {...props?.checkForm1.getInputProps("zip")}
            />
          </MarginBox>
          <MarginBox>
            <Checkbox
              width='100%'
              height='auto'
              style={{ paddingBottom: "15px" }}
              id='differentBill'
              checked={props?.checkForm1.values.differentBill}
              fontSizeLabel='12px'
              label={t("Different_billing_information")}
              {...props?.checkForm1.getInputProps("differentBill")}
            />
            {props?.checkForm1?.values?.differentBill && (
              <>
                <MarginBox>
                  <InputField
                    width='100%'
                    height='auto'
                    id='company_name1'
                    placeholder={t("Company_name")}
                    {...props?.checkForm1.getInputProps("company_name1")}
                  />
                </MarginBox>
                <MarginBox>
                  <InputField
                    width='100%'
                    height='auto'
                    id='address1'
                    placeholder={t("Address")}
                    {...props?.checkForm1.getInputProps("address1")}
                  />
                </MarginBox>
                <MarginBox>
                  <InputField
                    width='100%'
                    height='auto'
                    id='email1'
                    placeholder={t("Billing_email")}
                    {...props?.checkForm1.getInputProps("email1")}
                  />
                </MarginBox>
                <MarginBox>
                  <StyleWrapper>
                    <StyleSelectButtonOut onClick={() => setShowCountrySearch2(true)}>
                      <p
                        style={{
                          marginLeft: "10px",
                          color: getCheckedCountry(countryData2)[0] ? "#000" : "#6E6E6E",
                          fontSize: "16px",
                        }}>
                        {getCheckedCountry(countryData2)[0]
                          ? getCheckedCountry(countryData2)[0]
                          : t("Country")}
                        <StyleIcon>
                          <MemoGrayArrow />
                        </StyleIcon>
                      </p>
                    </StyleSelectButtonOut>
                  </StyleWrapper>
                  {props?.checkForm1?.errors?.country1 &&
                    getCheckedCountry(countryData2).length == 0 && (
                      <StyleError>{t("Please_Select_Country")}</StyleError>
                    )}
                  <Drawer
                    position='right'
                    padding={"0px"}
                    onClose={() => setShowCountrySearch2(false)}
                    opened={showCountrySearch2}>
                    <CountrySearch
                      onHide={() => setShowCountrySearch2(false)}
                      getData={setCountryData2}
                      passData={countryData2}
                      title={t("Select_Country")}
                    />
                  </Drawer>
                </MarginBox>
                <MarginBox>
                  <InputField
                    width='100%'
                    height='auto'
                    id='city1'
                    placeholder={t("City")}
                    {...props?.checkForm1.getInputProps("city1")}
                  />
                </MarginBox>
                <MarginBox>
                  <InputField
                    width='100%'
                    height='auto'
                    id='zip1'
                    placeholder={t("Zip")}
                    {...props?.checkForm1.getInputProps("zip1")}
                  />
                </MarginBox>
              </>
            )}
            <Checkbox
              width='100%'
              height='auto'
              id='termsConditions'
              checked={props?.checkForm1.values.termsConditions}
              fontSizeLabel='12px'
              label={
                <StyledAnchor
                  href={termsOfUse}
                  target='_blank'
                  style={{
                    color: props?.form?.errors?.termsConditions ? "#f03e3e" : "",
                  }}>
                  {t("I_accept_the_Terms_and_Conditions_for_using_Jobpark")}
                </StyledAnchor>
              }
              {...props?.checkForm1.getInputProps("termsConditions")}
            />
          </MarginBox>
        </ContentBox>
      </form>
      <StyleButtonWrapper>
        <StyleButton
          style={{
            background: !props?.checkForm1?.values.termsConditions ? "#6E6E6E" : "",
          }}
          form={"Invoice1"}
          disabled={!props?.checkForm1?.values.termsConditions}
          onClick={() => {
            // console.log(`Condition --> `, validURL(props?.checkForm1?.values.email, "email"));
            if (props?.checkForm1?.values.differentBill == false) {
              if (
                props?.checkForm1?.values.company_name &&
                props?.checkForm1?.values.address &&
                props?.checkForm1?.values.email &&
                getCheckedCountry(props?.checkForm1?.values.country).length != 0 &&
                props?.checkForm1?.values.city &&
                props?.checkForm1?.values.zip &&
                props?.checkForm1?.values.differentBill == false &&
                props?.checkForm1?.values.termsConditions &&
                props?.checkForm1?.errors?.company_name == undefined &&
                props?.checkForm1?.errors.address == undefined &&
                props?.checkForm1?.errors.email == undefined &&
                props?.checkForm1?.errors.city == undefined &&
                props?.checkForm1?.errors.zip == undefined &&
                validURL(props?.checkForm1?.values.city, "city") &&
                validURL(props?.checkForm1?.values.zip, "zip") &&
                validURL(props?.checkForm1?.values.email, "email")
              ) {
                postDataHandler();
              }
            } else {
              if (
                props?.checkForm1?.values.company_name &&
                props?.checkForm1?.values.address &&
                props?.checkForm1?.values.email &&
                getCheckedCountry(props?.checkForm1?.values.country).length != 0 &&
                props?.checkForm1?.values.city &&
                props?.checkForm1?.values.zip &&
                props?.checkForm1?.values.differentBill &&
                props?.checkForm1?.values.termsConditions &&
                props?.checkForm1?.values.company_name1 &&
                props?.checkForm1?.values.address1 &&
                props?.checkForm1?.values.email1 &&
                getCheckedCountry(props?.checkForm1?.values.country1).length != 0 &&
                props?.checkForm1?.values.city1 &&
                props?.checkForm1?.values.zip1 &&
                props?.checkForm1?.errors?.company_name == undefined &&
                props?.checkForm1?.errors.address == undefined &&
                props?.checkForm1?.errors.email == undefined &&
                props?.checkForm1?.errors.city == undefined &&
                props?.checkForm1?.errors.zip == undefined &&
                props?.checkForm1?.errors?.company_name1 == undefined &&
                props?.checkForm1?.errors.address1 == undefined &&
                props?.checkForm1?.errors.email1 == undefined &&
                props?.checkForm1?.errors.city1 == undefined &&
                props?.checkForm1?.errors.zip1 == undefined &&
                validURL(props?.checkForm1?.values.city, "city") &&
                validURL(props?.checkForm1?.values.city1, "city") &&
                validURL(props?.checkForm1?.values.zip, "zip") &&
                validURL(props?.checkForm1?.values.zip1, "zip") &&
                validURL(props?.checkForm1?.values.email, "email") &&
                validURL(props?.checkForm1?.values.email1, "email")
              ) {
                postDataHandler();
              }
            }
          }}>
          {t("Place_order")}
        </StyleButton>
      </StyleButtonWrapper>
      <ErrorModal
        opened={showModal}
        message={showModalMessage}
        onClose={() => {
          setShowModal(false);
          props?.setShowSubscriptionListing(false);
          props?.setShowInvoice1(false);
          props?.setShowModalBox(false);
          props?.setCurrencySelected("EUR");
        }}
      />
      <ErrorModal
        opened={showErrorModal}
        message={showErrorModalMessage}
        onClose={() => {
          setShowErrorModal(false);
        }}
      />
    </Wrapper>
  );
};
export default InvoiceForm;
