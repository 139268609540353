import * as React from "react";

function CheckedOrange(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...props}>
      <circle cx={10.5} cy={10.5} r={10.5} fill="#FF3000" />
      <path
        d="M5.32 9.946l-.35-.344-.35.342-.97.947-.364.356.363.358 3.814 3.751.35.345.351-.345 8.187-8.053.362-.357-.362-.356-.962-.946-.35-.345-.351.344L7.814 12.4 5.319 9.946z"
        fill="#fff"
        stroke="#fff"
      />
    </svg>
  );
}

const MemoCheckedOrange = React.memo(CheckedOrange);
export default MemoCheckedOrange;
