import React from "react";
import { Chip as MTChip } from "@mantine/core";

interface dataProps {
  label: string;
}
const DataCard: React.FC<dataProps> = ({ label }) => {
  let rootStyles = {
    width: "fit-content",
    margin: "2px",
  };
  return (
    <MTChip
      value='chip'
      tabIndex={-1}
      radius={30}
      checked={false}
      variant='filled'
      sx={rootStyles}
      styles={{
        label: {
          color: "#FF5500",
          backgroundColor: "#FFF",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "11px",
          lineHeight: "16px",
          padding: "5px 7px 0 8px",
          pointerEvents: "none",
        },
        filled: {
          backgroundColor: "#FFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
        },
      }}>
      {label}
    </MTChip>
  );
};

export default DataCard;
