import * as React from "react";

function SuccessIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
      <path d="M24 0C10.752 0 0 10.752 0 24C0 37.248 10.752 48 24 48C37.248 48 48 37.248 48 24C48 10.752 37.248 0 24 0ZM19.2 36L7.2 24L10.584 20.616L19.2 29.208L37.416 10.992L40.8 14.4L19.2 36Z" fill="#399E5C" />
    </svg>

  );
}

const MemoSuccessIcon = React.memo(SuccessIcon);
export default MemoSuccessIcon;
