import * as React from "react";

function WhiteDownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 21" fill="none" {...props}>
      <path
        d="M25.8 7.928C24.893 3.399 20.853 0 16 0c-3.853 0-7.2 2.152-8.867 5.303C3.12 5.723 0 9.069 0 13.125 0 17.469 3.587 21 8 21h17.333C29.013 21 32 18.06 32 14.437c0-3.464-2.733-6.273-6.2-6.51zm-3.133 3.885L16 18.375l-6.667-6.563h4v-5.25h5.334v5.25h4z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoWhiteDownloadIcon = React.memo(WhiteDownloadIcon);
export default MemoWhiteDownloadIcon;
