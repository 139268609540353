import * as React from "react";

function BillingAndPayments(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M26.1 11H10.9c-1.055 0-1.89.834-1.89 1.875L9 24.125C9 25.165 9.845 26 10.9 26h15.2c1.054 0 1.9-.834 1.9-1.875v-11.25c0-1.04-.846-1.875-1.9-1.875zm0 13.125H10.9V18.5h15.2v5.625zm0-9.375H10.9v-1.875h15.2v1.875z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoBillingAndPayments = React.memo(BillingAndPayments);
export default MemoBillingAndPayments;
