import * as React from "react";

function CheckLogo(props) {
    return (
        <svg width={props?.width ? props.width : "14"} height={props?.height ? props.height : "14"} viewBox="0 0 14 14" fill="none" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99967 0.583344C3.45592 0.583344 0.583008 3.45626 0.583008 7.00001C0.583008 10.5438 3.45592 13.4167 6.99967 13.4167C10.5434 13.4167 13.4163 10.5438 13.4163 7.00001C13.4163 3.45626 10.5434 0.583344 6.99967 0.583344ZM9.78101 5.91501C9.83222 5.85647 9.87121 5.78827 9.89568 5.71444C9.92015 5.64061 9.92961 5.56263 9.9235 5.48509C9.91739 5.40754 9.89583 5.33201 9.86009 5.26292C9.82436 5.19383 9.77517 5.13259 9.71541 5.08279C9.65566 5.033 9.58655 4.99566 9.51215 4.97296C9.43775 4.95027 9.35956 4.94269 9.28219 4.95066C9.20482 4.95863 9.12982 4.982 9.06161 5.01938C8.9934 5.05676 8.93336 5.10741 8.88501 5.16834L6.37668 8.17776L5.07876 6.87926C4.96874 6.773 4.82139 6.7142 4.66844 6.71553C4.51549 6.71686 4.36919 6.77821 4.26103 6.88637C4.15288 6.99452 4.09153 7.14083 4.0902 7.29378C4.08887 7.44672 4.14767 7.59408 4.25392 7.70409L6.00392 9.45409C6.06124 9.51138 6.12986 9.55609 6.20542 9.58538C6.28097 9.61467 6.3618 9.6279 6.44275 9.62422C6.5237 9.62055 6.60299 9.60004 6.67558 9.56402C6.74817 9.528 6.81245 9.47725 6.86434 9.41501L9.78101 5.91501Z"
                fill={props?.fill ? props?.fill : "#FF5500"}
            />
        </svg>
    );
}

const MemoCheckLogo = React.memo(CheckLogo);
export default MemoCheckLogo;
