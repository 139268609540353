import * as React from "react";

function DeleteIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M12.857 25.222c0 .978.772 1.778 1.714 1.778h6.858c.942 0 1.714-.8 1.714-1.778V14.556H12.857v10.666zM24 11.89h-3L20.143 11h-4.286l-.857.889h-3v1.778h12v-1.778z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoDeleteIcon = React.memo(DeleteIcon);
export default MemoDeleteIcon;
