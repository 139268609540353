import React, { ReactNode, useState, useEffect } from "react";
import Drawer from "ui/data-display/Drawer";
import Listing from "./Listing";
import ContactUsForm from "./ContactUsForm";
import InvoiceForm from "./InvoiceForm";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { useTranslation } from "react-i18next";
// import { getAllFilters } from "services/taxonomies/taxonomies";
// import { CountryStore } from "stores/taxnomies/country.store";

interface SubscriptionListingProps {
  children?: ReactNode;
  show?: boolean;
  onHide?: () => void;
  showSubscriptionListing?: any;
  setShowSubscriptionListing?: any;
  setShowModalBox?: any;
}

const SubscriptionListing: React.FC<SubscriptionListingProps> = (props) => {
  //Country API Call
  // useEffect(() => {
  //     getAllFilters()
  //         .then((response) => {
  //             response.data.country.map((item: any) => CountryStore.set(item.id, item.name))
  //         })
  //         .catch((error) => {
  //             console.log(error)
  //         })
  // }, [])
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    company_name: Yup.string().required(t("Company_Nameis_a_required_field")),
    address: Yup.string().required(t("Address_is_a_required_field")),
    email: Yup.string().email(t("Invalid_email_address")).required(t("Please_enter_email_address")),
    country: Yup.string().required(t("Country_cannot_be_empty")),
    city: Yup.string()
      .required(t("City_is_a_required_field"))
      .matches(
        /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/,
        t("Only_alphabets_are_allowed_for_this_field")
      ),
    zip: Yup.string()
      .required(t("Zip_is_a_required_field"))
      .matches(/^\w+$/, t("Enter_valid_zip_code_with_no_spaces")),
    termsConditions: Yup.boolean().oneOf([true], "Required"),
    company_name1: Yup.string().when("differentBill", {
      is: true,
      then: Yup.string().ensure().required(t("Company_Nameis_a_required_field")),
    }),
    address1: Yup.string()
      .ensure()
      .when("differentBill", {
        is: true,
        then: Yup.string().required(t("Address_is_a_required_field")),
      }),
    email1: Yup.string()
      .ensure()
      .when("differentBill", {
        is: true,
        then: Yup.string()
          .email(t("Invalid_email_address"))
          .required(t("Please_enter_email_address")),
      }),
    country1: Yup.string()
      .ensure()
      .when("differentBill", {
        is: true,
        then: Yup.string().required(t("Country_cannot_be_empty")),
      }),
    city1: Yup.string()
      .ensure()
      .when("differentBill", {
        is: true,
        then: Yup.string()
          .required(t("City_is_a_required_field"))
          .matches(
            /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/,
            t("Only_alphabets_are_allowed_for_this_field")
          ),
      }),
    zip1: Yup.string()
      .ensure()
      .when("differentBill", {
        is: true,
        then: Yup.string()
          .required(t("Zip_is_a_required_field"))
          .matches(/^\w+$/, t("Enter_valid_zip_code_with_no_spaces")),
      }),
  });

  //Show/Hide State for Drawer
  const [showContachUs, setShowContactUs] = useState(false);
  const [showInvoice1, setShowInvoice1] = useState(false);

  //User Input State Data
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [currencySelected, setCurrencySelected] = useState<any>("EUR");

  //Invoice Forms
  const form1 = useForm({
    schema: yupResolver(schema),
    initialValues: {
      company_name: "",
      address: "",
      email: "",
      country: [],
      city: "",
      zip: "",
      differentBill: false,
      company_name1: "",
      address1: "",
      email1: "",
      country1: [],
      city1: "",
      zip1: "",
      termsConditions: false,
    },
  });
  useEffect(() => {
    setCurrencySelected("EUR");
  }, []);
  useEffect(() => {
    if (!props?.showSubscriptionListing) setCurrencySelected("EUR");
  }, [props?.showSubscriptionListing]);
  // const form2 = useForm({
  //     schema: yupResolver(schema1),
  //     initialValues: {
  //         company_name: '',
  //         address: '',
  //         email: '',
  //         country: [],
  //         city: '',
  //         zip: '',
  //     }
  // });

  // useEffect(() => {
  //     if (form1.values.differentBill)
  //         setShowInvoice2(true)
  // }, [form1.values.differentBill])

  return (
    <>
      <Drawer
        position='right'
        padding={"0px"}
        onClose={() => props?.setShowSubscriptionListing(false)}
        opened={props?.showSubscriptionListing}
        width='415px'>
        <Listing
          onHideModel={props?.setShowSubscriptionListing}
          setShowContactUs={setShowContactUs}
          setShowInvoice={setShowInvoice1}
          setSelectedPlanData={setSelectedPlanData}
          setCurrencySelected={setCurrencySelected}
          currencySelected={currencySelected}
          selectedPlanData={selectedPlanData}
        />
      </Drawer>
      <Drawer
        position='right'
        padding={"0px"}
        onClose={() => setShowContactUs(false)}
        opened={showContachUs}
        width='415px'>
        <ContactUsForm
          onHideModel={setShowContactUs}
          setShowSubscriptionListing={props?.setShowSubscriptionListing}
        />
      </Drawer>
      <Drawer
        position='right'
        padding={"0px"}
        onClose={() => setShowInvoice1(false)}
        opened={showInvoice1}>
        <InvoiceForm
          onHideModel={setShowInvoice1}
          setShowInvoice1={setShowInvoice1}
          checkForm1={form1}
          setShowSubscriptionListing={props?.setShowSubscriptionListing}
          selectedPlanData={selectedPlanData}
          currencySelected={currencySelected}
          setShowModalBox={props?.setShowModalBox}
          setCurrencySelected={setCurrencySelected}
          type={1}
        />
      </Drawer>
    </>
  );
};
export default SubscriptionListing;
