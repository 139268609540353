import * as React from "react";

function GrayArrow(props) {
    return (
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" {...props}>
            <path d="M1.7998 8.6001L5.3998 5.0001L1.7998 1.4001" stroke="#6E6E6E" stroke-width="1.5" stroke-linecap="square" />
        </svg>

    );
}

const MemoGrayArrow = React.memo(GrayArrow);
export default MemoGrayArrow;
