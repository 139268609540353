import * as React from "react";

function Twitter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx={20} cy={20} r={20} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.52 17.405l.034.554-.56-.068c-2.036-.26-3.816-1.141-5.326-2.621l-.74-.735-.19.543c-.402 1.208-.145 2.485.694 3.344.448.474.348.542-.425.26-.268-.09-.503-.158-.526-.124-.078.079.19 1.107.403 1.514.291.564.884 1.118 1.533 1.446l.549.26-.65.01c-.626 0-.648.012-.581.25.224.734 1.108 1.514 2.093 1.852l.693.238-.604.361a6.302 6.302 0 01-3 .836c-.503.012-.917.057-.917.09 0 .114 1.365.747 2.16.995 2.384.735 5.215.418 7.342-.836 1.51-.893 3.021-2.667 3.727-4.384.38-.915.76-2.587.76-3.39 0-.52.034-.587.66-1.209.37-.361.717-.757.784-.87.112-.214.101-.214-.47-.022-.951.339-1.085.294-.615-.215.347-.361.76-1.017.76-1.209 0-.034-.167.023-.357.124-.202.114-.65.283-.985.385l-.605.192-.548-.373c-.302-.204-.727-.43-.951-.497-.571-.158-1.444-.136-1.959.045-1.399.508-2.283 1.82-2.182 3.254z"
        fill="#F50"
      />
    </svg>
  );
}

const MemoTwitter = React.memo(Twitter);
export default MemoTwitter;
