import { Modal } from "../../../ui/modals/Modal";
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Button from "ui/buttons/Button";
import { useTranslation } from "react-i18next";
import InputField from "ui/inputs/Inputfield";
import Switch from "ui/inputs/Switch";
import Radio from "ui/inputs/Radio";
import { UserContext } from "context/state";
import { createSaveFilter } from "services/saveFilter";
import { useForm } from "@mantine/form";
import { ErrorModal } from "ui/modals/ErrorModal";

const ModalText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e6e6e;
  margin: 16px 0 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;
const ModalItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 10px 0px;
  margin: 0 0 0 0;
  justify-content: space-between;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
    margin: 0 0 10px 0;
  }
`;
const StyleToggle = styled.div`
  position: relative;
  align-items: right;
  @media only screen and (min-width: 1000px) {
    align-items: right;
  }
`;
const StyledCheckBoxWrapper = styled.div`
  display: flex;
  margin: 25px 0 27px 0;
  flex-direction: row;
  gap: 28px;
`;

type CustomProps = {
  saveSearchModal?: boolean;
  setSaveSearchModal: (value: boolean) => void;
};

const setSaveFilter = (
  data: any,
  setMessage: (value: string) => void,
  setShowMessage: (value: boolean) => void,
  t: any
) => {
  createSaveFilter(data).then((res: any) => {
    if (res?.success) {
      setShowMessage(true);
      setMessage(t("save_filter_successfully"));
    } else {
      setShowMessage(true);
      if (res.message) setMessage(res.message);
      else setMessage(t("save_filter_failed"));
    }
  });
};

const SaveSearchModal: React.FC<CustomProps> = ({
  saveSearchModal,
  setSaveSearchModal,
  ...props
}) => {
  const { t } = useTranslation();
  const { filterData, userData, savedAge, setSavedAge } =
    useContext(UserContext);
  const [emailNotifications, setEmailNotifications] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [Message, setMessage] = useState<string>("");
  const form = useForm<{ name: string; radioValue: string }>({
    initialValues: { name: "", radioValue: "daily" },
    validate: (values) => ({
      name:
        values.name.length >=61
          ? t("name_should_be_less_than_60_characters")
          : null || values.name === ""
          ? t("search_name_cannot_be_empty")
          : null,
    }),
  });

  const handleSaveFilter = (values: any, filters?: {}) => {
    let currentFilters = filterData;
    let age = [];
    if (filters != undefined && Object.keys(filters).length > 0) {
      age = currentFilters?.age;
    } else {
      age = savedAge;
    }
    let minAge = 67;
    let maxAge = 18;
    if (age?.length != 0 && age != undefined) {
      minAge = age[1];
      maxAge = age[0];
    }
    let birthdayMin = maxAge;
    let birthdayMax = minAge;
    let concatenatedPositions =
      currentFilters?.industry !== undefined
        ? currentFilters?.positions.concat(currentFilters?.industry)
        : []; //concatinating arrays
    let set = new Set(concatenatedPositions); //using Set to make sure no duplication
    concatenatedPositions = Array.from(set);
    let data = {
      // ...(concatenatedPositions !== undefined &&
      //   concatenatedPositions.length > 0 && {
      //     position_held: concatenatedPositions,
      //   }),
      drivers_license: currentFilters?.drivingLicense,
      email_type: emailNotifications ? values.radioValue : "",
      birthdate_max: birthdayMax,
      birthdate_min: birthdayMin,
      position_type: currentFilters?.workType,
      position_held:concatenatedPositions,
      industry: currentFilters?.industry,
      search_title: values.name.trim(),
      language: currentFilters?.language,
      location: currentFilters?.country,
    };
    createSaveFilter(data)
      .then((res:any)=>{
        if(res?.success){
            setShowMessage(true)
            setMessage(t("filter_saved_successfully"))
        }
        else{
          setShowMessage(true)
          if(res.message)
          setMessage(res.message)
          else
          setMessage(t("save_filter_failed"))
        }
    })
  };
  return (
    <>
      {showMessage && (
        <ErrorModal
          opened={showMessage}
          onClose={() => {
            setShowMessage(false);
            setSaveSearchModal(false);
          }}
          message={Message}
        />
      )}
      <Modal
        show={saveSearchModal}
        onHide={setSaveSearchModal}
        showClose={true}
        showOpenButton={false}
        title={t("save_filter_as")}
        background="#FFFFFF"
        openChildModel={showMessage}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            handleSaveFilter(values);
          })}
        >
          <InputField
            width="100%"
            height="auto"
            id="searchName"
            radius={10}
            styles={{
              input: {
                border: "1px solid #C3C3C3",
                color: "rgb(18, 59, 61) !important",
              },
            }}
            {...form.getInputProps("name")}
          />
          <ModalText>
            {t("save_filter_get_notified_of_new_matching_results")}
          </ModalText>
          <ModalItemWrapper>
            <ModalText style={{ color: "#123B3D", width: "232px" }}>
              {t(
                "do_you_want_to_receive_email_for_new_candidate_for_this_filter"
              )}
            </ModalText>
            <StyleToggle>
              <Switch
                checked={emailNotifications}
                onChange={() => setEmailNotifications(!emailNotifications)}
              />
            </StyleToggle>
          </ModalItemWrapper>
          {emailNotifications && (
            <StyledCheckBoxWrapper>
              <Radio
                name="emailNotification"
                options={[
                  {
                    key: t("daily"),
                    value: "daily",
                    id: "1",
                  },
                  {
                    key: t("weekly"),
                    value: "weekly",
                    id: "2",
                  },
                ]}
                {...form.getInputProps("radioValue")}
              />
            </StyledCheckBoxWrapper>
          )}
          <ModalItemWrapper style={{ padding: "13px 0px 0px 0px" }}>
            <Button
              variant="filled"
              children={t("Save")}
              width="100%"
              height="54px"
              disabled={form.values.name == "" ? true : false}
              type="submit"
            />
          </ModalItemWrapper>
        </form>
      </Modal>
    </>
  );
};
export default SaveSearchModal;
