import * as React from "react";

function GreyClose(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M11.834 1.34L10.659.163 6 4.822 1.342.164.167 1.34l4.658 4.658-4.658 4.659 1.175 1.175L6 7.172l4.659 4.659 1.175-1.175-4.659-4.659 4.659-4.658z"
        fill="#6F6F6F"
      />
    </svg>
  );
}

const MemoGreyClose = React.memo(GreyClose);
export default MemoGreyClose;
