import * as React from "react";

function Citizen(props) {
  return (
    <svg width={props?.width} height={props?.height} viewBox="0 0 12 14" fill="none" {...props}>
      <path
        d="M6 .333a5.996 5.996 0 00-1.54 11.793L6 13.667l1.54-1.54A5.996 5.996 0 006 .334zm0 1.334c1.107 0 2 .893 2 2s-.893 2-2 2-2-.893-2-2 .893-2 2-2zM6 11.2a4.8 4.8 0 01-4-2.146C2.02 7.726 4.667 7 6 7c1.327 0 3.98.727 4 2.053A4.8 4.8 0 016 11.2z"
        fill="#4CAF50"
      />
    </svg>
  );
}

const MemoCitizen = React.memo(Citizen);
export default MemoCitizen;
