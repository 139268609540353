//the function returns correct home path based on roles
enum Role{
    candidate='candidate',
    employer='employer'
}
function getPathForHome(role:Role,isAdmin:boolean) {
    let path =''
    switch(true){
        case role=='candidate': path='/home'
        break;
        case role=='employer' && isAdmin: path='/admin-home'
        break;
        case role=='employer': path='/employer-home'
        break;
        default:path='/'
    }
    return path
}

export default getPathForHome