import React, { useContext, useEffect, useState } from "react";
import { Box, Modal as MTModal } from "@mantine/core";
import Hand from "ui/icons/Hand.png";
import MemoEditProfile from "ui/icons/EditProfile";
import MemoPasswordAndSecurtity from "ui/icons/PasswordAndSecurtity";
import MemoBillingAndPayments from "ui/icons/BillingAndPayments";
import MemoFAQ from "ui/icons/FAQ";
import MemoEmailNotifications from "ui/icons/EmailNotifications";
import MemoLogout from "ui/icons/Logout";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "context/state";
import {
  deleteUser,
  emailNotificationSetting,
  getUserDetails,
  logOut,
} from "services/user/User";
import Switch from "ui/inputs/Switch";
import styled from "@emotion/styled";
import { ErrorModal } from "ui/modals/ErrorModal";
import { useTranslation } from "react-i18next";
import MemoDeleteIcon from "ui/icons/DeleteIcon";
import Button from "ui/buttons/Button";
import { AnonymizeUserData } from "./AnonymizeUserData";
import MemoSaveSettingIcon from "ui/icons/SaveSettingIcon";

let itemWrapper = {
  display: "flex",
  alignItems: "center",
  padding: "10px 10px 10px 0",
  margin: "0 0 10px 0",
};

let linkStylesMobile = {
  paddingLeft: "15px",
  textDecoration: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#123B3D",
};

let linkStylesDesktop = {
  paddingLeft: "15px",
  color: "#123B3D",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: 400,
  textDecoration: "none",
  lineHeight: "24px",
};

const StyleHeadText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #123b3d;
  display: flex;
  align-items: left;
  justify-content: left;
  margin: 20px 0 25px 20px;
  @media only screen and (min-width: 1000px) {
    margin: 20px 0 25px 0px;
  }
`;

const StyleItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0px;
  margin: 0 0 10px 30px;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0;
    margin: 0 0 10px 0;
  }
`;

const ShowDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;

const StyleToggle = styled.div`
  position: absolute;
  right: 20px;
  @media only screen and (min-width: 1000px) {
    position: relative;
    right: 0;
  }
`;
const ShowMobile = styled.div`
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    width: 400px;
  }
`;

const EmailTextWrapper = styled.div`
  @media only screen and (min-width: 1000px) {
    margin: 0 90px 0 0;
  }
`;

const StyleElement = styled(Box)`
  margin: 50px 0 0 0;
`;

const StyleHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #123b3d;
  letter-spacing: 0.3px;
  margin: 7px 0 0 1px;
`;

const StyleDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #6e6e6e;
  margin: 19px 0 0 2px;
`;

interface ComponentProps {
  loader?: boolean;
  setLoader?: any;
}
const SettingsComponent: React.FC<ComponentProps> = ({ loader, setLoader }) => {
  const {
    userId,
    role,
    userData,
    setUserData,
    setUserId,
    setActiveTap,
    setFilterData,
    setRole,
    setSavedAge,
    setRedirectToSubscription,
    setFirstSession,
    setShowDropdown,
    setShowMobileVerify,
    setCount,
    isAdmin,
    setIsAdmin
  } = useContext(UserContext);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const history = useHistory();
  const [pushPath, setPushPath] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const saveFeatureEnabledFromEnv =(process.env.REACT_APP_TOGGLE_SAVE_SEARCH as string).trim().toLowerCase() === 'true' ?? false

  useEffect(() => {
    if (
      (userData?.is_subscribed && role == "employer") ||
      role === "candidate"
    ) {
      setPushPath(true);
    }
  }, []);
  useEffect(() => {
    if (userId)
      getUserDetails(userId)
        .then((response) => {
          setUserData(response?.data);
        })
        .catch((err) => console.log(err));
  }, [userId]);

  const handleLogout = () => {
    let data = {
      uid: userId,
    };
    logOut(data).then((res) => {
      if (res.success) {
        // localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("userData");
        localStorage.removeItem("filters");
        history.push("/login");
        clearContextStates();
      }
    });
  };
  const clearContextStates = () => {
    setUserData({});
    setUserId("");
    setActiveTap(undefined);
    setFilterData({});
    setRole("");
    setSavedAge([]);
    setRedirectToSubscription(false);
    setFirstSession(false);
    setShowDropdown(false);
    setShowMobileVerify(false);
    setCount(0);
    setIsAdmin(false)
  };
  useEffect(() => {
    if (emailNotifications) {
      let data = {
        email_notification: userData?.email_notification === true ? 0 : 1,
      };
      emailNotificationSetting(data).then((response) => {
        // console.log(response);
        getUserDetails(userId)
          .then((response) => {
            setUserData(response?.data);
          })
          .catch((err) => console.log(err));
        setEmailNotifications(false);
      });
    }
  }, [emailNotifications]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [opened, setOpened] = useState(false);
  const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  const settingsOptionsHandler = (styleVariable: any) => {
    return (
      <Box sx={{ width: "100%" }}>
        <ErrorModal
          opened={showErrorModal}
          message={t("This_section_is")}
          onClose={() => setShowErrorModal(false)}
        />
        <StyleItemWrapper
          style={{ width: "fit-content" }}
          onClick={() => {
            !userData?.is_subscribed &&
              role == "employer" &&
              setShowErrorModal(true);
          }}
        >
          <Link
            style={{
              cursor: "pointer",
            }}
            onClick={(event) => {
              if (!pushPath) {
                event.preventDefault();
              }
            }}
            to={role === "candidate" ? "/profile-creation" : "/edit-profile"}
          >
            <MemoEditProfile width="36px" height="36px" />
          </Link>
          <Link
            style={{
              ...styleVariable,
              cursor: "pointer",
            }}
            onClick={(event) => {
              if (!pushPath) {
                event.preventDefault();
              }
            }}
            to={role === "candidate" ? "/profile-creation" : "/edit-profile"}
          >
            {t("Edit_Profile")}
          </Link>
        </StyleItemWrapper>
        <StyleItemWrapper>
          <Link
            to={
              role === "candidate"
                ? "/reset-password"
                : "/employer/reset-password"
            }
          >
            <MemoPasswordAndSecurtity width="36px" height="36px" />
          </Link>
          <Link
            style={styleVariable}
            to={
              role === "candidate"
                ? "/reset-password"
                : "/employer/reset-password"
            }
          >
            {t("Password_and_securtity")}
          </Link>
        </StyleItemWrapper>
        {role === "employer" && (
          <StyleItemWrapper
            style={{ width: "fit-content" }}
            onClick={() => {
              !userData?.is_subscribed &&
                role == "employer" &&
                setShowErrorModal(true);
            }}
          >
            <Link
              style={{
                cursor: "pointer",
              }}
              onClick={(event) => {
                if (!pushPath) {
                  event.preventDefault();
                }
              }}
              to={"/billing"}
            >
              <MemoBillingAndPayments width="36px" height="36px" />
            </Link>
            <Link
              style={{
                ...styleVariable,
                cursor: "pointer",
              }}
              onClick={(event) => {
                if (!pushPath) {
                  event.preventDefault();
                }
              }}
              to={"/billing"}
            >
              {t("Billing_and_payments")}
            </Link>
          </StyleItemWrapper>
        )}
        {!isAdmin&& role=="employer" && saveFeatureEnabledFromEnv && 
        <StyleItemWrapper>
          <Link to={"/saved-search"}>
            {" "}
            <MemoSaveSettingIcon width="36px" height="36px" />
          </Link>
          <Link style={styleVariable} to={"/saved-search"}>
            {t("saved_filters")}
          </Link>
        </StyleItemWrapper>}
        <StyleItemWrapper>
          <Link to={"/faq"}>
            {" "}
            <MemoFAQ width="36px" height="36px" />
          </Link>
          <Link style={styleVariable} to={"/faq"}>
            {t("FAQs")}
          </Link>
        </StyleItemWrapper>
        <StyleItemWrapper>
          <MemoEmailNotifications width="36px" height="36px" />
          <EmailTextWrapper style={{ ...styleVariable }}>
            {t("Email_Notifications")}
          </EmailTextWrapper>
          <StyleToggle>
            <Switch
              checked={userData?.email_notification}
              onChange={() => setEmailNotifications(true)}
            />
          </StyleToggle>
        </StyleItemWrapper>
        <StyleItemWrapper>
          {" "}
          <MemoDeleteIcon
            width="36px"
            height="36px"
            style={{ cursor: "pointer" }}
            onClick={() => setOpened(true)}
          />
          <p
            style={{ ...styleVariable, margin: "0 0 0 0", cursor: "pointer" }}
            onClick={() => setOpened(true)}
          >
            {t("Delete_account")}
          </p>
        </StyleItemWrapper>
        <StyleItemWrapper
          style={{ width: "fit-content" }}
          onClick={() => handleLogout()}
        >
          <MemoLogout
            width="36px"
            height="36px"
            style={{ cursor: "pointer" }}
            onClick={() => handleLogout()}
          />
          <p
            style={{ ...styleVariable, cursor: "pointer", margin: "0 0 0 0" }}
            onClick={() => handleLogout()}
          >
            {t("Logout")}
          </p>
        </StyleItemWrapper>
      </Box>
    );
  };
  const { t } = useTranslation();

  //function handles delete user
  const handleDeleteUser = () => {
    setLoader(true);
    const id = userData?.user_id
    deleteUser(id).then((res) => {
      setOpened(false);
      
      if (res?.success) {
        AnonymizeUserData(id)
        clearContextStates();
        localStorage.clear();
        setTimeout(()=>{
          setLoader(false);
          history.push("/");
        },5000)
      } else {
        setLoader(false);
        setErrorText(res?.message);
        setShowError(true);
      }
    });
  };
  return (
    <Wrapper>
      <StyleHeadText>
        {t("Settings")} <img src={Hand} width="28px" height="28px" />
      </StyleHeadText>
      <ShowMobile>{settingsOptionsHandler(linkStylesMobile)}</ShowMobile>
      <ShowDesktop>{settingsOptionsHandler(linkStylesDesktop)}</ShowDesktop>
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => setShowError(false)}
      />
      <MTModal
        centered
        opened={opened}
        padding={20}
        radius={30}
        overlayBlur={5}
        onClose={() => setOpened(false)}
        title={
          <>
            <StyleHead>{t("Are_you_sure")}</StyleHead>
            <StyleDescription>
              {t(
                "are_you_sure_you_want_to_delete_the_account_all_the_account_data_will_be_deleted"
              )}
            </StyleDescription>
          </>
        }
        styles={{
          close: {
            display: "none",
          },
          modal: {
            maxWidth: "339px",
            width: "100%",
          },
          title: {
            marginRight: "0",
          },
        }}
      >
        <>
          <Button
            variant="filled"
            style={{
              height: "54px",
              marginTop: "13px",
            }}
            onClick={() => {
              handleDeleteUser();
            }}
          >
            {t("Confirm")}
          </Button>
          <Button
            variant="subtle"
            style={{
              height: "21px",
              marginTop: "19px",
              marginBottom: "13px",
            }}
            onClick={() => setOpened(false)}
          >
            {t("Cancel")}
          </Button>
        </>
      </MTModal>
    </Wrapper>
  );
};

export default SettingsComponent;
