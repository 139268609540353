import React, { ReactNode, useState } from "react";
import { createStyles, Chip, Chips } from "@mantine/core";
import styled from "@emotion/styled";

interface ChipProps {
  children?: ReactNode;
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  data?: { value: string | undefined; label: string }[] | undefined;
}
const useStyles = createStyles((theme, _params, getRef) => ({
  iconWrapper: {
    ref: getRef("iconWrapper"),
  },
  root:{
    marginRight:"10px",
    marginTop:'-15px'
  },
  label: {
    display: "flex",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#6A6A6A",
    backgroundColor: "transparent !important",
    height: "35px !important",
  },
  "&.mantine-1183y36": {
    gap: "0px !important",
  },
  checked: {
    backgroundColor: `#FF5500 !important`,
    color: theme.white,
    border: "1px solid #FF5500  !important",
    paddingLeft: "24px !important",
    paddingRight: "24px !important",

    [`& .${getRef("iconWrapper")}`]: {
      color: "#FF5500 !important",
      display: "none",
    },
  },
}));

export const ChipInput: React.FC<ChipProps> = ({
  width,
  height,
  color,
  backgroundColor,
  fontSize,
  data,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Chips position='left' classNames={classes} {...props} multiple size='md' radius='md'>
      {data?.map((item) => {
        return <Chip value={item?.value}>{item?.label}</Chip>;
      })}
    </Chips>
  );
};
