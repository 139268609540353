import * as React from "react";

function Filter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 13" fill="none" {...props}>
      <path
        d="M7.389 13h4.222v-2.167H7.39V13zM0 0v2.167h19V0H0zm3.167 7.583h12.666V5.417H3.167v2.166z"
        fill="#FF3000"
      />
    </svg>
  );
}

const MemoFilter = React.memo(Filter);
export default MemoFilter;
