import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import SearchAndSelectAbstraction from "ui/data-display/SearchAndSelectAbstraction";
import { Stack } from "@mantine/core";
import styled from "@emotion/styled";
import { fontBlack } from "constants/colors";
import TextArea from "ui/inputs/Textarea";
import Button from "ui/buttons/Button";
import { PrivateContext, UserContext } from "context/state";
import { useForm } from "@mantine/form";

interface AboutProps {
  show?: boolean;
  onHide?: any;
  getData?: any;
  passData?: any;
  title?: string;
  setAddClickedFor?: any;
}
const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-top: 26px;
  color: ${fontBlack};
  max-width: 298px;
`;
const AddButtonWrapper = styled.div`
  margin-top: 0px;
  position: absolute;
  bottom: 10px;
  left:0;
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  z-index: 2000;
  text-align: center;
`;
const AboutDrawer: React.FC<AboutProps> = ({
  show,
  onHide,
  title,
  setAddClickedFor,
}) => {
  const { t } = useTranslation();
  const { about, setAbout } = useContext(PrivateContext);
  const { userData } = useContext(UserContext);
  const form = useForm({
    initialValues: {
      aboutText: userData?.about_yourself ?? "",
    },
  });
  const fontStyles = {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    color: fontBlack,
  };
  const inputStyle = {
    border: "none",
    height: "257px",
    color: fontBlack,
    "::placeholder": {
      color: "#6E6E6E !important",
      fontFamily: "Poppins !important",
      opacity: "1",
      fontWeight: 400,
      fontSize: "16px",
    },
  };
  const handleAboutSubmit = (values: any) => {
    setAbout(values?.aboutText);
    setAddClickedFor();
  };
  return (
    <Stack align={"center"}>
      <SearchAndSelectAbstraction
        //title={title ? title : 'Title'}
        setShow={onHide}
        showSearch={false}
        //clearAll={() => true}
        showClear={false}
        //showFilter_Clear={2}
        buttonText={t(`Add`)}
        //resetFilter={setRestData} // Reseting the Data
        //setAddElements={setAddElements}
      >
        <Title>{t("about")}</Title>
        <form onSubmit={form.onSubmit((values) => handleAboutSubmit(values))}>
          <TextArea
            styles={{ label: fontStyles, input: inputStyle }}
            //label={t("Write_about_yourself")}
            width="100%"
            height="auto"
            {...form.getInputProps("aboutText")}
            placeholder={t("Enter_Bio")}
            sx={{
              margin: "27px auto 93px auto",
            }}
          />
          <AddButtonWrapper>
            <Button
              type="submit"
              variant="filled"
              children={t("Save")}
              width="335px"
              height="54px"
            />
          </AddButtonWrapper>
        </form>
      </SearchAndSelectAbstraction>
    </Stack>
  );
};

export default AboutDrawer;
