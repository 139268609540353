import { Grid, Group } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useViewportSize } from "@mantine/hooks";
import { UserContext } from "context/state";
import getBrowserLanguage from "helpers/get-browser-language";
import CountrySearch from "helpers/search-select/CountrySearch";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Drawer from "ui/data-display/Drawer";
import MemoChevronDown from "ui/icons/ChevronDown";
import MemoPhoneIcon from "ui/icons/PhoneIcon";
import InputField from "ui/inputs/Inputfield";
import { MultiSelectButton } from "ui/inputs/MultiSelectButton";
import * as Yup from "yup";
import { putUserDetails } from "services/user/User";
import { useHistory } from "react-router";
import { getCountryCodes } from "constants/countrycodes";
import { IndustrySearch } from "helpers/search-select/IndustrySearch";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { fontBlack } from "constants/colors";
import { Industries } from "modules/imports/interfaces/interfaces";

export const StyleSelectField = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;

  /* Dropdown */

  .dropdown {
    display: inline-block;
    position: relative;
  }

  .dd-button {
    display: flex;
    border: none;
    border-radius: 12px;
    justify-content: left;
    align-items: center;
    padding: 0px 0px 0px 12px;
    background-color: #ffffff;
    cursor: pointer;
    width: 132px;
    height: 39px;
  }

  .dd-button-logo {
    position: absolute;
    top: 32%;
    right: 10px;
  }

  .dd-input {
    display: none;
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    width: 144px;
  }

  #scrollbar1::-webkit-scrollbar {
    display: none;
  }

  .dd-input + .dd-menu {
    display: none;
  }

  .dd-input:checked + .dd-menu {
    display: block;
  }
  body:focus + .dd-menu {
    display: none;
  }

  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }

  .dd-menu li:hover {
    background-color: #f6f6f6;
  }

  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }

  .dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
`;

const StyleError = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 5px;
  word-break: break-word;
  color: #f03e3e;
  display: flex;
  font-family: Poppins;
`;
const StyleButton = styled.div`
  position: fixed;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px -16px 44px rgba(0, 0, 0, 0.1);
  margin: 0 0 0 -14px;
  padding: 2px;
  min-height: 72px;
  bottom: 0;
  width: 100%;
  @media only screen and (min-width: 1000px) {
    background: transparent;
    position: relative;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    bottom: 0;
    padding: 0;
    margin: 0;
    min-height: 0;
    max-width: 557px;
    z-index: 0;
  }
`;

const StyleButtonWrapper = styled.div`
  margin: 0 20px 0 10px;
  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

const Container = styled.div`
  @media only screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
`;

const StyleSpacing = styled.div`
  //margin-top: 20px;
  @media only screen and (min-width: 1000px) {
    margin-top: 2.5%;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 10px;
  }
`;
const WrapperStyle = styled.div`
  margin-bottom: 120px;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 0px;
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const EmployerOnboardingStep = () => {
  const { t } = useTranslation();
  const { showDropdown, setShowDropdown, userData, userId } =
    useContext(UserContext);
  const { width } = useViewportSize();
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [countryData, setCountryData] = useState<[]>([]);
  const history = useHistory();
  const [industryData, setIndustryData] = useState<Industries[]>([]);
  const [showIndustrySearch, setShowIndustrySearch] = useState(false);
  const countryCodes = getCountryCodes()
  const schema = Yup.object().shape({
    phone: Yup.string()
      .matches(/^[0-9]*$/, t("Invalid_phone_number"))
      .required(t("Please_enter_phone_number")),
    code: Yup.string()
      .matches(/^(--.+|(?!--).*)$/, t("Please_select_country_code"))
      .required(t("Please_enter_phone_number")),
    companyname: Yup.string()
      .trim()
      .required(t("Company_Nameis_a_required_field"))
      .matches(/^(?!\s+$).*/, t("Company_Name_should_have_at_least_2_letters"))
      .min(2, t("Company_Name_should_have_at_least_2_letters")),
    contactperson: Yup.string()
      .trim()
      .required(t("Contact_Person_should_have_at_least_2_letters"))
      .matches(
        /^(?!\s+$).*/,
        t("Contact_Person_should_have_at_least_2_letters")
      )
      .min(2, t("Contact_Person_should_have_at_least_2_letters")),
    country: Yup.array()
      .test(
        "test-length",
        t("Country_cannot_be_empty"),
        () => getCheckedIds(countryData, false)?.length > 0
      )
      .required(t("Country_cannot_be_empty"))
      //industryIds: Yup.array().min(1, t("Please_select_Industry"))
  });
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      companyname: "",
      contactperson: "",
      email: userData?.email != "" ? userData?.email : "",
      code: "--",
      phone: "",
      // industry: [{}],
      country: [],
      // industryType:[{}],
      // industryIds: [""],
    },
  });
  // Function to Get all the checked IDs
  const getCheckedIds = (
    data: object[],
    preferredJob: boolean,
    industryOnly?: boolean
  ) => {
    let checkedIds: string[] = [];
    if (preferredJob) {
      data?.forEach((item: any) =>
        item?.checked ? checkedIds.push(item?.id, item?.parentId) : ""
      );
    } else if (industryOnly) {
      data?.forEach((item: any) =>
        item?.checked ? checkedIds.push(item?.parentId) : ""
      );
    } else {
      data?.forEach((objectData: any) => {
        objectData?.data?.forEach((checkboxData: any) => {
          if (checkboxData?.checked) {
            checkedIds.push(checkboxData.id);
          }
        });
      });
    }
    return checkedIds;
  };
  // useEffect(()=>{
  //   setIndustryData(IndustryStore.getAll(userData?.industry_type_filter))
  // },[])
  useEffect(() => {
    form.setFieldValue("country", countryData);
  }, [countryData]);
  // useEffect(() => {
  //   form.setFieldValue("industryIds",userData?.industry_type_filter)
  // }, [userData?.industry_type_filter]);

  

  // useEffect(() => {
  //   form.setFieldValue("industryType", industryData);
  //   form.setFieldValue("industryIds",getCheckedIds(industryData,false,false));
  // }, [industryData]);
  const submitDataHandler = () => {
    let putdata = {
      company_name: form.values.companyname.trim(),
      contact_person: form.values.contactperson.trim(),
      phone_number: form.values.code + form.values.phone,
      // preferred_job: form.values.industryIds,
      // preferred_industry_position: form.values.industryIds,
      // industry_type_filter: form.values.industryIds,
      location: getCheckedIds(countryData, false),
      type: "create",
    };
    putUserDetails(userId, putdata).then((res) => {
      if (res?.success) {
        history.push("/employer-home");
      }
    });
  };
  return (
    <WrapperStyle>
      <form onSubmit={form.onSubmit((values) => submitDataHandler())}>
        <Grid gutter={width > 1000 ? 20 : 10.5}>
          <Grid.Col lg={6}>
            <InputField
              width="100%"
              height="auto"
              id="companyName"
              label={t("Company_name")}
              {...form.getInputProps("companyname")}
              style={{ marginTop: "10px" }}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <InputField
              width="100%"
              height="auto"
              id="contact_person"
              label={t("contact_person")}
              {...form.getInputProps("contactperson")}
              style={{ marginTop: "10px" }}
            />
          </Grid.Col>
          <Grid.Col lg={6} md={12}>
            <InputField
              id="phone"
              phoneType={true}
              placeholder={t("phone")}
              label={t("Phone")}
              style={{ marginTop: "10px" }}
              icon={
                <div style={{ position: "relative" }}>
                  <StyleSelectField>
                    <label className="dropdown">
                      <div
                        className="dd-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowDropdown(!showDropdown);
                        }}
                        style={{
                          width: "65px",
                          borderRight: "1px solid rgba(0, 0, 0, 0.4)",
                          marginRight: "10px",
                          borderRadius: "0",
                        }}
                      >
                        {form?.values?.code}
                        <MemoChevronDown
                          className="dd-button-logo"
                          style={{ marginRight: "10px" }}
                        />
                      </div>

                      <input
                        type="checkbox"
                        className="dd-input"
                        checked={showDropdown}
                        id={form?.values?.code}
                      />

                      <ul
                        id="dd-menuID"
                        className="dd-menu"
                        style={{
                          zIndex: "300",
                          width: "auto",
                          maxHeight: "300px",
                          overflow: "auto",
                          borderRadius: "10px",
                        }}
                      >
                        {countryCodes?.length != 0 &&
                          countryCodes
                            ?.sort((a: any, b: any) =>
                              t(a?.label)?.localeCompare(
                                t(b?.label),
                                `${getBrowserLanguage()}`,
                                {
                                  ignorePunctuation: true,
                                }
                              )
                            )
                            ?.map((item: any) => {
                              return (
                                <li
                                  style={{
                                    zIndex: "200",
                                    width: "auto",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    form.setFieldValue("code", item?.value);
                                    setShowDropdown(false);
                                  }}
                                >
                                  {/* {`${item?.label} ${item?.value}`} */}
                                  <div> {t(`${item?.label}`)}</div>
                                  <div> {`${item?.value}`}</div>
                                </li>
                              );
                            })}
                      </ul>
                    </label>
                  </StyleSelectField>
                  <MemoPhoneIcon
                    width="16.03px"
                    height="24.26px"
                    style={{ position: "absolute", top: "5px", left: "90px" }}
                  />
                </div>
              }
              {...form.getInputProps("phone")}
            />
            <StyleError>{form?.errors?.code}</StyleError>
            <StyleError>{form?.errors?.phone}</StyleError>
          </Grid.Col>
          <Grid.Col lg={6} md={12}>
            <InputField
              width="100%"
              height="auto"
              id="email"
              label={t("Email")}
              {...form.getInputProps("email")}
              type="email"
              style={{ marginTop: "10px" }}
              disabled={true}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          {/* <Grid.Col lg={6} md={12}>
            <StyleSpacing>
              <Drawer
                padding={"0px"}
                position='right'
                onClose={() => setShowIndustrySearch(false)}
                opened={showIndustrySearch}>
                <IndustrySearch
                  onHide={() => setShowIndustrySearch(false)}
                  getData={(value: any) => setIndustryData(value)}
                  passData={industryData}
                  title={t("Industry")}
                  savedData={form.values.industryIds}
                />
              </Drawer>
              <MultiSelectButton
                data={industryData}
                header={t(`Select_your_industry`)}
                type="in"
                setData={setIndustryData}
                placeholder={"Select_Industry"}
                onClick={() => setShowIndustrySearch(true)}
                //infoText={t("Country you’re looking for people in?")}
                errorMessage={
                  form?.errors?.industryIds &&
                  getCheckedIds(industryData, false)?.length == 0
                    ? t("Please_select_Industry")
                    : undefined
                }
              />
            </StyleSpacing>
          </Grid.Col> */}
          <Grid.Col lg={6}>
            <StyleSpacing>
              <MultiSelectButton
                data={countryData}
                header={t("where_is_your_business_located")}
                type="in"
                setData={setCountryData}
                buttonLabel={t("Search__Select")}
                onClick={() => setShowCountrySearch(true)}
                //infoText={t("Country you’re looking for people in?")}
                errorMessage={
                  form?.errors?.country &&
                  getCheckedIds(countryData, false)?.length == 0
                    ? t("Please_Select_Country")
                    : undefined
                }
              />
            </StyleSpacing>
            <Drawer
              position="right"
              padding={"0px"}
              onClose={() => setShowCountrySearch(false)}
              opened={showCountrySearch}
            >
              <CountrySearch
                title={t("Country")}
                singleSelect={false}
                getData={setCountryData}
                passData={countryData}
                onHide={() => setShowCountrySearch(false)}
                checkAll={true}
                savedData={userData.location}
              />
            </Drawer>
          </Grid.Col>
        </Grid>
        <Container>
          <StyleButton>
            <Group
              position="center"
              mt="xs"
              sx={{
                display: "flex",
                bottom: "0",
                justifyContent: "flex-end",
              }}
            >
              <StyleButtonWrapper>
                <button
                  type="submit"
                  style={{
                    marginRight: "0px",
                    width: "125px",
                    height: "54px",
                    fontSize: "16px",
                    borderRadius: "52px",
                    border: "none",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    color: "#fff",
                    background:
                      "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
                  }}
                >
                  {t("Submit")}
                </button>
              </StyleButtonWrapper>
            </Group>
          </StyleButton>
        </Container>
      </form>
    </WrapperStyle>
  );
};

export default EmployerOnboardingStep;
