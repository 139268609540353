import googleLib from "google-libphonenumber";
//function seperates country code and number
function SeperatePhoneAndCode(phone:string) {
    let utilInstance = googleLib.PhoneNumberUtil.getInstance();
  const numberparsed = utilInstance.parseAndKeepRawInput(phone, "US");
  let seperated:{}={}
  if(numberparsed.getCountryCode()){
    seperated ={
        code: numberparsed.getCountryCode(),
        phoneNumber: numberparsed.getNationalNumber()
      }
  }
  return seperated
  
}

export default SeperatePhoneAndCode