import { Box } from '@mantine/core'
import MemoBlackChevronLeft from 'ui/icons/BlackChevronLeft'
import MemoLikeActive from 'ui/icons/LikeActive'
import MemoWhiteChevronLeft from 'ui/icons/WhiteChevronLeft'
import React, { Dispatch, ReactNode, SetStateAction, useContext, useTransition } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { UserContext } from "context/state";
import MemoFavouriteOrange from 'ui/icons/FavouriteOrange';
import MemoFavouriteGrey from 'ui/icons/FavouriteGrey';
import { useTranslation } from 'react-i18next'

const TopDiv = styled.div`
width: 416px;
height: 296px;
background: linear-gradient(180deg, #FF3000 -6.93%, #FF5500 93.07%);
border-radius: 48px;
top: -120px;
position:absolute;
align-items:center;
.backContainer{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:166px;
    padding:0 28px 0 20px;
}
`
enum userType {
  candidate = 1,
  employer = 2,
}
interface skeleton {
    children?: any,
    icon?: any,
    onHide?: any,
    setFavourite?: any,
    selectedProfile?: string
    isFavourite?: boolean,
    previewMode?:boolean,
    setShowPreview?:Dispatch<SetStateAction<boolean>>,
    type:userType
}
const StyleButton = styled.div`
    width: 36px;
    height:36px;
    border-radius: 56px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

const PreviewEditWrapper = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    display:flex;
    align-items: center;
    cursor: pointer;
`

const RightSideBarSkeleton: React.FC<skeleton> = ({icon, onHide, setFavourite, selectedProfile ="", isFavourite,previewMode,setShowPreview, type=1}) => {
    let BoxStyles = {
        width: '100%',
        height: '160px',
        background: 'linear-gradient(180deg, #FF3000 -6.93%, #FF5500 93.07%)',
        borderBottomLeftRadius: '48px',
        borderBottomRightRadius: '48px',
    }
    let iconWrapperStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '16px',
        padding: '20px 28px 0 20px'
    }

    const { role } = useContext(UserContext)
    const { t } = useTranslation();
    const favouriteIcon = () => {
        if (isFavourite)
            return <StyleButton><MemoFavouriteOrange style={{ cursor: "pointer" }} onClick={setFavourite} width="18px" height="23px" /></StyleButton>
        else
            return <StyleButton><MemoFavouriteGrey style={{ cursor: "pointer" }} onClick={setFavourite} width="18px" height="23px" /></StyleButton>
    }
    return (
      <Box sx={BoxStyles}>
        <Box sx={iconWrapperStyles}>
          <div style={{ cursor: "pointer" }} onClick={() => onHide(false)}>
            <MemoWhiteChevronLeft />
          </div>
          <div>
            {type==1 ? (
              <PreviewEditWrapper onClick={()=>{if(setShowPreview!) setShowPreview(!previewMode)}}>
                {icon}
                {previewMode ? t("Edit") : t("preview")}
              </PreviewEditWrapper>
            ) : role !== "candidate" && selectedProfile !== '' ? (
              favouriteIcon()
            ) : (
              ""
            )}
          </div>
        </Box>
      </Box>
    );
}

export default RightSideBarSkeleton