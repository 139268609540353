//Mobile verification mobile strings, note also update these in translations if you make changes to them

export const CommonStrings = [
  "Your mobile has not yet been verified. Verify Now!",
  "Get notified when employers contact you. Verify phone!",
  "Never miss a notification. Verify mobile now!",
];

export const CandidateStrings = [
  "Stay updated on all messages. Verify phone number!",
  "Missing out on notifications from potential employers? Verify phone number!"
];

export const EmployerStrings = [
  "Get notifications on your phone when a candidate answers. Verify phone now!",
  "Missing out on your chat notifications? Verify phone now!",
];

export const timer = 20000
