import * as React from "react";

function FilterDash(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 16" fill="none" {...props}>
      <path
        d="M8.944 16h5.112v-2.667H8.944V16zM0 0v2.667h23V0H0zm3.833 9.333h15.334V6.667H3.833v2.666z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoFilterDash = React.memo(FilterDash);
export default MemoFilterDash;
