import React, { ReactNode } from "react";
import { Input, InputWrapper, TextInput, TextInputProps } from "@mantine/core";
import styled from "@emotion/styled";

interface InputFieldProps extends TextInputProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  placeholder?: string;
  id: string;
  description?: string;
  label?: string | ReactNode;
  icon?: ReactNode;
  sx?: any;
  placeholderColor?: any;
  phoneType?: boolean;
  dob?: boolean; //added this change for modified date field
  labelColor?:string
}
interface StyleInputFieldProps {
  width?: string;
}
const StyleInputField = styled.div<StyleInputFieldProps>`
  width: ${(props) => (props?.width ? props.width : "100%")};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  @media only screen and (min-width: 1000px) {
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
  @media screen and (min-resolution: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
`;

const InputField: React.FC<InputFieldProps> = ({
  width,
  height,
  children,
  color,
  backgroundColor,
  icon,
  fontSize,
  placeholder,
  id,
  description,
  placeholderColor,
  label,
  phoneType = false,
  dob,
  labelColor='',
  ...props
}) => {
  const styles = {
    width: dob ? "100%" : width ? width : "100%",
    height: height,
    fontSize: fontSize ?? "16px",
    fontFamily: "Poppins",
    // paddingBottom: "15px",
    color: "#123B3D",
  };
  let wrapperwidth = dob ? width : undefined;
  return (
    <StyleInputField width={wrapperwidth}>
      <TextInput
        id={id}
        label={label}
        description={description}
        placeholder={placeholder}
        icon={icon}
        radius={12}
        styles={{
          root: {
            ...styles,
            textAlign: "left",
          },
          invalid: {
            color: "#123B3D",
          },
          input: {
            fontSize: fontSize ?? "16px",
            fontWeight: 400,
            height: "54px",
            padding: phoneType ? "0 10px 0 120px !important" : "auto", //can't set padding to only zero
            background: "#FFF",
            border: "0px",
            fontFamily: "Poppins !important",
            color: "#123B3D !important",
            "::placeholder": {
              color: placeholderColor ? placeholderColor : "#6E6E6E !important",
              fontFamily: "Poppins !important",
              opacity: "1",
            },
            ":disabled":{
              backgroundColor:"#FFF"
            }
          },
          label: {
            fontWeight: 400,
            color:labelColor!=''?labelColor: "#123B3D",
            lineHeight: "20px",
            letterSpacing: "0.3px",
          },
          error: {
            fontFamily: "Poppins !important",
            display: phoneType ? "none" : "block",
          },
          icon: phoneType
            ? {
                pointerEvents: "auto",
                zIndex: 10,
                width: "100px",
              }
            : {},
        }}
        {...props}
      />
    </StyleInputField>
  );
};
export default InputField;
