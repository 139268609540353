import * as React from "react";

function EditProfile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M18.159 17.898c1.94 0 3.511-1.545 3.511-3.45 0-1.904-1.572-3.448-3.511-3.448-1.94 0-3.511 1.544-3.511 3.449 0 1.904 1.572 3.449 3.51 3.449zM18.159 19.621c-3.514 0-6.364 2.799-6.364 6.25h12.728c0-3.451-2.85-6.25-6.364-6.25z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoEditProfile = React.memo(EditProfile);
export default MemoEditProfile;
