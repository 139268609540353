export const TRANSLATIONS_PL = {
  Find_the_Perfect_Job_Match: "Znajdź idealnego pracownika lub pracodawcę",
  Providing_the_best_solutions_to_find_the_people_you_need_to_take_your_business_to_the_next_level:
    "Zapewniamy najlepsze rozwiązania umożliwiające znalezienie wyjątkowych pracowników lub pracodawców, którzy pomogą Ci rozwinąć się zawodowo.",
  I_am: "Szukam…",
  Yes: "Tak",
  No: "Nie",
  Search: "Szukaj",
  Type_of_work: "Rodzaj szukanej pracy",
  Driving_License: "Prawo jazdy",
  Select_Sec_lang: "Wybierz język dodatkowy",
  Select_Pri_lang: "Pierwszy język",
  select_the_below_option: "(wybierz jedną z poniższych opcji)",
  Looking_to_Hire: "Pracodawca",
  Looking_for_Work: "Kandydat",
  Country_you_like: "Kraj, w którym chcesz pracować",
  Login: "Zaloguj się",
  Back: "Wróć",
  Age: "Wiek",
  Email_Address: "Adres e-mail",
  Password: "Hasło",
  Already_Have_Account_Log_In: "Masz już konto? ",
  Forgot_Password: "Nie pamiętasz hasła?",
  Log_in: "Zaloguj się",
  Continue: "Dalej",
  Or_Continue_with: "lub kontynuuj za pomocą",
  New_User_Create_Account: "Jesteś nowym użytkownikiem? Utwórz konto",
  Create_Account: "",
  We_provide_the_best_solution_to_fill_the_workplace_with_the_right_people:
    "W kilka chwil znajdziesz pracę lub pracownika!",
  Send_Email: "Wyślij e-mail",
  Check_your_email: "Sprawdź skrzynkę pocztową",
  We_have_sent_password_recovery_instructions_to_your_email:
    "Na podany przez Ciebie adres e-mail wysłaliśmy instrukcje odzyskiwania hasła",
  Okay: "Rozumiem",
  Create_New_Password: "Utwórz nowe hasło",
  Your_new_password_must_be_different_from_your_previous_passwords: "Nowe hasło musi różnić się od poprzedniego.",
  Reset_Login: "Resetuj i zaloguj",
  Confirm_Password: "Powtórz hasło",
  Create_an_Account: "Utwórz konto",
  Phone: "Numer telefonu",
  Verify_Phone: "Potwierdź numer telefonu",
  Please_enter_the_verification_code_sent_to:
    "Wprowadź jednorazowe hasło, które przesłaliśmy na Twój numer telefonu",
  Didnt_receive_the_code: "Kod nie dotarł? ",
  Resend: "Wyślij jeszcze raz",
  Verify: "Potwierdź",
  Please_Wait_Verifying_OTP: "Prosimy czekać. Trwa weryfikacja hasła jednorazowego",
  Your_mobile_number_is_verified: "Twój numer telefonu został zweryfikowany!",
  Verify_Email: "Potwierdź adres e-mail",
  We_have_sent_an_email: "Na adres ",
  We_have_sent_an_email1: " została wysłana wiadomość.",
  Please_click_the_link_to_verify:
    " Kliknij link, aby potwierdzić swój adres e-mail. Pamiętaj, aby sprawdzić folder ze spamem.",
  Proceed: "Dalej",
  Didnt_receive_the_link: "Link nie dotarł? ",
  Congratulations: "Gratulacje!",
  You_are_now_ready_to_find_you_first_candidates: "Możesz rozpocząć poszukiwanie kandydatów!",
  Filter: "Filtrowanie",
  Refine_your_search_with_extra_filters_to_pinpoint_the_people_you_need:
    "Zawężaj kryteria wyszukiwania i znajduj idealnych kandydatów dzięki dodatkowym filtrom.",
  Favourites: "Ulubione",
  Find_the_talent_you_have_saved_organize_them_and_hire:
    "Odszukuj zapisanych przez siebie kandydatów, sortuj profile i oferuj zatrudnienie!",
  Chat: "Czat",
  Find_all_your_messages_in_one_place:
    "Przechowuj wszystkie wiadomości w jednym miejscu. Wysyłaj odpowiedzi i umawiaj się na spotkania!",
  Start_Filtering: "Ustaw filtry",
  Reset_Filter: "Resetuj filtry",
  Start_by_filtering_your_requirements: "Aby znaleźć dobre dopasowanie,zacznij od określenia swoich wymagań",
  Where_is_the_job_located: "Miejsce zatrudnienia",
  Employees_needed_from_which_industry: "Branże, z których potrzebni są pracownicy",
  What_position_do_you_need_to_fill: "Stanowiska, których dotyczy rekrutacja",
  Required_language: "Wymagane języki",
  When_should_the_position_be_filled: "Termin rozpoczęcia pracy",
  Drivers_license_required: "Wymagane kategorie prawa jazdy",
  Apply_filter: "Zastosuj filtry",
  Clear_All: "Wyczyść wszystko",
  What_industry_do_you_seek_employees: "Branże, z których potrzebni są pracownicy",
  Add: "Dodaj",
  What_position_need_to_beemployed: "Stanowiska, których dotyczy rekrutacja",
  Required_drivers_license: "Wymagane kategorie prawa jazdy",
  What_position_need_to_be_employed: "Wymiar zatrudnienia",
  Home: "Strona startowa",
  Message: "Wiadomości",
  Settings: "Ustawienia",
  Welcome: "Witaj!",
  Filter_By: "Filtruj według",
  years: "lat",
  Primary_language: "Język główny",
  Experience: "Doświadczenie",
  Country: "Kraj",
  Verified: "Zweryfikowano",
  Info_Experience: "O firmie",
  Secondary_languages: "Języki dodatkowe",
  Secondary_languages_no_data: "Język dodatkowy jest niedostępny",
  License_no_data: "Prawo jazdy niedostępne",
  Drivers_license: "Prawo jazdy",
  Notice_Period: "",
  Availability: "Dostępność",
  CV: "CV",
  Send_CV_on_Email: "Prześlij CV na adres e-mail",
  Download_CV: "Pobierz CV",
  References_available: "Referencje",
  Maria_can_provide_references_upon_request: " może dostarczyć referencje na życzenie.",
  Chat_Now: "Rozpocznij czat",
  Start_talking: "Rozpocznij rozmowę!",
  To_chat_with_name: "Aby móc rozpocząć rozmowę z użytkownikiem",
  please_select_subscription: "wybierz subskrypcję.",
  Select_subscription: "Wybierz subskrypcję",
  Add_to_favourites: "Dodaj do ulubionych!",
  to_add: "Aby móc dodać użytkownika",
  to_fav: "do ulubionych, wybierz subskrypcję.",
  Edit_Profile: "Edycja profilu",
  Password_and_securtity: "Hasło i zabezpieczenia",
  Billing_and_payments: "Rachunki i płatności",
  FAQ: "FAQ",
  FAQs: "FAQs",
  Email_Notifications: "Powiadomienia e-mail",
  Logout: "Wyloguj",
  Support: "Pomoc",
  Get_in_touch_contact: "Napisz do nas na adres",
  Privacy_Policy: "Polityka prywatności",
  Terms_of_use: "Regulamin",
  Current_Password: "Bieżące hasło",
  Update_Password: "Zmień hasło",
  Billing_Details: "Dane rozliczeniowe",
  Invoice: "Faktura",
  Subscription_Details: "Szczegóły subskrypcji",
  Continue_to_Checkout: "Przejdź do kasy",
  Disable_subscription: "Anuluj subskrypcję",
  month: "miesiąc",
  Expiration_date: "Data wygaśnięcia:",
  Transactions: "Transakcje",
  Lite_Subscription_paid: "Zapłacono za subskrypcję Lite",
  Transaction_ID: "Identyfikator transakcji:",
  Invoice_Details: "Szczegóły faktury",
  Invoice_no: "Numer faktury:",
  Paid: "Zapłacono",
  Billing_Address: "Adres rozliczeniowy",
  Amount_Paid: "Zapłacona kwota",
  Notifications: "Powiadomienia",
  send_you_message: "Masz wiadomość od użytkownika Richar Maxwell",
  _Days_Ago: "2 dni temu",
  Subscription: "Subskrypcja",
  Choose_a_plan: "Wybierz plan",
  Currency: "Waluta",
  Contact_Us: "Skontaktuj się z nami",
  Or: "Lub…",
  Name: "Imię i nazwisko",
  Email: "Adres e-mail",
  Company_name: "Nazwa firmy",
  Submit: "Prześlij",
  Address: "Adres",
  Billing_email: "Adres e-mail do przesyłania rozliczeń",
  City: "Miasto",
  Zip: "Kod pocztowy",
  Different_billing_information: "Inne dane rozliczeniowe",
  I_accept_the_Terms_and_Conditions_for_using_Jobpark:
    "Akceptuję regulamin świadczenia usług przez Jobpark",
  Place_order: "Złóż zamówienie",
  Payment: "Płatność",
  Are_you_Sure: "Potwierdź swój wybór",
  Are_you_sure_you_want_to_disable_the_subscriptions: "Czy na pewno chcesz anulować subskrypcje?",
  Confirm: "Potwierdź",
  Cancel: "Wróć",
  Complete_company_profile: "Uzupełnij profil firmy",
  Company_logo: "Logo firmy (opcjonalnie)",
  _300_x_300_pixels_for_best_quality:
    "Aby uzyskać najlepszą jakość, wybierz obraz o rozmiarze 300 × 300 pikseli. Obsługiwane formaty: JPG, JPEG i PNG.",
  Social_Media_Links: "Linki do mediów społecznościowych",
  Facebook_Link: "Link do profilu na Facebooku",
  Twitter_Link: "Link do profilu na Twitterze",
  Linkedin_Link: "Link do profilu na LinkedIn",
  Instagram_Link: "Link do profilu na Instagramie",
  Select_your_industry: "Wybierz branżę",
  Link_to_website: "Link do strony internetowej",
  Description: "Opis",
  Save: "Zapisz",
  Skip: "Pomiń",
  location: "Wybierz kraj",
  Invite_2_New_Users_: "Zaproś 2 nowych użytkowników - ZA DARMO",
  Add_New_User: "Dodaj nowego użytkownika",
  A_notification_will_be_send_to_the_users_email_address:
    "Na adres e-mail użytkownika zostanie wysłana wiadomość",
  Type_a_message: "Wpisz treść wiadomości",
  No_Conversation_yet: "Jeszcze nie masz żadnych konwersacji!",
  We_will_let_you_know_when_someone_needs_your_help:
    "Powiadomimy Cię, gdy ktoś będzie potrzebować Twojej pomocy",
  Available: "Dostępność",
  Type_of_position: "Rodzaj pozycji",
  Name_SM: " w mediach społecznościowych",
  Check: "Sprawdź użytkownika ",
  CV_is_Forwarded_to_Email: "CV zostało przesłane na adres e-mail",
  CV_is_Downloaded: "CV zostało pobrane",
  Link: "Link",
  Social_media: "Media społecznościowe",
  Congrats_Tell_us_about_you: "Sukces! Opowiedz nam o sobie",
  An_email_has_been_sent:
    "Na adres xyz@gmail.com została wysłana wiadomość. Kliknij zawarty w niej link, aby potwierdzić swój adres e-mail.",
  Tell_us_about_yourself: "Sukces! Opowiedz nam o sobie",
  Your_name: "Imię i nazwisko",
  Birthday: "Data urodzenia",
  Positions_held: "Zajmowane stanowiska",
  Select_Position: "Wybierz stanowiska",
  Select_Industry: "Wybierz branże",
  Where_are_you_from: "Kraj pochodzenia",
  Select_Country: "Wybierz kraj",
  Next: "Dalej",
  Will_expect_new_document: "Dobrze, rozumiem!",
  EU_citizen: "Obywatelstwo kraju należącego do UE lub EOG",
  EU_citizen1: "Obywatelstwo kraju należącego do UE lub EOG",
  Search__Select: "Szukaj i wybierz",
  Select: "Wybierz",
  Profile_picture_: "Zdjęcie profilowe (opcjonalnie)",
  Video_: "Wideo (opcjonalnie)",
  Aspect_ratio_: "Obsługiwane proporcje obrazu: 4:3 i 16:9. Obsługiwane formaty: MOV i MP4.",
  Upload_CV_: "CV (opcjonalnie)",
  Supported_file_pdf: "Obsługiwane formaty: PDF.",
  Can_you_provide_refrences_upon_request: "Czy możesz dostarczyć referencje na życzenie?",
  Write_about_yourself: "Napisz o sobie",
  Write_about_your_experience: "Napisz o swoim doświadczeniu",
  Tell_us_about_your_education: "Napisz o swoim wykształceniu",
  Last_Step: "Ostatni krok",
  Ok_so_tell_us_what_you_are_searching:
    "Powiedz nam, czego szukasz i gdzie chcesz pracować - my zajmiemy się resztą!",
  What_work_do_you_seek: "Jakiej pracy szukasz?",
  What_country_do_you_seek_to_work_in: "W jakim kraju chcesz pracować?",
  When_are_you_available_to_start: "Kiedy możesz zacząć pracę?",
  Now: "Od razu",
  Other: "Inne",
  Full_time_or_part_time: "W jakim wymiarze chcesz pracować?",
  Welcome_to_JobPark: "Witaj na platformie Jobpark! Oto jak ona działa…",
  How_it_works: "",
  You_are_now_registered_and_your_profile_will_be_displayed_for_employers:
    "Rejestracja przebiegła pomyślnie i Twój profil będzie widoczny dla pracodawców.",
  Find_all_your_messages_in_oneplace:
    "Przechowuj wszystkie wiadomości w jednym miejscu. Wysyłaj odpowiedzi i rozmawiaj z potencjalnymi pracodawcami.",
  Use_settings_to_change_your_profile_and_what_employers_you_are_exposed_to:
    "Korzystaj z ustawień, aby edytować swój profil oraz decydować o tym, którym pracodawcom będzie on wyświetlany.",
  Lets_Go: "Zaczynamy",
  Welcome_Back: "Witaj ponownie!",
  Industry_Experience: "Doświadczenie branżowe",
  Businesss_Type: "Rodzaj działalności",
  Profile_Activation: "Profil aktywny/nieaktywny",
  Set_your_profile_inactive: "Zdezaktywuj swój profil",
  Why_are_you_deactivating: "Dlaczego chcesz zdezaktywować swój profil?",
  Please_tell_us_more_so_we_can_make_Jobpark_better:
    "Podaj nam szczegóły, abyśmy mogli ulepszyć platformę Jobpark",
  I_was_hired_through_Jobpark: "Zatrudniono mnie za pośrednictwem platformy Jobpark",
  I_was_hired_through_another_platform: "Zatrudniono mnie za pośrednictwem innej platformy",
  Jobpark_didnt_work_for_me: "Platforma Jobpark nie spełniła moich oczekiwań",
  I_dont_want_to_use_Jobpark: "Nie chcę korzystać z platformy Jobpark",
  Use_settings_to_change_your_profile:
    "Korzystaj z ustawień, aby edytować swój profil oraz decydować o tym, którym pracodawcom będzie on wyświetlany.",
  Youll_find_all_your_messages:
    "Wszystkie wiadomości znajdziesz w jednym miejscu, w którym będziesz mieć możliwość rozmawiania z pracodawcami.",
  to_remove_fav: "Aby dokonać zmian w liście ulubionych, wybierz subskrypcję.",
  new_password: "Nowe hasło",
  Select_country: "Wybierz swój kraj",
  Cancel_subscription: "Anuluj/zawieś subskrypcję",
  Expires_end: "Subskrypcja ważna do:",
  No_active_subscription: "Brak aktywnych subskrypcji",
  No_Favourites_found: "Brak elementów na liście ulubionych",
  add_as_fav: "Dodaj kandydatów do listy ulubionych z poziomu strony startowej",
  No_Results: "Nic nie znaleziono!",
  No_item_match:
    "Nie znaleźliśmy żadnego elementu pasującego do Twojego zapytania - zmodyfikuj kryteria i spróbuj jeszcze raz.",
  First_language: "Pierwszy język",
  Second_language: "Drugi język",
  Where_do_you_work: "Gdzie chcesz pracować?",
  About_me: "O mnie",
  Education: "Wykształcenie",
  References_not_available: "Referencje niedostępne",
  My_social_media: "Moje profile w mediach społecznościowych",
  BusinessType: "Rodzaj działalności",
  Connect_media: "Media społecznościowe",
  lite_plan_description: "Dla małych firm szukających jednego, najlepszego kandydata",
  premium_plan_description: "Dla firm, które zatrudniają pracowników kilka razy w ciągu roku",
  enterprise_plan_description: "Dla agencji rekrutacyjnych i dużych przedsiębiorstw",
  OTP_verified_successfully: "Numer telefonu został zweryfikowany!",
  This_section_is: "Ta sekcja dostępna jest tylko dla abonentów z aktywną płatną subskrypcją.",
  Please_select_Subscription_Plan: "Wybierz subskrypcję.",
  Name_required_field: "Wpisz imię i nazwisko.",
  Please_enter_phone_number: "Wprowadź numer telefonu",
  Phone_number_is_not_valid: "Nieprawidłowy numer telefonu. Dodaj międzynarodowy numer kierunkowy.",
  Company_Nameis_a_required_field: "Wpisz nazwę firmy.",
  Address_is_a_required_field: "Wpisz adres.",
  Country_cannot_be_empty: "Pole z nazwą kraju nie może być puste.",
  City_is_a_required_field: "Wpisz nazwę miejscowości.",
  Only_alphabets_are_allowed_for_this_field: "To pole może zawierać wyłącznie litery.",
  Zip_is_a_required_field: "Wpisz kod pocztowy.",
  Enter_valid_zip_code_with_no_spaces: "Wpisz prawidłowy kod pocztowy (bez spacji).",
  Please_Select_Country: "Wybierz kraj.",
  Please_Select_Location: "Wybierz kraj",
  Enter_Password: "Wpisz hasło.",
  Password_must_contain_8_characters:
    "Hasło musi składać się z co najmniej 8 znaków (w tym z 1 dużej litery, 1 cyfry i 1 znaku specjalnego).",
  Passwords_must_match: "Wprowadzone hasła muszą być identyczne.",
  Please_enter_valid_password: "Wpisz prawidłowe hasło.",
  Please_enter_name: "Wpisz imię i nazwisko.",
  Please_complete_your_profile: "Aby kontynuować, dokończ uzupełnianie profilu.",
  Name_should_have_at_least_2_letters:
    "Pole z imieniem i nazwiskiem musi zawierać co najmniej 2 litery.",
    Please_Select_First_Language: "Wybierz swój pierwszy język.",
  Please_Select_Secondary_Language: "Wybierz drugi język.",
  Please_Select_Driving_License: "Wybierz kategorie prawa jazdy.",
  Enter_valid_Link: "Wprowadź prawidłowy link.",
  Please_select_a_reason: "Wybierz opcję.",
  Invalid_file_format_jpg: "Nieprawidłowy format pliku. Obsługiwane formaty to JPG, JPEG i PNG.",
  Please_enter_password: "Wpisz hasło.",
  Enter_valid_link_URl: "Wpisz prawidłowy adres URL.",
  Enter_valid_facebook_URl: "Wpisz prawidłowy adres URL profilu na Facebooku.",
  Enter_valid_instagram_URl: "Wpisz prawidłowy adres URL profilu na Instagramie.",
  Enter_valid_linkedin_URl: "Wpisz prawidłowy adres URL profilu na LinkedInie.",
  Enter_valid_twitter_URl: "Wpisz prawidłowy adres URL profilu na Twitterze.",
  Must_be_a_valid_email_address: "Wpisz prawidłowy adres e-mail.",
  Email_must_be_less_than_100_characters: "Adres e-mail musi składać się z mniej niż 100 znaków.",
  Please_enter_valid_email: "Wpisz prawidłowy adres e-mail.",
  Date_of_Birth_cannot_be_empty: "Pole z datą urodzenia nie może być puste.",
  Please_select_Positions: "Wybierz dotychczas zajmowane przez Ciebie stanowiska.",
  Please_select_time_frame: "Wybierz przedział czasowy.",
  Please_verify_email_to_proceed: "Aby kontynuować, potwierdź swój adres e-mail.",
  Please_enter_email_address: "Wpisz prawidłowy adres e-mail.",
  Invalid_email_address: "Nieprawidłowy adres e-mail.",
  Form_Submitted: "Przesłano formularz.",
  Invalid_file_format_pdf: "Nieprawidłowy format pliku. Obsługiwany format to PDF.",
  Invalid_image_format: "Nieprawidłowy format pliku graficznego.",
  Please_select_Industry_exp: "Wybierz doświadczenie branżowe.",
  Please_select_work_schedule: "Wybierz wymiar pracy.",
  Experience_from_industry_alert:
    "Wskaż branże, w których masz doświadczenie. Możesz wybrać więcej niż jedną opcję.",
  What_positions_have_you_worked_in_alert:
    "Wskaż dotychczas zajmowane przez Ciebie stanowiska. Dzięki temu pracodawcy i rekruterzy będą mogli łatwiej Cię znaleźć.",
  Where_are_you_from_alert: "Określ, skąd pochodzisz.",
  EU_EEA_citizen_alert:
    "Pracodawcy potrzebują informacji na temat tego, czy masz obywatelstwo kraju należącego do UE/EOG.",
  Primary_language_alert:
    "Wskaż swój język ojczysty, aby pracodawcy i rekruterzy mogli łatwiej Cię znaleźć.",
  Secondary_language_alert:
    "Wskaż języki dodatkowe, którymi się posługujesz. Możesz dodać ich więcej. Dotyczy języków, które znasz na poziomie co najmniej komunikatywnym.",
  Drives_license_alert:
    "Wskaż kategorie prawa jazdy, którymi dysponujesz, aby pracodawcy i rekruterzy mogli łatwiej Cię znaleźć.",
  Profile_picture_alert: "Prześlij i dostosuj swoje zdjęcie profilowe. Ten krok jest opcjonalny.",
  Upload_Video_alert:
    "Maksymalna długość: 60 sekund, maksymalny rozmiar: 50 MB. Ten krok jest opcjonalny.",
  Upload_CV_alert:
    "Prześlij swoje CV, aby pracodawcy i rekruterzy mogli pobrać je bezpośrednio z Twojego profilu. Ten krok jest opcjonalny.",
  Write_about_yourself_alert:
    "Radzimy, aby pisać po angielsku. Napisz krótko o sobie oraz o tym, co możesz wnieść od siebie w miejscu pracy. Jeśli chcesz pracować w konkretnej miejscowości w danym kraju, możesz dodać tę informację tutaj.",
  Write_about_your_experience_alert:
    "Radzimy, aby pisać po angielsku. Napisz coś więcej o swoim doświadczeniu zawodowym.",
  Tell_us_about_your_education_alert: "Radzimy, aby pisać po angielsku.",
  What_work_do_you_seek_alert:
    "Wskaż branżę, w której chcesz pracować, oraz stanowiska, które chcesz zajmować. Znajdziemy dla Ciebie odpowiednich pracodawców!",
  What_country_do_you_seek_to_work_in_alert:
    "Wskaż kraj europejski, w którym chcesz pracować. Nie zamykasz się na żadne propozycje? Zaznacz wszystkie opcje!",
  When_are_you_available_to_start_alert:
    "Wskaż termin, w którym możesz zacząć nową pracę. Ta informacja będzie widoczna w Twoim profilu.",
  Full_time_or_part_time_alert: "Wskaż interesujący Cię wymiar pracy.",
  Invalid_phone_number: "Nieprawidłowy numer telefonu.",
  You_are_now_registered:
    "Jesteś teraz w bazie kandydatów, a Twój profil jest widoczny dla pracodawców w wybranych przez Ciebie krajach.",
  When_an_employer_contact: "Kiedy pracodawca się z Tobą skontaktuje, zobaczysz go tutaj.",
  Find_all_your_messages:
    "Przechowuj wszystkie wiadomości w jednym miejscu. Wysyłaj odpowiedzi i rozmawiaj z potencjalnymi pracodawcami.",
  Use_settings_to_change:
    "Korzystaj z ustawień, aby edytować swój profil oraz decydować o tym, którym pracodawcom będzie on wyświetlany.",
  Please_select_country_code: "Proszę wybrać kod kraju",
  Set_your_profile_active: "Aktywuj swój profil",
  Austria: "Austria",
  Belgium: "Belgia",
  Bulgaria: "Bułgaria",
  Croatia: "Chorwacja",
  Cyprus: "Cypr",
  Czech_Republic: "Czechy",
  Denmark: "Dania",
  Netherlands: "Holandia",
  United_Kingdom: "Wielka Brytania",
  Estonia: "Estonia",
  Finland: "Finlandia",
  France: "Francja",
  Germany: "Niemcy",
  Greece: "Grecja",
  Hungary: "Węgry",
  Iceland: "Islandia",
  Ireland: "Irlandia",
  Italy: "Włochy",
  Latvia: "Łotwa",
  Lithuania: "Litwa",
  Luxembourg: "Luksemburg",
  Malta: "Malta",
  Norway: "Norwegia",
  Poland: "Polska",
  Portugal: "Portugalia",
  Romania: "Rumunia",
  Slovakia: "Słowacja",
  Slovenia: "Słowenia",
  Spain: "Hiszpania",
  Sweden: "Szwecja",
  Ukraine: "Ukraina",
  Please_select_Industry: "Wybierz branżę",
  First_Name: "Imię",
  Last_Name: "Nazwisko",
  Your_account_is_not_verified:
    "Twoje konto nie zostało jeszcze zweryfikowane. Prosimy o dokończenie procesu weryfikacji.",
  Your_email_was_successfully_verified: "Weryfikacja adresu e-mail przebiegła pomyślnie.",
  All: "Zaznacz wszystko",
  First_Name_should_have_at_least_2_letters: "Imię powinno zawierać minimum 2 litery",
  Company_Name_should_have_at_least_2_letters: "Nazwa firmy powinno zawierać minimum 2 litery",
  Last_Name_should_have_at_least_2_letters: "Nazwisko powinno zawierać minimum 2 litery",
  By_registering: "Rejestrując się, akceptuję",
  Terms_of_Use: "warunki korzystania",
  information_in_accordance:
    "z platformy Jobpark oraz wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z",
  privacy_policy: "polityką prywatności",
  platformy_Jobpark: "platformy Jobpark.",
  Invalid_Day: "Nieprawidłowy dzień.",
  Day_cannot_be_empty: "Musisz podać dzień.",
  Month_cannot_be_empty: "Musisz podać miesiąc.",
  Year_cannot_be_empty: "Musisz podać rok.",
  Remember_me: "Zapamiętaj mnie",
  default_error_message: "Coś poszło nie tak. Spróbuj ponownie.",
  when_can_you_start:
    "Przykładowo: kiedy możesz rozpocząć nową pracę, dlaczego szukasz nowych doświadczeń itp. Jeżeli poszukujesz pracy poza swoim krajem ojczystym, napisz o tym posługując się językiem angielskim.",
  Get_OTP :"Pobierz kod SMS",
  Not_Verified:"Nie zweryfikowany",
  Click_here_to_verify:"Kliknij tutaj, aby zweryfikować.",
  "Your mobile has not yet been verified. Verify Now!":"Twój numer telefonu nie został jeszcze zweryfikowany. Zweryfikuj teraz!",
  "Get notified when employers contact you. Verify phone!":"Otrzymuj powiadomienia, gdy pracodawcy się z Tobą skontaktują. Zweryfikuj telefon!",
  "Never miss a notification. Verify mobile now!":"Nie przegap żadnego powiadomienia. Zweryfikuj swój numer telefonu!",
  "Stay updated on all messages. Verify phone number!":"Bądź na bieżąco ze wszystkimi wiadomościami. Zweryfikuj swój numer telefonu!",
  "Missing out on notifications from potential employers? Verify phone number!":"Omijają Cię powiadomienia od potencjalnych pracodawców? Zweryfikuj numer telefonu!",
  "Get notifications on your phone when a candidate answers. Verify phone now!":"Otrzymuj powiadomienia na telefon, gdy ktoś z kandydatów odpowie. Zweryfikuj numer telefonu teraz!",
  "Missing out on your chat notifications? Verify phone now!":"Omijają Cię powiadomienia o aktywności na czacie? Zweryfikuj numer telefonu teraz!",
  "Phone number is in use. Add a different number.":"Ten numer telefonu jest już używany. Dodaj inny numer.",
  "Yay! You have seen it all":"Tak! Widziałeś to wszystko",
  Delete_account:"Usuń konto",
  Are_you_sure:"Jesteś pewien?",
  are_you_sure_you_want_to_delete_the_account_all_the_account_data_will_be_deleted:"Jesteś pewien, że chcesz usunąć konto? Wszystkie dane powiązane z kontem zostaną utracone bezpowrotnie.",
  please_wait_till_we_delete_your_account_permanently:"Prosimy o chwilkę cierpliwości, podczas gdy usuwamy Twoje konto.",
  "Create Profile":"Utwórz profil",
  basic_information:"Podstawowe informacje",
  "Select Experience":"Wybierz Doświadczenie",
  "Select your experience":"Zaznacz swoje doświadczenie",
  "What industry you're looking at?":"Jaka branża Cię interesuje?",
  "Position held":"Zajmowane stanowisko",
  your_search:"Czego szukasz",
  your_search_name:"Kandydat poszukuje",
  about:"O mnie",
  about_name:"O {{name}}",
  which_country_you_will_like_to_work:"Kraj, w którym chciałbyś pracować",
  which_country_you_will_like_to_work_name:"Kandydat zainteresowany jest pracą w:",
  country_of_origin:"Kraj pochodzenia",
  Enter_Bio:"Przykład: kiedy możesz zacząć pracę w nowej pracy, dlaczego szukasz nowych doświadczeń itp. Jeśli szukasz pracy poza granicami kraju, uzupełnij dane w języku angielskim.",
  upload:"Załaduj",
  profile_image:'Zdjęcie profilowe',
  Edit:'Edytuj',
  profile_completion_text:' danych Twojego profilu zostało wypełnionych. Uzupełnienie profilu w całości pomoże Ci zyskać większe zainteresowanie pracodawców.',
  preview:'Podgląd',
  contact_person:"Osoba kontaktowa",
  what_industries_you_re_looking_for_people_in:	"W jakich branżach szukasz ludzi?",
  country_you_re_looking_for_people_in:	"Kraj, w którym szukasz ludzi?",
  create_company_profile:"Utwórz profil firmy",
  Industry:"Branża",
  Contact_Person_should_have_at_least_2_letters:"Osoba kontaktowa musi składać się z co najmniej 2 liter.",
  phone:"Telefon",
  profile_completed:"Profil został utworzony",
  Website:"Strona internetowa",
  where_is_your_business_located:"Gdzie mieści się Twoja firma?",
  what_are_you_looking_to_do:"Czego szukasz?",
  find_a_job:"Szukam pracy",
  find_candidates_and_hire:"Szukam ludzi do pracy",
  save_filter_as:"Zapisz filtr jako",
  filter_saved_successfully:"Filtr zapisany pomyślnie",
  save_search:"Zapisz filtry, aby otrzymywać codzienne lub cotygodniowe wiadomości e-mail z pasującymi kandydatami.",
  save_filter_failed:"Nie udało się zapisać filtra, spróbuj ponownie.",
  saved_filters_plus_additional_filters_will_be_added_to_the_existing_filter:"Zmiany i aktualizacje będą dodawane do tego filtra.",
  update_the_filter:"Uaktualnić filtr?",
  saved_filter:"Zapisz filtr",
  save_deleted_successfully:"Filtr został usunięty pomyślnie",
  save_filter_get_notified_of_new_matching_results:"Zapisz filtr i otrzymuj powiadomienia o spełniających wymagania kandydatach.",
  daily:"Codziennie",
  weekly:"Cotygodniowo",
  delete:"Usuń",
  do_you_want_to_receive_email_for_new_candidate_for_this_filter:"Czy chcesz otrzymywać e-maile o nowych kandydatach spełniających wymagania tego filtra?",
  select_only_3_industries :"Wybierz do trzech branż",
  please_select_only_3_industries:"Wybierz maks. 3 branże",
  view_contact_details:"Zobacz informacje kontaktowe",
  company_information:"Informacje o firmie",
  where_is_the_business_located:"Gdzie znajduje się siedziba firmy?",
  i_want_to:"Chcę:",
  get_hired:"Zostać zatrudnionym",
  want_to_find_candidates_instead: "Czy chcesz znaleźć kandydatów zamiast?",
  find_candidates : "Znaleźć kandydatów",
  want_to_get_hired_instead: "Chcesz zostać zatrudniony zamiast?",
  click_here:	"Kliknij tutaj.",
  saved_filters:'Zapisz filtr'
};
