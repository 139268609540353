import { Box, Modal, Title } from "@mantine/core";
import React, { useContext, useState,useEffect, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Button from "ui/buttons/Button";
import MemoBlackChevronLeft from "ui/icons/BlackChevronLeft";
import Search from "ui/inputs/Search";
import { useTranslation } from "react-i18next";
import { SelectedSearchContext, UserContext } from "context/state";
import MemoSaveSearchIcon from "ui/icons/SaveSearchIcon";
import Switch from "ui/inputs/Switch";
import Checkbox from "ui/inputs/Checkbox";
import SaveSearchModal from "modules/saveSearch/saveSearchModels/SaveSearchModal";
import UpdateFilterModal from "modules/saveSearch/saveSearchModels/UpdateFilterModal";
import Radio from "ui/inputs/Radio";
import LabelwithInfo from "./LabelwithInfo";
import { ProfilCreationAlertModel } from "./ProfilCreationAlertModel";
import MemoInfo from "ui/icons/Info";
import { ErrorModal } from "ui/modals/ErrorModal";
import { Filters } from "modules/imports/interfaces/interfaces";

const BackClearWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 0px;
`;
const SaveSearchWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 0;
`;
const SaveSearch = styled.div`
  min-width: "123px";
  height: 34px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff5500;
  border: 1px solid #c3c3c3;
  justify-content: center;
  padding: 0 5px;
`;
const Text = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  padding-left: 8px;
`;

const ModalText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6e6e6e;
  margin: 16px 0 0 0;
  @media only screen and (min-width: 1000px) {
  }
`;
const ModalItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 10px 0px;
  margin: 22px 0 0 0;
  justify-content: space-between;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
    margin: 0 0 10px 0;
  }
`;
const StyleToggle = styled.div`
  position: relative;
  align-items: right;
  @media only screen and (min-width: 1000px) {
    align-items: right;
  }
`;
const StyledCheckBoxWrapper = styled.div`
  display: flex;
  margin: 27px 0 0 0;
  flex-direction: row;
  gap: 28px;
`;
const Header = styled(Title)<{ bottomMargin?: string }>`
  margin: 0 0 23px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  max-width: 298px;
  color: #123b3d;
  margin-top: 26px;
  margin-bottom: 27px;
`;
const Description = styled(Title)`
  margin: 0 0 30px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6e6e6e;
  /* @media only screen and (min-width: 1000px) {
        width:376px;
  } */
  max-width: 336px;
`
const ChildWrapper = styled(Box)`
  margin: 29px 0px 0px 0px;
`;

const StyleButton = styled.div`
  position: sticky;
  bottom: 0px;
  left: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f7f7f7;
  z-index: 99;
  @media only screen and (min-width: 1000px) {
    position: sticky;
    width: 100%;
  }
`
const Subtext = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #6a6a6a;
`;
const MainContainer = styled(Box)`
  min-width: 336px;
  background-color: #F7F7F7;
`
interface CustomProps {
  setShow: (event: any) => void //show hide sidebar
  showSearch?: boolean //show search
  showFilter_Clear?: number //show 1 - Reset Filter & 2 - Clear all
  clearAll?: (event: any) => void //clear all checkboxes
  resetFilter?: any //clear all resetFilter
  children?: any //render checkboxes
  title?: string //title for the search
  buttonText?: string // Button text
  description?: string // Description After Head
  route?: string //for re-routing
  setCandidateList?: any
  searchString?: string
  setSearchString?: Dispatch<SetStateAction<string>>
  setAddElements?: Dispatch<SetStateAction<boolean>>
  filterUpdate?: any
  bottomMargin?: string,
  showClear?:boolean,
  secondaryLanguage?:boolean
  showSaveSearchFeatures?: boolean; // for save search to update/edit
  subtext?:string,
  currentFilters?:Filters
}
interface SaveSearch{
  handleClick : ()=>void,
  buttonText:string
}
const SaveSearchComponent:React.FC<SaveSearch> =({handleClick,buttonText})=>{

  return(
    <SaveSearch
            onClick={handleClick}
          >
            <MemoSaveSearchIcon
              //style={{ paddingLeft: "6px"}}
              width="8px"
              height="14px"
            />{" "}
            <Text>{buttonText}</Text>
          </SaveSearch>
  )
}

interface BackClear{
  showClear:boolean|undefined,
  setShow:Dispatch<SetStateAction<boolean>>,
  resetFilter:Dispatch<SetStateAction<boolean>>,
  buttonText:string
}

const BackClearComponent:React.FC<BackClear> = ({setShow,showClear,resetFilter,buttonText}) => {
  return <>
  <Button
    variant="subtle"
    width="fit-content"
    sx={{ padding: "0" }}
    onClick={() => setShow(false)}
  >
    <MemoBlackChevronLeft />
  </Button>
  {showClear !== false && (
    <Button
      color={"#FF3000"}
      variant="subtle"
      width="fit-content"
      sx={{ paddingRight: "0" }}
      onClick={() => resetFilter(true)}
    >
      {buttonText}
    </Button>
  )}
</>;
};
const SearchAndSelectAbstraction: React.FC<CustomProps> = ({
  setShow,
  clearAll,
  resetFilter,
  buttonText = "Button",
  showSearch = false,
  showFilter_Clear = 1,
  children,
  title,
  description,
  searchString,
  setSearchString,
  setAddElements,
  filterUpdate,
  bottomMargin,
  showClear ,
  showSaveSearchFeatures,
  subtext="",
  currentFilters,
  ...props
}) => {
  const { t } = useTranslation();
  const { role,setFilterData,isAdmin } = useContext(UserContext);
  const [saveSearchModal, setSaveSearchModal] = useState<boolean>(false);
  const [showUpdateMessageModal, setUpdateMessageModal] =
    useState<boolean>(false);
  const [showInfo, setShowInfo] = useState(false);
  //const {selectedCard,setSelectedCard} = useContext(SelectedSearchContext);

 
  const handleButtonClick = () => {
    if (showSaveSearchFeatures) {
      
      setUpdateMessageModal(true);
    } else{ setShow(false)}
    
    if (showFilter_Clear == 1 && !showSaveSearchFeatures) {
      // getFilteredCandidates(data)
      //     .then(res => { console.log(res); setCandidateList(res) })
      //     .catch(err => console.log(err))
   filterUpdate();
    }
  };

const handleSaveSearchButtonClick =()=>{
  setFilterData(currentFilters)
  setSaveSearchModal(true);
}
const showSaveFilterButton =()=>{
  let saveFeatureEnabledFromEnv =(process.env.REACT_APP_TOGGLE_SAVE_SEARCH as string).trim().toLowerCase() === 'true' ?? false
  if(!showSaveSearchFeatures && title == t("Filter") && !isAdmin && saveFeatureEnabledFromEnv)
    return true
  else
    return false
}
  return (
    <>
      <MainContainer
      >
        <BackClearWrapper>
          <BackClearComponent
            setShow={setShow}
            showClear={showClear}
            buttonText={
              showFilter_Clear == 1 ? t("Reset_Filter") : t("Clear_All")
            }
            resetFilter={resetFilter}
          />
        </BackClearWrapper>
        <SaveSearchWrapper>
          {title && (
            <Header bottomMargin={bottomMargin}>
              {title}
              {subtext && role == "candidate" && <Subtext>({subtext})</Subtext>}
              {showFilter_Clear == 1 && !isAdmin && (
                <MemoInfo
                  height="15px"
                  width="15px"
                  style={{
                    alignSelf: "center",
                    paddingLeft: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowInfo(true)}
                />
              )}
              <ErrorModal
                opened={showInfo}
                onClose={() => setShowInfo(false)}
                message={t("save_search")}
              />

              {/* <ProfilCreationAlertModel
              opened={showInfo}
              infoText={t("save_search")}
              onClose={() => setShowInfo(false)}
            /> */}
            </Header>
          )}
          {showSaveFilterButton() && (
            <SaveSearchComponent
              handleClick={handleSaveSearchButtonClick}
              buttonText={t("saved_filter")}
            />
          )}
        </SaveSearchWrapper>
        {description && <Description>{description}</Description>}
        {showSearch && (
          <Search
            placeholder={t("Search")}
            id="industry"
            width="334px"
            height="54px"
            onChange={(event: any) => {
              if (setSearchString) {
                setSearchString(event.currentTarget.value);
              }
            }}
          />
        )}
        <ChildWrapper> {children}</ChildWrapper>
        {filterUpdate || showSaveSearchFeatures ? (
          <StyleButton>
            <Button
              variant="filled"
              children={buttonText}
              sx={{ marginTop: "0px", position: "relative", bottom: "0px" }}
              width="335px"
              height="54px"
              onClick={() => {
                handleButtonClick();

                //setAddElements(true);
              }}
            />
          </StyleButton>
        ) : (
          <></>
        )}
      </MainContainer>
      {saveSearchModal && (
        <SaveSearchModal
          saveSearchModal={saveSearchModal}
          setSaveSearchModal={setSaveSearchModal}
        />
      )}
      {showUpdateMessageModal && (
        <UpdateFilterModal
          showUpdateMessageModal={showUpdateMessageModal}
          setUpdateMessageModal={setUpdateMessageModal}
          showFilter={setShow}
          filterUpdate={filterUpdate}
        />
      )}
    </>
  );
};
export default SearchAndSelectAbstraction;
