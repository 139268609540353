import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { SelectedSearchContext, UserContext } from "context/state";
import PrivateLayout from "layouts/PrivateLayout";
import BackButton from "ui/buttons/BackButton";
import {
  ContainerStyle,
  StyleBackButton,
  StyleHeadTextWrapper,
  StyleHeadWrapper,
} from "helpers/f-a-q/FAQscreen";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroll-component";
import SavedSearchCard from "modules/saveSearch/SavedSearchCard";
import { getSavedFilters, updateFilter } from "services/saveFilter";
import getPageLimit from "helpers/GetPageLimit";
import NoResultFound from "helpers/NoResultFound";
import Loader from "ui/feedback/Loader";
import { deleteSavedFilter } from "services/saveFilter";
import { ErrorModal } from "ui/modals/ErrorModal";

interface ProfileWrapperProps {
  customHeight?: string;
}
const ProfileWrapper = styled.div<ProfileWrapperProps>`
  overflow-y: auto;
  height: ${(props) => (props?.customHeight ? "70vh" : "75vh")};
  @media only screen and (min-width: 1000px) {
    margin-top: 18px;
    height: ${(props) => (props?.customHeight ? props?.customHeight : "71vh")};
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    gap: 15px;
    padding-bottom: 0px;
  }
`;
// search card context
interface SelectedSearchContextProps {
  children: React.ReactNode;
}

interface SavedList{
  search_id:string
  search_title:string
}
interface SelectedCard{
  searchId:string,
  searchTitle:string,
  emailType:string,
}
export const SelectedCardProvider: React.FC<SelectedSearchContextProps> = ({ children }) => {
  const [selectedCard, setSelectedCard] = useState<SelectedCard>({
    searchId:"",
    searchTitle:"",
    emailType:"",
    });

  return (
    <SelectedSearchContext.Provider value={{selectedCard,setSelectedCard}}>
      {children}
    </SelectedSearchContext.Provider>
  );
};
// search card end
export default function SavedSearchScreen() {
  const { filterData, setFilterData, setActiveTap } = useContext(UserContext);

  useEffect(() => {
    setFilterData({
      age: [18, 67],
      positions: [],
      industry: [],
      country: [],
      language: [],
      availability: [],
      workType: [],
      drivingLicense: [],
      preferredIndustryPosition: [],

    });
    setActiveTap(4);
  }, []);
  const { t } = useTranslation();
  const [savedList, setSavedList] = useState<SavedList[]>([]);
  const [activePage, setPage] = useState(1);
  const [moreProfiles, setMoreProfiles] = useState(true);
  const [loader, setLoader] = useState(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [Message, setMessage] = useState<string>("");

  const getList = (pagination?: boolean) => {
    const currentLimit = getPageLimit() ?? 10;
    let data = {
      limit: currentLimit,
      page: pagination == true ? activePage + 1 : 1,
    };

    if (pagination) {
      getSavedFilters(data).then((res) => {
        setPage(Number(res.current_page));
        if (res?.current_page < res?.total_pages) {
          setSavedList((prevSavedList) => pagination ? prevSavedList.concat(res.data) : res.data);
          setMoreProfiles(true);
        } else {
          if (res?.current_page >= res?.total_pages) {
            setSavedList((prevSavedList) => pagination ? prevSavedList.concat(res.data) : res.data);
            setMoreProfiles(false);
          }
        }
        setLoader(false);
      });
    } else {
      getSavedFilters(data).then((res) => {
        setPage(Number(res.current_page));
        setSavedList(res.data);
        setMoreProfiles(true);
        setLoader(false);
      });
    }
  };
  const handleDeleteFilter = (id: number) => {
    deleteSavedFilter(id).then((res: any) => {
      if (res?.message) {
        setShowMessage(true);
        setMessage(t("save_deleted_successfully"));
      } else {
        setShowMessage(true);
        setMessage(t("save_filter_failed"));
      }
    });
  };
  // update filter
  const handleUpdateSavedFilter = (id: number,data:{}) => {
    updateFilter(id, data).then((res: any) => {
      if (res) {
        setShowMessage(true);
        setMessage(res.message);
        getList(false);
      } else {
        setShowMessage(true);
        if (res.message) setMessage(res.message);
        else setMessage(t("save_filter_failed"));
      }
    });
    getList(false);
  };
  useEffect(() => {
    getList(false);
  }, [showMessage]);

  return (
    <SelectedCardProvider>
    <PrivateLayout showBottomMenu={false} showMobileViewLogo={false}>
      <ContainerStyle>
        <StyleHeadWrapper>
          <StyleBackButton>
            <BackButton />
          </StyleBackButton>
          <StyleHeadTextWrapper>{t("saved_filter")}</StyleHeadTextWrapper>
        </StyleHeadWrapper>
        <ProfileWrapper id="scrollableDiv">
          {loader ? (
            <Loader />
          ) : savedList?.length == 0 || savedList === undefined ? (
            <NoResultFound candidate={false} showText={false} />
          ) : (
            <InfiniteScroll
              dataLength={savedList?.length}
              next={() => getList(true)}
              hasMore={moreProfiles}
              loader={<></>}
              scrollableTarget="scrollableDiv"
            >
             
              <CardsWrapper>
                {savedList?.length > 0 &&
                  savedList?.map((item: any, index) => {
                    return (
                      <SavedSearchCard
                        name={item.search_title}
                        id={item.search_id}
                        handleDeleteFilter={handleDeleteFilter}
                        handleUpdateSavedFilter={handleUpdateSavedFilter}
                      />
                    );
                  })}
              </CardsWrapper>
            </InfiniteScroll>
          )}
        </ProfileWrapper>
      </ContainerStyle>
      {showMessage && (
        <ErrorModal
          opened={showMessage}
          onClose={() => {
            setShowMessage(false);
          }}
          message={Message}
        />
      )}
    </PrivateLayout>
    </SelectedCardProvider>

  );
}
