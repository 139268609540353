import React, { ReactNode, useState, useEffect, useContext } from "react";
import { Box, Grid, Text, Group, Stack } from "@mantine/core";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { DropZone } from "ui/inputs/Dropzone";
import InputField from "ui/inputs/Inputfield";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import TextArea from "ui/inputs/Textarea";
import Button from "ui/buttons/Button";
import { Modal as MTModal } from "@mantine/core";
import MemoAddIcon from "ui/icons/addIcon";
import MemoI_Big from "ui/icons/I_Big";
import MemoTrashIcon from "ui/icons/TrashIcon";
import { IndustrySearch } from "helpers/search-select/IndustrySearch";
import Drawer from "ui/data-display/Drawer";
import MemoGrayArrow from "ui/icons/GrayArrow";
import { getAllFilters } from "services/taxonomies/taxonomies";
import { IndustryStore } from "stores/taxnomies/industry.store";
import { putUserDetails, deleteUserCompany } from "services/user/User";
import { UserContext } from "context/state";
import { useHistory } from "react-router-dom";
import { addUser } from "services/employer/employer";
import { ErrorModal } from "ui/modals/ErrorModal";
import CountrySearch from "helpers/search-select/CountrySearch";
import { CountryStore } from "stores/taxnomies/country.store";
import { getUserDetails } from "services/user/User";
import getBrowserLanguage from "helpers/get-browser-language";
import SeperatePhoneAndCode from "./profile-creation/SeperatePhoneAndCode";
import MemoPhoneIcon from "ui/icons/PhoneIcon";
import { StyleSelectField } from "ui/inputs/Select";
import MemoChevronDown from "ui/icons/ChevronDown";
import { getCountryCodes } from "constants/countrycodes";

const ModalInput = (props: any) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const planName = useContext(UserContext);
  const { userId, setUserData, role } = useContext(UserContext);
  const [errorValidationName, setErrorValidationName] = useState(false);
  const [errorValidationEmail, setErrorValidationEmail] = useState(false);

  const schemaAddUser = Yup.object().shape({
    name: Yup.string().required(t("Please_enter_name")),
    email: Yup.string().email(t("Invalid_email_address")).required(t("Please_enter_email_address")),
  });

  // Validation Function
  function validURL(str: string) {
    var patternEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return !!patternEmail.test(str);
  }

  const newUserForm = useForm({
    initialValues: {
      name: "",
      email: "",
    },
    schema: yupResolver(schemaAddUser),
  });
  const handleAddUserSubmit = (values: any) => {
    addUser(values).then((response) => {
      // console.log(`res`, response);
      if (response?.success) {
        // props.form.setFieldValue('userList', [...props.form.values.userList, ...[values]])
        newUserForm.reset();
        setOpened(false);
        setErrorText(response?.message);
        setShowError(true);
        getUserDetails(userId).then((response) => {
          props.form.setFieldValue("userList", props?.TransformGetUserData(response?.users));
          setUserData(response?.data);
        });
      } else {
        setErrorText(response?.message[0]);
        setShowError(true);
      }
    });
  };

  useEffect(() => {
    setErrorValidationName(false);
  }, [newUserForm?.values?.name]);

  useEffect(() => {
    setErrorValidationEmail(false);
  }, [newUserForm?.values?.email]);

  return (
    <>
      <MTModal
        opened={opened}
        padding={20}
        radius={30}
        onClose={() => setOpened(false)}
        title={t("Add_New_User")}
        styles={{
          modal: {
            backgroundColor: "#F7F7F7",
          },
          close: {
            display: "none",
          },
          title: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            textAlign: "right",
            letterSpacing: "0.3px",
            color: "#123B3D",
            padding: "10px",
          },
        }}>
        <Box style={{ margin: "10px 0 10px 0" }}>
          <InputField
            id='name'
            placeholder={t("Name")}
            width='100%'
            height='auto'
            {...newUserForm.getInputProps("name")}
          />
          {errorValidationName && !newUserForm?.values?.name.trim() && (
            <StyleError>{t("Please_enter_name")}</StyleError>
          )}
        </Box>
        <Box style={{ margin: "10px 0 10px 0" }}>
          <InputField
            id='email'
            placeholder={t("Email")}
            width='100%'
            height='auto'
            {...newUserForm.getInputProps("email")}
          />
          {errorValidationEmail && !newUserForm?.values?.email ? (
            <StyleError>{t("Please_enter_email_address")}</StyleError>
          ) : (
            newUserForm?.values?.email &&
            !validURL(newUserForm?.values?.email) && (
              <StyleError>{t("Invalid_email_address")}</StyleError>
            )
          )}
        </Box>
        <Box style={{ position: "relative", margin: "20px 10px 40px 10px" }}>
          <MemoI_Big width='16px' height='16px' style={{ position: "absolute" }} />
          <StyleModalDescriptions>
            {t("A_notification_will_be_send_to_the_users_email_address")}
          </StyleModalDescriptions>
        </Box>
        <Button
          variant={"filled"}
          type={"button"}
          onClick={() => {
            setErrorValidationName(true);
            setErrorValidationEmail(true);
            if (
              newUserForm?.values?.name.trim() &&
              newUserForm?.values?.email &&
              validURL(newUserForm?.values?.email)
            )
              handleAddUserSubmit(newUserForm?.values);
          }}>
          {t("Add")}
        </Button>
        <Button
          variant={"subtle"}
          color={"#123B3D"}
          onClick={() => {
            newUserForm.reset();
            setOpened(false);
          }}>
          {t("Cancel")}
        </Button>
      </MTModal>
      {planName?.userData?.is_subscribed &&
        planName?.userData?.plan_name == "Enterprise" &&
        props?.form?.values?.userList?.length != 1 && (
          // new Date() <= new Date(planName?.userData?.plan_expiration_date) &&
          <Group>
            <StyleSpacing>
              <Box
                onClick={() => setOpened(true)}
                style={{ cursor: "pointer", margin: "20px 0 20px 0" }}>
                <MemoAddIcon />
                <StyleAddNewUser>{t("Add_New_User")}</StyleAddNewUser>
              </Box>
            </StyleSpacing>
          </Group>
        )}
      <ErrorModal opened={showError} message={errortext} onClose={() => setShowError(false)} />
    </>
  );
};

const StyleHeader = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #123b3d;
`;

const StyleMediaText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #4d4d4d;
  margin-bottom: 0px;
`;

const StyleAddNewUser = styled.div`
  font-family: "Poppins";
  float: right;
  margin: 0 10px 0 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #123b3d;
`;

const ContainerStyle = styled.div`
  width: 100%;
  height: 100vh;
  @media only screen and (min-width: 1000px) {
    overflow-y: hidden;
    height: 100%;
  }
`;
const StyleModalDescriptions = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6e6e6e;
  margin-left: 25px;
`;

const StyleButtonDesktopView = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
    margin-top: 20px;
  }
`;
const StyleButtonMobileView = styled.div`
  display: block;
  padding-bottom: 20px;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

const StyleElement = styled.div`
  width: 100%;
  @media only screen and (min-width: 1000px) {
    width: 50%;
    float: left;
    overflow: auto;
  }
`;

const StyleDesktopDiv1 = styled.div`
  margin: 0 10px 0 10px;
  @media only screen and (min-width: 1000px) {
    margin: 0 80px 0 10px;
  }
`;
const StyleDesktopDiv2 = styled.div`
  margin: 0 10px 0 10px;
  @media only screen and (min-width: 1000px) {
    margin: 0 80px 0 0px;
  }
`;

const StyleSpacing = styled.div`
  margin-bottom: 10px;
  @media only screen and (min-width: 1000px) {
    margin: 16px 10px 10px 0;
    margin-right: 60px;
  }
`;

const CardStyle = styled.div`
  background-color: #fff;
  display: inline-block;
  box-shadow: 0px 10px 35px rgba(64, 59, 75, 0.1);
  border-radius: 11px;
  min-height: 80px;
  height: auto;
  margin: 10px 10px 10px 10px;
  position: relative;
  @media only screen and (min-width: 1000px) {
    min-width: 419px;
    width: fit-content;
    padding-right: 10px;
  }
`;

const CardStyleTextName = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #123b3d;
  padding: 10px 0 0 10px;
  margin: 0 60px 0 0;
  @media only screen and (min-width: 1000px) {
    max-width: 350px;
    margin: 0 100px 0 0;
  }
`;
const CardStyleTextEmail = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;
  padding: 0 0 0 10px;
  /* max-width: 350px; */
`;

const StyleDeleteButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 30px;
`;

const StyleCardHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #6e6e6e;
`;

const StyleHead = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  padding: 0px;
  color: #6e6e6e;
  padding-top: 20px;
  margin-bottom: 6px;
`;

const StyleWrapper = styled.div`
  margin-right: 10px;
`;

const StyleIndustryLabel = styled.div`
  display: inline;
  line-height: 1.5;
  color: #000;
`;

const StyleError = styled.div`
  font-size: 14px;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0 0 0 5px;
  word-break: break-word;
  color: #f03e3e;
  font-family: Poppins;
`;

const StyleSelectButtonOut = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6e6e6e;
  cursor: pointer;
  padding: 5px;
  justify-content: center;
  width: 100%;
  margin-right: 30px;
`;

const StyleIcon = styled.div`
  /* float: right; */
  justify-content: right;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 97%;
  display: flex;
  margin-right: 10px;
  margin-top: 0px;
`;
const CountryLabel = styled.div`
      display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
    cursor: default;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    color: #6E6E6E;
    line-height: 20px;
    letter-spacing: 0.3px;
`
interface ProfileFormScreenProps {
  children?: ReactNode;
  formType: 1 | 2; // 1 -> EmployeerPrifile | 2 -> EmployeerPrifileEnterprise
  contexData?: any;
}

export const ProfileFormScreen: React.FC<ProfileFormScreenProps> = ({
  children,
  formType,
  ...props
}) => {
  const { t } = useTranslation();

  const [industryData, setIndustryData] = useState<any>([]);
  const [countryData, setCountryData] = useState<any>([]);

  const [showIndustrySearch, setShowIndustrySearch] = useState(false);
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [savedIndustryIds, setSavedIndustryIds] = useState([]);

  const { userId, userData, setUserData, role,showDropdown, setShowDropdown } = useContext(UserContext);
  const countryCodes = getCountryCodes()
  const history = useHistory();

  const planName = useContext(UserContext);

  const schema = Yup.object().shape({
    name: Yup.string()
      .matches(/^(?!\s+$).*/, t("Company_Name_should_have_at_least_2_letters"))
      .min(2, t("Company_Name_should_have_at_least_2_letters")),
    link: Yup.string()
      .nullable()
      .matches(
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        t("Enter_valid_link_URl")
      ),
    facebook: Yup.string()
      .nullable()
      .matches(
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        t("Enter_valid_facebook_URl")
      ),
    instagram: Yup.string()
      .nullable()
      .matches(
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        t("Enter_valid_instagram_URl")
      ),
    linkedin: Yup.string()
      .nullable()
      .matches(
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        t("Enter_valid_linkedin_URl")
      ),
    twitter: Yup.string()
      .nullable()
      .matches(
        /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        t("Enter_valid_twitter_URl")
      ),
    // industry_ids: Yup.array().min(1, t("Please_select_Industry")),
    location_ids: Yup.array().min(1, t("Please_Select_Location")),
    phone: Yup.string()
      .matches(/^[0-9]*$/, t("Invalid_phone_number"))
      .required(t("Please_enter_phone_number")),
    code: Yup.string()
      .matches(/^(--.+|(?!--).*)$/, t("Please_select_country_code"))
      .required(t("Please_enter_phone_number")),
    contactperson: Yup.string()
      .trim()
      .required(t("Contact_Person_should_have_at_least_2_letters"))
      .matches(
        /^(?!\s+$).*/,
        t("Contact_Person_should_have_at_least_2_letters")
      )
      .min(2, t("Contact_Person_should_have_at_least_2_letters")),
  });
  // Transform Get Data
  const TransformGetUserData = (values: any) => {
    var data: any = [];
    values?.map((val: any) => {
      data.push({
        id: val?.id,
        name: val?.name,
        email: val?.mail,
      });
    });
    return data;
  };

  // Function to display saved data
  const savedDataHandler = (data: any, savedData: any) => {
    let flag = false;
    savedData?.map((savedID: string) => {
      if (savedID == data?.id) flag = true;
    });
    return flag;
  };

  useEffect(() => {
    getAllFilters()
      .then((response) => {
        response?.data?.country
          ?.sort((a: any, b: any) =>
            a?.name?.localeCompare(b?.name, `${getBrowserLanguage()}`, {
              ignorePunctuation: true,
            })
          )
          ?.map((item: any) => CountryStore.set(item.id, item.name));
          response.data.industry_type.map((item: any) => {
            let value = {
              name: item?.name,
              positions: item?.positions,
            };
            IndustryStore.set(item.id, item.name);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    getUserDetails(userId).then((response) => {
      setUserData(response?.data);
    });
  }, []);
  useEffect(() => {
    if (
      Array.from(IndustryStore.data, ([id, name]) => ({ id, name })).length != 0 &&
      Array.from(CountryStore.data, ([id, name]) => ({ id, name })).length != 0
    ) {
      // setIndustryData([
      //   {
      //     headerName: "",
      //     data: Array.from(IndustryStore.data, ([id, name]) => ({
      //       id,
      //       name,
      //     })).map((obj) => ({
      //       ...obj,
      //       checked:
      //         userData?.industry_type?.length != 0
      //           ? savedDataHandler(obj, userData?.industry_type)
      //           : false,
      //       show: true,
      //     })),
      //   },
      // ]);

      setCountryData([
        {
          headerName: "",
          data: Array.from(CountryStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              [userData?.location]?.length != 0
                ? savedDataHandler(obj, [userData?.location])
                : false,
            show: true,
          })),
        },
      ]);
    }
  }, []);
  // useEffect(() => {
  //   setSavedIndustryIds(userData?.industry_type);
  // }, [userData?.industry_type]);

  

  // useEffect(() => {
  //   form.setFieldValue("industry_type", industryData);
  //   setSavedIndustryIds(getCheckedIds(industryData));
  // }, [industryData]);

  useEffect(() => {
    if (
      Array.from(IndustryStore.data, ([id, name]) => ({ id, name })).length != 0 &&
      Array.from(CountryStore.data, ([id, name]) => ({ id, name })).length != 0
    ) {
      // setIndustryData([
      //   {
      //     headerName: "",
      //     data: Array.from(IndustryStore.data, ([id, name]) => ({
      //       id,
      //       name,
      //     })).map((obj) => ({
      //       ...obj,
      //       checked:
      //         userData?.industry_type?.length != 0
      //           ? savedDataHandler(obj, userData?.industry_type)
      //           : false,
      //       show: true,
      //     })),
      //   },
      // ]);

      setCountryData([
        {
          headerName: "",
          data: Array.from(CountryStore.data, ([id, name]) => ({
            id,
            name,
          })).map((obj) => ({
            ...obj,
            checked:
              userData?.location?.length != 0
                ? savedDataHandler(obj, userData?.location)
                : false,
            show: true,
          })),
        },
      ]);
    }
    if (userData?.first_name) form.setFieldValue("name", userData?.first_name);
    if (userData?.link_to_website) form.setFieldValue("link", userData?.link_to_website);
    if (userData?.users != "0" && userData?.users?.length != 0)
      form.setFieldValue("userList", TransformGetUserData(userData?.users));
    if (userData?.description) form.setFieldValue("description", userData?.description);
    if (userData?.social_facebook_link)
      form.setFieldValue("facebook", userData?.social_facebook_link);
    if (userData?.social_instagram_link)
      form.setFieldValue("instagram", userData?.social_instagram_link);
    if (userData?.social_linkedin_link)
      form.setFieldValue("linkedin", userData?.social_linkedin_link);
    if (userData?.social_twitter_link) form.setFieldValue("twitter", userData?.social_twitter_link);
  }, [userData]);

  useEffect(() => {
    form.setFieldValue("location", countryData);
  }, [countryData]);

 

  const fontStyles = {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    color: "#6E6E6E",
  };

  const inputStyle = {
    border: "none",
  };

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      prifileImage: [],
      name: userData?.name ? userData?.name : "",
      location: [],
      // industry_type: [],
      // industry_ids: [],
      location_ids: [],
      link: userData?.link_to_website ? userData?.link_to_website : "",
      userList:
        userData?.users != "0" && userData?.users?.length != 0
          ? TransformGetUserData(userData?.users)
          : userData?.parent_user?.id
          ? TransformGetUserData([userData?.parent_user])
          : [],
      description: userData?.description ? userData?.description : "",
      facebook: userData?.social_facebook_link ? userData?.social_facebook_link : "",
      instagram: userData?.social_instagram_link ? userData?.social_instagram_link : "",
      linkedin: userData?.social_linkedin_link ? userData?.social_linkedin_link : "",
      twitter: userData?.social_twitter_link ? userData?.social_twitter_link : "",
      code: '--',
      phone: '',
      contactperson:userData?.contact_person
    },
  });

  useEffect(()=>{
    //extracting phone and code from phone number string
    let phonenumberToPass = userData.phone ? userData.phone :""
    if(role==="employer" && phonenumberToPass!="" && phonenumberToPass!="+0000000000"){
      let seperated:{
        code?:string,
        phoneNumber?:string
      }= SeperatePhoneAndCode(phonenumberToPass)
      if(seperated?.code && seperated?.phoneNumber){
        form.setFieldValue("code",`+${seperated?.code}`)
        form.setFieldValue("phone",seperated?.phoneNumber)
      }
    }
  },[userData?.phone])

  //Function to get list of checked IDs
  const getCheckedIds = (data: any) => {
    let checkedIds: any = [];
    data?.forEach((objectData: any) => {
      objectData?.data?.forEach((checkboxData: any) => {
        if (checkboxData.checked) {
          checkedIds.push(checkboxData.id);
        }
      });
    });
    return checkedIds;
  };

  //Function to get list of checked Names
  const getCheckedName = (data: any) => {
    let checkedName: any = [];
    data?.forEach((objectData: any) => {
      objectData?.data?.forEach((checkboxData: any) => {
        if (checkboxData?.checked) {
          checkedName.push(checkboxData?.name);
        }
      });
    });
    return checkedName;
  };

  // useEffect(() => {
  //   form.setFieldValue("industry_ids", getCheckedIds(form?.values?.industry_type));
  // }, [form?.values?.industry_type]);

  useEffect(() => {
    form.setFieldValue("location_ids", getCheckedIds(form?.values?.location));
  }, [form?.values?.location]);

  function validURL(str: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol  /^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$/
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  const TransformDataSubmit = (values: any) => ({
    company_name: values.name.trim(),
    //industry_type: getCheckedIds(values.industry_type),
    location: getCheckedIds(values.location),
    link_to_website: values.link,
    // USERLIST: values.userList, // Check
    description: values.description,
    social_facebook_link: values.facebook,
    social_instagram_link: values.instagram,
    social_linkedin_link: values.linkedin,
    social_twitter_link: values.twitter,
    type:"edit",
    phone_number:values.code + values.phone,
    contact_person: values.contactperson.trim(),
  });

  // Save Data API Call
  const SubmitDataHandler = () => {
    putUserDetails(userId, TransformDataSubmit(form.values))
      .then((response) => {
        history.push("/home");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ContainerStyle>
      <form onSubmit={form.onSubmit((values) => SubmitDataHandler())}>
        <StyleElement>
          <StyleDesktopDiv1>
            <StyleSpacing>
              <StyleHeader>{t("Complete_company_profile")}</StyleHeader>
              <DropZone
                label={t("Company_logo")}
                description={t("_300_x_300_pixels_for_best_quality")}
                acceptType={"image"}
                savedData={userData?.company_logo ? [userData?.company_logo] : []}
              />
            </StyleSpacing>
            <StyleSpacing>
              <InputField
                id='name'
                label={t("Company_name")}
                placeholder={t("Company_name")}
                placeholderColor={`#6E6E6E !important`}
                labelColor="#6E6E6E"
                width='100%'
                height='auto'
                {...form.getInputProps("name")}
              />
            </StyleSpacing>
            <StyleSpacing>
            <InputField
              width="100%"
              height="auto"
              id="contact_person"
              label={t("contact_person")}
              placeholder={t("contact_person")}
              labelColor="#6E6E6E"
              {...form.getInputProps("contactperson")}
            />
            </StyleSpacing>
            <StyleSpacing>
            <InputField
                  id='phone'
                  phoneType={true}
                  labelColor="#6E6E6E"
                  label={t('Phone')}
                  placeholder={t('Phone')}
                  icon={
                    <div style={{ position: 'relative' }}>
                      <StyleSelectField>
                        <label className='dropdown'>
                          <div
                            className='dd-button'
                            onClick={(e) => {
                              e.preventDefault()
                              setShowDropdown(!showDropdown)
                            }}
                            style={{
                              width: '65px',
                              borderRight: '1px solid rgba(0, 0, 0, 0.4)',
                              marginRight: '10px',
                              borderRadius: '0',
                            }}>
                            {form?.values?.code}
                            <MemoChevronDown
                              className='dd-button-logo'
                              style={{ marginRight: '10px' }}
                            />
                          </div>

                          <input
                            type='checkbox'
                            className='dd-input'
                            checked={showDropdown}
                            id={form?.values?.code}
                          />

                          <ul
                            id='dd-menuID'
                            className='dd-menu'
                            style={{
                              zIndex: '200',
                              width: 'auto',
                              maxHeight: '300px',
                              overflow: 'auto',
                              borderRadius: '10px',
                            }}>
                            {countryCodes?.length != 0 &&
                              countryCodes
                                ?.sort((a: any, b: any) =>
                                  t(a?.label)?.localeCompare(
                                    t(b?.label),
                                    `${getBrowserLanguage()}`,
                                    {
                                      ignorePunctuation: true,
                                    }
                                  )
                                )
                                ?.map((item: any) => {
                                  return (
                                    <li
                                      style={{
                                        zIndex: '200',
                                        width: 'auto',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '10px',
                                      }}
                                      onClick={() => {
                                        form.setFieldValue('code', item?.value)
                                        setShowDropdown(false)
                                      }}>
                                      {/* {`${item?.label} ${item?.value}`} */}
                                      <div> {t(`${item?.label}`)}</div>
                                      <div> {`${item?.value}`}</div>
                                    </li>
                                  )
                                })}
                          </ul>
                        </label>
                      </StyleSelectField>
                      <MemoPhoneIcon
                        width='16.03px'
                        height='24.26px'
                        style={{ position: 'absolute', top: '5px', left: '90px' }}
                      />
                    </div>
                  }
                  {...form.getInputProps('phone')}
                />
                <StyleError>{form?.errors?.code}</StyleError>
                <StyleError>{form?.errors?.phone}</StyleError>
            </StyleSpacing>
            {/* <StyleSpacing>
              <StyleHead>{t(`Select_your_industry`)}</StyleHead>
              <StyleWrapper>
                <StyleSelectButtonOut onClick={() => setShowIndustrySearch(true)}>
                  <p style={{ marginLeft: "15px", fontSize: "16px" }}>
                    {getCheckedName(industryData)?.length != 0
                      ? getCheckedName(industryData)?.map((industryLabel: any, index: any) => {
                          return (
                            <StyleIndustryLabel>
                              {`${industryLabel}`}
                              {getCheckedName(industryData)?.length == 1
                                ? ""
                                : index + 1 == getCheckedName(industryData)?.length
                                ? "."
                                : ", "}
                            </StyleIndustryLabel>
                          );
                        })
                      : t("Select")}{" "}
                  </p>
                </StyleSelectButtonOut>
              </StyleWrapper>
              {form?.errors?.industry_ids && <StyleError>{form?.errors?.industry_ids}</StyleError>}
              <Drawer
                padding={"0px"}
                position='right'
                onClose={() => setShowIndustrySearch(false)}
                opened={showIndustrySearch}>
                <IndustrySearch
                  onHide={() => setShowIndustrySearch(false)}
                  getData={(value: any) => setIndustryData(value)}
                  passData={industryData}
                  title={t(`Select_your_industry`)}
                  savedData={savedIndustryIds}
                />
              </Drawer>
            </StyleSpacing> */}
            <StyleSpacing>
              <StyleWrapper>
                <CountryLabel>{t("where_is_your_business_located")}</CountryLabel>
                <StyleSelectButtonOut onClick={() => setShowCountrySearch(true)}>
                  {/* <StyleIcon>
                    <MemoGrayArrow />
                  </StyleIcon> */}
                  <p
                    style={{
                      marginLeft: "15px",
                      fontSize: "16px",
                      color: getCheckedIds(countryData).length >= 1 ? "#000" : "",
                    }}>
                    {getCheckedName(countryData)?.length != 0
                      ? getCheckedName(countryData)?.map((countryLabel: any, index: any) => {
                          return (
                            <StyleIndustryLabel>
                              {`${countryLabel}`}
                              {getCheckedName(countryData)?.length == 1
                                ? ""
                                : index + 1 == getCheckedName(countryData)?.length
                                ? "."
                                : ", "}
                            </StyleIndustryLabel>
                          );
                        })
                      : t("location")}
                  </p>
                </StyleSelectButtonOut>
              </StyleWrapper>
              {form?.errors?.location_ids && <StyleError>{form?.errors?.location_ids}</StyleError>}
              <Drawer
                padding={"0px"}
                position='right'
                onClose={() => setShowCountrySearch(false)}
                opened={showCountrySearch}>
                <CountrySearch
                  onHide={() => setShowCountrySearch(false)}
                  getData={setCountryData}
                  passData={countryData}
                  title={t("Select_country")}
                  savedData={userData.location}
                  singleSelect={false}
                  checkAll={true}
                />
              </Drawer>
            </StyleSpacing>
            {/* <StyleSpacing>
                        <InputField
                            id='location'
                            placeholder={t("location")}
                            placeholderColor={`#6E6E6E`}
                            width='100%'
                            height='auto'
                            {...form.getInputProps('location')}
                        />
                    </StyleSpacing> */}
            <StyleSpacing>
              <InputField
                id='link'
                label={t("Website")}
                labelColor="#6E6E6E"
                placeholder={t("Link_to_website")}
                placeholderColor={`#6E6E6E`}
                width='100%'
                height='auto'
                {...form.getInputProps("link")}
              />
              {/* {(form.values.link) &&
                                (!validURL(form.values.link)) &&
                                <StyleError> Enter valid Link</StyleError>
                            } */}
            </StyleSpacing>
            <StyleSpacing>
              <TextArea
                styles={{
                  label: fontStyles,
                  input: inputStyle,
                }}
                label={t("Description")}
                width='100%'
                height='auto'
                minRows={5}
                {...form.getInputProps("description")}
              />
            </StyleSpacing>
            <StyleSpacing>
              <StyleButtonDesktopView>
                <Button variant={"filled"} type='submit'>
                  {t("Save")}
                </Button>
                <Button
                  variant={"subtle"}
                  color={"#FF5500"}
                  type='button'
                  onClick={() => history.goBack()}>
                  {t("Skip")}
                </Button>
              </StyleButtonDesktopView>
            </StyleSpacing>
          </StyleDesktopDiv1>
        </StyleElement>
        <StyleElement>
          <StyleDesktopDiv2>
            <StyleSpacing style={{ marginBottom: "18px" }}>
              {/* <Group> */}
              <StyleMediaText>{t("Social_Media_Links")}</StyleMediaText>
              <StyleSpacing style={{ width: "100%", marginLeft: "0" }}>
                <InputField
                  placeholderColor={`#6E6E6E`}
                  id='facebook'
                  placeholder={t("Facebook_Link")}
                  width='100%'
                  height='auto'
                  {...form.getInputProps("facebook")}
                />
                {/* {(form.errors.facebook) &&
                                    (!validURL(form.values.facebook)) &&
                                    <StyleError> Enter valid Link</StyleError>
                                } */}
              </StyleSpacing>
              <StyleSpacing style={{ width: "100%", marginLeft: "0" }}>
                <InputField
                  placeholderColor={`#6E6E6E`}
                  id='twitter'
                  placeholder={t("Twitter_Link")}
                  width='100%'
                  height='auto'
                  {...form.getInputProps("twitter")}
                />
                {/* {(form.errors.twitter) &&
                                    (!validURL(form.values.twitter)) &&
                                    <StyleError> Enter valid Link</StyleError>
                                } */}
              </StyleSpacing>
              <StyleSpacing style={{ width: "100%", marginLeft: "0" }}>
                <InputField
                  placeholderColor={`#6E6E6E`}
                  id='linkedin'
                  placeholder={t("Linkedin_Link")}
                  width='100%'
                  height='auto'
                  {...form.getInputProps("linkedin")}
                />
                {/* {(form.errors.linkedin) &&
                                    (!validURL(form.values.linkedin)) &&
                                    <StyleError> Enter valid Link</StyleError>
                                } */}
              </StyleSpacing>
              <StyleSpacing style={{ width: "100%", marginLeft: "0" }}>
                <InputField
                  placeholderColor={`#6E6E6E`}
                  id='instagram'
                  placeholder={t("Instagram_Link")}
                  width='100%'
                  height='auto'
                  {...form.getInputProps("instagram")}
                />
                {/* {(form.errors.instagram) &&
                                    (!validURL(form.values.instagram)) &&
                                    <StyleError> Enter valid Link</StyleError>
                                } */}
              </StyleSpacing>
              {/* </Group> */}
            </StyleSpacing>
            {/* {
              // userData?.is_subscribed &&
              planName?.userData?.plan_name == "Enterprise" && (
                <StyleSpacing style={{ margin: "20px 0 0 0" }}>
                  <StyleCardHead>{t("Invite_2_New_Users_")}</StyleCardHead>
                </StyleSpacing>
              )
            } */}
            {/* {
              // userData?.is_subscribed &&
              form?.values?.userList?.length != 0 &&
                formType == 2 &&
                form?.values?.userList?.map((cardData: any, index: any) => {
                  console.log(`userData`, userData?.priviledge_level);
                  return (
                    <>
                      <CardStyle>
                        <Stack spacing={10}>
                          <CardStyleTextName>{cardData.name}</CardStyleTextName>
                          <CardStyleTextEmail>{cardData.email}</CardStyleTextEmail>
                        </Stack>
                        {userData?.priviledge_level == "manager" ? (
                          <StyleDeleteButton
                            onClick={(e) => {
                              console.log(`Details User`, cardData);
                              e.preventDefault();
                              deleteUserCompany(cardData.id)
                                .then((response) => {
                                  console.log(response);
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                              form.setFieldValue(
                                "userList",
                                form?.values?.userList?.filter(function (
                                  value: any,
                                  deleteIndex: any
                                ) {
                                  return index !== deleteIndex;
                                })
                              );
                            }}>
                            <MemoTrashIcon />
                          </StyleDeleteButton>
                        ) : (
                          ""
                        )}
                      </CardStyle>
                    </>
                  );
                })
            } */}
            {/* {formType == 2 && (
              <ModalInput form={form} TransformGetUserData={TransformGetUserData} />
            )} */}
            <StyleButtonMobileView>
              <Button
                variant={"filled"}
                type='submit'
                // onClick={() => SubmitDataHandler()}
              >
                {t("Save")}
              </Button>
              <Button
                variant={"subtle"}
                color={"#FF5500"}
                type={"button"}
                onClick={() => history.goBack()}>
                {t("Skip")}
              </Button>
            </StyleButtonMobileView>
          </StyleDesktopDiv2>
        </StyleElement>
      </form>
    </ContainerStyle>
  );
};
