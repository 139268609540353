import * as React from "react";

function Bellicon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M10.499 3.412a.768.768 0 01-.769-.768V.768a.768.768 0 011.537 0v1.876a.768.768 0 01-.768.768z"
        fill="#B28964"
      />
      <path
        d="M10.5 0v3.412a.768.768 0 00.768-.768V.768A.768.768 0 0010.5 0z"
        fill="#896746"
      />
      <path
        d="M16.978 16.116H4.023a.769.769 0 01-.627-1.211l1.863-2.637V7.116a5.247 5.247 0 015.242-5.241 5.247 5.247 0 015.241 5.241v5.152l1.864 2.636a.769.769 0 01-.628 1.212z"
        fill="#FDBF00"
      />
      <path
        d="M10.5 20.999a2.17 2.17 0 01-2.168-2.168 2.17 2.17 0 012.167-2.167 2.17 2.17 0 012.168 2.167A2.17 2.17 0 0110.499 21z"
        fill="#B28964"
      />
      <path
        d="M17.605 14.904l-1.864-2.636V7.116A5.247 5.247 0 0010.5 1.875v14.241h6.477a.769.769 0 00.628-1.212z"
        fill="#FF9800"
      />
      <path
        d="M12.667 18.831a2.17 2.17 0 00-2.167-2.167V21a2.17 2.17 0 002.167-2.168z"
        fill="#896746"
      />
    </svg>
  );
}

const MemoBellicon = React.memo(Bellicon);
export default MemoBellicon;
