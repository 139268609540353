import * as React from "react";

function EyeIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_9732_6321)">
        <path
          d="M11.001 4.125C6.418 4.125 2.504 6.975.918 11c1.586 4.024 5.5 6.875 10.083 6.875 4.584 0 8.498-2.85 10.084-6.875-1.586-4.024-5.5-6.875-10.084-6.875zm0 11.458A4.585 4.585 0 016.418 11a4.585 4.585 0 014.583-4.583A4.585 4.585 0 0115.585 11 4.585 4.585 0 0111 15.583zm0-7.333A2.746 2.746 0 008.251 11a2.746 2.746 0 002.75 2.75 2.746 2.746 0 002.75-2.75 2.746 2.746 0 00-2.75-2.75z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_9732_6321">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoEyeIcon = React.memo(EyeIcon);
export default MemoEyeIcon;
