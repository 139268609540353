import { createStyles, keyframes } from '@mantine/core'
import MemoLoading_Icon from 'ui/icons/Loading_Icon'
import React from 'react'
import LoadIcon from 'ui/icons/Loading_Img.png'
import styled from '@emotion/styled';
import { useViewportSize } from '@mantine/hooks';

export const bounce = keyframes({
  '100%': { transform: 'rotate(0deg)' },
  '0%': { transform: 'rotate(360deg)' }
});
const useStyles = createStyles((theme) => ({
  loader: {
    width: "100px",
    height: "auto",
    background: "transparent",
    animation: `${bounce} 1s linear infinite`
  }
}))

const LoaderWrapper= styled.div<{margin?:string|undefined, marginTop?:string|undefined}>`
  position: fixed;
  top:${props => props?.marginTop ? props?.marginTop : '50%'};
  left:38%;
  z-index: 1000;
  @media only screen and (min-width: 1000px) {
    left:${props => props?.margin ? props?.margin : '45%'};
    top:${props => props?.marginTop ? props?.marginTop : '45%'};
  }
`
const StyleLoaderText = styled.div<{textWidth?:string|undefined}>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #123B3D;
    z-index: 20;
    align-self: center;
    width: ${props => props?.textWidth ? props?.textWidth : '170px'};
    margin-left: ${props => props?.textWidth ? '-36%' : '-19%'};
    @media only screen and (min-width: 1000px) {
      //margin-top: 130px;
  }
`;
interface loaderProps{
  margin?:string,
  text?:string,
  marginTop?:string,
  textWidth?:string
}

const Loader:React.FC<loaderProps>=({margin,text, marginTop,textWidth,...props}) =>{
  const { classes } = useStyles()
  //let margin=margin ?? undefined
  return (
    <>
    <LoaderWrapper margin={margin ?? undefined} marginTop={marginTop ?? undefined}>
      <img src={LoadIcon} alt="loader" className={classes.loader} />
      {text &&
        <StyleLoaderText textWidth={textWidth}>{text}</StyleLoaderText>
      }
    </LoaderWrapper>
    </>
  )
}

export default Loader