import React, { useState, useEffect,useContext } from 'react'
import { StyleSelectField } from "ui/inputs/Select";
import MemoChevronDown from "ui/icons/ChevronDown";
import styled from '@emotion/styled';
import { UserContext } from "context/state";

interface SelectProps {
  data: string[],
  dataType: "day" | "month" | "year",
  selectedValue?: string | '',
  onClick?: any,
  form?: any
  showSelect?: any,
  setShowSelect?: any,
  id?:string,
  showSelectedDropdown?: boolean,
  setShowSelectedDropdown?: any,
}

const StyledWrapper = styled.div`
  position: relative;
  /* #scrollbar1::-webkit-scrollbar
{
	display: none;
} */
#year{
  margin-right: 0 !important;
}
.ulclass{
  z-index: 199;
  @media only screen and (min-width: 1000px){
    z-index: 205;
  }
  li{
    padding: 10px 15px 10px 15px !important;
  }
  .chevron-down {
    position: absolute;
    top: 32%;
    margin-right: 10px;
    @media only screen and (min-width: 1000px){
      right: 2px;
    }
  }
}
`;
interface ListProps{
  color?:string,
  width?:string
}
const ListWrapper = styled.div<ListProps>`
  width: 65px;
    height: 54px !important;
    margin-right: 10px;
    border-radius: 12px;
    border: none;
    display: flex;
    border: none;
    border-radius: 12px;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 0px 0px 12px;
    background-color: #ffffff;
    cursor: pointer;
    color: ${props => props?.color ? props?.color :"#6E6E6E"};
    @media only screen and (min-width: 1000px){
      justify-content: space-evenly;
      width: 55px;
    }
`;
const CustomSelect: React.FC<SelectProps> = ({ data, selectedValue, dataType, form,showSelectedDropdown,
  setShowSelectedDropdown, ...props }) => {
  const { setShowSelectDay, setShowSelectMonth, setShowSelectYear } = useContext(UserContext);

  let placeholder = ""
  switch (dataType) {
    case "day": placeholder = "DD";
      break;
    case "month": placeholder = "MM";
      break;
    case "year": placeholder = "YYYY";
      break;
  }


  return (
    <>
      <StyledWrapper >
        <StyleSelectField>
          <label className='dropdown'>
            <ListWrapper
              className='selectbox'
              id={props?.id}
              width={"55px !important"}
              color={selectedValue ? "#123B3D" : "#6E6E6E"}
              onClick={(e) => {
                // e.stopPropagation();
                e.preventDefault();
                setShowSelectedDropdown(!showSelectedDropdown);
                switch (dataType) {
                  case "day":
                    setShowSelectMonth(false);
                    setShowSelectYear(false);
                    break;
                  case "month":
                    setShowSelectDay(false);
                    setShowSelectYear(false);
                    break;
                  case "year":
                    setShowSelectDay(false);
                    setShowSelectMonth(false);
                    break;
                }
              }}
              >
              {selectedValue ? selectedValue : placeholder}
              <MemoChevronDown
                className='chevron-down'
              />
            </ListWrapper>

            <input
              type='checkbox'
              checked={showSelectedDropdown}
              className='dd-input'
              id={selectedValue}
            />

            <ul
              className='dd-menu ulclass'
              style={{
                width: "74px",
                maxHeight: "150px",
                overflow: "auto",
                borderRadius: "10px",
                padding: "none !important"
              }}>
              {data?.length != 0 &&
                data?.map((item: any) => {
                  return (
                    <li
                      style={{
                        zIndex: "200",
                        width: "auto",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                      onClick={() => {
                        form.setFieldValue(dataType, item);
                        switch (dataType) {
                          case "day":
                            setShowSelectDay(false);
                            break;
                          case "month":
                            setShowSelectMonth(false);
                            break;
                          case "year":
                            setShowSelectYear(false);
                            break;
                        }
                      }}
                    >
                      <div> {`${item}`}</div>
                    </li>
                  );
                })}
            </ul>
          </label>
        </StyleSelectField>
        {/* <select
                      placeholder={placeholder}
                      style={{
                        width: "65px",
                        height:"54px",
                        borderRight: "1px solid rgba(0, 0, 0, 0.4)",
                        marginRight: "10px",
                        borderRadius: "12px",
                        border:"none"
                      }}
                      id="scrollbar1"
                      >
                        <option value="" disabled selected>{placeholder}</option>
                      {data?.length != 0 &&
                              data?.map((item: any) => { 
                                  return (
                                    <option
                                      style={{
                                        zIndex: "200",
                                        width: "auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                      }}
                                      onClick={
                                        ()=>form.setFieldValue(dataType,item)
                                    }
                                      >
                                      <div> {`${item}`}</div>
                                    </option>
                                  );
                                })}
                      </select> */}
      </StyledWrapper>
    </>
  );
};

export default CustomSelect;
