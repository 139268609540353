import * as React from "react";

function ProfileIncomplete(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 23" fill="none" {...props}>
      <circle cx={7.443} cy={3.881} r={3.256} fill="#838383" />
      <path
        d="M15 14.985c0-.97-.194-1.93-.57-2.825a7.377 7.377 0 00-1.627-2.396 7.508 7.508 0 00-2.433-1.6 7.603 7.603 0 00-5.74 0 7.51 7.51 0 00-2.433 1.6A7.376 7.376 0 00.57 12.16 7.284 7.284 0 000 14.985h15z"
        fill="#838383"
      />
      <circle cx={7.5} cy={14.5} r={4} fill="#838383" stroke="#fff" />
      <path d="M6 14.105l1.105 1.046L9.255 13" stroke="#fff" />
    </svg>
  );
}

const MemoProfileIncomplete = React.memo(ProfileIncomplete);
export default MemoProfileIncomplete;
