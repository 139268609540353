import React, { ReactNode, useState } from "react";
import { Select as MTSelect } from "@mantine/core";
import MemoHome from "ui/icons/Home";
import { ChevronIcon } from "@mantine/core/lib/components/Select/SelectRightSection/ChevronIcon";
import MemoChevronDown from "ui/icons/ChevronDown";
import styled from "@emotion/styled";

interface SelectProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  placeholder?: string;
  id?: string;
  error?: string | ReactNode;
  description?: string;
  label?: string | ReactNode;
  onChange?: (event: any) => void;
  data: any;
  value?: string | null | undefined;
}

// const StyleSelectField = styled.div`
//   width: 100%;
//     input:-webkit-autofill,
//     input:-webkit-autofill:hover,
//     input:-webkit-autofill:focus,
//     input:-webkit-autofill:active{
//         -webkit-box-shadow: 0 0 0 30px white inset !important;
//     }
//   @media only screen and (min-width: 1000px) {
//   }
//   @media screen and (-webkit-min-device-pixel-ratio:0) {
//     select,
//     textarea,
//     input {
//       font-size: 16px;
//     }
// }
// `

export const StyleSelectField = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #6e6e6e;

  /* Dropdown */

  .dropdown {
    display: inline-block;
    position: relative;
  }

  .dd-button {
    display: flex;
    border: none;
    border-radius: 12px;
    justify-content: left;
    align-items: center;
    padding: 0px 0px 0px 12px;
    background-color: #ffffff;
    cursor: pointer;
    width: 132px;
    height: 39px;
  }

  .dd-button-logo {
    position: absolute;
    top: 32%;
    right: 10px;
  }

  .dd-input {
    display: none;
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    right: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    width: 144px;
  }

  #scrollbar1::-webkit-scrollbar
{
	display: none;
}

  .dd-input + .dd-menu {
    display: none;
  }

  .dd-input:checked + .dd-menu {
    display: block;
  }
  body:focus + .dd-menu {
    display: none;
  }

  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }

  .dd-menu li:hover {
    background-color: #f6f6f6;
  }

  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }

  .dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
`;

const Select: React.FC<SelectProps> = ({
  width,
  height,
  children,
  color,
  backgroundColor,
  fontSize,
  placeholder,
  id,
  description,
  label,
  data,
  ...props
}) => {
  // const styles = {
  //   width: width ?? "100%",
  //   height: height,
  //   fontSize: "16px !important",
  //   fontFamily: "Poppins",
  //   textAlign: 'left',
  // }
  const [selection, setSelection] = useState<any>("EUR");
  return (
    <StyleSelectField>
      <label className='dropdown'>
        <div className='dd-button'>
          {selection}
          <MemoChevronDown className='dd-button-logo' />
        </div>

        <input type='checkbox' className='dd-input' id={selection} onChange={props?.onChange} />

        <ul className='dd-menu' id='dropdown'>
          {data?.length != 0 &&
            data?.map((currencyData: any) => {
              return (
                <li onClick={() => setSelection(currencyData?.value)}>{currencyData?.label}</li>
              );
            })}
        </ul>
      </label>
      {/* <MTSelect
      styles={(theme) => ({
        root: { ...styles, textAlign: 'left' },
        rightSection: { pointerEvents: 'none' },
        label: {
          fontFamily: 'Poppins !important',
          fontSize: '14px',
          fontStyle: 'normal',
          lineHeight: '20px',
          letterSpacing: '0.3px',
          color: '#6E6E6E',
        },
        input: {
          background: '#FFFFFF',
          borderRadius: '12px',
          height: '54px',
          fontFamily: 'Poppins !important',
          '::placeholder': {
            fontFamily: 'Poppins !important',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '130%',
            color: '#6E6E6E',
            opacity: '1',
          }
        }
      })}
      placeholder={placeholder} radius={12} id={id} label={label} description={description} data={data} {...props}
      rightSection={<MemoChevronDown onChange={props?.onChange} />}
    /> */}
    </StyleSelectField>
  );
};
export default Select;
