//import { Form, Button as BSButton, Row, Col } from "react-bootstrap";
import React, { ReactNode, useContext } from "react";
import { Button as MTButton, ButtonVariant, SharedButtonProps } from "@mantine/core";
import MemoBlackChevronLeft from "ui/icons/BlackChevronLeft";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { UserContext } from "context/state";

interface ButtonProps extends SharedButtonProps {
  width?: string;
  height?: string;
  children?: ReactNode;
  variant?: ButtonVariant | undefined;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  onClick?: () => void;
  goBackStatus?: boolean;
  goOnHome?: boolean;
  addPhone?:boolean;
  onButtonClick?:any
}

const StyleBackButton = styled.div`
  position: relative;
  padding: 0;
  width: fit-content;
  margin-top: 30px;
  @media only screen and (min-width: 1000px) {
    //width:210px;
  }
`;

const BackButton: React.FC<ButtonProps> = ({
  width,
  height,
  goBackStatus = false,
  children,
  variant,
  fontSize,
  color,
  goOnHome = false,
  radius,
  fontWeight,
  lineHeight,
  ...props
}) => {
  let history = useHistory();
  const { t } = useTranslation();
  const { role } = useContext(UserContext);
  let styles = {
    width: width ?? "fit-content",
    height: height,
    padding: "0 !important",
    fontSize: fontSize ?? "18px",
    color: color ?? "#123B3D",
    borderRadius: radius ?? "52px",
    border: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
    fontWeight: fontWeight ?? 500,
    fontFamily: "Poppins",
    lineHeight: lineHeight ?? "19px",
    cursor: "pointer",
  };

  switch (variant) {
    case "filled":
      styles = {
        ...styles,
        color: color ?? "#fff",
        border: "none",
        background: "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
        "&:hover": {
          background: "linear-gradient(90deg, #FF3000 6.7%, #FF5500 95.4%)",
        },
        fontWeight: fontWeight ?? 600,
      };
      break;
    case "default":
      styles = {
        ...styles,
        color: color ?? "#000",
        border: "1px solid #000",
        background: "#FFF",
        "&:hover": {
          background: "#FFF",
        },
        fontWeight: fontWeight ?? 600,
      };
      break;
    case "subtle":
      styles = {
        ...styles,
        color: color ?? "#000",
        borderRadius: "none",
        border: "none",
        background: "transparent",
        "&:hover": {
          background: "transparent",
        },
      };
      break;
  }

  return (
    <StyleBackButton>
      <MTButton
        variant='white'
        leftIcon={<MemoBlackChevronLeft />}
        color={color}
        onClick={() => {
          if(props?.addPhone){
            props?.onButtonClick()
          }
          else if (goOnHome) {
            role === "candidate" ? history.push("/home") : history.push("/employer-home");
          } else {
            if (!goBackStatus) history.goBack();
          }
        }}
        styles={(theme) => ({
          root: styles,
          leftIcon: {
            paddingRight: "14px",
            margin: 0,
          },
        })}
        {...props}>
        {t(children as any)}
      </MTButton>
    </StyleBackButton>
  );
};
export default BackButton;
