import { useContext, useEffect, useState } from "react";
import PrivateLayout from "layouts/PrivateLayout";
import styled from "@emotion/styled";
import SettingsComponent from "./SettingsComponent";
import Support from "./Support";
import { UserContext } from "context/state";
import Loader from "ui/feedback/Loader";
import { useTranslation } from "react-i18next";

const SettingSupportWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 85px 0;
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

const StyleDivider = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  margin: 42px 0 0 0;
  padding: 22px 0 0 0;
  @media only screen and (min-width: 1000px) {
    border-top: none;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    margin: 30px 0 0 60px;
    padding: 0 0 0 60px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;
function Settings() {
  const [loader, setLoader] = useState(false);
  const { setActiveTap } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    setActiveTap(4);
  }, []);

  return (
    <PrivateLayout>
      {loader ? (
        <Loader
          marginTop="35%"
          textWidth="340px"
          text={t("please_wait_till_we_delete_your_account_permanently")}
        />
      ) : (
        <SettingSupportWrapper>
          <SettingsComponent loader={loader} setLoader={setLoader} />
          <StyleDivider>
            <Support />
          </StyleDivider>
        </SettingSupportWrapper>
      )}
    </PrivateLayout>
  );
}

export default Settings;
