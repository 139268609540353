import axiosInstance from "services/AxiosInstance"

export const getAllEmployers =()=>{
    return axiosInstance.get('api/employers')
    .then((response)=> response.data)
    .catch((error)=>error)
}

export const addFavouriteList = (setOfIds:{})=>{
    return axiosInstance.post('add-favourite-list',setOfIds)
    .then((response)=> response.data)
    .catch((error)=>error)
}