import React, { ReactNode, useState, useEffect } from 'react'
import { Box, Stack } from '@mantine/core'
import SearchAndSelectAbstraction from 'ui/data-display/SearchAndSelectAbstraction'
import CheckboxGroup from 'ui/inputs/CheckboxGroup'
import { LicenseStore } from 'stores/taxnomies/license.store'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import isNone from 'helpers/isNone'
import { License, Licenses } from 'modules/imports/interfaces/interfaces'


const CheckBoxWrapper = styled(Stack)`
  width: auto;
  height: auto;
  /* overflow-y: auto; */
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
`

interface SelectDrivingLicenseProps {
  children?: ReactNode
  show?: boolean
  onHide?: any
  getData?: any
  passData?: any
  title?: string
  savedData?: any // Pass the Saved Data
  setAddClickedFor?:any
  dontHideDrawer?:boolean
  showNone?:boolean
}

export const SelectDrivingLicense: React.FC<SelectDrivingLicenseProps> = ({
  children,
  passData,
  title,
  savedData = [],
  setAddClickedFor,
  dontHideDrawer,
  showNone=false,
  ...props
}) => {
  //State to reset selected checkbox
  const [resetData, setRestData] = useState(false)

  // Add Button State for Drawer
  const [addElements, setAddElements] = useState<boolean>(false)
  const [noneClicked , setNoneClicked] = useState(false)
  const [noneIndex,setNoneIndex]= useState<number|null>(null)
  const [unCheckNone,setUncheckNone] = useState(false)
  // Update Checkbox on Add Button
  const [updateCheckboxData, setUpdateCheckboxData] = useState<Licenses[]>([]);

  // Function to display saved data
  const savedDataHandler = (data: any) => {
    let flag = false
    savedData?.map((savedID: string) => {
      if (savedID == data.id) flag = true
    })
    return flag
  }

  useEffect(() => {
    let licenseStoreeData = Array.from(LicenseStore.data, ([id, name]) => ({
      id,
      name,
    }))
    let licenseStoreeDataUpdated:Licenses[] = licenseStoreeData.map((vehicleType: any) => {
      
      let licenceData:Licenses = {
        headerName:'',
        data:[]
      }
      licenceData = {
        headerName: vehicleType.name.name,
        data: vehicleType.name.positions.map((val: any) => {
          let updatedData: any = {
            id: val.id,
            name: val.name,
            checked: savedDataHandler(val),
          }
          return updatedData
        }),
      }
      return licenceData
    })
    if(!showNone){
      licenseStoreeDataUpdated=licenseStoreeDataUpdated.filter((item:any)=> !isNone(item.headerName))
    }
    setUpdateCheckboxData(licenseStoreeDataUpdated)
  }, [savedData])
  useEffect(()=>{
    if(updateCheckboxData.length>0 && showNone){
      const indexOfNone=updateCheckboxData.findIndex((item:Licenses)=> isNone(item.headerName))
    setNoneIndex(indexOfNone)
    }
  },[updateCheckboxData])

  const resetHandler = () => {
    let resetlicenseStoreeData = Array.from(LicenseStore.data, ([id, name]) => ({ id, name }))
    let resetlicenseStoreeDataUpdated:Licenses[] = resetlicenseStoreeData.map((vehicleType: any) => {
      let licenceData:Licenses = {
        headerName:'',
        data:[]
      }
      licenceData = {
        headerName: vehicleType?.name?.name,
        data: vehicleType?.name?.positions,
      }
      return licenceData
    })

    let resetlicenseStoreeDataUpdatedFilter = resetlicenseStoreeDataUpdated?.filter(
      (value) => Object.keys(value).length !== 0
    )
    if(!showNone) resetlicenseStoreeDataUpdatedFilter = resetlicenseStoreeDataUpdatedFilter.filter((item:any)=> !isNone(item.headerName))
    setUpdateCheckboxData(resetlicenseStoreeDataUpdatedFilter)
  }

  useEffect(() => {
    if (resetData) resetHandler()
  }, [resetData])

  useEffect(() => {
    
    if(unCheckNone){
      let dataTransform:Licenses[] = updateCheckboxData
      if (noneIndex !=null ) {
        let noneData:License[]= dataTransform[noneIndex].data
        dataTransform[noneIndex] = {
          ...dataTransform[noneIndex],
          data: noneData.map((item:License)=>{
            return{
              ...item,
              checked: isNone(item.name) ?false:item.checked
            }
          }),
        };
      }
      setUpdateCheckboxData(dataTransform);
    }
  }, [unCheckNone])

  useEffect(()=>{
    if(noneClicked){
      let licenseStoreeData = Array.from(LicenseStore.data, ([id, name]) => ({
        id,
        name,
      }))
    
      let licenseStoreeDataUpdated:Licenses[] = licenseStoreeData.map((vehicleType: any) => {
        let licenceData:Licenses = {
          headerName:'',
          data:[]
        }
        licenceData = {
          headerName: vehicleType.name.name,
          data: vehicleType.name.positions.map((val: any) => {
            let updatedData: License = {
              id: val.id,
              name: val.name,
              checked: isNone(val.name)? true: false,
            }
            return updatedData
          }),
        }
        return licenceData
      })
      setUpdateCheckboxData(licenseStoreeDataUpdated)
    }
  },[noneClicked])

  const addElementHandler = () => {
    props?.getData(updateCheckboxData)
    //  setAddElements(false)
  }
  const { t } = useTranslation()
  return (
    <Stack align={'center'}>
      <SearchAndSelectAbstraction
        title={title ? title : 'Title'}
        setShow={props.onHide}
        showSearch={false}
        clearAll={() => true}
        showFilter_Clear={2}
        buttonText={t(`Add`)}
        resetFilter={setRestData} // Reseting the Data
        setAddElements={setAddElements}>
          <CheckBoxWrapper>
        {updateCheckboxData?.map((dataGroup: any, index: any) => {
          return (
            <CheckboxGroup
              checkAll={true}
              data={updateCheckboxData}
              header={dataGroup.headerName}
              getData={setUpdateCheckboxData}
              index={index}
              resetData={resetData}
              setRestData={setRestData}
              addElements={addElements}
              setAddElements={setAddElements}
              multiObject={true}
              showAddButton={updateCheckboxData?.length == index + 1 ? true : false}
              setShow={props.onHide}
              addElementHandler={addElementHandler}
              setAddClickedFor={setAddClickedFor}
              dontHideDrawer={dontHideDrawer}
              noneClicked={noneClicked}
              setNoneClicked={setNoneClicked}
              noneIndex={noneIndex}
              setUncheckNone={setUncheckNone}
              unCheckNone={unCheckNone}
            />
          )
        })}
        </CheckBoxWrapper>
      </SearchAndSelectAbstraction>
    </Stack>
  )
}
