import React, { useState, useContext, useEffect } from "react";
import { ProfileFormScreen } from "./ProfileFormScreen";
import PrivateLayout from "layouts/PrivateLayout";
import { UserContext } from 'context/state'

export default function EditProfile() {
    const contexData = useContext(UserContext);
    const {setActiveTap} = useContext(UserContext)
    useEffect(()=>{
    setActiveTap(4)
    },[])

    const checkDropdown =(e:any)=>{
        if(contexData.showDropdown){
            e.preventDefault()
            contexData.setShowDropdown(false)
        }
    }
    return (
        <PrivateLayout showBottomMenu={false}>
            <div style={{ margin: '10px' }} 
                onClick={(e)=>{
                    checkDropdown(e)
               }}>
                <ProfileFormScreen
                    formType={contexData?.role == "candidate" ? 1 : 2}
                    contexData={contexData}
                />
            </div>
        </PrivateLayout>
    );
}