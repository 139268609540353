import * as React from "react";

function CvIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" {...props}>
      <path
        d="M16.294 6.544c.554.554.83.83.977 1.183.146.352.146.744.146 1.527v7.038c0 1.807 0 2.71-.562 3.272-.561.561-1.465.561-3.272.561h-5.75c-1.807 0-2.71 0-3.272-.561C4 19.002 4 18.099 4 16.292V6.708c0-1.807 0-2.71.561-3.272.562-.561 1.465-.561 3.272-.561h3.204c.784 0 1.175 0 1.527.146.353.146.63.423 1.184.977l2.546 2.546z"
        stroke="#4CAF50"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M7 11.722c0 1.062.696 1.722 1.68 1.722.84 0 1.434-.486 1.608-1.26H9.196c-.09.246-.252.384-.534.384-.366 0-.618-.288-.618-.846s.252-.846.618-.846c.282 0 .45.15.534.384h1.092C10.114 10.462 9.52 10 8.68 10 7.696 10 7 10.666 7 11.722zM10.519 10.048l1.188 3.348h1.266l1.188-3.348h-1.092l-.726 2.388-.726-2.388h-1.098z"
        fill="#4CAF50"
      />
    </svg>
  );
}

const MemoCvIcon = React.memo(CvIcon);
export default MemoCvIcon;
