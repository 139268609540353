import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mantine/core';
import PublicLayout from 'layouts/PublicLayout';
import Loader from 'ui/feedback/Loader'
import { useTranslation } from "react-i18next";

interface VerifiyOTPLoaderProps {
    children?: ReactNode,
    show?: boolean,
    onHide?: () => void
}

const StyleHeader = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #123B3D;
    z-index: 20;
    margin: 20px;
    align-self: center;
`;

const StyleImageContainer = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 1000px) {
         position: relative;
    }
`;

const StyleWrapper = styled.div`
    padding-top: 60%;
 @media only screen and (min-width: 1000px) {
        padding-top: 0;
    }
`;

const StyleLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
     @media only screen and (min-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;


 const VerifiyOTPLoader: React.FC<VerifiyOTPLoaderProps> = (props) => {

    const { t } = useTranslation();

    return (
        <PublicLayout position='center'>
            <Box style={{ width: '100%', overflow: 'hidden' }}>
                <StyleWrapper>
                    <StyleImageContainer>
                        <StyleLoading>
                            <Loader />
                        </StyleLoading>
                        <StyleHeader>{t("Please_Wait_Verifying_OTP")}</StyleHeader>
                    </StyleImageContainer>
                </StyleWrapper>
            </Box>
        </PublicLayout>
    )
}
export default VerifiyOTPLoader