import * as React from "react";

function SaveSettingIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
        fill="#FF3000"
      />
      <path
        d="M13 10.5v13.358a.5.5 0 00.915.279l3.67-5.467a.5.5 0 01.83 0l3.67 5.467a.5.5 0 00.915-.279V10.5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5z"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSaveSettingIcon = React.memo(SaveSettingIcon);
export default MemoSaveSettingIcon;
