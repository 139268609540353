import * as React from "react";

function BlackChevronLeft(props) {
  return (
    <svg width="10px" height="21px" viewBox="0 0 12 19" fill="none" {...props}>
      <path
        d="M10 17L2 9.5 10 2"
        stroke="#123B3D"
        strokeWidth={'3px'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoBlackChevronLeft = React.memo(BlackChevronLeft);
export default MemoBlackChevronLeft;
