import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import Home from "ui/icons/Home";
import MemoMessage from "ui/icons/Message";
import MemoLike from "ui/icons/Like";
import MemoSetting from "ui/icons/Setting";
import JobParkLogoTransparent from "assets/images/JobPark-logo.png";
import { Grid } from "@mantine/core";
import { useHistory } from "react-router-dom";
import { UserContext } from "context/state";
import { useTranslation } from "react-i18next";
import getPathForHome from "helpers/GetPathForHome";

const MenuText = styled.div`
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #6e6e6e;
  @media only screen and (min-width: 1000px) {
    font-size: 14px;
    color: #6e6e6e;
  }
`;
const MenuBarDesktopWrapper = styled.div`
  margin-top: 42px;
  @media only screen and (min-width: 1000px) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const MenuBarDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
    background: #ffffff;
    border-radius: 20px;
    width: 155px;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;
const Wrapper = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  display: block;
  width: 80%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyleLogoDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: flex;
    position: relative;
    border-radius: 0px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
  }
`;

const StyleLogoImage = styled.img`
  width: 106px;
  height: 28px;
  @media only screen and (min-width: 1000px) {
    width: 112px;
    height: 30px;
  }
`;

interface DesktopLayoutProps {
  backgroundImg?: any;
  position?: "top" | "center";
  setActiveTap?: any;
  activeTap?: number;
}

const DesktopLayout: React.FC<DesktopLayoutProps> = (props: any) => {
  const history = useHistory();
  const { role, activeTap, setActiveTap, numberOfUnreadMessages,isAdmin } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.pathname.includes("/employer/message")) {
      setActiveTap(2);
    }
  }, []);

  return (
    <>
      <StyleLogoDesktop>
        <StyleLogoImage
          alt="Jobpark logo"
          style={{ cursor: "pointer" }}
          src={JobParkLogoTransparent}
          onClick={() => {
            history.push(getPathForHome(role,isAdmin))
          }}
        />
      </StyleLogoDesktop>
      <MenuBarDesktopWrapper>
        <MenuBarDesktop>
          <Wrapper
            onClick={() => {
              setActiveTap(1);
              history.push(getPathForHome(role,isAdmin))
            }}>
            <Grid columns={10} justify='center' align='center'>
              <Grid.Col span={2}>
                <Home width='20px' height='20px' color={activeTap == 1 ? "#FF3000" : ""} />
              </Grid.Col>
              <Grid.Col span={8}>
                <MenuText style={{ color: activeTap == 1 ? "#FF3000" : "" }}>{t("Home")}</MenuText>
              </Grid.Col>
            </Grid>
          </Wrapper>
          {role === "employer" && !isAdmin && (
            <Wrapper
              onClick={() => {
                setActiveTap(3);
                history.push("/favourites");
              }}>
              <Grid columns={10} justify='center' align='center'>
                <Grid.Col span={2}>
                  <MemoLike width='20px' height='20px' color={activeTap == 3 ? "#FF3000" : ""} />
                </Grid.Col>
                <Grid.Col span={8}>
                  <MenuText style={{ color: activeTap == 3 ? "#FF3000" : "" }}>
                    {t("Favourites")}
                  </MenuText>
                </Grid.Col>
              </Grid>
            </Wrapper>
          )}
          <Wrapper
            onClick={() => {
              setActiveTap(4);
              history.push("/setting");
            }}>
            <Grid columns={10} justify='center' align='center'>
              <Grid.Col span={2}>
                <MemoSetting width='20px' height='20px' color={activeTap == 4 ? "#FF3000" : ""} />
              </Grid.Col>
              <Grid.Col span={8}>
                <MenuText style={{ color: activeTap == 4 ? "#FF3000" : "" }}>
                  {t("Settings")}
                </MenuText>
              </Grid.Col>
            </Grid>
          </Wrapper>
        </MenuBarDesktop>
      </MenuBarDesktopWrapper>
    </>
  );
};
export default DesktopLayout;
