import React from "react";
import { Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import Button from "ui/buttons/Button";
import InputField from "ui/inputs/Inputfield";
import PublicLayout from "layouts/PublicLayout";
import BackButton from "ui/buttons/BackButton";
import MemoEmail_icon from "ui/icons/Email_icon";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { forgotPassword } from "services/user/User";

const Heading = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #123b3d;
  @media only screen and (min-width: 1000px) {
  }
`;
const FormContainer = styled.div`
  /* width: 100%; */
  height: 80vh;
  @media only screen and (min-width: 1000px) {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyleWrapper = styled.div`
  margin: 5px;
  @media only screen and (min-width: 1000px) {
    min-width: 335px;
    max-width: 335px;
  }
`;
const StyleButton = styled.div`
  position: fixed;
  bottom: 20px;
  left: auto;
  width: 90%;
  margin-top: 15px;
  @media only screen and (min-width: 1000px) {
    position: relative;
    bottom: auto;
    width: 100%;
    top: 20px;
  }
`;
const StyleInputField = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  margin-top: 15px;
  @media only screen and (min-width: 1000px) {
  }
`;

export default function ForgotPassword() {
  let history = useHistory();
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      email: "",
    },
    schema: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(t("Must_be_a_valid_email_address"))
          .max(100, t("Email_must_be_less_than_100_characters"))
          .required(t("Please_enter_valid_email")),
      })
    ),
  });

  return (
    <>
      <PublicLayout>
        <FormContainer>
          <StyleWrapper>
            <BackButton>{t("Back")}</BackButton>
            <form
              onSubmit={form.onSubmit((values) => {
                forgotPassword(values)
                  .then((response) => console.log(response.data))
                  .catch((error) => console.log(error));
                history.push("/forgot-password/check-email");
              })}>
              <Stack spacing={0} style={{ marginTop: "25px" }}>
                <Heading>{t("Forgot_Password")}</Heading>
                <StyleInputField>
                  <InputField
                    id='email'
                    placeholder={t("Email_Address")}
                    icon={<MemoEmail_icon />}
                    {...form.getInputProps("email")}
                  />
                </StyleInputField>
              </Stack>
              <StyleButton>
                <Button
                  type='submit'
                  fontWeight={700}
                  fontSize='18px'
                  lineHeight='110%'
                  width='100%'
                  variant='filled'>
                  {t("Send_Email")}
                </Button>
              </StyleButton>
            </form>
          </StyleWrapper>
        </FormContainer>
      </PublicLayout>
    </>
  );
}
