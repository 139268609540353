import { Modal } from "../../../ui/modals/Modal";
import { useContext, useEffect, useState } from "react";
import { SelectedSearchContext, UserContext } from "context/state";
import styled from "styled-components";
import Button from "ui/buttons/Button";
import { useTranslation } from "react-i18next";
import MemoInfo from "ui/icons/Info";
import InputField from "ui/inputs/Inputfield";

const ModalText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #123b3d;
  margin: 16px 0 0 0;
  text-align: center;
  min-height: 53px;
  @media only screen and (min-width: 1000px) {
  }
`;
const ModalItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 32px 0 32px;
  justify-content: space-between;
  @media only screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
  }
`;

const ModalButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 13px 22px 0 22px;
  @media only screen and (min-width: 1000px) {
  }
`;
const ModalTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #123b3d;
  margin-bottom: 8px;
`;
const ModelIcon = styled.div``;

interface CustomProps {
  showUpdateMessageModal?: boolean;
  setUpdateMessageModal: (value: boolean) => void;
  showFilter: (value: boolean) => void;
  filterUpdate: () => void;
}

const UpdateFilterModal: React.FC<CustomProps> = ({
  showUpdateMessageModal = false,
  setUpdateMessageModal,
  showFilter,
  filterUpdate,
  ...props
}) => {
  const { t } = useTranslation();
  const { filterData, setFilterData, savedAge, setSavedAge } =
    useContext(UserContext);
  const { selectedCard, setSelectedCard } = useContext(SelectedSearchContext);
  const [name, setName] = useState<string>(selectedCard.searchTitle);
  const [isEdited, setEdited] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [Message, setMessage] = useState<string>("");

  return (
    <>
      {/* update message confirmation modal */}
      <Modal
        show={showUpdateMessageModal}
        onHide={setUpdateMessageModal}
        showOpenButton={false}
        background="#FFFFFF"
        openChildModel={showMessage}
      >
        <ModalItemWrapper>
          <ModelIcon>
            <MemoInfo width="68px" height="86px" />
          </ModelIcon>
          <ModalTitle>{t("update_the_filter")}</ModalTitle>
          <InputField
            width="100%"
            height="auto"
            id="searchName"
            radius={10}
            value={selectedCard.searchTitle}
            error={
              selectedCard.searchTitle == ""
                ? t("search_name_cannot_be_empty")
                : null || selectedCard.searchTitle.length >= 61
                ? t("name_should_be_less_than_60_characters")
                : null
            }
            onChange={(event) => {
              setSelectedCard({
                ...selectedCard,
                searchTitle: event.currentTarget.value,
              });
              setEdited(true);
            }}
            styles={{
              input: {
                border: "1px solid #C3C3C3",
                color: "#6E6E6E !important",
              },
            }}
          />
          <ModalText>
            {t(
              "saved_filters_plus_additional_filters_will_be_added_to_the_existing_filter"
            )}
          </ModalText>
        </ModalItemWrapper>
        <ModalButton>
          <Button
            variant="filled"
            children={t("Yes")}
            width="100%"
            height="54px"
            disabled={
              selectedCard.searchTitle == "" ||
              selectedCard.searchTitle.length >= 61
                ? true
                : false
            }
            onClick={() => {
              filterUpdate();
              setUpdateMessageModal(false);
              showFilter(false);
            }}
          />
          <Button
            width="100%"
            height="54px"
            background="#123B3D"
            children={t("No")}
            onClick={() => {
              setUpdateMessageModal(false);
              showFilter(false);
            }}
          />
        </ModalButton>
      </Modal>
    </>
  );
};
export default UpdateFilterModal;
