import React, { ReactNode, useState } from "react";
import { Modal as MTModal, Button, Group } from "@mantine/core";

interface ModalProps {
  children?: ReactNode;
  show?: boolean;
  showClose?: boolean;
  onHide?: any;
  showOpenButton?: boolean;
  title?: string;
  background?:string;
  openChildModel?:boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  show = false,
  onHide,
  showOpenButton = true,
  title,
  showClose = false,
  background,
  openChildModel,
  ...props
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <MTModal
        opened={show}
        onClose={() => onHide(false)}
        title={title}
        centered
        {...props}
        overlayOpacity={0.55}
        overlayBlur={5}
        styles={{
          modal: {
            display: openChildModel ?  "none" : "",
            borderRadius: "20px",
            height: "auto",
            width: "auto",
            maxWidth: "339px",
            minWidth: "300px",
            margin: "10px",
            background: background ?   background :"#F7F7F7",
          },
          close: { display: showClose ? "" : "none" },
          title: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            textAlign: "right",
            letterSpacing: "0.3px",
            color: "#123B3D",
          },
        }}
      >
        {children}
      </MTModal>

      {showOpenButton && (
        <Group position="center">
          <Button onClick={() => onHide(true)}>Open Modal</Button>
        </Group>
      )}
    </>
  );
};
