import React, { ReactNode, useState } from "react";
import {
  Radio as MTRadio,
  RadioGroup,
  RadioGroupProps,
} from "@mantine/core";

interface CustomRadioGroupProps {
  name:string,
  options:any,
  value?:string,
  onChange?:(event: any) => (void);
}

const Radio: React.FC<CustomRadioGroupProps> = ({
  name,
  options,
  value,
  onChange,
  ...props
}) => {

  return (
    <RadioGroup
    size="xs"
    value={value}
    color={"red"}
    onChange={onChange}
    name={name}
    styles={{
      radio:{cursor:"pointer"},
      label: { 
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      color:"#123B3D",
     },
    }}
    {...props}
    > 
    {options.map((option: any) => {
      return (
        <MTRadio key={option.value} value={option.value} label={option.key} />
      );
    })}
  </RadioGroup>
  );
};

export default Radio;
