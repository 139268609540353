import { Drawer as MTDrawer, DrawerProps, DrawerPosition } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

interface CustomDrawerProps extends DrawerProps {
  position: DrawerPosition | undefined
  padding?: any,
  width?:string
}

const Drawer: React.FC<CustomDrawerProps> = ({ ...props }) => {

  const {width} = useViewportSize()

  return (
    <>
      <MTDrawer
        {...props}
        padding={props?.padding ? props.padding : "xl"}
        size="lg"
        withCloseButton={false}
        overlayBlur={5}
        overlayOpacity={0.55}
        styles={{
          drawer: {
            backgroundColor: '#F7F7F7',
            overflow: 'auto',
            width: width <1000 ? "100vw" : "430px"
            // width:`${width!='' ? width: 'auto'} !important`,
            // maxWidth:'416px !important'
          }
        }}
      >
        {props.children}
      </MTDrawer>
    </>
  );
}

export default Drawer