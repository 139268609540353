import styled from "@emotion/styled";
import { UserContext } from "context/state";
import PrivateLayout from "layouts/PrivateLayout";
import React, { useContext, useEffect, useState } from "react";
import { addFavourite, chatStarted, getFavourites } from "services/employer/employer";
import Cards from "ui/data-display/Cards";
import HomeScreenComponent from "../helpers/HomeFavouriteComponent";
import Profile from "assets/images/Profile.png";
import Loader from "ui/feedback/Loader";
import Drawer from "ui/data-display/Drawer";
import UserProfileDisplay from "./user-company-profile/UserProfileDisplay";
import { getUserDetails } from "services/user/User";
import NoResultFound from "helpers/NoResultFound";
import { ChatFavModal } from "helpers/chat-fav-modal/modal";
import { Redirect, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { ErrorModal } from "ui/modals/ErrorModal";
import { useTranslation } from "react-i18next";
import { Pagination } from '@mantine/core';
import { useViewportSize } from "@mantine/hooks";
import getPageLimit from "helpers/GetPageLimit";
const ProfileWrapper = styled.div`
  overflow-y: auto;
  height: 71vh;
  @media only screen and (min-width: 1000px) {
    //height: auto !important;
    margin-top: 18px;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    gap: 15px;
  }
`;
const NoResultFoundWrapper = styled.div`
  width: 334px;
  height: 85%;
  padding: 5% 5% 5% 5%;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    height: 100%;
    padding: 0;
    max-width: 100%;
  }
`;
let sizeLimit=10;
interface favoriteCandidate{
  about:string,
  date_of_birth:string,
  favourite:boolean,
  first_name:string
  last_name:string
  location:string
  name:string
  profile_pic: string
  uid: string,
  chat:boolean
}
function FavouritesScreen() {
  const { role, userData, setActiveTap, setRedirectToSubscription } = useContext(UserContext);
  const [favouriteList, setFavouriteList] = useState<favoriteCandidate[]>([]);
  const [loading, setLoading] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<{
    [key: string]: any;
  }>({});
  const [showModaBox, setShowModalBox] = useState(userData?.is_subscribed ? false : true);
  const history = useHistory();
  const [searchString, setSearchString] = useState(undefined);
  const [moreProfiles, setMoreProfiles] = useState(true);
  const contextData = useContext(UserContext);
  const [showError, setShowError] = useState(false);
  const [errortext, setErrorText] = useState("");
  const [activePage, setPage] = useState(1);
  const { t } = useTranslation();
  const { height, width } = useViewportSize();
  
  const getProfile = (id: any) => {
    // console.log("clicked id", id);
    getUserDetails(id)
      .then((response) => {
        // console.log(response);
        setSelectedProfile(response?.data);
      })
      .catch((err) => console.log(err));
    setShowProfile(true);
  };

  const setFavourite = (id: any, currentFavourite?: boolean) => {
    let data = {
      candidate_id: id,
      favourite: currentFavourite === true ? "0" : "1",
    };
    const initialState = favouriteList
    const filterItems=favouriteList.filter((item:favoriteCandidate) => item?.uid != id)
    setFavouriteList(filterItems)
    if (selectedProfile?.user_id) {
      setShowProfile(false)
    }
    try{
      addFavourite(data)
    }
    catch (error){
      setFavouriteList(initialState)
    }
  };
  const chatInitiated = (id: any) => {
    // if (
    //   contextData?.userData?.is_subscribed &&
    //   !contextData?.userData?.profile_status &&
    //   contextData?.userData?.role === "employer"
    // ) {
    //   setShowProfile(false);
    //   setErrorText(t("Please_complete_your_profile"));
    //   setShowError(true);
    // } else {
    //   let data = {
    //     candidate_id: id,
    //   };
    //   chatStarted(data).then((res) => {
    //     history.push("/employer/message/" + id);
    //     // console.log(res);
    //   });
    // }
    let data = {
      candidate_id: id,
    };
    chatStarted(data).then((res) => {
      history.push("/employer/message/" + id);
      // console.log(res);
    })
    .catch((error)=>console.log(error))
  };

  const getList = (fetchMore?: boolean) => {
    const currentLimit = getPageLimit()
    if(fetchMore){
      getFavourites(searchString,activePage,currentLimit).then((res) => {
        if (res?.current_page<res?.total_pages) {
          //page = Math.floor(res?.total / 10);
          //setPage(page);
          let list=favouriteList.concat(res?.data)
          setFavouriteList(list)
          setMoreProfiles(true)
          setPage(activePage+1)
        }else{
          let list=favouriteList.concat(res?.data)
          setFavouriteList(list)
          setMoreProfiles(res?.current_page>=res?.total_pages?false:true)
        }
        setLoading(false);
      });
    }
    else{
      getFavourites(searchString,1,currentLimit).then((res) => {
        setFavouriteList(res?.data);
        setPage(2)
        setMoreProfiles(res?.current_page>=res?.total_pages?false:true)
        setLoading(false);
      });
    }
    
  };

  // const handleButtonClick = () => {
  //   // console.log("Button clicked");
  //   let showPlans = {
  //     show: true,
  //   };
  //   //setRedirect(true)
  //   setRedirectToSubscription(true);
  //   history.push({
  //     pathname: "/employer-home",
  //     search: "?query=abc",
  //     state: { detail: "some_value" },
  //   });
  //   setShowModalBox(false);
  // };

  useEffect(() => {
    setActiveTap(3);
    getList();
  }, []);
  useEffect(() => {
    if (!showProfile) {
      setSelectedProfile({});
    }
  }, [showProfile]);

  useEffect(() => {
    if (role === "employer" && searchString != undefined) getList();
  }, [searchString]);
  if (loading) return <Loader />;
  // if (!userData?.is_subscribed) {
  //   return (
  //     <PrivateLayout>
  //       <ChatFavModal
  //         type={2}
  //         show={showModaBox}
  //         onHide={() => {
  //           setShowModalBox(false);
  //           history.push("/employer-home");
  //         }}
  //         buttonOnClick={() => {
  //           handleButtonClick();
  //         }}
  //       />
  //     </PrivateLayout>
  //   );
  // }
  return (
    <PrivateLayout isFavouriteScreen={true} typeOfLayout='homeFavourite'>
      <ErrorModal
        opened={showError}
        message={errortext}
        onClose={() => setShowError(false)}
        callFunction={() => history.push("/edit-profile")}
      />
      <HomeScreenComponent
        isFavouriteScreen={true}
        isMessageScreen={false}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <ProfileWrapper
        id="scrollableDiv">
        {favouriteList?.length == 0 || favouriteList === undefined ? (
          <NoResultFoundWrapper>
            <NoResultFound usedIn='favourites' />
          </NoResultFoundWrapper>
        ) : (
          <InfiniteScroll
            dataLength={favouriteList?.length}
            next={() => (role === "employer" ? getList(true) : "")}
            hasMore={moreProfiles}
            loader={<></>}
            scrollableTarget='scrollableDiv'
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>{t("Yay! You have seen it all")}</b>
            //   </p>
            // }
            >
            <CardsWrapper>
              {favouriteList?.map((item: any, index) => {
                return (
                  <Cards
                    key={`favourite${index}`}
                    cvstatus={item.cv_status==true}
                    favourite={item?.favourite}
                    onClick={() => getProfile(item?.uid)}
                    chatInitiated={() => chatInitiated(item?.uid)}
                    setFavourite={() => setFavourite(item?.uid, item?.favourite)}
                    profileImageURL={item?.profile_pic != "" ? item?.profile_pic : Profile}
                    profileName={item?.first_name}
                    location={item?.location}
                    time={item?.date_of_birth}
                    description={item?.about}
                    type={1}></Cards>
                );
              })}
            </CardsWrapper>
            </InfiniteScroll>
        )}
      </ProfileWrapper>
      <Drawer
        position='right'
        padding={"0"}
        onClose={() => setShowProfile(false)}
        opened={showProfile}>
        {role !== "candidate" ? (
          <UserProfileDisplay
            selectedUser={selectedProfile}
            onHideModel={setShowProfile}
            type={2}
            chatInitiated={() => chatInitiated(selectedProfile?.user_id)}
            setFavourite={() =>
              setFavourite(selectedProfile?.user_id, selectedProfile?.favourite_status)
            }
          />
        ) : (
          <></>
        )}
      </Drawer>
    </PrivateLayout>
  );
}
export default FavouritesScreen;