import { Box } from "@mantine/core";
import React from "react";
import Button from "ui/buttons/Button";
import Password from "ui/inputs/Password";
import { useForm } from "@mantine/form";
import InputField from "ui/inputs/Inputfield";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      companyname: "",
    },

    validate: (values) => ({
      name: values.name.length > 2 ? null : "Name should be greater than 2 characters",
      email: /^\S+@\S+$/.test(values.email) ? null : t("Invalid_email_address"),
      phone: values.name.length > 10 ? null : "Invalid number",
      companyname: values.name.length > 2 ? null : "Name should be greater than 2 characters",
    }),
  });
  return (
    <>
      {/* {console.log(form.values)} */}
      <Box sx={{ maxWidth: 340 }} mx='auto'>
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
          <InputField id='name' placeholder='Name' {...form.getInputProps("name")} />
          <InputField id='email' placeholder='Email' {...form.getInputProps("email")} />
          <InputField id='phone' placeholder='Phone' {...form.getInputProps("phone")} />
          <InputField
            id='companyname'
            placeholder='Company name'
            {...form.getInputProps("companyname")}
          />
          <Button variant='filled' type='submit' style={{ marginTop: "293px" }} width='100%'>
            Submit
          </Button>
        </form>
      </Box>
    </>
  );
}

export default ContactUs;
